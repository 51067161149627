import { Cmd_none, Cmd_batch, Cmd_OfPromise_either } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { fetchWithDecoder, addPrefix } from "../CommunicationV2.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { contains, filter, map, ofArray, singleton } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { list_type, string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { ValidationOnGetRequest } from "../ErrorHandling.js";
import { State, Msg } from "./Types.js";
import { addPrefix as addPrefix_1, fetchWithDecoder as fetchWithDecoder_1 } from "../Communication.js";
import { UserId, RemoteValue$1, PostResponse$1_$reflection, EmptyResponse_$reflection } from "../Shared/Shared.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { User as User_2, Requests_EditUserFormDto_$reflection, Requests_EditUserFormDto, Requests_UpdateUserLanguageDto, Helper_languageFromString, User_$reflection, Requests_UpdateUserLanguageDto_$reflection } from "../Shared/User.js";
import { Cmd_OfPromise_either as Cmd_OfPromise_either_1 } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { unwrapUserId } from "../Shared/Helper.js";
import { Storage_$reflection } from "../Shared/Storage.js";
import { Cmd_errorToast, Cmd_successToast } from "../Components/Toast.js";
import { stringHash } from "../fable_modules/fable-library-js.4.19.2/Util.js";

export const resetPasswordCmd = Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
    let url_2;
    return ((url_2 = addPrefix(url), (refreshOn) => fetchWithDecoder(url_2, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
    }, refreshOn)))(true).then((_arg) => {
        const result_1 = _arg;
        return (result_1.tag === 1) ? (Promise.resolve((() => {
            throw new ValidationOnGetRequest(result_1.fields[0]);
        })())) : (Promise.resolve(result_1.fields[0]));
    });
})), "/api/users/reset-password", (Item) => (new Msg(2, [Item])), (Item_1) => (new Msg(9, [Item_1])));

export function updateUserLanguageCmd(dto) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder_1(0, addPrefix_1(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(EmptyResponse_$reflection())));
    })))), ["/api/receivers/users/selected-language", toString(0, Auto_generateBoxedEncoder_437914C6(Requests_UpdateUserLanguageDto_$reflection(), undefined, undefined, undefined)(dto))], (Item) => (new Msg(3, [Item])), (Item_1) => (new Msg(9, [Item_1])));
}

export function init(tokenDataDto) {
    return [new State(false, new RemoteValue$1(1, []), new RemoteValue$1(1, [])), Cmd_batch(ofArray([Cmd_OfPromise_either_1((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder_1(0, addPrefix_1(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), User_$reflection()));
    })))), `/api/receivers/users/${unwrapUserId(new UserId(tokenDataDto.UserId))}`, (Item) => (new Msg(5, [Item])), (Item_1) => (new Msg(9, [Item_1]))), Cmd_OfPromise_either_1((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder_1(0, addPrefix_1(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(Storage_$reflection())));
    })))), "/api/storages", (Item_2) => (new Msg(8, [Item_2])), (Item_3) => (new Msg(9, [Item_3])))]))];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [state, updateUserLanguageCmd(new Requests_UpdateUserLanguageDto(Helper_languageFromString(msg.fields[0])))];
        case 0:
            return [state, resetPasswordCmd];
        case 2:
            return [state, Cmd_successToast("profile.send_email")];
        case 3:
            return [state, Cmd_none()];
        case 5:
            return [new State(state.Message, new RemoteValue$1(0, [msg.fields[0]]), state.Storages), Cmd_none()];
        case 6: {
            const matchValue_1 = state.User;
            const matchValue_2 = state.Storages;
            let matchResult, storages, user_2;
            if (matchValue_1.tag === 1) {
                if (matchValue_2.tag === 1) {
                    matchResult = 0;
                }
                else {
                    matchResult = 0;
                }
            }
            else if (matchValue_2.tag === 0) {
                matchResult = 1;
                storages = matchValue_2.fields[0];
                user_2 = matchValue_1.fields[0];
            }
            else {
                matchResult = 0;
            }
            switch (matchResult) {
                case 0:
                    return [state, Cmd_none()];
                default: {
                    const dto_1 = new Requests_EditUserFormDto(user_2.Name, user_2.PersonnelNumber, user_2.Department, user_2.ImageUrl, user_2.Role, map((s_1) => s_1.Id, filter((s) => contains(user_2.Id, s.AssignedUserIds, {
                        Equals: (x, y) => (x === y),
                        GetHashCode: stringHash,
                    }), storages)), user_2.AllowUserToSubcontractorTransfer, user_2.ActivatedEmailNotification, user_2.CustomProperties);
                    return [state, Cmd_OfPromise_either_1((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        let url_2;
                        return ((url_2 = addPrefix(tupledArg[0]), (refreshOn) => fetchWithDecoder(url_2, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
                            const pr = response.arrayBuffer();
                            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
                        }, refreshOn)))(true);
                    })), [`/api/receivers/users/${user_2.Id}`, toString(0, Auto_generateBoxedEncoder_437914C6(Requests_EditUserFormDto_$reflection(), undefined, undefined, undefined)(dto_1))], (Item) => (new Msg(7, [Item])), (Item_1) => (new Msg(9, [Item_1])))];
                }
            }
        }
        case 8:
            return [new State(state.Message, state.User, new RemoteValue$1(0, [msg.fields[0]])), Cmd_none()];
        case 7: {
            const matchValue_4 = state.User;
            if (matchValue_4.tag === 1) {
                return [state, Cmd_none()];
            }
            else {
                return [state, Cmd_OfPromise_either_1((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder_1(0, addPrefix_1(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
                    const pr_1 = response_2.arrayBuffer();
                    return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), User_$reflection()));
                })))), `/api/receivers/users/${unwrapUserId(new UserId(matchValue_4.fields[0].Id))}`, (Item_2) => (new Msg(5, [Item_2])), (Item_3) => (new Msg(9, [Item_3])))];
            }
        }
        case 9:
            return [new State(true, state.User, state.Storages), Cmd_errorToast("profile.fail_reset_password")];
        default: {
            const matchValue = state.User;
            if (matchValue.tag === 0) {
                const user = matchValue.fields[0];
                return [new State(state.Message, new RemoteValue$1(0, [new User_2(user.Id, user.ImageUrl, user.Name, user.PersonnelNumber, user.Events, user.QRCodeIds, user.LoginInformation, user.LocationId, user.Role, user.IsDeleted, user.NotificationToken, user.ShownWelcomeTourSteps, user.AllowUserToSubcontractorTransfer, user.Department, user.Language, msg.fields[0], user.UserConfiguration, user.IsArchived, user.CustomProperties)]), state.Storages), Cmd_none()];
            }
            else {
                return [state, Cmd_none()];
            }
        }
    }
}

