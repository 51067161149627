import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, lambda_type, unit_type, union_type, string_type, option_type, class_type, bool_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { SetId_$reflection } from "../../Shared/Shared.js";
import { ToolConfirmReminderDto, ToolConfirmReminderDto_$reflection } from "../../Shared/Tool.js";
import { DataTransfer_SetReminder_$reflection } from "../../Shared/Set.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { now, timeOfDay, add } from "../../fable_modules/fable-library-js.4.19.2/Date.js";
import { confirmReminderCmd } from "../../Requests/Set.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";
import { singleton as singleton_1, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { InformationAlert } from "../../Components/Alert.js";
import { Interop_reactApi } from "../../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { empty, singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { TextValue } from "../../Components/Text.js";
import { Checkbox } from "../../Components/Checkbox.js";
import { defaultOf } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { DatePicker } from "../../Components/DatePicker.js";
import { unwrap } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { TextArea } from "../../Components/TextArea.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Dialog } from "../../Components/Dialog.js";
import { TextButton } from "../../Components/Button.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetLastInspectionDate", "SetDate", "SetCreateNewDate", "SetConfirmationComment", "ConfirmToolReminder", "ToolReminderConfirmed", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Sets.Dialog.ConfirmReminderDialog.Msg", [], Msg, () => [[["Item", bool_type]], [["Item", option_type(class_type("System.DateTime"))]], [["Item", bool_type]], [["Item", string_type]], [], [["Item", string_type]], [["Item", class_type("System.Exception")]]]);
}

export class State extends Record {
    constructor(SetId, IsLoading, SuccessCallback, Dto, OnClose, IsNormalReminder, IsTitleInvalid, Reminder) {
        super();
        this.SetId = SetId;
        this.IsLoading = IsLoading;
        this.SuccessCallback = SuccessCallback;
        this.Dto = Dto;
        this.OnClose = OnClose;
        this.IsNormalReminder = IsNormalReminder;
        this.IsTitleInvalid = IsTitleInvalid;
        this.Reminder = Reminder;
    }
}

export function State_$reflection() {
    return record_type("Sets.Dialog.ConfirmReminderDialog.State", [], State, () => [["SetId", SetId_$reflection()], ["IsLoading", bool_type], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["Dto", ToolConfirmReminderDto_$reflection()], ["OnClose", lambda_type(unit_type, unit_type)], ["IsNormalReminder", bool_type], ["IsTitleInvalid", bool_type], ["Reminder", DataTransfer_SetReminder_$reflection()]]);
}

function init(props) {
    return [new State(props.SetId, false, props.SuccessCallback, new ToolConfirmReminderDto(!props.Reminder.IsConfirmed, props.Reminder.Id, props.Reminder.Interval != null, false, undefined, ""), props.OnClose, props.Reminder.Interval == null, false, props.Reminder), Cmd_none()];
}

function update(msg, state) {
    let bind$0040_1, bind$0040_2, bind$0040_3, bind$0040;
    switch (msg.tag) {
        case 1: {
            const value_1 = msg.fields[0];
            if (value_1 == null) {
                return [state, Cmd_none()];
            }
            else {
                return [new State(state.SetId, state.IsLoading, state.SuccessCallback, (bind$0040_1 = state.Dto, new ToolConfirmReminderDto(bind$0040_1.IsConfirmed, bind$0040_1.ReminderId, bind$0040_1.CreateNewDate, bind$0040_1.SetLastInspectionDate, add(value_1, timeOfDay(now())), bind$0040_1.ConfirmationComment)), state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.Reminder), Cmd_none()];
            }
        }
        case 2:
            return [new State(state.SetId, state.IsLoading, state.SuccessCallback, (bind$0040_2 = state.Dto, new ToolConfirmReminderDto(bind$0040_2.IsConfirmed, bind$0040_2.ReminderId, msg.fields[0], bind$0040_2.SetLastInspectionDate, bind$0040_2.LastInspectionDate, bind$0040_2.ConfirmationComment)), state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.Reminder), Cmd_none()];
        case 3:
            return [new State(state.SetId, state.IsLoading, state.SuccessCallback, (bind$0040_3 = state.Dto, new ToolConfirmReminderDto(bind$0040_3.IsConfirmed, bind$0040_3.ReminderId, bind$0040_3.CreateNewDate, bind$0040_3.SetLastInspectionDate, bind$0040_3.LastInspectionDate, msg.fields[0])), state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.Reminder), Cmd_none()];
        case 4:
            return [new State(state.SetId, true, state.SuccessCallback, state.Dto, state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.Reminder), confirmReminderCmd(state.SetId, state.Dto, (Item) => (new Msg(5, [Item])), (Item_1) => (new Msg(6, [Item_1])))];
        case 5:
            if (msg.fields[0] === "notAuthorized") {
                return [new State(state.SetId, false, state.SuccessCallback, state.Dto, state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.Reminder), Cmd_errorToast("validation_error.not_authorized")];
            }
            else {
                return [new State(state.SetId, false, state.SuccessCallback, state.Dto, state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.Reminder), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                    state.SuccessCallback();
                }), Cmd_ofEffect((_arg_1) => {
                    state.OnClose();
                }), Cmd_successToast("tool.dialog_mark_appointment_as_done_success")]))];
            }
        case 6:
            return [new State(state.SetId, false, state.SuccessCallback, state.Dto, state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.Reminder), Cmd_none()];
        default:
            return [new State(state.SetId, state.IsLoading, state.SuccessCallback, (bind$0040 = state.Dto, new ToolConfirmReminderDto(bind$0040.IsConfirmed, bind$0040.ReminderId, bind$0040.CreateNewDate, msg.fields[0], bind$0040.LastInspectionDate, bind$0040.ConfirmationComment)), state.OnClose, state.IsNormalReminder, state.IsTitleInvalid, state.Reminder), Cmd_none()];
    }
}

function DialogBody(props) {
    let elems, elems_2;
    const t = useTranslation()[0];
    const isCreateNextReminderCheckboxVisible = !props.State.Reminder.IsConfirmed && (props.State.Reminder.Interval != null);
    const labelText = props.State.Reminder.IsConfirmed ? t("tool.dialog_mark_appointment_as_not_done") : t("tool.dialog_mark_appointment_as_done");
    const formState = props.State.IsLoading ? "disabled" : "enabled";
    if (props.State.Reminder.IsConfirmed) {
        return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems = [createElement(InformationAlert, {
            Label: t("reminder_dialog.mark_as_not_done_information"),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
    }
    else {
        return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-4"])], (elems_2 = toList(delay(() => append(singleton(createElement(TextValue, {
            FontWeight: "normal",
            TestId: "",
            Text: labelText,
        })), delay(() => append(isCreateNextReminderCheckboxVisible ? append(singleton(Checkbox({
            ComponentState: formState,
            IsChecked: props.State.Dto.CreateNewDate,
            Label: t("reminder_dialog.normal_reminder"),
            OnCheck: (value_4) => {
                props.Dispatch(new Msg(2, [value_4]));
            },
            TestId: "",
        })), delay(() => singleton(createElement(InformationAlert, {
            Label: t("reminder_dialog.create_new_reminder_info"),
        })))) : singleton(defaultOf()), delay(() => {
            let elems_1;
            return !props.State.Reminder.IsConfirmed ? append(props.State.Dto.SetLastInspectionDate ? singleton(createElement("div", createObj(singleton_1((elems_1 = [createElement(DatePicker, {
                ComponentState: formState,
                IsClearable: true,
                Label: t("reminder_dialog.check_date"),
                OnChange: (newDate) => {
                    props.Dispatch(new Msg(1, [newDate]));
                },
                TestId: "tool-add-reminder-dialog-check-date-test-id",
                Value: unwrap(props.State.Dto.LastInspectionDate),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))]))))) : singleton(defaultOf()), delay(() => singleton(createElement(TextArea, {
                ComponentState: formState,
                Label: t("general.comment"),
                OnChange: (value_7) => {
                    props.Dispatch(new Msg(3, [value_7]));
                },
                TestId: "tool-add-reminder-dialog-comment-test-id",
                Value: props.State.Dto.ConfirmationComment,
            })))) : singleton(defaultOf());
        })))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
    }
}

export function OpenConfirmReminderDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init({
        OnClose: props.OnClose,
        Reminder: props.Reminder,
        SetId: props.SetId,
        SuccessCallback: props.SuccessCallback,
    });
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    return createElement(Dialog, (Title = t("general.mark_as_done"), {
        Body: singleton_1(createElement(DialogBody, {
            Dispatch: dispatch,
            State: state_1,
        })),
        Controls: toList(delay(() => append(singleton(createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-confirm-reminder-dialog-test-id",
            Variant: "blueButton",
        })), delay(() => (!state_1.Reminder.IsConfirmed ? singleton(createElement(TextButton, {
            ComponentState: state_1.IsLoading ? "disabled" : "enabled",
            Label: t("general.mark_as_done"),
            OnClick: () => {
                dispatch(new Msg(4, []));
            },
            TestId: "save-confirm-dialog-test-id",
            Variant: "blueButton",
        })) : empty()))))),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

