import { createElement } from "react";
import React from "react";
import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.2.7.0/React.fs.js";
import { FloatingPortal, FloatingFocusManager, useDismiss, useClick, autoUpdate, autoPlacement, useFloating } from "@floating-ui/react";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { singleton, empty, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { Badge } from "./Badge.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { singleton as singleton_1, ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";
import Arrow_Down_3 from "../../public/assets/icons/Arrow_Down_3.svg";
import { TextButton } from "./Button.js";
import Delete_1 from "../../public/assets/icons/Delete_1.svg";
import { Interop_reactApi as Interop_reactApi_1 } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";

export function ReactPopover(props) {
    let elems_7;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setIsOpen = patternInput[1];
    const isOpen = patternInput[0];
    const floatingProps = useFloating({
        middleware: [autoPlacement({
            allowedPlacements: ["bottom-end"],
        })],
        onOpenChange: setIsOpen,
        open: isOpen,
        strategy: "fixed",
        whileElementsMounted: autoUpdate,
    });
    const click = useClick(floatingProps.context);
    const dismiss = useDismiss(floatingProps.context);
    return createElement("div", createObj(ofArray([["className", join(" ", ["relative"])], (elems_7 = toList(delay(() => {
        let matchValue, buttonBadge, elems;
        return append((matchValue = props.ButtonBadge, (matchValue == null) ? (empty()) : ((buttonBadge = matchValue, singleton(createElement("div", createObj(ofArray([["key", "popover-button-badge-key"], ["className", join(" ", ["absolute", "right-[-20px]", "top-[-10px]"])], (elems = [createElement(Badge, {
            Color: "#14BC9C",
            Label: buttonBadge,
            TestId: `${buttonBadge}-test-id`,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))))))), delay(() => {
            let elems_3, elems_2, elems_1;
            return append(singleton(createElement("div", createObj(ofArray([["data-test-id", props.TestId], ["onClick", (_arg) => {
                setIsOpen(!isOpen);
            }], ["ref", floatingProps.refs.setReference], ["className", join(" ", ["flex", "items-center", "justify-center", "h-10", "px-3.5", "text-sm", "rounded-lg", "font-semibold", "border", "border-[#B6BECB]", "text-black", "cursor-pointer", "grow"])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["stroke-1", "text-black", "h-5", "w-5", "mr-2.5"])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["h-3", "w-3", "mt-1"])], (elems_1 = [Arrow_Down_3()], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), props.ButtonLabel], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))), delay(() => {
                let props_9, children_1, props_8, children, elems_6;
                return isOpen ? singleton((props_9 = ofArray([["root", document.body], (children_1 = singleton_1((props_8 = ofArray([["context", floatingProps.context], ["modal", false], (children = singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["relative", "bg-white", "rounded", "drop-shadow-xl", "z-50"])], ["ref", floatingProps.refs.setFloating], ["style", floatingProps.floatingStyles], (elems_6 = toList(delay(() => {
                    let elems_5, elems_4;
                    return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-end", "pt-8"])], (elems_5 = [createElement(TextButton, {
                        ComponentState: "enabled",
                        Icon: createElement("div", createObj(ofArray([["className", join(" ", ["h-3", "w-3"])], (elems_4 = [Delete_1()], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))),
                        Label: "",
                        OnClick: () => {
                            setIsOpen(false);
                        },
                        TestId: `${props.TestId}-close-button`,
                        Variant: "default",
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))), delay(() => props.Body(() => {
                        setIsOpen(false);
                    })));
                })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))), ["children", Interop_reactApi_1.Children.toArray(children)])]), Interop_reactApi_1.createElement(FloatingFocusManager, createObj(props_8)))), ["children", Interop_reactApi_1.Children.toArray(children_1)])]), Interop_reactApi_1.createElement(FloatingPortal, createObj(props_9)))) : empty();
            }));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])));
}

