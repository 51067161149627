import { Union } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { class_type, union_type, bool_type, string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { useTranslation } from "react-i18next";
import { singleton as singleton_1, pick, tryPick } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { singleton, empty, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.19.2/MapUtil.js";
import { Tooltip as Tooltip_1 } from "react-tooltip";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";

export class CommonProps extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Place", "Type", "Effect", "Html", "Wrapper"];
    }
}

export function CommonProps_$reflection() {
    return union_type("SharedComponents.ReactTooltip.CommonProps", [], CommonProps, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", string_type]]]);
}

export class TooltipProps extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Content", "Place", "Type", "Effect", "Html", "Wrapper"];
    }
}

export function TooltipProps_$reflection() {
    return union_type("SharedComponents.ReactTooltip.TooltipProps", [], TooltipProps, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", string_type]]]);
}

export class tooltipProps {
    constructor() {
    }
}

export function tooltipProps_$reflection() {
    return class_type("SharedComponents.ReactTooltip.tooltipProps", undefined, tooltipProps);
}

export function tooltipProps_$ctor() {
    return new tooltipProps();
}

export function tooltipProps_place_Z721C83C5(p) {
    return new TooltipProps(1, [p]);
}

export function tooltipProps_type_Z721C83C5(v) {
    return new TooltipProps(2, [v]);
}

export function tooltipProps_effect_Z721C83C5(v) {
    return new TooltipProps(3, [v]);
}

export function tooltipProps_html_Z1FBCCD16(v) {
    return new TooltipProps(4, [v]);
}

export function tooltipProps_wrapper_Z721C83C5(v) {
    return new TooltipProps(5, [v]);
}

export function Tooltip(tooltipInputProps) {
    let elems, props_4;
    const props = tooltipInputProps.props;
    const patternInput = useTranslation();
    const place_1 = tryPick((_arg) => {
        if (_arg.tag === 1) {
            return _arg.fields[0];
        }
        else {
            return undefined;
        }
    }, props);
    const ttype = tryPick((_arg_1) => {
        if (_arg_1.tag === 2) {
            return _arg_1.fields[0];
        }
        else {
            return undefined;
        }
    }, props);
    const effect = tryPick((_arg_2) => {
        if (_arg_2.tag === 3) {
            return _arg_2.fields[0];
        }
        else {
            return undefined;
        }
    }, props);
    const html = tryPick((_arg_3) => {
        if (_arg_3.tag === 4) {
            return _arg_3.fields[0];
        }
        else {
            return undefined;
        }
    }, props);
    const wrapper = tryPick((_arg_4) => {
        if (_arg_4.tag === 5) {
            return _arg_4.fields[0];
        }
        else {
            return undefined;
        }
    }, props);
    const content = pick((_arg_5) => {
        if (_arg_5.tag === 0) {
            return _arg_5.fields[0];
        }
        else {
            return undefined;
        }
    }, props);
    const props_1 = toList(delay(() => {
        let matchValue;
        return append((matchValue = place_1, (matchValue == null) ? (empty()) : singleton(new CommonProps(0, [matchValue]))), delay(() => {
            let matchValue_1;
            return append((matchValue_1 = ttype, (matchValue_1 == null) ? (empty()) : singleton(new CommonProps(1, [matchValue_1]))), delay(() => {
                let matchValue_2;
                return append((matchValue_2 = effect, (matchValue_2 == null) ? (empty()) : singleton(new CommonProps(2, [matchValue_2]))), delay(() => {
                    let matchValue_3;
                    return append((matchValue_3 = html, (matchValue_3 == null) ? (empty()) : singleton(new CommonProps(3, [matchValue_3]))), delay(() => {
                        const matchValue_4 = wrapper;
                        if (matchValue_4 == null) {
                            return empty();
                        }
                        else {
                            return singleton(new CommonProps(4, [matchValue_4]));
                        }
                    }));
                }));
            }));
        }));
    }));
    return createElement("span", createObj(singleton_1((elems = [createElement("i", {
        "data-tip": patternInput[1].t(content),
        className: "fas fa-info-circle date-icon",
    }), (props_4 = keyValueList(props_1, 1), react.createElement(Tooltip_1, props_4))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]))));
}

export function UpdatedTooltip_Tooltip(updatedTooltip_TooltipInputProps) {
    let elems, props_4;
    const props = updatedTooltip_TooltipInputProps.props;
    const place_1 = tryPick((_arg) => {
        if (_arg.tag === 1) {
            return _arg.fields[0];
        }
        else {
            return undefined;
        }
    }, props);
    const ttype = tryPick((_arg_1) => {
        if (_arg_1.tag === 2) {
            return _arg_1.fields[0];
        }
        else {
            return undefined;
        }
    }, props);
    const effect = tryPick((_arg_2) => {
        if (_arg_2.tag === 3) {
            return _arg_2.fields[0];
        }
        else {
            return undefined;
        }
    }, props);
    const html = tryPick((_arg_3) => {
        if (_arg_3.tag === 4) {
            return _arg_3.fields[0];
        }
        else {
            return undefined;
        }
    }, props);
    const wrapper = tryPick((_arg_4) => {
        if (_arg_4.tag === 5) {
            return _arg_4.fields[0];
        }
        else {
            return undefined;
        }
    }, props);
    const content = pick((_arg_5) => {
        if (_arg_5.tag === 0) {
            return _arg_5.fields[0];
        }
        else {
            return undefined;
        }
    }, props);
    const props_1 = toList(delay(() => {
        let matchValue;
        return append((matchValue = place_1, (matchValue == null) ? (empty()) : singleton(new CommonProps(0, [matchValue]))), delay(() => {
            let matchValue_1;
            return append((matchValue_1 = ttype, (matchValue_1 == null) ? (empty()) : singleton(new CommonProps(1, [matchValue_1]))), delay(() => {
                let matchValue_2;
                return append((matchValue_2 = effect, (matchValue_2 == null) ? (empty()) : singleton(new CommonProps(2, [matchValue_2]))), delay(() => {
                    let matchValue_3;
                    return append((matchValue_3 = html, (matchValue_3 == null) ? (empty()) : singleton(new CommonProps(3, [matchValue_3]))), delay(() => {
                        const matchValue_4 = wrapper;
                        if (matchValue_4 == null) {
                            return empty();
                        }
                        else {
                            return singleton(new CommonProps(4, [matchValue_4]));
                        }
                    }));
                }));
            }));
        }));
    }));
    return createElement("span", createObj(singleton_1((elems = [createElement("i", {
        "data-tip": content,
        className: "fas fa-info-circle date-icon",
    }), (props_4 = keyValueList(props_1, 1), react.createElement(Tooltip_1, props_4))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]))));
}

