import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.2.7.0/React.fs.js";
import { equals, createObj } from "./fable_modules/fable-library-js.4.19.2/Util.js";
import { split, join } from "./fable_modules/fable-library-js.4.19.2/String.js";
import { Navigation, TopNavigation } from "./Navigation.js";
import { item } from "./fable_modules/fable-library-js.4.19.2/Array.js";
import { Variant, debugActive } from "./Variant.js";
import { Msg } from "./Types.js";
import { map, defaultArg, unwrap } from "./fable_modules/fable-library-js.4.19.2/Option.js";
import { empty, singleton, append, delay, toList } from "./fable_modules/fable-library-js.4.19.2/Seq.js";
import { ViewContainer } from "./Components/ViewContainer.js";
import { AuthorizedUrl } from "./Routes.js";
import { FloatingDialog } from "./Widgets/FloatingDialog.js";
import { toLocalTime, toString } from "./fable_modules/fable-library-js.4.19.2/Date.js";
import { RouterModule_nav } from "./fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { ofArray } from "./fable_modules/fable-library-js.4.19.2/List.js";
import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/Interop.fs.js";

export function NavigationContainer(props) {
    let elems_2, elems_1;
    const patternInput = useTranslation();
    const auth = useAuth();
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(true);
    const isDialogVisible = patternInput_2[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-[16rem,2px,calc(100vw-16rem-2px)]", "print:flex", "grid-rows-[4rem,calc(100vh-5rem)]", "h-100", "grow"])], (elems_2 = [createElement(TopNavigation, {
        Configuration: props.Configuration,
        OnLogout: () => {
            const subdomain = item(0, split(window.location.host, ["."], undefined, 0));
            auth.removeUser();
            auth.signoutRedirect({
                post_logout_redirect_uri: debugActive ? "http://localhost:8080/#/" : ((Variant.tag === 1) ? (`https://${subdomain}.inventory-one.com/#/`) : (`https://${subdomain}.meinwerkzeugkoffer.de/#/`)),
            });
            props.Dispatch(new Msg(3, []));
        },
        SubTestId: unwrap(props.SubTestId),
        SubTitle: unwrap(props.SubTitle),
        TestId: props.TestId,
        Title: props.Title,
        UserData: props.UserData,
        Username: props.UserData.UserName,
    }), createElement(Navigation, {
        AccountSettings: props.AccountSettings,
        CurrentUrl: props.CurrentUrl,
        IsInTestphase: props.IsInTestphase,
        UserData: props.UserData,
    }), createElement("div", {
        className: join(" ", ["row-start-1", "row-span-2", "min-w-[2px]", "bg-divider"]),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["grow", "flex", "flex-col", "bg-background", "relative"])], (elems_1 = toList(delay(() => append(singleton(createElement(ViewContainer, {
        Children: props.SubView,
    })), delay(() => {
        let elems, Text$;
        if (!equals(props.CurrentUrl, new AuthorizedUrl(73, [])) && isDialogVisible) {
            const matchValue_3 = props.AccountSettings.AccountState;
            switch (matchValue_3) {
                case "testPeriodEnded":
                case "testPeriodActive":
                    return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["w-248", "bottom-10", "left-[50%]", "translate-x-[-50%]", "bg-transpatent", "grid-cols-2", "absolute"])], (elems = [createElement(FloatingDialog, (Text$ = patternInput[0]("dashboard.testphasemodal"), {
                        Date: defaultArg(map((accountEndDate) => toString(toLocalTime(accountEndDate), "dd.MM.yyyy"), props.AccountSettings.AccountEndDate), ""),
                        OnCancelClick: () => {
                            patternInput_2[1](!isDialogVisible);
                        },
                        OnClick: () => {
                            RouterModule_nav(ofArray(["customer", "wizard"]), 1, 1);
                        },
                        Text: Text$,
                    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))));
                default: {
                    return empty();
                }
            }
        }
        else {
            return empty();
        }
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

