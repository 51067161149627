import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { OnTableRowClick$1, Table, ColumnDefinition$1, CellContent } from "../../Widgets/Table/Table.js";
import { createObj, int32ToString } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { Button } from "../../Components/Button.js";
import Shipment_Add from "../../../public/assets/icons/Shipment_Add.svg";
import { RouterModule_encodeParts, RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { toArray, empty, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Interop_reactApi } from "../../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { unwrapDispolistId } from "../../Shared/Helper.js";
import { update as update_1, init as init_1 } from "./State.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Skeleton } from "../../Components/Skeleton.js";

function OverviewTable(props) {
    let elems_2, elems_1, elems, Label;
    const t = useTranslation()[0];
    const columnDefinitions = [new ColumnDefinition$1("name", "", t("general.name"), (dto) => (new CellContent(0, [dto.Name])), "text", "text"), new ColumnDefinition$1("sumTools", "", t("dispolist.sum_tools"), (dto_1) => (new CellContent(0, [int32ToString(dto_1.SumTools)])), "text", "text"), new ColumnDefinition$1("sumToolsWithQuantity", "", t("dispolist.sum_tools_with_quantity"), (dto_2) => (new CellContent(0, [int32ToString(dto_2.SumToolsWithQuantity)])), "text", "text"), new ColumnDefinition$1("sumConsumables", "", t("dispolist.sum_consumables"), (dto_3) => (new CellContent(0, [int32ToString(dto_3.SumConsumables)])), "text", "text")];
    return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "pb-2"])], (elems_1 = [createElement("div", {
        className: join(" ", ["grow"]),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "gap-3"])], (elems = [createElement(Button, (Label = t("settings.create_new_dispolist"), {
        ComponentState: "enabled",
        Icon: Shipment_Add(),
        Label: Label,
        OnClick: () => {
            RouterModule_nav(ofArray(["inventory-lists", "new"]), 1, 1);
        },
        TestId: "add-new-inventory-button",
        Variant: "primary",
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement(Table, {
        ColumnDefinitions: columnDefinitions,
        Controls: empty(),
        Options: {
            BorderStyle: "full",
            CSVDownload: false,
            ConfigurableColumns: false,
            DefaultSortColumn: ["name", "asc"],
            GlobalSearch: false,
            OnRowClick: new OnTableRowClick$1(1, [(row) => RouterModule_encodeParts(ofArray(["inventory-lists", unwrapDispolistId(row.Id)]), 1)]),
            RowSelection: "disabled",
        },
        TableRows: toArray(props.Dispolists),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

export function OverviewView(props) {
    let init;
    const matchValue = ((init = init_1(props.UserData), React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, undefined)))[0].Dispolists;
    if (matchValue.tag === 0) {
        return createElement(Skeleton, {
            Variant: "table",
        });
    }
    else {
        return createElement(OverviewTable, {
            Dispolists: matchValue.fields[0],
        });
    }
}

