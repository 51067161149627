import { empty as empty_1, isEmpty, singleton as singleton_1, tryFind, ofArray, contains, filter, toArray, map } from "../../../fable_modules/fable-library-js.4.19.2/List.js";
import { parse } from "../../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { StorageId__get_unwrap, FormState__isValid, FormState__getValidation, LabelId } from "../../../Shared/Shared.js";
import { multiSelectWithLabel, toNoOptionsMessage, CommonPropsMultiSelect$1, OptionType$1 } from "../../../SharedComponents/ReactSelect.js";
import { stringHash, createObj, safeHash, equals } from "../../../fable_modules/fable-library-js.4.19.2/Util.js";
import { Msg } from "./Types.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useReact_useMemo_10C6A43C, useFeliz_React__React_useState_Static_1505 } from "../../../fable_modules/Feliz.2.7.0/React.fs.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.2/String.js";
import { NewCategoryDialog } from "../../../Widgets/Dialogs/NewCategoryDialog.js";
import { empty, append, singleton, delay, toList } from "../../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { AreaAnchorMenu } from "../../../Widgets/AnchorMenu.js";
import { Interop_reactApi } from "../../../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { WarningAlert, InformationAlert, ErrorAlert } from "../../../Components/Alert.js";
import { FormImageSection, FormSection } from "../../../Widgets/FormSection.js";
import { Feature, isFeatureEnabled } from "../../../FeatureFlip.js";
import { map as map_1, value as value_16, unwrap } from "../../../fable_modules/fable-library-js.4.19.2/Option.js";
import { CurrencyInput, Input, AutoSuggestInput } from "../../../Components/Input.js";
import { Select } from "../../../Components/Select.js";
import { TextArea } from "../../../Components/TextArea.js";
import { Button, TextButton } from "../../../Components/Button.js";
import { QrCodeTable } from "../../../Widgets/Table/QrCodeTable.js";
import { DatePicker } from "../../../Components/DatePicker.js";
import { NewInventoryCustomProperties } from "../../../Widgets/CustomProperties.js";
import { PrinzingSpecificForm } from "./CustomerSpecificView.js";
import { initNewToolModalView, initCopyNewTool, initNewToolView, update as update_1, initEditView } from "./State.js";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import Arrow_Left_1 from "../../../../public/assets/icons/Arrow_Left_1.svg";
import { RouterModule_encodeQueryString, RouterModule_nav } from "../../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { unwrapToolId } from "../../../Shared/Helper.js";
import { getQueryParams } from "../../../Common.js";
import { PageHeader } from "../../../Components/Text.js";
import { Skeleton } from "../../../Components/Skeleton.js";
import { ModalSheet } from "../../../Components/ModalSheet.js";

function selectLabelProps(toolForm, labels, dispatch, isDisabled) {
    const categoryIds = map((arg) => (new LabelId(parse(arg))), toolForm.CategoryIds);
    return [new CommonPropsMultiSelect$1(0, [toArray(map((l_1) => (new OptionType$1(l_1.Name, l_1.Id)), filter((l) => !contains(l.Id, categoryIds, {
        Equals: equals,
        GetHashCode: safeHash,
    }), labels)))]), new CommonPropsMultiSelect$1(2, [(arg_1) => {
        dispatch(new Msg(9, [arg_1]));
    }]), new CommonPropsMultiSelect$1(5, [true]), new CommonPropsMultiSelect$1(9, [false]), new CommonPropsMultiSelect$1(8, [true]), new CommonPropsMultiSelect$1(6, ["select.label_placeholder"]), new CommonPropsMultiSelect$1(11, [(_arg) => toNoOptionsMessage("select.label_no_options", _arg)]), new CommonPropsMultiSelect$1(12, ["flex-grow-1"]), new CommonPropsMultiSelect$1(7, [isDisabled]), new CommonPropsMultiSelect$1(3, [toArray(map((l_3) => (new OptionType$1(l_3.Name, l_3.Id)), filter((l_2) => contains(l_2.Id, categoryIds, {
        Equals: equals,
        GetHashCode: safeHash,
    }), labels)))])];
}

export function ToolForm(props) {
    let elems_5, elems, elems_4, elems_3;
    const t = useTranslation()[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("master_data");
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setIsNewCateogoryDialogOpen = patternInput_2[1];
    const componentState = props.FormState.IsLoading ? "disabled" : "enabled";
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-[10rem,1fr]", "mt-12"])], (elems_5 = [createElement(NewCategoryDialog, {
        IsOpen: patternInput_2[0],
        OnClose: () => {
            setIsNewCateogoryDialogOpen(false);
            props.Dispatch(new Msg(22, []));
        },
    }), createElement("div", createObj(ofArray([["className", join(" ", [])], (elems = toList(delay(() => (props.WithAnchorMenu ? singleton(createElement(AreaAnchorMenu, {
        Anchors: toList(delay(() => append(singleton({
            Id: "master_data",
            Label: t("general.master_data"),
        }), delay(() => append(singleton({
            Id: "qr_codes",
            Label: t("general.qr_codes"),
        }), delay(() => append(singleton({
            Id: "appointments",
            Label: t("general.appointments"),
        }), delay(() => append(singleton({
            Id: "commercial_data",
            Label: t("tool.commercial_data"),
        }), delay(() => (props.IsCustomPropertiesEnabled ? singleton({
            Id: "custom_properties",
            Label: t("tool.custom_properties"),
        }) : empty()))))))))))),
        OnSelectAnchor: patternInput_1[1],
        SelectedAnchor: patternInput_1[0],
    })) : empty()))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "max-w-5xl"])], (elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-9"])], (elems_3 = toList(delay(() => {
        let matchValue_1;
        return append((matchValue_1 = FormState__getValidation(props.FormState, t, "global"), (matchValue_1 == null) ? (empty()) : singleton(createElement(ErrorAlert, {
            Label: matchValue_1,
        }))), delay(() => append(singleton(createElement(InformationAlert, {
            Label: t("tool.create_tool_validation_info"),
        })), delay(() => {
            let Header;
            return append(singleton(createElement(FormSection, (Header = t("general.master_data"), {
                Content: toList(delay(() => append(isFeatureEnabled(new Feature(5, [])) ? (!props.IsEdit ? singleton(createElement(FormImageSection, {
                    FormState: componentState,
                    SelectedFile: unwrap(props.SelectedFile),
                    SetSelectedFile: (file) => {
                        props.Dispatch(new Msg(31, [file]));
                    },
                })) : empty()) : empty(), delay(() => append(singleton(useReact_useMemo_10C6A43C(() => createElement(AutoSuggestInput, {
                    ComponentState: componentState,
                    Label: t("tool.manufacturer"),
                    OnChange: (arg) => {
                        props.Dispatch(new Msg(0, [arg]));
                    },
                    Suggestions: props.Manufacturers,
                    TestId: "create-tool-form-manufacturer",
                    ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "manufacturer")),
                    Value: props.ToolForm.Manufacturer,
                }), [props.ToolForm.Manufacturer, props.Manufacturers, () => FormState__isValid(props.FormState), componentState])), delay(() => append(singleton(createElement(Input, {
                    ComponentState: componentState,
                    Label: t("tool.model"),
                    OnChange: (arg_1) => {
                        props.Dispatch(new Msg(2, [arg_1]));
                    },
                    TestId: "create-tool-form-model",
                    ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "model")),
                    Value: props.ToolForm.Model,
                })), delay(() => append(singleton(createElement(Input, {
                    ComponentState: componentState,
                    Label: t("tool.serial_number"),
                    OnChange: (arg_2) => {
                        props.Dispatch(new Msg(3, [arg_2]));
                    },
                    TestId: "create-tool-form-serial-number",
                    Value: props.ToolForm.SerialNumber,
                })), delay(() => append(singleton(createElement(Input, {
                    ComponentState: componentState,
                    Label: t("tool.displayname"),
                    OnChange: (arg_3) => {
                        props.Dispatch(new Msg(1, [arg_3]));
                    },
                    TestId: "create-tool-form-display-name",
                    ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "displayName")),
                    Value: props.ToolForm.DisplayName,
                })), delay(() => append(singleton(createElement(Input, {
                    ComponentState: componentState,
                    Label: t("tool.inventory_number"),
                    OnChange: (arg_4) => {
                        props.Dispatch(new Msg(4, [arg_4]));
                    },
                    TestId: "create-tool-form-inventory-number",
                    Value: props.ToolForm.InventoryNumber,
                })), delay(() => {
                    let Label_5, Options, NoOptionsMessage, Value_6, storage_2;
                    const storage = tryFind((s) => (StorageId__get_unwrap(s.Id) === props.ToolForm.StorageId), props.Storages);
                    return append(singleton(createElement(Select, (Label_5 = t("general.storage"), (Options = map((storage_1) => ({
                        label: storage_1.Name,
                        value: storage_1.Id,
                    }), filter((s_1) => {
                        if (props.UserData.Role === "toolManager") {
                            return contains(props.UserData.UserId, s_1.AssignedUserIds, {
                                Equals: (x, y) => (x === y),
                                GetHashCode: stringHash,
                            });
                        }
                        else {
                            return true;
                        }
                    }, props.Storages)), (NoOptionsMessage = t("tool.no_storage_found"), (Value_6 = ((storage == null) ? undefined : ((storage_2 = storage, {
                        label: storage_2.Name,
                        value: storage_2.Id,
                    }))), {
                        ComponentState: componentState,
                        IsClearable: false,
                        Label: unwrap(Label_5),
                        NoOptionsMessage: NoOptionsMessage,
                        OnChange: (s_2) => {
                            props.Dispatch(new Msg(5, [value_16(s_2)]));
                        },
                        Options: Options,
                        PlaceholderKey: "",
                        TestId: "create-tool-form-select",
                        ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "storage")),
                        Value: unwrap(Value_6),
                    })))))), delay(() => append(singleton(createElement(Input, {
                        ComponentState: componentState,
                        Label: t("tool.storage_space"),
                        OnChange: (arg_5) => {
                            props.Dispatch(new Msg(6, [arg_5]));
                        },
                        TestId: "create-tool-form-storage-space",
                        Value: props.ToolForm.StorageSpace,
                    })), delay(() => append(singleton(createElement(TextArea, {
                        ComponentState: componentState,
                        Label: t("general.comment"),
                        OnChange: (arg_6) => {
                            props.Dispatch(new Msg(7, [arg_6]));
                        },
                        TestId: "create-tool-form-comment",
                        Value: props.ToolForm.Comment,
                    })), delay(() => {
                        let elems_1;
                        return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-1"])], ["data-test-id", "tool-form-categories"], (elems_1 = [multiSelectWithLabel(selectLabelProps(props.ToolForm, props.Labels, props.Dispatch, props.FormState.IsLoading), t("tool.label"), ""), createElement(TextButton, {
                            ComponentState: componentState,
                            Label: t("settings.create_new_label"),
                            OnClick: () => {
                                setIsNewCateogoryDialogOpen(true);
                            },
                            TestId: "add-new-category-button",
                            Variant: "default",
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))));
                    }))))));
                })))))))))))))),
                Header: Header,
                Id: "master_data",
            }))), delay(() => {
                let Header_1;
                return append(singleton(createElement(FormSection, (Header_1 = t("general.qr_codes"), {
                    Content: singleton_1(createElement(QrCodeTable, {
                        OnAssignQrCode: (arg_7) => {
                            props.Dispatch(new Msg(11, [arg_7]));
                        },
                        OnDeleteQrCodes: (arg_8) => {
                            props.Dispatch(new Msg(23, [arg_8]));
                        },
                        QrCodes: props.ToolForm.QRCodeIds,
                        UserData: props.UserData,
                    })),
                    Header: Header_1,
                    Id: "qr_codes",
                }))), delay(() => {
                    let Header_2;
                    return append(singleton(createElement(FormSection, (Header_2 = t("general.appointments"), {
                        Content: singleton_1(createElement(DatePicker, {
                            ComponentState: componentState,
                            IsClearable: true,
                            Label: t("tool.last_check"),
                            OnChange: (date) => {
                                props.Dispatch(new Msg(8, [date]));
                            },
                            TestId: "tool-form-last-check",
                            Value: unwrap(props.LastInspectionDate),
                        })),
                        Header: Header_2,
                        Id: "appointments",
                    }))), delay(() => {
                        let Header_3;
                        return append(singleton(createElement(FormSection, (Header_3 = t("tool.commercial_data"), {
                            Content: ofArray([createElement(Input, {
                                ComponentState: componentState,
                                Label: t("general.supplier"),
                                OnChange: (arg_9) => {
                                    props.Dispatch(new Msg(12, [arg_9]));
                                },
                                TestId: "tool-form-supplier",
                                Value: props.ToolForm.CommercialData.Supplier,
                            }), createElement(Input, {
                                ComponentState: componentState,
                                Label: t("general.supplier_article_number"),
                                OnChange: (arg_10) => {
                                    props.Dispatch(new Msg(13, [arg_10]));
                                },
                                TestId: "tool-form-article-number",
                                Value: props.ToolForm.CommercialData.SupplierArticleNumber,
                            }), createElement(CurrencyInput, {
                                ComponentState: componentState,
                                Label: t("general.purchase_price"),
                                OnChange: (price_1) => {
                                    props.Dispatch(new Msg(14, [price_1]));
                                },
                                TestId: "tool-form-purchase-price",
                                Value: unwrap(map_1((price) => (price / 100), props.ToolForm.CommercialData.PurchasePrice)),
                            }), createElement(DatePicker, {
                                ComponentState: componentState,
                                IsClearable: true,
                                Label: t("general.purchase_date"),
                                OnChange: (date_1) => {
                                    props.Dispatch(new Msg(15, [date_1]));
                                },
                                TestId: "tool-form-purchase-date",
                                Value: unwrap(props.PurchaseDate),
                            }), createElement(DatePicker, {
                                ComponentState: componentState,
                                IsClearable: true,
                                Label: t("general.gurantee_date"),
                                OnChange: (date_2) => {
                                    props.Dispatch(new Msg(16, [date_2]));
                                },
                                TestId: "tool-form-gurantee-date",
                                Value: unwrap(props.WarrantyDate),
                            }), createElement(TextArea, {
                                ComponentState: componentState,
                                Label: t("general.comment"),
                                OnChange: (arg_11) => {
                                    props.Dispatch(new Msg(17, [arg_11]));
                                },
                                TestId: "tool-form-commercial-data-comment",
                                Value: props.ToolForm.CommercialData.Comment,
                            })]),
                            Header: Header_3,
                            Id: "commercial_data",
                        }))), delay(() => {
                            let Header_4;
                            return append(props.IsCustomPropertiesEnabled ? singleton(createElement(FormSection, (Header_4 = t("tool.custom_properties"), {
                                Content: singleton_1(createElement(NewInventoryCustomProperties, {
                                    CustomProperties: props.ToolForm.CustomProperties,
                                    FormState: props.FormState,
                                    LabelIds: map((arg_13) => (new LabelId(parse(arg_13))), props.ToolForm.CategoryIds),
                                    OnSetCustomProperty: (arg_12) => {
                                        props.Dispatch(new Msg(10, [arg_12]));
                                    },
                                    PropertyConfigurations: props.PropertyConfigurations,
                                })),
                                Header: Header_4,
                                Id: "custom_properties",
                            }))) : empty(), delay(() => {
                                let matchValue_2;
                                return append((matchValue_2 = props.ToolForm.PrinzingSpecificFields, (matchValue_2 == null) ? (empty()) : singleton(createElement(PrinzingSpecificForm, {
                                    ComponentState: componentState,
                                    Dispatch: props.Dispatch,
                                    FormState: props.FormState,
                                    IsDisabled: props.FormState.IsLoading,
                                    PrinzingSpecific: matchValue_2,
                                }))), delay(() => {
                                    let matchValue_3;
                                    return append((matchValue_3 = FormState__getValidation(props.FormState, t, "global"), (matchValue_3 == null) ? (empty()) : singleton(createElement(ErrorAlert, {
                                        Label: matchValue_3,
                                    }))), delay(() => {
                                        let elems_2;
                                        return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-end"])], (elems_2 = [createElement(Button, {
                                            ComponentState: componentState,
                                            Label: t("general.save"),
                                            OnClick: props.OnSave,
                                            TestId: "tool-form-save-button",
                                            Variant: "primary",
                                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))));
                                    }));
                                }));
                            }));
                        }));
                    }));
                }));
            }));
        }))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])));
}

export function EditToolView(props) {
    let elems_1, elems, Label;
    const patternInput = useTranslation();
    let patternInput_1;
    const init = initEditView(props.UserData, props.ToolId);
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, [props.ToolId]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    const matchValue = state_1.ToolForm;
    const matchValue_1 = state_1.Storages;
    const matchValue_2 = state_1.Labels;
    const matchValue_3 = state_1.PropertyConfigurations;
    const matchValue_4 = state_1.IsCustomPropertiesEnabled;
    let matchResult, isPropertyConfigurationsEnabled, labels, propertyConfigurations, storages, tool;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_2.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_3.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_4.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        isPropertyConfigurationsEnabled = matchValue_4.fields[0];
        labels = matchValue_2.fields[0];
        propertyConfigurations = matchValue_3.fields[0];
        storages = matchValue_1.fields[0];
        tool = matchValue.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["mb-3", "flex"])], (elems = [createElement(TextButton, (Label = patternInput[0]("general.back"), {
                ComponentState: "enabled",
                Icon: Arrow_Left_1(),
                Label: Label,
                OnClick: () => {
                    RouterModule_nav(ofArray(["tools", unwrapToolId(props.ToolId) + RouterModule_encodeQueryString(getQueryParams(undefined))]), 1, 1);
                },
                TestId: "tools-new-back-to-overview-button",
                Variant: "default",
            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement(PageHeader, {
                Text: "tool.edit_tool",
            }), createElement(ToolForm, {
                Dispatch: dispatch,
                FormState: state_1.FormState,
                IsCustomPropertiesEnabled: isPropertyConfigurationsEnabled && !isEmpty(propertyConfigurations),
                IsEdit: true,
                Labels: labels,
                LastInspectionDate: unwrap(state_1.LastInspectionDate),
                Manufacturers: state_1.Manufacturers,
                OnSave: () => {
                    dispatch(new Msg(20, []));
                },
                PropertyConfigurations: propertyConfigurations,
                PurchaseDate: unwrap(state_1.PurchaseDate),
                SelectedFile: unwrap(state_1.SelectedFile),
                Storages: storages,
                ToolForm: tool,
                UserData: state_1.UserData,
                WarrantyDate: unwrap(state_1.WarrantyDate),
                WithAnchorMenu: true,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
        default:
            return createElement(Skeleton, {
                Variant: "normal",
            });
    }
}

export function NewToolView(props) {
    let elems_1, elems, Label;
    let patternInput;
    const init = initNewToolView(props.UserData);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useTranslation();
    const matchValue = state_1.ToolForm;
    const matchValue_1 = state_1.Storages;
    const matchValue_2 = state_1.Labels;
    const matchValue_3 = state_1.PropertyConfigurations;
    const matchValue_4 = state_1.IsCustomPropertiesEnabled;
    let matchResult, isPropertyConfigurationsEnabled, labels, propertyConfigurations, storages, tool;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_2.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_3.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_4.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        isPropertyConfigurationsEnabled = matchValue_4.fields[0];
        labels = matchValue_2.fields[0];
        propertyConfigurations = matchValue_3.fields[0];
        storages = matchValue_1.fields[0];
        tool = matchValue.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["mb-3", "flex"])], (elems = [createElement(TextButton, (Label = patternInput_1[0]("general.back_to_overview"), {
                ComponentState: "enabled",
                Icon: Arrow_Left_1(),
                Label: Label,
                OnClick: () => {
                    RouterModule_nav(singleton_1("tools" + RouterModule_encodeQueryString(getQueryParams(undefined))), 1, 1);
                },
                TestId: "tools-new-back-to-overview-button",
                Variant: "default",
            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement(PageHeader, {
                Text: "tool.create_new_tool",
            }), createElement(ToolForm, {
                Dispatch: dispatch,
                FormState: state_1.FormState,
                IsCustomPropertiesEnabled: isPropertyConfigurationsEnabled && !isEmpty(propertyConfigurations),
                IsEdit: false,
                Labels: labels,
                LastInspectionDate: unwrap(state_1.LastInspectionDate),
                Manufacturers: state_1.Manufacturers,
                OnSave: () => {
                    dispatch(new Msg(19, []));
                },
                PropertyConfigurations: propertyConfigurations,
                PurchaseDate: unwrap(state_1.PurchaseDate),
                SelectedFile: unwrap(state_1.SelectedFile),
                Storages: storages,
                ToolForm: tool,
                UserData: state_1.UserData,
                WarrantyDate: unwrap(state_1.WarrantyDate),
                WithAnchorMenu: true,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
        default:
            return createElement(Skeleton, {
                Variant: "normal",
            });
    }
}

export function CopyNewToolView(props) {
    let elems_1, elems, Label;
    let patternInput;
    const init = initCopyNewTool(props.ToolId, props.UserData);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, [props.ToolId]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useTranslation();
    const matchValue = state_1.ToolForm;
    const matchValue_1 = state_1.Storages;
    const matchValue_2 = state_1.Labels;
    const matchValue_3 = state_1.PropertyConfigurations;
    const matchValue_4 = state_1.IsCustomPropertiesEnabled;
    let matchResult, isCustomPropertiesEnabled, labels, propertyConfigurations, storages, tool;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_2.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_3.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_4.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        isCustomPropertiesEnabled = matchValue_4.fields[0];
        labels = matchValue_2.fields[0];
        propertyConfigurations = matchValue_3.fields[0];
        storages = matchValue_1.fields[0];
        tool = matchValue.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["mb-3", "flex"])], (elems = [createElement(TextButton, (Label = patternInput_1[0]("general.back"), {
                ComponentState: "enabled",
                Icon: Arrow_Left_1(),
                Label: Label,
                OnClick: () => {
                    RouterModule_nav(ofArray(["tools", unwrapToolId(props.ToolId) + RouterModule_encodeQueryString(getQueryParams(undefined))]), 1, 1);
                },
                TestId: "tools-new-back-to-overview-button",
                Variant: "default",
            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement(PageHeader, {
                Text: "tool.copy_tool",
            }), createElement(ToolForm, {
                Dispatch: dispatch,
                FormState: state_1.FormState,
                IsCustomPropertiesEnabled: isCustomPropertiesEnabled && !isEmpty(propertyConfigurations),
                IsEdit: false,
                Labels: labels,
                LastInspectionDate: unwrap(state_1.LastInspectionDate),
                Manufacturers: state_1.Manufacturers,
                OnSave: () => {
                    dispatch(new Msg(19, []));
                },
                PropertyConfigurations: propertyConfigurations,
                PurchaseDate: unwrap(state_1.PurchaseDate),
                SelectedFile: unwrap(state_1.SelectedFile),
                Storages: storages,
                ToolForm: tool,
                UserData: state_1.UserData,
                WarrantyDate: unwrap(state_1.WarrantyDate),
                WithAnchorMenu: true,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
        default:
            return createElement(Skeleton, {
                Variant: "normal",
            });
    }
}

export function NewToolModalView(props) {
    let elems, matchValue, matchValue_1, matchValue_2, matchValue_3, matchValue_4, storages_1, matchValue_6, storageId, elems_1;
    let patternInput;
    const init = initNewToolModalView(props.UserData, props.OnSuccessCallback, props.IsSelectedStorageIdNeeded, props.StorageId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useTranslation();
    return createElement(ModalSheet, {
        CloseButtonTestId: "",
        Content: (props.IsSelectedStorageIdNeeded && (props.StorageId == null)) ? createElement("div", createObj(ofArray([["className", join(" ", ["px-5"])], (elems = [createElement(WarningAlert, {
            Label: patternInput_1[0]("set.add_tool_dialog.storage_not_selected_information"),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))) : ((matchValue = state_1.ToolForm, (matchValue_1 = state_1.Storages, (matchValue_2 = state_1.Labels, (matchValue_3 = state_1.PropertyConfigurations, (matchValue_4 = state_1.IsCustomPropertiesEnabled, (matchValue.tag === 0) ? createElement(Skeleton, {
            Variant: "normal",
        }) : ((matchValue_1.tag === 0) ? createElement(Skeleton, {
            Variant: "normal",
        }) : ((matchValue_2.tag === 0) ? createElement(Skeleton, {
            Variant: "normal",
        }) : ((matchValue_3.tag === 0) ? createElement(Skeleton, {
            Variant: "normal",
        }) : ((matchValue_4.tag === 0) ? createElement(Skeleton, {
            Variant: "normal",
        }) : ((storages_1 = ((matchValue_6 = props.StorageId, (matchValue_6 == null) ? matchValue_1.fields[0] : ((storageId = matchValue_6, filter((storage) => equals(storage.Id, storageId), matchValue_1.fields[0]))))), createElement("div", createObj(ofArray([["className", join(" ", ["pb-5"])], (elems_1 = [createElement(ToolForm, {
            Dispatch: dispatch,
            FormState: state_1.FormState,
            IsCustomPropertiesEnabled: matchValue_4.fields[0] && !isEmpty(matchValue_3.fields[0]),
            IsEdit: false,
            Labels: matchValue_2.fields[0],
            LastInspectionDate: unwrap(state_1.LastInspectionDate),
            Manufacturers: state_1.Manufacturers,
            OnSave: () => {
                dispatch(new Msg(19, []));
            },
            PropertyConfigurations: matchValue_3.fields[0],
            PurchaseDate: unwrap(state_1.PurchaseDate),
            SelectedFile: unwrap(state_1.SelectedFile),
            Storages: storages_1,
            ToolForm: matchValue.fields[0],
            UserData: state_1.UserData,
            WarrantyDate: unwrap(state_1.WarrantyDate),
            WithAnchorMenu: false,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))))))))))))))),
        Controls: empty_1(),
        IsModalOpen: props.IsOpen,
        OnClose: props.OnClose,
    });
}

