import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { Checkbox } from "../Components/Checkbox.js";
import { ActivatedEmailNotification } from "../Shared/User.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";

export function EmailNotification(props) {
    let elems;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-2"])], (elems = [createElement("div", {
        className: join(" ", ["font-bold", "mb-1"]),
        children: t("user.email_notification.title"),
    }), Checkbox({
        ComponentState: props.FormState,
        IsChecked: props.User.ActivatedEmailNotification.ToolReservation,
        Label: t("user.email_notification.tool_reservation"),
        OnCheck: (_arg) => {
            let bind$0040;
            props.OnChangeEmailNotification((bind$0040 = props.User.ActivatedEmailNotification, new ActivatedEmailNotification(bind$0040.ToolReminder, bind$0040.ConsumableMinimumStock, bind$0040.ToolWithQuantityMinimumStock, bind$0040.ProblemReport, !props.User.ActivatedEmailNotification.ToolReservation)));
        },
        TestId: "",
    }), Checkbox({
        ComponentState: props.FormState,
        IsChecked: props.User.ActivatedEmailNotification.ProblemReport,
        Label: t("user.email_notification.problem_report"),
        OnCheck: (_arg_1) => {
            let bind$0040_1;
            props.OnChangeEmailNotification((bind$0040_1 = props.User.ActivatedEmailNotification, new ActivatedEmailNotification(bind$0040_1.ToolReminder, bind$0040_1.ConsumableMinimumStock, bind$0040_1.ToolWithQuantityMinimumStock, !props.User.ActivatedEmailNotification.ProblemReport, bind$0040_1.ToolReservation)));
        },
        TestId: "",
    }), Checkbox({
        ComponentState: props.FormState,
        IsChecked: props.User.ActivatedEmailNotification.ToolWithQuantityMinimumStock,
        Label: t("user.email_notification.toolwithquantity_minimum_stock"),
        OnCheck: (_arg_2) => {
            let bind$0040_2;
            props.OnChangeEmailNotification((bind$0040_2 = props.User.ActivatedEmailNotification, new ActivatedEmailNotification(bind$0040_2.ToolReminder, bind$0040_2.ConsumableMinimumStock, !props.User.ActivatedEmailNotification.ToolWithQuantityMinimumStock, bind$0040_2.ProblemReport, bind$0040_2.ToolReservation)));
        },
        TestId: "",
    }), Checkbox({
        ComponentState: props.FormState,
        IsChecked: props.User.ActivatedEmailNotification.ConsumableMinimumStock,
        Label: t("user.email_notification.consumable_minimum_stock"),
        OnCheck: (_arg_3) => {
            let bind$0040_3;
            props.OnChangeEmailNotification((bind$0040_3 = props.User.ActivatedEmailNotification, new ActivatedEmailNotification(bind$0040_3.ToolReminder, !props.User.ActivatedEmailNotification.ConsumableMinimumStock, bind$0040_3.ToolWithQuantityMinimumStock, bind$0040_3.ProblemReport, bind$0040_3.ToolReservation)));
        },
        TestId: "",
    }), Checkbox({
        ComponentState: props.FormState,
        IsChecked: props.User.ActivatedEmailNotification.ToolReminder,
        Label: t("user.email_notification.tools_reminder"),
        OnCheck: (_arg_4) => {
            let bind$0040_4;
            props.OnChangeEmailNotification((bind$0040_4 = props.User.ActivatedEmailNotification, new ActivatedEmailNotification(!props.User.ActivatedEmailNotification.ToolReminder, bind$0040_4.ConsumableMinimumStock, bind$0040_4.ToolWithQuantityMinimumStock, bind$0040_4.ProblemReport, bind$0040_4.ToolReservation)));
        },
        TestId: "",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

