import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { TextButton } from "../Components/Button.js";
import Arrow_Left_1 from "../../public/assets/icons/Arrow_Left_1.svg";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { singleton as singleton_1, ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { singleton, empty, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { TextValue } from "../Components/Text.js";

export function DetailViewHeader(props) {
    let elems_6, elems, Label, elems_4, elems_2, elems_1, elems_3, elems_5;
    const t = useTranslation()[0];
    return createElement("div", createObj(singleton_1((elems_6 = [createElement("div", createObj(ofArray([["className", join(" ", ["mb-3.5"])], (elems = [createElement(TextButton, (Label = t("general.overview"), {
        ComponentState: "enabled",
        Icon: Arrow_Left_1(),
        Label: Label,
        OnClick: props.OnBack,
        TestId: "back-to-overview-button",
        Variant: "default",
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-between", "items-center"])], (elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "items-center", "gap-3"])], (elems_2 = [createElement("div", {
        className: join(" ", ["font-bold", "text-4xl", "text-black"]),
        children: props.Title,
        "data-test-id": `tool-${props.Title}-header-test-id`,
    }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row"])], (elems_1 = toList(delay(() => props.Badges)), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "gap-5"])], (elems_3 = toList(delay(() => {
        let matchValue, onDelete;
        return append((matchValue = props.OnDelete, (matchValue == null) ? (empty()) : ((onDelete = matchValue, singleton(createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.remove"),
            OnClick: onDelete,
            TestId: "delete-button",
            Variant: "redButton",
        }))))), delay(() => {
            let matchValue_1, onDuplicate;
            return append((matchValue_1 = props.OnDuplicate, (matchValue_1 == null) ? (empty()) : ((onDuplicate = matchValue_1, singleton(createElement(TextButton, {
                ComponentState: "enabled",
                Label: t("general.duplicate"),
                OnClick: onDuplicate,
                TestId: "duplicate-button",
                Variant: "default",
            }))))), delay(() => {
                const matchValue_2 = props.OnEdit;
                if (matchValue_2 == null) {
                    return empty();
                }
                else {
                    const onEdit = matchValue_2;
                    return singleton(createElement(TextButton, {
                        ComponentState: "enabled",
                        Label: t("general.edit"),
                        OnClick: onEdit,
                        TestId: "edit-button",
                        Variant: "default",
                    }));
                }
            }));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["mb-8", "text-sm"])], (elems_5 = toList(delay(() => {
        const matchValue_3 = props.SubTitle;
        if (matchValue_3 == null) {
            return empty();
        }
        else {
            return singleton(createElement(TextValue, {
                FontWeight: "small",
                TestId: "",
                Text: matchValue_3,
            }));
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))]))));
}

