import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { TableControl$1, OnTableRowClick$1, Table, ColumnDefinition$1, CellContent } from "../../Widgets/Table/Table.js";
import { roleToString } from "../../Shared/Helper.js";
import { createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { singleton, ofArray, toArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { RouterModule_nav, RouterModule_encodeParts } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import User_Add_Plus from "../../../public/assets/icons/User_Add_Plus.svg";
import { Interop_reactApi } from "../../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { update as update_1, init as init_1 } from "./State.js";
import { Skeleton } from "../../Components/Skeleton.js";

function UsersOverview(props) {
    let elems, TableRows, Options;
    const t = useTranslation()[0];
    const columnDefinitions = [new ColumnDefinition$1("name", "", t("general.name"), (dto) => (new CellContent(0, [dto.Name])), "text", "text"), new ColumnDefinition$1("personnelNumber", "", t("user.personnel_number"), (dto_1) => (new CellContent(0, [dto_1.PersonnelNumber])), "text", "text"), new ColumnDefinition$1("emailPhoneNumber", "", t("user.email_mobile_number"), (dto_2) => {
        const matchValue = dto_2.LoginInformation;
        return (matchValue.tag === 1) ? (new CellContent(0, [matchValue.fields[0]])) : (new CellContent(0, [matchValue.fields[0]]));
    }, "text", "text"), new ColumnDefinition$1("department", "", t("user.department"), (dto_3) => (new CellContent(0, [dto_3.Department])), "select", "text"), new ColumnDefinition$1("role", "", t("general.role"), (dto_4) => (new CellContent(0, [t(`role.${roleToString(dto_4.Role)}`)])), "select", "text")];
    return createElement("div", createObj(singleton((elems = [createElement(Table, (TableRows = toArray(props.Users), (Options = {
        BorderStyle: "row",
        CSVDownload: false,
        ConfigurableColumns: false,
        DefaultSortColumn: ["name", "asc"],
        GlobalSearch: true,
        OnRowClick: new OnTableRowClick$1(1, [(dto_5) => RouterModule_encodeParts(ofArray(["settings", "users", dto_5.Id]), 1)]),
        RowSelection: "disabled",
    }, {
        ColumnDefinitions: columnDefinitions,
        Controls: singleton(new TableControl$1(t("user.create_new_user"), User_Add_Plus(), (_arg) => {
            RouterModule_nav(ofArray(["settings", "users", "new"]), 1, 1);
        }, false, false, "")),
        Options: Options,
        TableRows: TableRows,
    })))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]))));
}

export function UsersOverviewView(props) {
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    const matchValue = state_1.Users;
    const matchValue_1 = state_1.CustomerConfiguration;
    let matchResult, customerConfiguration, users;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        customerConfiguration = matchValue_1.fields[0];
        users = matchValue.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement(UsersOverview, {
                CustomerConfiguration: customerConfiguration,
                Dispatch: patternInput[1],
                UserData: props.UserData,
                Users: users,
            });
        default:
            return createElement(Skeleton, {
                Variant: "table",
            });
    }
}

