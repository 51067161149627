import { i18n } from "./I18n.js";
import { tokenKey } from "./Constants.js";
import { jwtDecode } from "jwt-decode";
import { parseCustomer } from "./Common.js";
import { roleFromString } from "./Shared/Helper.js";
import { RequestedValue$1, TokenDataDto, UserTentantConfiguration_$reflection, UserTentantConfiguration } from "./Shared/Shared.js";
import { uint64 as uint64_1, int64 as int64_1, decimal as decimal_1, Auto_generateBoxedDecoder_Z6670B51, fromString } from "./fable_modules/Thoth.Json.10.2.0/Decode.fs.js";
import { createObj, uncurry2 } from "./fable_modules/fable-library-js.4.19.2/Util.js";
import { getCaseFields, getCaseName as getCaseName_1, isUnion, option_type, list_type } from "./fable_modules/fable-library-js.4.19.2/Reflection.js";
import { value as value_19, map, flatten, defaultArg, ofNullable } from "./fable_modules/fable-library-js.4.19.2/Option.js";
import { empty as empty_2, cons, ofArray, singleton as singleton_1, tryFind } from "./fable_modules/fable-library-js.4.19.2/List.js";
import { Variant, debugActive } from "./Variant.js";
import { initializeSentry } from "./Sentry.js";
import { parseUrl } from "./Routes.js";
import { RouterModule_router, RouterModule_nav, RouterModule_urlSegments } from "./fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { compare, toLocalTime, utcNow, date } from "./fable_modules/fable-library-js.4.19.2/Date.js";
import { State_$reflection, Msg, State } from "./Types.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none } from "./fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { empty, singleton, append, delay, toList } from "./fable_modules/fable-library-js.4.19.2/Seq.js";
import { Cmd_ofEffect as Cmd_ofEffect_1 } from "./fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { getAccountSettings, getTestphase } from "./Requests/Customer.js";
import { getCustomerConfiguration } from "./Requests/Configuration.js";
import i18next from "i18next";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { useTranslation } from "react-i18next";
import { split, join } from "./fable_modules/fable-library-js.4.19.2/String.js";
import { item } from "./fable_modules/fable-library-js.4.19.2/Array.js";
import { WebStorageStateStore } from "oidc-client-ts";
import { html5Backend } from "./Bindings/Feliz.DnDProvider.js";
import { localStorageProvider } from "./Bindings/Fable.Import.SWR.js";
import { Toaster } from "./Components/Toast.js";
import { ActivePage } from "./ActivePage.js";
import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/Interop.fs.js";
import { ErrorBoundary } from "@sentry/react";
import { Interop_reactApi as Interop_reactApi_1 } from "./fable_modules/Feliz.2.7.0/Interop.fs.js";
import { SWRConfig } from "swr";
import { LoadingPage } from "./LoadingPage.js";
import { DndProvider } from "react-dnd";
import { AuthProvider } from "react-oidc-context";
import { ProgramModule_run } from "./fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Program_withReactBatched } from "./fable_modules/Fable.Elmish.React.4.0.0/react.fs.js";
import { ProgramModule_mkProgram, ProgramModule_withConsoleTrace } from "./fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Program_withDebuggerUsing, Debugger_showWarning, Debugger_showError } from "./fable_modules/Fable.Elmish.Debugger.4.0.0/debugger.fs.js";
import { newGuid } from "./fable_modules/fable-library-js.4.19.2/Guid.js";
import { add } from "./fable_modules/fable-library-js.4.19.2/Map.js";
import { Auto_generateBoxedEncoder_437914C6, uint64, int64, decimal } from "./fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { empty as empty_1 } from "./fable_modules/Thoth.Json.10.2.0/Extra.fs.js";
import { ExtraCoders } from "./fable_modules/Thoth.Json.10.2.0/Types.fs.js";
import { fromValue } from "./fable_modules/Thoth.Json.10.2.0/Decode.fs.js";
import { Debugger_ConnectionOptions } from "./fable_modules/Fable.Elmish.Debugger.4.0.0/debugger.fs.js";
import { Options$1 } from "./fable_modules/Fable.Elmish.Debugger.4.0.0/Fable.Import.RemoteDev.fs.js";
import { connectViaExtension } from "remotedev";


export function resetToken(_arg) {
    localStorage.removeItem(tokenKey);
    localStorage.removeItem("app-cache");
}

export function parseToken(token) {
    let matchValue;
    const decodedToken = jwtDecode(token);
    return new TokenDataDto(decodedToken.username, decodedToken.user_id, decodedToken.customer, parseCustomer(decodedToken.customer), new UserTentantConfiguration(decodedToken.customer, roleFromString(decodedToken.role)), (matchValue = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(option_type(list_type(UserTentantConfiguration_$reflection())), undefined, undefined)), decodedToken.tenants), (matchValue.tag === 1) ? undefined : matchValue.fields[0]), roleFromString(decodedToken.role), token);
}

export function readTenantFromLocalStorage(tokenData) {
    const tenant = ofNullable(localStorage.getItem("tenant"));
    let matchResult, tenant_1, tokenData_1;
    if (tokenData != null) {
        if (tenant != null) {
            matchResult = 0;
            tenant_1 = tenant;
            tokenData_1 = tokenData;
        }
        else {
            matchResult = 1;
        }
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0: {
            const patternInput = defaultArg(flatten(map((allowedTenants) => map((allowedTenant_1) => [allowedTenant_1.Role, allowedTenant_1.Tenant], tryFind((allowedTenant) => (allowedTenant.Tenant === tenant_1), allowedTenants)), tokenData_1.Tenants)), [tokenData_1.Role, tokenData_1.CustomerId]);
            const tenantCustomerId = patternInput[1];
            return new TokenDataDto(tokenData_1.UserName, tokenData_1.UserId, tenantCustomerId, parseCustomer(tenantCustomerId), tokenData_1.MainTenant, tokenData_1.Tenants, patternInput[0], tokenData_1.Token);
        }
        default:
            return tokenData;
    }
}

export function getTokenDataFromLocalStorage(_arg) {
    const tokenData = map((arg) => parseToken(JSON.parse(arg).access_token), ofNullable(localStorage.getItem(tokenKey)));
    if (!debugActive) {
        initializeSentry(tokenData);
    }
    return readTenantFromLocalStorage(tokenData);
}

export function isAdmin(state) {
    if (value_19(state.UserData).Role === "administrator") {
        return true;
    }
    else {
        return value_19(state.UserData).Role === "system";
    }
}

export function init() {
    const initialUrl = parseUrl(RouterModule_urlSegments(window.location.hash, 1));
    return [new State([false, date(utcNow())], initialUrl, undefined, new RequestedValue$1(0, []), new RequestedValue$1(0, [])), Cmd_none()];
}

export function update(msg, state) {
    let userData_2, userData;
    switch (msg.tag) {
        case 1:
            return [state, Cmd_batch(toList(delay(() => append(singleton(Cmd_ofEffect((_arg_2) => {
                window.history.replaceState({}, document.title, window.location.pathname);
            })), delay(() => {
                const matchValue_1 = state.UserData;
                if (matchValue_1 == null) {
                    return empty();
                }
                else {
                    const matchValue_2 = matchValue_1.Role;
                    switch (matchValue_2) {
                        case "user":
                            return singleton(Cmd_ofEffect_1((_arg_4) => {
                                RouterModule_nav(singleton_1("tools"), 1, 1);
                            }));
                        default:
                            return singleton(Cmd_ofEffect_1((_arg_3) => {
                                RouterModule_nav(singleton_1("dashboard"), 1, 1);
                            }));
                    }
                }
            })))))];
        case 2: {
            const tokenData_2 = readTenantFromLocalStorage(getTokenDataFromLocalStorage(undefined));
            if (!debugActive) {
                initializeSentry(tokenData_2);
            }
            return [new State(state.IsInTestphase, state.CurrentUrl, tokenData_2, state.Configuration, state.AccountSettings), (tokenData_2 != null) ? ((userData_2 = tokenData_2, Cmd_batch(toList(delay(() => append(singleton(getTestphase((Item_6) => (new Msg(5, [Item_6])), (Item_7) => (new Msg(8, [Item_7])))), delay(() => append(singleton(getCustomerConfiguration((Item_8) => (new Msg(6, [Item_8])), (Item_9) => (new Msg(8, [Item_9])))), delay(() => append(singleton(getAccountSettings((Item_10) => (new Msg(7, [Item_10])), (Item_11) => (new Msg(8, [Item_11])))), delay(() => {
                const matchValue_3 = userData_2.Role;
                switch (matchValue_3) {
                    case "user":
                        return singleton(Cmd_ofEffect_1((_arg_6) => {
                            RouterModule_nav(singleton_1("tools"), 1, 1);
                        }));
                    default:
                        return singleton(Cmd_ofEffect_1((_arg_5) => {
                            RouterModule_nav(singleton_1("dashboard"), 1, 1);
                        }));
                }
            }))))))))))) : Cmd_none()];
        }
        case 3: {
            resetToken(undefined);
            return [new State(state.IsInTestphase, state.CurrentUrl, undefined, state.Configuration, state.AccountSettings), Cmd_none()];
        }
        case 6: {
            const configuration = msg.fields[0];
            const matchValue_4 = configuration.CustomI18nextNamespace;
            if (matchValue_4 == null) {
            }
            else {
                const customI18nextNamespace = matchValue_4;
                i18next.setDefaultNamespace(customI18nextNamespace);
            }
            return [new State(state.IsInTestphase, state.CurrentUrl, state.UserData, new RequestedValue$1(1, [configuration]), state.AccountSettings), Cmd_none()];
        }
        case 7:
            return [new State(state.IsInTestphase, state.CurrentUrl, state.UserData, state.Configuration, new RequestedValue$1(1, [msg.fields[0]])), Cmd_none()];
        case 4:
            return [new State(state.IsInTestphase, msg.fields[0], state.UserData, state.Configuration, state.AccountSettings), Cmd_none()];
        case 5: {
            const testphase = msg.fields[0];
            const patternInput = state.IsInTestphase;
            const isInTestphase = patternInput[0];
            if (testphase.Phase.tag === 0) {
                const today = toLocalTime(date(utcNow()));
                const endDate = toLocalTime(date(testphase.EndDate));
                return [isInTestphase ? state : (new State([true, endDate], state.CurrentUrl, state.UserData, state.Configuration, state.AccountSettings)), (compare(today, endDate) > 0) ? Cmd_ofEffect_1((_arg_7) => {
                    RouterModule_nav(singleton_1("testphaseend"), 1, 1);
                }) : Cmd_none()];
            }
            else {
                return [isInTestphase ? (new State([false, patternInput[1]], state.CurrentUrl, state.UserData, state.Configuration, state.AccountSettings)) : state, Cmd_none()];
            }
        }
        case 8:
            return [state, Cmd_none()];
        default: {
            const tokenData = readTenantFromLocalStorage(parseToken(msg.fields[0]));
            if (!debugActive) {
                initializeSentry(tokenData);
            }
            return [new State(state.IsInTestphase, state.CurrentUrl, tokenData, state.Configuration, state.AccountSettings), (tokenData != null) ? ((userData = tokenData, Cmd_batch(toList(delay(() => append(singleton(getTestphase((Item) => (new Msg(5, [Item])), (Item_1) => (new Msg(8, [Item_1])))), delay(() => append(singleton(getCustomerConfiguration((Item_2) => (new Msg(6, [Item_2])), (Item_3) => (new Msg(8, [Item_3])))), delay(() => append(singleton(getAccountSettings((Item_4) => (new Msg(7, [Item_4])), (Item_5) => (new Msg(8, [Item_5])))), delay(() => {
                let matchValue;
                return append((matchValue = userData.Role, (matchValue === "system") ? singleton(Cmd_ofEffect_1((_arg) => {
                    RouterModule_nav(singleton_1("dashboard"), 1, 1);
                })) : ((matchValue === "toolManager") ? singleton(Cmd_ofEffect_1((_arg) => {
                    RouterModule_nav(singleton_1("dashboard"), 1, 1);
                })) : ((matchValue === "user") ? singleton(Cmd_ofEffect_1((_arg_1) => {
                    RouterModule_nav(singleton_1("tools"), 1, 1);
                })) : singleton(Cmd_ofEffect_1((_arg) => {
                    RouterModule_nav(singleton_1("dashboard"), 1, 1);
                }))))), delay(() => singleton(singleton_1((dispatch) => {
                    dispatch(new Msg(1, []));
                }))));
            }))))))))))) : Cmd_none()];
        }
    }
}

export function View(viewInputProps) {
    let elems_1, props_3;
    const dispatch = viewInputProps.dispatch;
    const state = viewInputProps.state;
    const patternInput = useTranslation();
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "h-full", "font-inter", "flex-col"])], (elems_1 = [(props_3 = toList(delay(() => {
        const subdomain = item(0, split(window.location.host, ["."], undefined, 0));
        return append(debugActive ? append(singleton(["client_id", "inventory-one-development"]), delay(() => append(singleton(["authority", "https://app.inventory-one.com/auth/realms/development"]), delay(() => singleton(["redirect_uri", "http://localhost:8080/#/"]))))) : ((Variant.tag === 0) ? append(singleton(["client_id", "mein-werkzeugkoffer-browser"]), delay(() => append(singleton(["authority", "https://app.meinwerkzeugkoffer.de/auth/realms/mein-werkzeugkoffer"]), delay(() => singleton(["redirect_uri", `https://${subdomain}.meinwerkzeugkoffer.de/#/`]))))) : append(singleton(["client_id", "inventory-one-browser"]), delay(() => append(singleton(["authority", "https://app.inventory-one.com/auth/realms/inventory-one"]), delay(() => singleton(["redirect_uri", `https://${subdomain}.inventory-one.com/#/`])))))), delay(() => append(singleton(["response_mode", "fragment"]), delay(() => append(singleton(["userStore", new WebStorageStateStore({
            store: window.localStorage,
        })]), delay(() => append(singleton(["onSigninCallback", (value_12) => {
            if (value_12 == null) {
            }
            else {
                dispatch(new Msg(0, [value_12.access_token]));
            }
        }]), delay(() => {
            let children_2, props_2, children_1;
            return singleton((children_2 = singleton_1((props_2 = ofArray([["backend", html5Backend], (children_1 = toList(delay(() => {
                let inputProperties, elements_2;
                return patternInput[2] ? singleton((inputProperties = createObj(ofArray([["key", "dnd-provider-swr-config-key"], ["value", {
                    provider: localStorageProvider,
                }], (elements_2 = ofArray([createElement(Toaster, null), RouterModule_router(createObj(toList(delay(() => append(singleton(["onUrlChanged", (arg_1) => {
                    dispatch(new Msg(4, [parseUrl(arg_1)]));
                }]), delay(() => {
                    let elements, elements_1, props, children;
                    return debugActive ? singleton((elements = singleton_1(createElement(ActivePage, {
                        Dispatch: dispatch,
                        State: state,
                    })), ["application", react.createElement(react.Fragment, {}, ...elements)])) : singleton((elements_1 = singleton_1((props = singleton_1((children = singleton_1(createElement(ActivePage, {
                        Dispatch: dispatch,
                        State: state,
                    })), ["children", Interop_reactApi.Children.toArray(children)])), Interop_reactApi.createElement(ErrorBoundary, createObj(props)))), ["application", react.createElement(react.Fragment, {}, ...elements_1)]));
                }))))))]), ["children", Interop_reactApi_1.Children.toArray(Array.from(elements_2))])])), Interop_reactApi.createElement(SWRConfig, inputProperties))) : singleton(createElement(LoadingPage, null));
            })), ["children", Interop_reactApi.Children.toArray(children_1)])]), Interop_reactApi.createElement(DndProvider, createObj(props_2)))), ["children", Interop_reactApi.Children.toArray(children_2)]));
        }))))))));
    })), Interop_reactApi.createElement(AuthProvider, createObj(props_3)))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_1))])])));
}

if (debugActive) {
    ProgramModule_run((() => {
        let copyOfStruct, copyOfStruct_1, copyOfStruct_2, opt, makeMsgObj, fallback, inputRecord_1, inputRecord_2;
        const program_4 = Program_withReactBatched("elmish-app", ProgramModule_withConsoleTrace(ProgramModule_mkProgram(init, update, (state_1, dispatch) => createElement(View, {
            state: state_1,
            dispatch: dispatch,
        }))));
        try {
            let patternInput;
            try {
                let coders;
                let extra_2_1;
                const extra_1_1 = new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Decimal", [decimal, (path) => ((value_1) => decimal_1(path, value_1))], empty_1.Coders));
                extra_2_1 = (new ExtraCoders((copyOfStruct_1 = newGuid(), copyOfStruct_1), add("System.Int64", [int64, int64_1], extra_1_1.Coders)));
                coders = (new ExtraCoders((copyOfStruct_2 = newGuid(), copyOfStruct_2), add("System.UInt64", [uint64, uint64_1], extra_2_1.Coders)));
                const encoder_3 = Auto_generateBoxedEncoder_437914C6(State_$reflection(), undefined, coders, undefined);
                const decoder_3 = Auto_generateBoxedDecoder_Z6670B51(State_$reflection(), undefined, coders);
                patternInput = [(x) => {
                    try {
                        return encoder_3(x);
                    }
                    catch (er) {
                        Debugger_showWarning(singleton_1(er.message));
                        return x;
                    }
                }, (x_1) => {
                    const matchValue = fromValue("$", uncurry2(decoder_3), x_1);
                    if (matchValue.tag === 1) {
                        throw new Error(matchValue.fields[0]);
                    }
                    else {
                        return matchValue.fields[0];
                    }
                }];
            }
            catch (er_2) {
                Debugger_showWarning(singleton_1(er_2.message));
                patternInput = [(value_7) => value_7, (_arg) => {
                    throw new Error("Cannot inflate model");
                }];
            }
            return Program_withDebuggerUsing(patternInput[0], patternInput[1], (opt = (new Debugger_ConnectionOptions(0, [])), (makeMsgObj = ((tupledArg) => ({
                type: tupledArg[0],
                msg: tupledArg[1],
            })), (fallback = (new Options$1(true, 443, "remotedev.io", true, (arg) => {
                const x_3 = arg;
                if (isUnion(x_3)) {
                    const getCaseName = (acc_mut, x_1_1_mut) => {
                        getCaseName:
                        while (true) {
                            const acc = acc_mut, x_1_1 = x_1_1_mut;
                            const acc_1 = cons(getCaseName_1(x_1_1), acc);
                            const fields_1 = getCaseFields(x_1_1);
                            if ((fields_1.length === 1) && isUnion(item(0, fields_1))) {
                                acc_mut = acc_1;
                                x_1_1_mut = item(0, fields_1);
                                continue getCaseName;
                            }
                            else {
                                return makeMsgObj([join("/", acc_1), fields_1]);
                            }
                            break;
                        }
                    };
                    return getCaseName(empty_2(), x_3);
                }
                else {
                    return makeMsgObj(["NOT-AN-F#-UNION", x_3]);
                }
            })), connectViaExtension((opt.tag === 1) ? ((inputRecord_1 = fallback, new Options$1(inputRecord_1.remote, opt.fields[1], opt.fields[0], false, inputRecord_1.getActionType))) : ((opt.tag === 2) ? ((inputRecord_2 = fallback, new Options$1(inputRecord_2.remote, opt.fields[1], opt.fields[0], inputRecord_2.secure, inputRecord_2.getActionType))) : (new Options$1(false, 8000, "localhost", false, fallback.getActionType))))))), program_4);
        }
        catch (ex) {
            Debugger_showError(ofArray(["Unable to connect to the monitor, continuing w/o debugger", ex.message]));
            return program_4;
        }
    })());
}
else {
    ProgramModule_run(Program_withReactBatched("elmish-app", ProgramModule_mkProgram(init, update, (state_3, dispatch_1) => createElement(View, {
        state: state_3,
        dispatch: dispatch_1,
    }))));
}

