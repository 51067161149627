import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavigationContainer } from "./NavigationContainer.js";
import { unwrap } from "./fable_modules/fable-library-js.4.19.2/Option.js";
import { AuthorizedUrl } from "./Routes.js";
import { EditFormView, NewFormView, CopyFormView } from "./Stocktaking/Form/View.js";
import { DetailView } from "./Stocktaking/Detail/View.js";
import { DetailView as DetailView_1 } from "./Stocktaking/StocktakingList/View.js";
import { DashboardView } from "./Dashboard/View.js";
import { OverviewView } from "./Tools/Overview/View.js";
import { EditToolView, CopyNewToolView, NewToolView } from "./Tools/Tool/Form/View.js";
import { SetId, UserId as UserId_2, LocationId, StorageId, ConsumableId, ToolId as ToolId_1 } from "./Shared/Shared.js";
import { ViewToolView } from "./Tools/View.js";
import { EditToolView as EditToolView_1, CopyNewToolWithQantityView, NewToolWithQantityView } from "./Tools/ToolWithQuantity/Form/View.js";
import { ToolWithQuantityDetailView } from "./Tools/ToolWithQuantity/Details/View.js";
import { ConsumablesOverviewView } from "./Consumables/Overview/View.js";
import { EditConsumablesView, CopyNewConsumablesView, NewConsumablesView } from "./Consumables/Form/View.js";
import { ConsumableDetailOverviewView } from "./Consumables/Detail/View.js";
import { StorageDetailsView, StorageEditView, StorageNewView, StoragesOverview } from "./Storage/View.js";
import { LocationDetailsView, EditLocationView, NewLocationView, LocationOverview } from "./Location/View.js";
import { UserOverviewView } from "./ToolBox/Overviews/User/View.js";
import { EditUserView, NewUserView } from "./Receiver/User/View.js";
import { EmployeeOverviewView } from "./ToolBox/Overviews/Employee/View.js";
import { EditEmployeeView, NewEmployeeView } from "./Receiver/Employee/View.js";
import { ProjectOverviewView } from "./ToolBox/Overviews/Project/View.js";
import { EditProjectView, NewProjectView } from "./Receiver/Project/View.js";
import { RoomOverviewView } from "./ToolBox/Overviews/Room/View.js";
import { EditRoomView, NewRoomView } from "./Receiver/Room/View.js";
import { SubcontractorOverviewView } from "./ToolBox/Overviews/Subcontractor/View.js";
import { EditSubcontractorView, NewSubcontractorView } from "./Receiver/Subcontractor/View.js";
import { ConstructionSiteOverviewView } from "./ToolBox/Overviews/ConstructionSite/View.js";
import { EditConstructionSiteView, NewConstructionSiteView } from "./Receiver/ConstructionSite/View.js";
import { ExternalPersonOverviewView } from "./ToolBox/Overviews/ExternalPerson/View.js";
import { EditExternalPersonView, NewExternalPersonView } from "./Receiver/ExternalPerson/View.js";
import { StudentOverviewView } from "./ToolBox/Overviews/Student/View.js";
import { EditStudentView, NewStudentView } from "./Receiver/Student/View.js";
import { SchoolClassOverviewView } from "./ToolBox/Overviews/SchoolClass/View.js";
import { EditSchoolClassView, NewSchoolClassView } from "./Receiver/SchoolClass/View.js";
import { VehiclesOverviewView } from "./ToolBox/Overviews/Vehicle/View.js";
import { VehicleEditView, NewVehicleView } from "./Receiver/Vehicle/View.js";
import { View } from "./Settings/View.js";
import { SystemSettingsView } from "./Settings/SystemSettings/View.js";
import { View as View_1 } from "./Settings/CustomProperty/View.js";
import { CategoriesOverviewView } from "./Settings/Categories/View.js";
import { UsersOverviewView } from "./Settings/Users/View.js";
import { ToolBoxView } from "./ToolBox/Detail/View.js";
import { ProfileView } from "./Profile/View.js";
import { CustomerDataView } from "./CustomerData/Detail/View.js";
import { WizardView } from "./CustomerData/NewCustomerWizard/Wizard.js";
import { WizardComplete } from "./CustomerData/NewCustomerWizard/WizardComplete.js";
import { ReceiversOverviewView } from "./ToolBox/View.js";
import { View as View_2 } from "./ToolBox/Dispolist/View.js";
import { ViewWithQueryParameter, View as View_3 } from "./Report/View.js";
import { View as View_4 } from "./Support/View.js";
import { View as View_5 } from "./TestphaseEnd/View.js";
import { BarcodeShopView } from "./Shop/View.js";
import { View as View_6 } from "./Importer/View.js";
import { View as View_7 } from "./Importer/Tool/View.js";
import { View as View_8 } from "./Importer/ToolWithQuantity/View.js";
import { View as View_9 } from "./Importer/Consumable/View.js";
import { View as View_10 } from "./Importer/Label/View.js";
import { View as View_11 } from "./Importer/User/View.js";
import { View as View_12 } from "./Importer/Employee/View.js";
import { View as View_13 } from "./Importer/Subcontractor/View.js";
import { View as View_14 } from "./Importer/Room/View.js";
import { View as View_15 } from "./Importer/Project/View.js";
import { View as View_16 } from "./Importer/ExternalPerson/View.js";
import { View as View_17 } from "./Importer/Student/View.js";
import { View as View_18 } from "./Importer/SchoolClass/View.js";
import { View as View_19 } from "./Importer/ConstructionSite/View.js";
import { ImporterView } from "./Importer/Vehicle/View.js";
import { OverviewView as OverviewView_1 } from "./InventoryLists/Overview/View.js";
import { EditInventoryListView, NewInventoryListView } from "./InventoryLists/Form/View.js";
import { DetailInventoryListView } from "./InventoryLists/Detail/View.js";
import { CalendarView } from "./Calendar/View.js";
import { ReservationView } from "./Reservation/Overview/View.js";
import { EditReservationForm, NewReservationForm } from "./Reservation/Form/View.js";
import { DetailReservationView } from "./Reservation/Detail/View.js";
import { OverviewView as OverviewView_2 } from "./Sets/Overview/View.js";
import { CopySetView, EditSetView, NewSetView } from "./Sets/Form/View.js";
import { SetDetailView } from "./Sets/Detail/View.js";
import { InternalServerError, NotFound, Forbidden, Unauthorized, BadRequest } from "./Error.js";
import { LoadingPage } from "./LoadingPage.js";
import { StocktakingOverview } from "./Stocktaking/Overview/View.js";
import { hasAuthParams, useAuth } from "react-oidc-context";
import { useReact_useEffect_311B4086, useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.2.7.0/React.fs.js";
import { Msg } from "./Types.js";

export function ActiveAuthorizedPage(props) {
    const t = useTranslation()[0];
    const navigationContainer = (currentUrl, title, subTitle, testId, subTestId, subView) => createElement(NavigationContainer, {
        AccountSettings: props.AccountSettings,
        Configuration: props.Configuration,
        CurrentUrl: currentUrl,
        Dispatch: props.Dispatch,
        IsInTestphase: props.IsInTestphase,
        SubTestId: unwrap(subTestId),
        SubTitle: unwrap(subTitle),
        SubView: subView,
        TestId: testId,
        Title: title,
        UserData: props.UserData,
    });
    const matchValue = props.Url;
    switch (matchValue.tag) {
        case 4:
            return navigationContainer(new AuthorizedUrl(59, []), t("stocktaking.stocktaking"), undefined, "stocktaking-top-navigation", undefined, createElement(CopyFormView, {
                StocktakingId: matchValue.fields[0],
                UserData: props.UserData,
            }));
        case 5:
            return navigationContainer(new AuthorizedUrl(59, []), t("stocktaking.stocktaking"), undefined, "stocktaking-top-navigation", undefined, createElement(NewFormView, {
                UserData: props.UserData,
            }));
        case 3:
            return navigationContainer(new AuthorizedUrl(59, []), t("stocktaking.stocktaking"), undefined, "stocktaking-top-navigation", undefined, createElement(EditFormView, {
                StocktakingId: matchValue.fields[0],
                UserData: props.UserData,
            }));
        case 2:
            return navigationContainer(new AuthorizedUrl(59, []), t("stocktaking.stocktaking"), undefined, "stocktaking-top-navigation", undefined, createElement(DetailView, {
                StocktakingId: matchValue.fields[0],
            }));
        case 6:
            return navigationContainer(new AuthorizedUrl(59, []), t("stocktaking.stocktaking"), t("stocktaking.stocktaking_list"), "stocktaking-top-navigation", undefined, createElement(DetailView_1, {
                StocktakingId: matchValue.fields[0],
                StocktakingListId: matchValue.fields[1],
            }));
        case 0:
            return navigationContainer(new AuthorizedUrl(0, []), t("dashboard.title"), undefined, "dashboard-top-navigation", undefined, createElement(DashboardView, {
                TokenData: props.UserData,
            }));
        case 7:
            return navigationContainer(new AuthorizedUrl(7, []), t("general.tool"), undefined, "tool-top-navigation", undefined, createElement(OverviewView, {
                UserData: props.UserData,
            }));
        case 8:
            return navigationContainer(new AuthorizedUrl(7, []), t("general.tool"), undefined, "", undefined, createElement(NewToolView, {
                UserData: props.UserData,
            }));
        case 9:
            return navigationContainer(new AuthorizedUrl(7, []), t("general.tool"), undefined, "", undefined, createElement(CopyNewToolView, {
                ToolId: new ToolId_1(matchValue.fields[0]),
                UserData: props.UserData,
            }));
        case 10:
            return navigationContainer(new AuthorizedUrl(7, []), t("general.tool"), undefined, "", undefined, createElement(ViewToolView, {
                CurrentUser: props.UserData,
                ToolId: new ToolId_1(matchValue.fields[0]),
            }));
        case 11:
            return navigationContainer(new AuthorizedUrl(7, []), t("general.tool"), undefined, "", undefined, createElement(EditToolView, {
                ToolId: new ToolId_1(matchValue.fields[0]),
                UserData: props.UserData,
            }));
        case 12:
            return navigationContainer(new AuthorizedUrl(7, []), t("general.tool_with_quantity"), undefined, "", undefined, createElement(NewToolWithQantityView, {
                UserData: props.UserData,
            }));
        case 13:
            return navigationContainer(new AuthorizedUrl(7, []), t("general.tool_with_quantity"), undefined, "", undefined, createElement(CopyNewToolWithQantityView, {
                ToolId: new ToolId_1(matchValue.fields[0]),
                UserData: props.UserData,
            }));
        case 14:
            return navigationContainer(new AuthorizedUrl(7, []), t("general.tool_with_quantity"), undefined, "", undefined, createElement(ToolWithQuantityDetailView, {
                ToolId: new ToolId_1(matchValue.fields[0]),
                UserData: props.UserData,
            }));
        case 15:
            return navigationContainer(new AuthorizedUrl(7, []), t("general.tool_with_quantity"), undefined, "", undefined, createElement(EditToolView_1, {
                ToolId: new ToolId_1(matchValue.fields[0]),
                UserData: props.UserData,
            }));
        case 16:
            return navigationContainer(new AuthorizedUrl(16, []), t("consumable.title"), undefined, "consumable-top-navigation", undefined, createElement(ConsumablesOverviewView, {
                UserData: props.UserData,
            }));
        case 17:
            return navigationContainer(new AuthorizedUrl(16, []), t("consumable.title"), undefined, "", undefined, createElement(NewConsumablesView, {
                UserData: props.UserData,
            }));
        case 18:
            return navigationContainer(new AuthorizedUrl(16, []), t("consumable.title"), undefined, "", undefined, createElement(CopyNewConsumablesView, {
                ConsumableId: new ConsumableId(matchValue.fields[0]),
                UserData: props.UserData,
            }));
        case 19:
            return navigationContainer(new AuthorizedUrl(16, []), t("consumable.title"), undefined, "", undefined, createElement(ConsumableDetailOverviewView, {
                ConsumableId: matchValue.fields[0],
                UserData: props.UserData,
            }));
        case 20:
            return navigationContainer(new AuthorizedUrl(16, []), t("consumable.title"), undefined, "", undefined, createElement(EditConsumablesView, {
                ConsumableId: new ConsumableId(matchValue.fields[0]),
                UserData: props.UserData,
            }));
        case 21:
            return navigationContainer(new AuthorizedUrl(21, []), t("settings.title"), t("storage.title"), "settings-top-navigation", "storage-sub-top-navigation", createElement(StoragesOverview, null));
        case 22:
            return navigationContainer(new AuthorizedUrl(21, []), t("settings.title"), t("storage.title"), "", undefined, createElement(StorageNewView, null));
        case 24:
            return navigationContainer(new AuthorizedUrl(21, []), t("settings.title"), t("storage.title"), "", undefined, createElement(StorageEditView, {
                StorageId: new StorageId(matchValue.fields[0]),
            }));
        case 23:
            return navigationContainer(new AuthorizedUrl(21, []), t("settings.title"), t("storage.title"), "", undefined, createElement(StorageDetailsView, {
                StorageId: new StorageId(matchValue.fields[0]),
            }));
        case 25:
            return navigationContainer(new AuthorizedUrl(25, []), t("settings.title"), t("location.title"), "", undefined, createElement(LocationOverview, null));
        case 26:
            return navigationContainer(new AuthorizedUrl(25, []), t("settings.title"), t("location.title"), "", undefined, createElement(NewLocationView, null));
        case 28:
            return navigationContainer(new AuthorizedUrl(25, []), t("settings.title"), t("location.title"), "", undefined, createElement(EditLocationView, {
                LocationId: new LocationId(matchValue.fields[0]),
            }));
        case 27:
            return navigationContainer(new AuthorizedUrl(25, []), t("settings.title"), t("location.title"), "", undefined, createElement(LocationDetailsView, {
                LocationId: new LocationId(matchValue.fields[0]),
            }));
        case 29:
            return navigationContainer(new AuthorizedUrl(29, []), t("toolbox.title"), undefined, "", undefined, createElement(UserOverviewView, {
                UserData: props.UserData,
            }));
        case 30:
            return navigationContainer(new AuthorizedUrl(30, []), t("receivers.title"), undefined, "", undefined, createElement(NewUserView, {
                OnBackPath: ["receivers", "users"],
            }));
        case 31: {
            const userId = matchValue.fields[0];
            return navigationContainer(new AuthorizedUrl(31, [userId]), t("receivers.title"), undefined, "", undefined, createElement(EditUserView, {
                OnBackPath: ["receivers", userId],
                UserId: new UserId_2(userId),
            }));
        }
        case 32:
            return navigationContainer(new AuthorizedUrl(32, []), t("toolbox.title"), undefined, "", undefined, createElement(EmployeeOverviewView, {
                UserData: props.UserData,
            }));
        case 33:
            return navigationContainer(new AuthorizedUrl(33, []), t("receivers.title"), undefined, "", undefined, createElement(NewEmployeeView, null));
        case 34: {
            const employeeId = matchValue.fields[0];
            return navigationContainer(new AuthorizedUrl(34, [employeeId]), t("receivers.title"), undefined, "", undefined, createElement(EditEmployeeView, {
                EmployeeId: new UserId_2(employeeId),
            }));
        }
        case 41:
            return navigationContainer(new AuthorizedUrl(41, []), t("toolbox.title"), undefined, "", undefined, createElement(ProjectOverviewView, {
                UserData: props.UserData,
            }));
        case 42:
            return navigationContainer(new AuthorizedUrl(42, []), t("receivers.title"), undefined, "", undefined, createElement(NewProjectView, null));
        case 43: {
            const projectId = matchValue.fields[0];
            return navigationContainer(new AuthorizedUrl(43, [projectId]), t("receivers.title"), undefined, "", undefined, createElement(EditProjectView, {
                ProjectId: new UserId_2(projectId),
            }));
        }
        case 35:
            return navigationContainer(new AuthorizedUrl(35, []), t("toolbox.title"), undefined, "", undefined, createElement(RoomOverviewView, {
                UserData: props.UserData,
            }));
        case 36:
            return navigationContainer(new AuthorizedUrl(36, []), t("receivers.title"), undefined, "", undefined, createElement(NewRoomView, null));
        case 37: {
            const roomId = matchValue.fields[0];
            return navigationContainer(new AuthorizedUrl(37, [roomId]), t("receivers.title"), undefined, "", undefined, createElement(EditRoomView, {
                RoomId: new UserId_2(roomId),
            }));
        }
        case 44:
            return navigationContainer(new AuthorizedUrl(44, []), t("toolbox.title"), undefined, "", undefined, createElement(SubcontractorOverviewView, {
                UserData: props.UserData,
            }));
        case 45:
            return navigationContainer(new AuthorizedUrl(45, []), t("receivers.title"), undefined, "", undefined, createElement(NewSubcontractorView, null));
        case 46: {
            const subcontractorId = matchValue.fields[0];
            return navigationContainer(new AuthorizedUrl(46, [subcontractorId]), t("receivers.title"), undefined, "", undefined, createElement(EditSubcontractorView, {
                SubcontractorId: new UserId_2(subcontractorId),
            }));
        }
        case 47:
            return navigationContainer(new AuthorizedUrl(47, []), t("toolbox.title"), undefined, "", undefined, createElement(ConstructionSiteOverviewView, {
                UserData: props.UserData,
            }));
        case 48:
            return navigationContainer(new AuthorizedUrl(48, []), t("receivers.title"), undefined, "", undefined, createElement(NewConstructionSiteView, null));
        case 49: {
            const constructionSiteId = matchValue.fields[0];
            return navigationContainer(new AuthorizedUrl(49, [constructionSiteId]), t("receivers.title"), undefined, "", undefined, createElement(EditConstructionSiteView, {
                ConstructionSiteId: new UserId_2(constructionSiteId),
            }));
        }
        case 38:
            return navigationContainer(new AuthorizedUrl(38, []), t("toolbox.title"), undefined, "", undefined, createElement(ExternalPersonOverviewView, {
                UserData: props.UserData,
            }));
        case 39:
            return navigationContainer(new AuthorizedUrl(39, []), t("receivers.title"), undefined, "", undefined, createElement(NewExternalPersonView, null));
        case 40: {
            const externalPersonId = matchValue.fields[0];
            return navigationContainer(new AuthorizedUrl(40, [externalPersonId]), t("receivers.title"), undefined, "", undefined, createElement(EditExternalPersonView, {
                ExternalPersonId: new UserId_2(externalPersonId),
            }));
        }
        case 50:
            return navigationContainer(new AuthorizedUrl(50, []), t("toolbox.title"), undefined, "", undefined, createElement(StudentOverviewView, {
                UserData: props.UserData,
            }));
        case 51:
            return navigationContainer(new AuthorizedUrl(51, []), t("receivers.title"), undefined, "", undefined, createElement(NewStudentView, null));
        case 52: {
            const studentId = matchValue.fields[0];
            return navigationContainer(new AuthorizedUrl(52, [studentId]), t("receivers.title"), undefined, "", undefined, createElement(EditStudentView, {
                StudentId: new UserId_2(studentId),
            }));
        }
        case 53:
            return navigationContainer(new AuthorizedUrl(53, []), t("toolbox.title"), undefined, "", undefined, createElement(SchoolClassOverviewView, {
                UserData: props.UserData,
            }));
        case 54:
            return navigationContainer(new AuthorizedUrl(54, []), t("receivers.title"), undefined, "", undefined, createElement(NewSchoolClassView, null));
        case 55: {
            const schoolClassId = matchValue.fields[0];
            return navigationContainer(new AuthorizedUrl(55, [schoolClassId]), t("receivers.title"), undefined, "", undefined, createElement(EditSchoolClassView, {
                SchoolClassId: new UserId_2(schoolClassId),
            }));
        }
        case 56:
            return navigationContainer(new AuthorizedUrl(56, []), t("toolbox.title"), undefined, "", undefined, createElement(VehiclesOverviewView, {
                UserData: props.UserData,
            }));
        case 57:
            return navigationContainer(new AuthorizedUrl(57, []), t("receivers.title"), undefined, "", undefined, createElement(NewVehicleView, null));
        case 58: {
            const vehicleId = matchValue.fields[0];
            return navigationContainer(new AuthorizedUrl(58, [vehicleId]), t("receivers.title"), undefined, "", undefined, createElement(VehicleEditView, {
                VehicleId: new UserId_2(vehicleId),
            }));
        }
        case 59:
            return navigationContainer(new AuthorizedUrl(59, []), t("settings.title"), undefined, "", undefined, createElement(View, null));
        case 60:
            return navigationContainer(new AuthorizedUrl(60, []), t("settings.title"), t("navbar.systemsettings"), "settings-top-navigation", "systemsettings-top-sub-navigation", createElement(SystemSettingsView, null));
        case 61:
            return navigationContainer(new AuthorizedUrl(61, []), t("settings.title"), t("settings.custom_property.nav_header"), "settings-top-navigation", "custom-properties-top-sub-navigation", createElement(View_1, null));
        case 62:
            return navigationContainer(new AuthorizedUrl(62, []), t("settings.title"), t("settings.label"), "settings-top-navigation", "categories-sub-top-navigation", createElement(CategoriesOverviewView, null));
        case 63:
            return navigationContainer(new AuthorizedUrl(63, []), t("settings.title"), t("navbar.users"), "settings-top-navigation", "settings-user-top-navigation", createElement(UsersOverviewView, {
                UserData: props.UserData,
            }));
        case 65:
            return navigationContainer(new AuthorizedUrl(63, []), t("settings.title"), t("navbar.users"), "settings-top-navigation", "user-settings-sub-top-navigation", createElement(NewUserView, {
                OnBackPath: ["settings", "users"],
            }));
        case 64: {
            const userId_1 = matchValue.fields[0];
            return navigationContainer(new AuthorizedUrl(63, []), t("settings.title"), t("navbar.users"), "", undefined, createElement(ToolBoxView, {
                OnBackPath: ["settings", "users"],
                OnEditPath: ["settings", "users", userId_1, "edit"],
                UserData: props.UserData,
                UserId: userId_1,
            }));
        }
        case 66: {
            const userId_2 = matchValue.fields[0];
            return navigationContainer(new AuthorizedUrl(63, []), t("settings.title"), t("navbar.users"), "", undefined, createElement(EditUserView, {
                OnBackPath: ["settings", "users", userId_2],
                UserId: new UserId_2(userId_2),
            }));
        }
        case 71:
            return navigationContainer(new AuthorizedUrl(71, []), t("profile.title"), undefined, "", undefined, createElement(ProfileView, {
                TokenDataDto: props.UserData,
            }));
        case 72:
            return navigationContainer(new AuthorizedUrl(72, []), t("customerdata.title"), undefined, "", undefined, createElement(CustomerDataView, {
                UserData: props.UserData,
            }));
        case 73:
            return createElement(WizardView, null);
        case 74:
            return navigationContainer(new AuthorizedUrl(74, []), t("customerdata.title"), undefined, "", undefined, createElement(WizardComplete, null));
        case 92:
            return navigationContainer(new AuthorizedUrl(92, []), t("toolbox.title"), undefined, "receiver-top-navigation", undefined, createElement(ReceiversOverviewView, {
                UserData: props.UserData,
            }));
        case 98:
            return navigationContainer(new AuthorizedUrl(92, []), t("toolbox.title"), undefined, "", undefined, createElement(ToolBoxView, {
                OnBackPath: ["receivers"],
                UserData: props.UserData,
                UserId: matchValue.fields[0],
            }));
        case 99:
            return navigationContainer(new AuthorizedUrl(92, []), t("toolbox.title"), undefined, "", undefined, createElement(View_2, {
                EventId: matchValue.fields[1],
                UserId: matchValue.fields[0],
            }));
        case 100:
            return navigationContainer(new AuthorizedUrl(100, []), t("report.title"), undefined, "report-top-navigation", undefined, createElement(View_3, {
                Customer: props.UserData.Customer,
            }));
        case 101:
            return navigationContainer(new AuthorizedUrl(100, []), t("report.title"), undefined, "report-top-navigation", undefined, createElement(ViewWithQueryParameter, {
                Customer: props.UserData.Customer,
                Parameter: matchValue.fields[0],
            }));
        case 91:
            return navigationContainer(new AuthorizedUrl(91, []), t("support.title"), undefined, "", undefined, createElement(View_4, null));
        case 102:
            return createElement(View_5, {
                CurrentUser: props.UserData,
            });
        case 75:
            return navigationContainer(new AuthorizedUrl(75, []), t("settings.title"), t("shop.title"), "settings-top-navigation", "shop-top-sub-navigation", createElement(BarcodeShopView, null));
        case 76:
            return navigationContainer(new AuthorizedUrl(76, []), t("settings.title"), t("importer.title"), "settings-top-navigation", "dataimport-top-sub-navigation", createElement(View_6, {
                CurrentUser: props.UserData,
            }));
        case 77:
            return navigationContainer(new AuthorizedUrl(77, []), t("importer.title"), undefined, "", undefined, createElement(View_7, null));
        case 78:
            return navigationContainer(new AuthorizedUrl(78, []), t("importer.title"), undefined, "", undefined, createElement(View_8, null));
        case 79:
            return navigationContainer(new AuthorizedUrl(79, []), t("importer.title"), undefined, "", undefined, createElement(View_9, null));
        case 80:
            return navigationContainer(new AuthorizedUrl(80, []), t("importer.title"), undefined, "", undefined, createElement(View_10, null));
        case 81:
            return navigationContainer(new AuthorizedUrl(81, []), t("importer.title"), undefined, "", undefined, createElement(View_11, null));
        case 82:
            return navigationContainer(new AuthorizedUrl(82, []), t("importer.title"), undefined, "", undefined, createElement(View_12, null));
        case 89:
            return navigationContainer(new AuthorizedUrl(89, []), t("importer.title"), undefined, "", undefined, createElement(View_13, null));
        case 84:
            return navigationContainer(new AuthorizedUrl(84, []), t("importer.title"), undefined, "", undefined, createElement(View_14, null));
        case 83:
            return navigationContainer(new AuthorizedUrl(83, []), t("importer.title"), undefined, "", undefined, createElement(View_15, null));
        case 85:
            return navigationContainer(new AuthorizedUrl(85, []), t("importer.title"), undefined, "", undefined, createElement(View_16, null));
        case 86:
            return navigationContainer(new AuthorizedUrl(86, []), t("importer.title"), undefined, "", undefined, createElement(View_17, null));
        case 87:
            return navigationContainer(new AuthorizedUrl(87, []), t("importer.title"), undefined, "", undefined, createElement(View_18, null));
        case 88:
            return navigationContainer(new AuthorizedUrl(88, []), t("importer.title"), undefined, "", undefined, createElement(View_19, null));
        case 90:
            return navigationContainer(new AuthorizedUrl(90, []), t("importer.title"), undefined, "", undefined, createElement(ImporterView, null));
        case 67:
            return navigationContainer(new AuthorizedUrl(67, []), t("general.dispolist"), undefined, "inventorylist-top-navigation", undefined, createElement(OverviewView_1, {
                UserData: props.UserData,
            }));
        case 68:
            return navigationContainer(new AuthorizedUrl(68, []), t("general.dispolist"), undefined, "", undefined, createElement(NewInventoryListView, {
                UserData: props.UserData,
            }));
        case 69: {
            const inventoryListId = matchValue.fields[0];
            return navigationContainer(new AuthorizedUrl(69, [inventoryListId]), t("dispolist.title"), undefined, "", undefined, createElement(DetailInventoryListView, {
                DispolistId: inventoryListId,
                UserData: props.UserData,
            }));
        }
        case 70: {
            const inventoryListId_1 = matchValue.fields[0];
            return navigationContainer(new AuthorizedUrl(70, [inventoryListId_1]), t("dispolist.title"), undefined, "", undefined, createElement(EditInventoryListView, {
                DispolistId: inventoryListId_1,
                UserData: props.UserData,
            }));
        }
        case 93:
            return navigationContainer(new AuthorizedUrl(93, []), t("navbar.calendar"), undefined, "calendar-top-navigation", undefined, createElement(CalendarView, {
                UserData: props.UserData,
            }));
        case 94:
            return navigationContainer(new AuthorizedUrl(94, []), t("navbar.reservation"), undefined, "reservation-top-navigation", undefined, createElement(ReservationView, {
                UserData: props.UserData,
            }));
        case 95:
            return navigationContainer(new AuthorizedUrl(95, []), t("navbar.reservation"), undefined, "", undefined, createElement(NewReservationForm, {
                UserData: props.UserData,
            }));
        case 97:
            return navigationContainer(new AuthorizedUrl(94, []), t("navbar.reservation"), undefined, "reservation-top-navigation", undefined, createElement(DetailReservationView, {
                ReservationId: matchValue.fields[0],
                UserData: props.UserData,
            }));
        case 96:
            return navigationContainer(new AuthorizedUrl(94, []), t("navbar.reservation"), undefined, "reservation-top-navigation", undefined, createElement(EditReservationForm, {
                ReservationId: matchValue.fields[0],
                UserData: props.UserData,
            }));
        case 103:
            return navigationContainer(new AuthorizedUrl(103, []), t("set.overview.title"), undefined, "set-top-navigation", undefined, createElement(OverviewView_2, {
                UserData: props.UserData,
            }));
        case 104:
            return navigationContainer(new AuthorizedUrl(103, []), t("set.set"), undefined, "", undefined, createElement(NewSetView, {
                UserData: props.UserData,
            }));
        case 106:
            return navigationContainer(new AuthorizedUrl(103, []), t("set.set"), undefined, "", undefined, createElement(SetDetailView, {
                SetId: matchValue.fields[0],
                UserData: props.UserData,
            }));
        case 107:
            return navigationContainer(new AuthorizedUrl(103, []), t("set.set"), undefined, "", undefined, createElement(EditSetView, {
                SetId: new SetId(matchValue.fields[0]),
                UserData: props.UserData,
            }));
        case 105:
            return navigationContainer(new AuthorizedUrl(103, []), t("set.set"), undefined, "", undefined, createElement(CopySetView, {
                SetId: new SetId(matchValue.fields[0]),
                UserData: props.UserData,
            }));
        case 108:
            return createElement(BadRequest, null);
        case 109:
            return createElement(Unauthorized, null);
        case 110:
            return createElement(Forbidden, null);
        case 111:
            return createElement(NotFound, null);
        case 112:
            return createElement(InternalServerError, null);
        case 113:
            return createElement(LoadingPage, null);
        default:
            return navigationContainer(new AuthorizedUrl(59, []), t("stocktaking.stocktaking"), undefined, "stocktaking-top-navigation", undefined, createElement(StocktakingOverview, null));
    }
}

export function ActivePage(props) {
    const auth = useAuth();
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const hasTriedSignin = patternInput[0];
    useReact_useEffect_311B4086(() => {
        if ((props.State.UserData == null) && auth.isAuthenticated) {
            props.Dispatch(new Msg(2, []));
        }
        if ((((!hasAuthParams() && !auth.isAuthenticated) && (auth.activeNavigator == null)) && !auth.isLoading) && !hasTriedSignin) {
            auth.signinRedirect();
            patternInput[1](true);
        }
    }, [auth, hasTriedSignin, props.State.UserData]);
    const matchValue = props.State.CurrentUrl;
    const matchValue_1 = props.State.UserData;
    const matchValue_2 = auth.isLoading;
    let matchResult, url, userData;
    switch (matchValue.fields[0].tag) {
        case 108: {
            matchResult = 0;
            break;
        }
        case 109: {
            matchResult = 1;
            break;
        }
        case 110: {
            matchResult = 2;
            break;
        }
        case 111: {
            matchResult = 3;
            break;
        }
        case 112: {
            matchResult = 4;
            break;
        }
        default:
            if (matchValue_1 == null) {
                if (matchValue_2) {
                    matchResult = 7;
                }
                else {
                    matchResult = 6;
                }
            }
            else if (matchValue_2) {
                matchResult = 7;
            }
            else {
                matchResult = 5;
                url = matchValue.fields[0];
                userData = matchValue_1;
            }
    }
    switch (matchResult) {
        case 0:
            return createElement(BadRequest, null);
        case 1:
            return createElement(Unauthorized, null);
        case 2:
            return createElement(Forbidden, null);
        case 3:
            return createElement(NotFound, null);
        case 4:
            return createElement(InternalServerError, null);
        case 5: {
            const matchValue_4 = props.State.AccountSettings;
            const matchValue_5 = props.State.Configuration;
            let matchResult_1, accountSettings, configuration;
            if (matchValue_4.tag === 0) {
                matchResult_1 = 1;
            }
            else if (matchValue_5.tag === 0) {
                matchResult_1 = 1;
            }
            else {
                matchResult_1 = 0;
                accountSettings = matchValue_4.fields[0];
                configuration = matchValue_5.fields[0];
            }
            switch (matchResult_1) {
                case 0:
                    return createElement(ActiveAuthorizedPage, {
                        AccountSettings: accountSettings,
                        Configuration: configuration,
                        Dispatch: props.Dispatch,
                        IsInTestphase: props.State.IsInTestphase,
                        Url: url,
                        UserData: userData,
                    });
                default:
                    return createElement(LoadingPage, null);
            }
        }
        case 6:
            return createElement(LoadingPage, null);
        default:
            return createElement(LoadingPage, null);
    }
}

