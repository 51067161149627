import { Union, Record } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { ActivatedEmailNotification_$reflection, User_$reflection } from "../../Shared/User.js";
import { ClientApiResponse$1_$reflection, FormState_$reflection, CustomerConfiguration_$reflection, RequestedValue$1_$reflection, StorageId_$reflection } from "../../Shared/Shared.js";
import { union_type, class_type, array_type, bool_type, option_type, string_type, record_type, list_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { Storage_$reflection } from "../../Shared/Storage.js";
import { PropertyConfigurationEnabledResponse_$reflection, CustomProperty_$reflection, PropertyConfiguration_$reflection } from "../../Shared/PropertyConfiguration.js";
import { OptionType$1_$reflection } from "../../SharedComponents/ReactSelect.js";

export class UserForm extends Record {
    constructor(User, SelectedStorages) {
        super();
        this.User = User;
        this.SelectedStorages = SelectedStorages;
    }
}

export function UserForm_$reflection() {
    return record_type("Receiver.User.Types.UserForm", [], UserForm, () => [["User", User_$reflection()], ["SelectedStorages", list_type(StorageId_$reflection())]]);
}

export class State extends Record {
    constructor(UserId, Form, Storages, CustomerConfiguration, DepartmentSuggestions, IsCustomPropertiesEnabled, PropertyConfigurations, FormState) {
        super();
        this.UserId = UserId;
        this.Form = Form;
        this.Storages = Storages;
        this.CustomerConfiguration = CustomerConfiguration;
        this.DepartmentSuggestions = DepartmentSuggestions;
        this.IsCustomPropertiesEnabled = IsCustomPropertiesEnabled;
        this.PropertyConfigurations = PropertyConfigurations;
        this.FormState = FormState;
    }
}

export function State_$reflection() {
    return record_type("Receiver.User.Types.State", [], State, () => [["UserId", option_type(string_type)], ["Form", RequestedValue$1_$reflection(UserForm_$reflection())], ["Storages", RequestedValue$1_$reflection(list_type(Storage_$reflection()))], ["CustomerConfiguration", RequestedValue$1_$reflection(CustomerConfiguration_$reflection())], ["DepartmentSuggestions", RequestedValue$1_$reflection(list_type(string_type))], ["IsCustomPropertiesEnabled", RequestedValue$1_$reflection(bool_type)], ["PropertyConfigurations", RequestedValue$1_$reflection(list_type(PropertyConfiguration_$reflection()))], ["FormState", FormState_$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetPersonnelNumber", "SetDepartment", "SetEmail", "SetPhoneNumber", "SetName", "SetRole", "SetAllowUserToSubcontractorTransfer", "SetCustomProperties", "ChangeEmailNotification", "AddStorage", "SetLoginWithPhoneNumber", "CreateUser", "UpdateUser", "SetInitialStorageIds", "FetchUser", "FetchUserAfterTransformToUserWithLogin", "UserFetched", "UserSaved", "StoragesFetched", "CustomerConfigurationFetched", "DepartmentSuggestionsFetched", "CustomPropertiesEnabledFetched", "PropertyConfigurationsFetched", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Receiver.User.Types.Msg", [], Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", option_type(OptionType$1_$reflection(string_type))]], [["Item", bool_type]], [["Item", list_type(CustomProperty_$reflection())]], [["Item", ActivatedEmailNotification_$reflection()]], [["Item", array_type(OptionType$1_$reflection(Storage_$reflection()))]], [["Item", bool_type]], [], [], [], [], [], [["Item", User_$reflection()]], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", list_type(Storage_$reflection())]], [["Item", CustomerConfiguration_$reflection()]], [["Item", list_type(string_type)]], [["Item", PropertyConfigurationEnabledResponse_$reflection()]], [["Item", list_type(PropertyConfiguration_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

