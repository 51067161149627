import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormContainer } from "../../Components/FormContainer.js";
import { empty, singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { Input } from "../../Components/Input.js";
import { Msg } from "./Types.js";
import { unwrap } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { FormState__getValidation } from "../../Shared/Shared.js";
import { NewReceiverCustomProperties } from "../../Widgets/CustomProperties.js";
import { PropertyConfigurationVisibility } from "../../Shared/PropertyConfiguration.js";
import { Table_UserVisibleTable } from "../UserSelect.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { initEdit, update as update_1, initNew } from "./State.js";
import { RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Skeleton } from "../../Components/Skeleton.js";
import { unwrapUserId } from "../../Shared/Helper.js";

export function VehicleForm(props) {
    const t = useTranslation()[0];
    const componentState = props.FormState.IsLoading ? "disabled" : "enabled";
    return createElement(FormContainer, {
        Children: toList(delay(() => append(singleton(createElement(Input, {
            ComponentState: componentState,
            Label: t("general.name"),
            OnChange: (name) => {
                props.Dispatch(new Msg(0, [name]));
            },
            TestId: "vehicle-form-name-input",
            ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "name")),
            Value: props.Vehicle.Name,
        })), delay(() => append(singleton(createElement(Input, {
            ComponentState: componentState,
            Label: t("vehicle.numberplate"),
            OnChange: (numberPlate) => {
                props.Dispatch(new Msg(1, [numberPlate]));
            },
            TestId: "vehicle-form-numberplate-input",
            ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "numberplate")),
            Value: props.Vehicle.NumberPlate,
        })), delay(() => append(singleton(createElement(Input, {
            ComponentState: componentState,
            Label: t("vehicle.manufacturer"),
            OnChange: (manufacturer) => {
                props.Dispatch(new Msg(2, [manufacturer]));
            },
            TestId: "vehicle-form-manufacturer-input",
            ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "manufacturer")),
            Value: props.Vehicle.Manufacturer,
        })), delay(() => append(singleton(createElement(Input, {
            ComponentState: componentState,
            Label: t("vehicle.model"),
            OnChange: (model) => {
                props.Dispatch(new Msg(3, [model]));
            },
            TestId: "vehicle-form-model-input",
            ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "model")),
            Value: props.Vehicle.Model,
        })), delay(() => append(props.IsCustomPropertiesEnabled ? singleton(createElement(NewReceiverCustomProperties, {
            CustomProperties: props.Vehicle.CustomProperties,
            FormState: props.FormState,
            OnSetCustomProperty: (arg) => {
                props.Dispatch(new Msg(4, [arg]));
            },
            PropertyConfigurationType: new PropertyConfigurationVisibility(9, []),
            PropertyConfigurations: props.PropertyConfigurations,
        })) : empty(), delay(() => singleton(createElement(Table_UserVisibleTable, {
            IsOpen: props.VisibleUserModalIsOpen,
            OnClose: () => {
                props.Dispatch(new Msg(7, []));
            },
            OnDelete: props.OnDeleteVisibleUserIds,
            OnSave: props.OnSaveVisibleUserIds,
            PropertyConfigurations: props.PropertyConfigurations,
            SetIsOpen: () => {
                props.Dispatch(new Msg(7, []));
            },
            Users: props.Users,
            VisibleForUserIds: props.Vehicle.VisibleForUserIds,
        })))))))))))))),
        Header: props.Header,
        OnBack: props.OnBack,
        OnSave: props.OnSave,
    });
}

export function NewVehicleView() {
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(initNew, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useTranslation();
    const matchValue = state_1.Form;
    const matchValue_1 = state_1.IsCustomPropertiesEnabled;
    const matchValue_2 = state_1.PropertyConfigurations;
    const matchValue_3 = state_1.Users;
    let matchResult, isCustomPropertiesEnabled, propertyConfigurations, users, vehicle;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_2.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_3.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        isCustomPropertiesEnabled = matchValue_1.fields[0];
        propertyConfigurations = matchValue_2.fields[0];
        users = matchValue_3.fields[0];
        vehicle = matchValue.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement(VehicleForm, {
                Dispatch: dispatch,
                FormState: state_1.FormState,
                Header: patternInput_1[0]("vehicle.create_new_vehicle"),
                IsCustomPropertiesEnabled: isCustomPropertiesEnabled,
                OnBack: () => {
                    RouterModule_nav(ofArray(["receivers", "vehicles"]), 1, 1);
                },
                OnDeleteVisibleUserIds: (userIds_1) => {
                    dispatch(new Msg(9, [userIds_1]));
                },
                OnSave: () => {
                    dispatch(new Msg(5, []));
                },
                OnSaveVisibleUserIds: (userIds) => {
                    dispatch(new Msg(8, [userIds]));
                },
                PropertyConfigurations: propertyConfigurations,
                Users: users,
                Vehicle: vehicle,
                VisibleUserModalIsOpen: state_1.VisibleUserModalIsOpen,
            });
        default:
            return createElement(Skeleton, {
                Variant: "detail",
            });
    }
}

export function VehicleEditView(props) {
    let patternInput;
    const init = initEdit(props.VehicleId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, [props.VehicleId]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useTranslation();
    const matchValue = state_1.Form;
    const matchValue_1 = state_1.IsCustomPropertiesEnabled;
    const matchValue_2 = state_1.PropertyConfigurations;
    const matchValue_3 = state_1.Users;
    let matchResult, isCustomPropertiesEnabled, propertyConfigurations, users, vehicle;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_2.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_3.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        isCustomPropertiesEnabled = matchValue_1.fields[0];
        propertyConfigurations = matchValue_2.fields[0];
        users = matchValue_3.fields[0];
        vehicle = matchValue.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement(VehicleForm, {
                Dispatch: dispatch,
                FormState: state_1.FormState,
                Header: patternInput_1[0]("vehicle.edit_vehicle"),
                IsCustomPropertiesEnabled: isCustomPropertiesEnabled,
                OnBack: () => {
                    RouterModule_nav(ofArray(["receivers", unwrapUserId(props.VehicleId)]), 1, 1);
                },
                OnDeleteVisibleUserIds: (userIds_1) => {
                    dispatch(new Msg(9, [userIds_1]));
                },
                OnSave: () => {
                    dispatch(new Msg(6, []));
                },
                OnSaveVisibleUserIds: (userIds) => {
                    dispatch(new Msg(8, [userIds]));
                },
                PropertyConfigurations: propertyConfigurations,
                Users: users,
                Vehicle: vehicle,
                VisibleUserModalIsOpen: state_1.VisibleUserModalIsOpen,
            });
        default:
            return createElement(Skeleton, {
                Variant: "detail",
            });
    }
}

