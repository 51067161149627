import { createElement } from "react";
import React from "react";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { ofArray, toArray } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { getMessages, localization } from "../SharedComponents/ReactBigCalendar.js";
import { currentLanguage } from "../LocalStorageHelper.js";
import { utcNow } from "../fable_modules/fable-library-js.4.19.2/Date.js";
import { defaultOf } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { Calendar } from "react-big-calendar";
import { Interop_reactApi as Interop_reactApi_1 } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";

export function BigCalendar(props) {
    let elems, properties, defaults;
    return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems = [(properties = ofArray([["key", "tool-reservation-calendar"], ["events", toArray(props.Events)], ["localizer", localization(currentLanguage(undefined))], ["onSelectEvent", props.OnSelect], ["popup", true], ["endAccessor", "end"], ["messages", getMessages(currentLanguage(undefined))], ["startAccessor", "start"], ["rangeStartMin", utcNow()]]), (defaults = {
        body: defaultOf(),
    }, Interop_reactApi.createElement(Calendar, Object.assign({}, defaults, createObj(properties)))))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))])])));
}

