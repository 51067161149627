import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";

export const spinner = createElement("div", createObj(ofArray([["className", "spinner-grow .spinner ml-auto mr-auto mt-auto mb-auto"], ["role", join(" ", ["status"])], (() => {
    const elems = [createElement("span", {
        className: "sr-only",
        children: "Loading ...",
    })];
    return ["children", Interop_reactApi.Children.toArray(Array.from(elems))];
})()])));

