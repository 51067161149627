import { createElement } from "react";
import React from "react";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { singleton, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { BlockHeader } from "../Components/Text.js";
import { singleton as singleton_1, ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";

export function SectionContainer(props) {
    let elems_3;
    const content = createElement("div", {
        className: join(" ", ["flex", "flex-shrink-0", "flex-col", "mb-3", "bg-white", "rounded", "px-12", "py-9"]),
        children: Interop_reactApi.Children.toArray([props.Content]),
    });
    return createElement("div", createObj(ofArray([["className", join(" ", ["mt-8"])], (elems_3 = toList(delay(() => {
        let elems_2, elems_1;
        const matchValue = props.Title;
        return (matchValue == null) ? singleton(content) : singleton(createElement("div", createObj(singleton_1((elems_2 = [createElement("div", createObj(ofArray([(elems_1 = [createElement(BlockHeader, {
            Text: matchValue,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))]), ["className", join(" ", ["mb-4"])]]))), content], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
}

export function TableContainer(props) {
    let elems_4;
    return createElement("div", createObj(ofArray([["className", join(" ", ["mt-8"])], (elems_4 = toList(delay(() => {
        let elems_2, elems;
        const matchValue = props.Title;
        return (matchValue == null) ? singleton(createElement("div", {
            className: join(" ", ["bg-white"]),
            children: Interop_reactApi.Children.toArray([props.Content]),
        })) : singleton(createElement("div", createObj(singleton_1((elems_2 = [createElement("div", createObj(ofArray([(elems = [createElement(BlockHeader, {
            Text: matchValue,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]), ["className", join(" ", ["mb-4"])]]))), createElement("div", {
            className: join(" ", ["bg-white"]),
            children: Interop_reactApi.Children.toArray([props.Content]),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])));
}

