import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useReact_useRef_1505 } from "../fable_modules/Feliz.2.7.0/React.fs.js";
import { Dialog } from "./Dialog.js";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { singleton, ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import react_signature_canvas from "react-signature-canvas";
import { Interop_reactApi as Interop_reactApi_1 } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { TextButton } from "./Button.js";

export function SignatureDialog(props) {
    let Title, elems, props_1;
    const t = useTranslation()[0];
    const signaturePad = useReact_useRef_1505(undefined);
    return createElement(Dialog, (Title = t("general.signature"), {
        Body: singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "h-[calc(100vh-13rem)]", "w-[calc(100vw-6.5rem)]"])], (elems = [(props_1 = ofArray([["canvasProps", {
            className: "grow",
        }], ["backgroundColor", "rgb(255, 255, 255)"], ["ref", signaturePad]]), Interop_reactApi.createElement(react_signature_canvas, createObj(props_1))), createElement("div", {
            className: join(" ", ["border"]),
        }), createElement("div", {
            children: props.Description,
        })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))])])))),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "confirmation-dialog-onClose-button",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.save"),
            OnClick: () => {
                const matchValue = signaturePad.current;
                if (matchValue == null) {
                }
                else {
                    const signaturePad_1 = matchValue;
                    signaturePad_1.getCanvas().toBlob((blob) => {
                        props.OnConfirm(new File([blob], "signature.png"));
                    });
                }
            },
            TestId: "confirmation-dialog-onConfirm-button",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

