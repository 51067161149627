import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, lambda_type, unit_type, union_type, list_type, class_type, bool_type, option_type, anonRecord_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { Helper_receiverTypeNameTranslation, ReceiverTypeName, Receiver_$reflection } from "../../Shared/User.js";
import { ToolReservationFormDto, ToolDetailDto_$reflection, ToolReservationResponse_$reflection, ToolReservationFormDto_$reflection } from "../../Shared/Tool.js";
import { ToolReservationId, EmptyResponse_$reflection, ToolId as ToolId_1, RequestedValue$1, TokenDataDto_$reflection, RequestedValue$1_$reflection, CustomerConfiguration_$reflection, PostResponse$1_$reflection } from "../../Shared/Shared.js";
import { Cmd_ofEffect, Cmd_none, Cmd_batch, Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { sortBy, toArray, map, tryFind, filter, find, singleton, empty, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { Cmd_OfPromise_either as Cmd_OfPromise_either_1 } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { getCustomerConfiguration } from "../../Requests/Configuration.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { safeHash, comparePrimitives, equals, createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { singleton as singleton_1, empty as empty_1, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { utcNow, toString as toString_1, compare } from "../../fable_modules/fable-library-js.4.19.2/Date.js";
import { InformationAlert, WarningAlert } from "../../Components/Alert.js";
import { Interop_reactApi } from "../../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { ToolInfo } from "../../Widgets/ToolInfoWidget.js";
import { parse } from "../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { GroupedSelect } from "../../Components/Select.js";
import { List_groupBy } from "../../fable_modules/fable-library-js.4.19.2/Seq2.js";
import { value as value_10, unwrap } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { useReact_useMemo_10C6A43C } from "../../fable_modules/Feliz.2.7.0/React.fs.js";
import { AutoSuggestInput } from "../../Components/Input.js";
import { DatePicker } from "../../Components/DatePicker.js";
import { TextArea } from "../../Components/TextArea.js";
import { defaultOf } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { Checkbox } from "../../Components/Checkbox.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Dialog } from "../../Components/Dialog.js";
import { Skeleton } from "../../Components/Skeleton.js";
import { TextButton } from "../../Components/Button.js";
import { ReservationDto_$reflection } from "../../Shared/Planning.js";
import { Cmd_getToolDetailDto } from "../../Requests/Tool.js";

class NewReservation_Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetSelectableReceivers", "SetSelectedReceiver", "SetName", "SetIsReceiverNotificationSet", "SetDescription", "SetStartDate", "SetEndDate", "SaveToolReservation", "ToolReservationSaved", "ReservationNameSuggestionsFetched", "ReceiversFetched", "CustomerConfigurationFetched", "FetchError"];
    }
}

function NewReservation_Msg_$reflection() {
    return union_type("Tools.ToolReservationDialog.NewReservation.Msg", [], NewReservation_Msg, () => [[], [["Item", option_type(anonRecord_type(["label", string_type], ["value", Receiver_$reflection()]))]], [["Item", string_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", class_type("System.DateTime")]], [["Item", class_type("System.DateTime")]], [["Item", ToolReservationFormDto_$reflection()]], [["Item", PostResponse$1_$reflection(ToolReservationResponse_$reflection())]], [["Item", list_type(string_type)]], [["Item", list_type(Receiver_$reflection())]], [["Item", CustomerConfiguration_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

function NewReservation_postToolReservationCmd(dto) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(ToolReservationResponse_$reflection())));
    })))), ["/api/tools/reservations", toString(0, Auto_generateBoxedEncoder_437914C6(ToolReservationFormDto_$reflection(), undefined, undefined, undefined)(dto))], (Item) => (new NewReservation_Msg(8, [Item])), (Item_1) => (new NewReservation_Msg(12, [Item_1])));
}

class NewReservation_State extends Record {
    constructor(Tool, Loading, SuccessCallback, StartDate, EndDate, Description, Name, SelectedUser, IsUserNotificationSet, OnClose, ReservationNameSuggestions, Receivers, CustomerConfiguration, UserData) {
        super();
        this.Tool = Tool;
        this.Loading = Loading;
        this.SuccessCallback = SuccessCallback;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.Description = Description;
        this.Name = Name;
        this.SelectedUser = SelectedUser;
        this.IsUserNotificationSet = IsUserNotificationSet;
        this.OnClose = OnClose;
        this.ReservationNameSuggestions = ReservationNameSuggestions;
        this.Receivers = Receivers;
        this.CustomerConfiguration = CustomerConfiguration;
        this.UserData = UserData;
    }
}

function NewReservation_State_$reflection() {
    return record_type("Tools.ToolReservationDialog.NewReservation.State", [], NewReservation_State, () => [["Tool", ToolDetailDto_$reflection()], ["Loading", bool_type], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["StartDate", class_type("System.DateTime")], ["EndDate", class_type("System.DateTime")], ["Description", string_type], ["Name", string_type], ["SelectedUser", option_type(anonRecord_type(["label", string_type], ["value", Receiver_$reflection()]))], ["IsUserNotificationSet", bool_type], ["OnClose", lambda_type(unit_type, unit_type)], ["ReservationNameSuggestions", list_type(string_type)], ["Receivers", RequestedValue$1_$reflection(list_type(Receiver_$reflection()))], ["CustomerConfiguration", RequestedValue$1_$reflection(CustomerConfiguration_$reflection())], ["UserData", TokenDataDto_$reflection()]]);
}

function NewReservation_init(props) {
    return [new NewReservation_State(props.Tool, false, props.SuccessCallback, props.StartDate, props.EndDate, props.Description, props.Name, undefined, props.IsUserNotificationSet, props.OnClose, empty(), new RequestedValue$1(0, []), new RequestedValue$1(0, []), props.UserData), Cmd_batch(ofArray([Cmd_OfPromise_either_1((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(string_type)));
    })))), "/api/tools/reservations/name-suggestions", (Item) => (new NewReservation_Msg(9, [Item])), (Item_1) => (new NewReservation_Msg(12, [Item_1]))), Cmd_OfPromise_either_1((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(Receiver_$reflection())));
    })))), "/api/receivers/active", (Item_2) => (new NewReservation_Msg(10, [Item_2])), (Item_3) => (new NewReservation_Msg(12, [Item_3]))), getCustomerConfiguration((Item_4) => (new NewReservation_Msg(11, [Item_4])), (Item_5) => (new NewReservation_Msg(12, [Item_5])))]))];
}

function NewReservation_update(msg, state) {
    let matchValue_6, user_1;
    switch (msg.tag) {
        case 1:
            return [new NewReservation_State(state.Tool, state.Loading, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, msg.fields[0], state.IsUserNotificationSet, state.OnClose, state.ReservationNameSuggestions, state.Receivers, state.CustomerConfiguration, state.UserData), Cmd_none()];
        case 3:
            return [new NewReservation_State(state.Tool, state.Loading, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, msg.fields[0], state.OnClose, state.ReservationNameSuggestions, state.Receivers, state.CustomerConfiguration, state.UserData), Cmd_none()];
        case 2:
            return [new NewReservation_State(state.Tool, state.Loading, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, msg.fields[0], state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ReservationNameSuggestions, state.Receivers, state.CustomerConfiguration, state.UserData), Cmd_none()];
        case 4:
            return [new NewReservation_State(state.Tool, state.Loading, state.SuccessCallback, state.StartDate, state.EndDate, msg.fields[0], state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ReservationNameSuggestions, state.Receivers, state.CustomerConfiguration, state.UserData), Cmd_none()];
        case 5:
            return [new NewReservation_State(state.Tool, state.Loading, state.SuccessCallback, msg.fields[0], state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ReservationNameSuggestions, state.Receivers, state.CustomerConfiguration, state.UserData), Cmd_none()];
        case 6:
            return [new NewReservation_State(state.Tool, state.Loading, state.SuccessCallback, state.StartDate, msg.fields[0], state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ReservationNameSuggestions, state.Receivers, state.CustomerConfiguration, state.UserData), Cmd_none()];
        case 7:
            return [new NewReservation_State(state.Tool, true, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ReservationNameSuggestions, state.Receivers, state.CustomerConfiguration, state.UserData), NewReservation_postToolReservationCmd(msg.fields[0])];
        case 8: {
            const matchValue_5 = msg.fields[0].Result;
            switch (matchValue_5.tag) {
                case 1: {
                    state.SuccessCallback();
                    return [new NewReservation_State(state.Tool, false, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ReservationNameSuggestions, state.Receivers, state.CustomerConfiguration, state.UserData), Cmd_none()];
                }
                case 2:
                    return [new NewReservation_State(state.Tool, false, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ReservationNameSuggestions, state.Receivers, state.CustomerConfiguration, state.UserData), Cmd_errorToast("dialog.reservation.toast_messages.already_exists")];
                case 3:
                    return [new NewReservation_State(state.Tool, false, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ReservationNameSuggestions, state.Receivers, state.CustomerConfiguration, state.UserData), Cmd_none()];
                default: {
                    state.SuccessCallback();
                    return [new NewReservation_State(state.Tool, false, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ReservationNameSuggestions, state.Receivers, state.CustomerConfiguration, state.UserData), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                        state.SuccessCallback();
                    }), Cmd_ofEffect((_arg_1) => {
                        state.OnClose();
                    }), Cmd_successToast("dialog.reservation.toast_messages.created")]))];
                }
            }
        }
        case 9:
            return [new NewReservation_State(state.Tool, state.Loading, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, msg.fields[0], state.Receivers, state.CustomerConfiguration, state.UserData), Cmd_none()];
        case 10: {
            const response_2 = msg.fields[0];
            return [new NewReservation_State(state.Tool, state.Loading, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, (matchValue_6 = state.UserData.Role, (matchValue_6 === "administrator") ? undefined : ((matchValue_6 === "system") ? undefined : ((matchValue_6 === "toolManager") ? undefined : ((user_1 = find((user) => (user.Id === state.UserData.UserId), response_2), {
                label: user_1.Name,
                value: user_1,
            }))))), state.IsUserNotificationSet, state.OnClose, state.ReservationNameSuggestions, new RequestedValue$1(1, [response_2]), state.CustomerConfiguration, state.UserData), singleton((dispatch) => {
                dispatch(new NewReservation_Msg(0, []));
            })];
        }
        case 11:
            return [new NewReservation_State(state.Tool, state.Loading, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ReservationNameSuggestions, state.Receivers, new RequestedValue$1(1, [msg.fields[0]]), state.UserData), singleton((dispatch_1) => {
                dispatch_1(new NewReservation_Msg(0, []));
            })];
        case 12:
            return [new NewReservation_State(state.Tool, false, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ReservationNameSuggestions, state.Receivers, state.CustomerConfiguration, state.UserData), Cmd_none()];
        default: {
            const matchValue = state.Receivers;
            const matchValue_1 = state.CustomerConfiguration;
            let matchResult, customerConfiguration, receivers;
            if (matchValue.tag === 1) {
                if (matchValue_1.tag === 1) {
                    matchResult = 1;
                    customerConfiguration = matchValue_1.fields[0];
                    receivers = matchValue.fields[0];
                }
                else {
                    matchResult = 0;
                }
            }
            else {
                matchResult = 0;
            }
            switch (matchResult) {
                case 0:
                    return [state, Cmd_none()];
                default: {
                    const matchValue_3 = state.UserData.Role;
                    switch (matchValue_3) {
                        case "user":
                            return [new NewReservation_State(state.Tool, state.Loading, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ReservationNameSuggestions, new RequestedValue$1(1, [filter((receiver) => {
                                const matchValue_4 = receiver.ReceiverType;
                                switch (matchValue_4.tag) {
                                    case 9:
                                        return customerConfiguration.ReceiverCreateReservationAllowedConfiguration.Vehicles;
                                    case 8:
                                        return customerConfiguration.ReceiverCreateReservationAllowedConfiguration.SchoolClasses;
                                    case 7:
                                        return customerConfiguration.ReceiverCreateReservationAllowedConfiguration.Students;
                                    case 2:
                                        return customerConfiguration.ReceiverCreateReservationAllowedConfiguration.ExternalPersons;
                                    case 4:
                                        return customerConfiguration.ReceiverCreateReservationAllowedConfiguration.Rooms;
                                    case 5:
                                        return customerConfiguration.ReceiverCreateReservationAllowedConfiguration.Projects;
                                    case 3:
                                        return customerConfiguration.ReceiverCreateReservationAllowedConfiguration.Subcontractos;
                                    case 6:
                                        return customerConfiguration.ReceiverCreateReservationAllowedConfiguration.ConstructionSites;
                                    case 0:
                                        if (!customerConfiguration.ReceiverCreateReservationAllowedConfiguration.Users) {
                                            return receiver.Id === state.UserData.UserId;
                                        }
                                        else {
                                            return customerConfiguration.ReceiverCreateReservationAllowedConfiguration.Users;
                                        }
                                    default:
                                        return customerConfiguration.ReceiverCreateReservationAllowedConfiguration.Employees;
                                }
                            }, receivers)]), state.CustomerConfiguration, state.UserData), Cmd_none()];
                        default:
                            return [state, Cmd_none()];
                    }
                }
            }
        }
    }
}

function NewReservation_DialogBody(props) {
    let elems_4;
    const patternInput = useTranslation();
    const t = patternInput[0];
    const formState = props.IsLoading ? "disabled" : "enabled";
    return createElement("div", createObj(ofArray([["id", "tool-reservation-dialog"], ["className", join(" ", ["grid", "gap-5"])], (elems_4 = toList(delay(() => {
        let matchValue, reminder, elems, arg_1;
        return append((matchValue = tryFind((r_1) => {
            if (compare(r_1.Date, props.StartDate) >= 0) {
                return compare(r_1.Date, props.EndDate) <= 0;
            }
            else {
                return false;
            }
        }, filter((r) => !r.IsConfirmed, props.ReminderDates)), (matchValue == null) ? (empty_1()) : ((reminder = matchValue, singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["w-96"])], (elems = [createElement(WarningAlert, {
            Label: (arg_1 = {
                dateString: toString_1(reminder.Date, "dd.MM.yyyy"),
                reminderName: reminder.Title,
            }, patternInput[1].t("tool.reservation_dialog.overlapping_date_warning", arg_1)),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))))))), delay(() => {
            let elems_1;
            return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_1 = [createElement(ToolInfo, {
                DisplayReservationAlert: false,
                ToolId: new ToolId_1(parse(props.Tool.Id)),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => append(singleton_1(createElement(GroupedSelect, (($value) => { $value.key = $value.Key; return $value; })({
                ComponentState: formState,
                IsClearable: false,
                Key: "assign-tool-select",
                Label: t("general.user"),
                NoOptionsMessage: "select.employee_no_options",
                OnChange: props.SetSelectedReceiver,
                Options: map((tupledArg) => {
                    const receiverType = tupledArg[0];
                    const receivers_1 = toArray(sortBy((receiver_2) => receiver_2.label.toLowerCase(), map((receiver_1) => {
                        const matchValue_2 = receiver_1.LocationName;
                        if (matchValue_2 != null) {
                            const locationName = matchValue_2;
                            if (equals(receiverType, new ReceiverTypeName(4, []))) {
                                return {
                                    label: `${locationName} - ${receiver_1.Name}`,
                                    value: receiver_1,
                                };
                            }
                            else {
                                return {
                                    label: `${receiver_1.Name} - ${locationName}`,
                                    value: receiver_1,
                                };
                            }
                        }
                        else {
                            return {
                                label: `${receiver_1.Name}`,
                                value: receiver_1,
                            };
                        }
                    }, tupledArg[1]), {
                        Compare: comparePrimitives,
                    }));
                    return {
                        label: Helper_receiverTypeNameTranslation(t, receiverType),
                        options: receivers_1,
                    };
                }, List_groupBy((receiver) => receiver.ReceiverType, filter((u) => {
                    if (props.IsReceiverNotificationSet) {
                        const matchValue_1 = u.ReceiverType;
                        switch (matchValue_1.tag) {
                            case 1:
                            case 5:
                            case 8:
                            case 6:
                            case 2:
                            case 7:
                            case 3:
                            case 9:
                            case 4:
                                return false;
                            default:
                                return true;
                        }
                    }
                    else {
                        return true;
                    }
                }, props.Users), {
                    Equals: equals,
                    GetHashCode: safeHash,
                })),
                PlaceholderKey: "select.employee_placeholder",
                Value: unwrap(props.SelectedReceiver),
            }))), delay(() => append(singleton_1(useReact_useMemo_10C6A43C(() => createElement(AutoSuggestInput, {
                ComponentState: formState,
                Label: t("general.name"),
                OnChange: props.SetReservationName,
                Suggestions: props.ReservervationNameSuggestions,
                TestId: "tool-reservation-dialog-name-test-id",
                Value: props.ReservationName,
            }), [props.ReservervationNameSuggestions, props.ReservationName, formState])), delay(() => append(singleton_1(createElement(InformationAlert, {
                Label: t("tool.reservation_dialog.reservation_name_tooltip"),
            })), delay(() => {
                let elems_2;
                return append(singleton_1(createElement("div", createObj(singleton((elems_2 = [createElement(DatePicker, {
                    ComponentState: formState,
                    IsClearable: false,
                    Label: t("general.start_date"),
                    OnChange: (date) => {
                        if (date == null) {
                        }
                        else {
                            const date_1 = date;
                            if (compare(date_1, props.EndDate) > 0) {
                                props.SetEndDate(date_1);
                            }
                            props.SetStartDate(date_1);
                        }
                    },
                    TestId: "tool-reservation-dialog-dialog-start-date-test-id",
                    Value: props.StartDate,
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))]))))), delay(() => {
                    let elems_3;
                    return append(singleton_1(createElement("div", createObj(singleton((elems_3 = [createElement(DatePicker, {
                        ComponentState: formState,
                        IsClearable: false,
                        Label: t("general.end_date"),
                        MinDate: props.StartDate,
                        OnChange: (date_2) => {
                            if (date_2 == null) {
                            }
                            else {
                                props.SetEndDate(date_2);
                            }
                        },
                        TestId: "tool-reservation-dialog-end-date-test-id",
                        Value: props.EndDate,
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))]))))), delay(() => append(singleton_1(createElement(TextArea, {
                        ComponentState: formState,
                        Label: t("general.description"),
                        OnChange: props.SetDescription,
                        TestId: "tool-reservation-dialog-description-test-id",
                        Value: props.Description,
                    })), delay(() => ((props.Tool.AssignedReceiverInformation == null) ? singleton_1(defaultOf()) : append(singleton_1(Checkbox({
                        ComponentState: formState,
                        IsChecked: props.IsReceiverNotificationSet,
                        Label: t("tool.user_reservation_notification"),
                        OnCheck: props.SetIsReceiverNotificationSet,
                        TestId: "tool-reservation-dialog-notification-test-id",
                    })), delay(() => singleton_1(createElement(InformationAlert, {
                        Label: t("tool.user_reservation_notification_info"),
                    })))))))));
                }));
            }))))))));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])));
}

export function NewReservation_AddToolReservationDialog(props) {
    let StartDate, Title, Label_1;
    const t = useTranslation()[0];
    let patternInput_1;
    const init = NewReservation_init((StartDate = utcNow(), {
        Description: "",
        EndDate: utcNow(),
        IsUserNotificationSet: false,
        Name: "",
        OnClose: props.OnClose,
        StartDate: StartDate,
        SuccessCallback: props.SuccessCallback,
        Tool: props.Tool,
        UserData: props.UserData,
    }));
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, NewReservation_update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    return createElement(Dialog, (Title = t("dialog.reservation.new_title"), {
        Body: toList(delay(() => {
            const matchValue = state_1.Receivers;
            let matchResult, receivers;
            if (matchValue.tag === 1) {
                if (state_1.CustomerConfiguration.tag === 1) {
                    matchResult = 1;
                    receivers = matchValue.fields[0];
                }
                else {
                    matchResult = 0;
                }
            }
            else {
                matchResult = 0;
            }
            switch (matchResult) {
                case 0:
                    return singleton_1(createElement(Skeleton, {
                        Variant: "normal",
                    }));
                default:
                    return singleton_1(createElement(NewReservation_DialogBody, {
                        Description: state_1.Description,
                        EndDate: state_1.EndDate,
                        IsLoading: state_1.Loading,
                        IsReceiverNotificationSet: state_1.IsUserNotificationSet,
                        ReminderDates: props.Tool.ReminderDates,
                        ReservationName: state_1.Name,
                        ReservervationNameSuggestions: state_1.ReservationNameSuggestions,
                        SelectedReceiver: unwrap(state_1.SelectedUser),
                        SetDescription: (value_2) => {
                            dispatch(new NewReservation_Msg(4, [value_2]));
                        },
                        SetEndDate: (value_5) => {
                            dispatch(new NewReservation_Msg(6, [value_5]));
                        },
                        SetIsReceiverNotificationSet: (value_3) => {
                            dispatch(new NewReservation_Msg(3, [value_3]));
                        },
                        SetReservationName: (value_1) => {
                            dispatch(new NewReservation_Msg(2, [value_1]));
                        },
                        SetSelectedReceiver: (value) => {
                            dispatch(new NewReservation_Msg(1, [value]));
                        },
                        SetStartDate: (value_4) => {
                            dispatch(new NewReservation_Msg(5, [value_4]));
                        },
                        StartDate: state_1.StartDate,
                        SuccessCallback: props.SuccessCallback,
                        Tool: props.Tool,
                        UserData: props.UserData,
                        Users: receivers,
                    }));
            }
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-reservation-dialog-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, (Label_1 = t("general.save"), {
            ComponentState: (state_1.SelectedUser == null) ? "disabled" : "enabled",
            Label: Label_1,
            OnClick: () => {
                dispatch(new NewReservation_Msg(7, [new ToolReservationFormDto("00000000-0000-0000-0000-000000000000", state_1.StartDate, state_1.EndDate, state_1.Name, value_10(state_1.SelectedUser).value.Id, "", "", "", props.Tool.Id, state_1.IsUserNotificationSet, state_1.Description)]));
            },
            TestId: "save-reservation-dialog-test-id",
            Variant: "blueButton",
        }))]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

class EditReservation_Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ToolReservationDeleted", "DeleteReservation", "SetSelectedReceiver", "SetName", "SetIsReceiverNotificationSet", "SetDescription", "SetStartDate", "SetEndDate", "SaveToolReservation", "ToolReservationSaved", "ReservationNameSuggestionsFetched", "ReceiverFetched", "ToolFetched", "FetchError"];
    }
}

function EditReservation_Msg_$reflection() {
    return union_type("Tools.ToolReservationDialog.EditReservation.Msg", [], EditReservation_Msg, () => [[["Item", PostResponse$1_$reflection(EmptyResponse_$reflection())]], [], [["Item", option_type(anonRecord_type(["label", string_type], ["value", string_type]))]], [["Item", string_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", class_type("System.DateTime")]], [["Item", class_type("System.DateTime")]], [], [["Item", PostResponse$1_$reflection(ToolReservationResponse_$reflection())]], [["Item", list_type(string_type)]], [["Item", list_type(Receiver_$reflection())]], [["Item", ToolDetailDto_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

function EditReservation_putToolReservationCmd(dto) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(ToolReservationResponse_$reflection())));
    })))), ["/api/tools/reservations", toString(0, Auto_generateBoxedEncoder_437914C6(ToolReservationFormDto_$reflection(), undefined, undefined, undefined)(dto))], (Item) => (new EditReservation_Msg(9, [Item])), (Item_1) => (new EditReservation_Msg(13, [Item_1])));
}

function EditReservation_deleteReservationCmd(toolId, reservationId) {
    return Cmd_OfPromise_either((url_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_1), singleton(new Types_RequestProperties(0, ["DELETE"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(EmptyResponse_$reflection())));
    })))), `/api/tools/${toolId.fields[0]}/reservations/${reservationId.fields[0]}`, (Item) => (new EditReservation_Msg(0, [Item])), (Item_1) => (new EditReservation_Msg(13, [Item_1])));
}

export class EditReservation_State extends Record {
    constructor(Loading, SuccessCallback, StartDate, EndDate, Description, Name, SelectedUser, IsUserNotificationSet, OnClose, ToolReservation, ReservationNameSuggestions, Receivers, Tool) {
        super();
        this.Loading = Loading;
        this.SuccessCallback = SuccessCallback;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.Description = Description;
        this.Name = Name;
        this.SelectedUser = SelectedUser;
        this.IsUserNotificationSet = IsUserNotificationSet;
        this.OnClose = OnClose;
        this.ToolReservation = ToolReservation;
        this.ReservationNameSuggestions = ReservationNameSuggestions;
        this.Receivers = Receivers;
        this.Tool = Tool;
    }
}

export function EditReservation_State_$reflection() {
    return record_type("Tools.ToolReservationDialog.EditReservation.State", [], EditReservation_State, () => [["Loading", bool_type], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["StartDate", class_type("System.DateTime")], ["EndDate", class_type("System.DateTime")], ["Description", string_type], ["Name", string_type], ["SelectedUser", option_type(anonRecord_type(["label", string_type], ["value", string_type]))], ["IsUserNotificationSet", bool_type], ["OnClose", lambda_type(unit_type, unit_type)], ["ToolReservation", ReservationDto_$reflection()], ["ReservationNameSuggestions", list_type(string_type)], ["Receivers", RequestedValue$1_$reflection(list_type(Receiver_$reflection()))], ["Tool", RequestedValue$1_$reflection(ToolDetailDto_$reflection())]]);
}

function EditReservation_init(props) {
    return [new EditReservation_State(false, props.SuccessCallback, props.ToolReservation.StartDate, props.ToolReservation.EndDate, props.ToolReservation.Description, props.ToolReservation.Name, {
        label: props.ToolReservation.ReceiverInformation.ReceiverName,
        value: props.ToolReservation.ReceiverInformation.ReceiverId,
    }, props.IsUserNotificationSet, props.OnClose, props.ToolReservation, empty(), new RequestedValue$1(0, []), new RequestedValue$1(0, [])), Cmd_batch(ofArray([Cmd_OfPromise_either_1((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(string_type)));
    })))), "/api/tools/reservations/name-suggestions", (Item) => (new EditReservation_Msg(10, [Item])), (Item_1) => (new EditReservation_Msg(13, [Item_1]))), Cmd_OfPromise_either_1((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(Receiver_$reflection())));
    })))), "/api/receivers/active", (Item_2) => (new EditReservation_Msg(11, [Item_2])), (Item_3) => (new EditReservation_Msg(13, [Item_3]))), Cmd_getToolDetailDto((Item_4) => (new EditReservation_Msg(12, [Item_4])), (Item_5) => (new EditReservation_Msg(13, [Item_5])), props.ToolId)]))];
}

function EditReservation_update(msg, state) {
    switch (msg.tag) {
        case 4:
            return [new EditReservation_State(state.Loading, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, msg.fields[0], state.OnClose, state.ToolReservation, state.ReservationNameSuggestions, state.Receivers, state.Tool), Cmd_none()];
        case 3:
            return [new EditReservation_State(state.Loading, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, msg.fields[0], state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ToolReservation, state.ReservationNameSuggestions, state.Receivers, state.Tool), Cmd_none()];
        case 5:
            return [new EditReservation_State(state.Loading, state.SuccessCallback, state.StartDate, state.EndDate, msg.fields[0], state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ToolReservation, state.ReservationNameSuggestions, state.Receivers, state.Tool), Cmd_none()];
        case 6:
            return [new EditReservation_State(state.Loading, state.SuccessCallback, msg.fields[0], state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ToolReservation, state.ReservationNameSuggestions, state.Receivers, state.Tool), Cmd_none()];
        case 7:
            return [new EditReservation_State(state.Loading, state.SuccessCallback, state.StartDate, msg.fields[0], state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ToolReservation, state.ReservationNameSuggestions, state.Receivers, state.Tool), Cmd_none()];
        case 1: {
            const matchValue = state.Tool;
            if (matchValue.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                return [new EditReservation_State(true, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ToolReservation, state.ReservationNameSuggestions, state.Receivers, state.Tool), EditReservation_deleteReservationCmd(new ToolId_1(parse(matchValue.fields[0].Id)), new ToolReservationId(parse(state.ToolReservation.Id)))];
            }
        }
        case 8: {
            const matchValue_1 = state.Tool;
            if (matchValue_1.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                return [new EditReservation_State(true, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ToolReservation, state.ReservationNameSuggestions, state.Receivers, state.Tool), EditReservation_putToolReservationCmd(new ToolReservationFormDto(state.ToolReservation.Id, state.StartDate, state.EndDate, state.Name, value_10(state.SelectedUser).value, "", "", "", matchValue_1.fields[0].Id, state.IsUserNotificationSet, state.Description))];
            }
        }
        case 9: {
            const matchValue_2 = msg.fields[0].Result;
            switch (matchValue_2.tag) {
                case 1:
                    return [new EditReservation_State(false, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ToolReservation, state.ReservationNameSuggestions, state.Receivers, state.Tool), Cmd_batch(ofArray([Cmd_ofEffect((_arg_2) => {
                        state.SuccessCallback();
                    }), Cmd_ofEffect((_arg_3) => {
                        state.OnClose();
                    }), Cmd_successToast("dialog.reservation.toast_messages.created")]))];
                case 2:
                    return [new EditReservation_State(false, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ToolReservation, state.ReservationNameSuggestions, state.Receivers, state.Tool), Cmd_errorToast("dialog.reservation.toast_messages.already_exists")];
                case 3:
                    return [new EditReservation_State(false, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ToolReservation, state.ReservationNameSuggestions, state.Receivers, state.Tool), Cmd_none()];
                default:
                    return [new EditReservation_State(false, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ToolReservation, state.ReservationNameSuggestions, state.Receivers, state.Tool), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                        state.SuccessCallback();
                    }), Cmd_ofEffect((_arg_1) => {
                        state.OnClose();
                    }), Cmd_successToast("dialog.reservation.toast_messages.created")]))];
            }
        }
        case 0:
            return [new EditReservation_State(false, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ToolReservation, state.ReservationNameSuggestions, state.Receivers, state.Tool), Cmd_batch(ofArray([Cmd_ofEffect((_arg_4) => {
                state.SuccessCallback();
            }), Cmd_ofEffect((_arg_5) => {
                state.OnClose();
            }), Cmd_successToast("tool.dialog_remove_reservation_success")]))];
        case 10:
            return [new EditReservation_State(state.Loading, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ToolReservation, msg.fields[0], state.Receivers, state.Tool), Cmd_none()];
        case 11:
            return [new EditReservation_State(state.Loading, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ToolReservation, state.ReservationNameSuggestions, new RequestedValue$1(1, [msg.fields[0]]), state.Tool), Cmd_none()];
        case 12:
            return [new EditReservation_State(state.Loading, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ToolReservation, state.ReservationNameSuggestions, state.Receivers, new RequestedValue$1(1, [msg.fields[0]])), Cmd_none()];
        case 13:
            return [new EditReservation_State(false, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, state.SelectedUser, state.IsUserNotificationSet, state.OnClose, state.ToolReservation, state.ReservationNameSuggestions, state.Receivers, state.Tool), Cmd_none()];
        default:
            return [new EditReservation_State(state.Loading, state.SuccessCallback, state.StartDate, state.EndDate, state.Description, state.Name, msg.fields[0], state.IsUserNotificationSet, state.OnClose, state.ToolReservation, state.ReservationNameSuggestions, state.Receivers, state.Tool), Cmd_none()];
    }
}

function EditReservation_DialogBody(props) {
    let elems_3;
    const patternInput = useTranslation();
    const t = patternInput[0];
    const formState = props.IsLoading ? "disabled" : "enabled";
    return createElement("div", createObj(ofArray([["id", "tool-reservation-dialog"], ["className", join(" ", ["grid", "gap-5"])], (elems_3 = toList(delay(() => {
        let matchValue, reminder, elems, arg_1;
        return append((matchValue = tryFind((r_1) => {
            if (compare(r_1.Date, props.StartDate) >= 0) {
                return compare(r_1.Date, props.EndDate) <= 0;
            }
            else {
                return false;
            }
        }, filter((r) => !r.IsConfirmed, props.ReminderDates)), (matchValue == null) ? (empty_1()) : ((reminder = matchValue, singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["w-96"])], (elems = [createElement(WarningAlert, {
            Label: (arg_1 = {
                dateString: toString_1(reminder.Date, "dd.MM.yyyy"),
                reminderName: reminder.Title,
            }, patternInput[1].t("tool.reservation_dialog.overlapping_date_warning", arg_1)),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))))))), delay(() => append(singleton_1(createElement(GroupedSelect, (($value) => { $value.key = $value.Key; return $value; })({
            ComponentState: (props.UserData.Role === "user") ? "disabled" : formState,
            IsClearable: false,
            Key: "assign-tool-select",
            Label: t("general.user"),
            NoOptionsMessage: "select.employee_no_options",
            OnChange: props.SetSelectedReceiver,
            Options: map((tupledArg) => {
                const receiverType = tupledArg[0];
                const receivers_1 = toArray(sortBy((receiver_2) => receiver_2.label.toLowerCase(), map((receiver_1) => {
                    const matchValue_2 = receiver_1.LocationName;
                    if (matchValue_2 != null) {
                        const locationName = matchValue_2;
                        if (equals(receiverType, new ReceiverTypeName(4, []))) {
                            return {
                                label: `${locationName} - ${receiver_1.Name}`,
                                value: receiver_1.Id,
                            };
                        }
                        else {
                            return {
                                label: `${receiver_1.Name} - ${locationName}`,
                                value: receiver_1.Id,
                            };
                        }
                    }
                    else {
                        return {
                            label: `${receiver_1.Name}`,
                            value: receiver_1.Id,
                        };
                    }
                }, tupledArg[1]), {
                    Compare: comparePrimitives,
                }));
                return {
                    label: Helper_receiverTypeNameTranslation(t, receiverType),
                    options: receivers_1,
                };
            }, List_groupBy((receiver) => receiver.ReceiverType, filter((u) => {
                if (props.IsReceiverNotificationSet) {
                    const matchValue_1 = u.ReceiverType;
                    switch (matchValue_1.tag) {
                        case 1:
                        case 5:
                        case 8:
                        case 6:
                        case 2:
                        case 7:
                        case 3:
                        case 9:
                        case 4:
                            return false;
                        default:
                            return true;
                    }
                }
                else {
                    return true;
                }
            }, props.Users), {
                Equals: equals,
                GetHashCode: safeHash,
            })),
            PlaceholderKey: "select.employee_placeholder",
            Value: unwrap(props.SelectedReceiver),
        }))), delay(() => append(singleton_1(useReact_useMemo_10C6A43C(() => createElement(AutoSuggestInput, {
            ComponentState: formState,
            Label: t("general.name"),
            OnChange: props.SetReservationName,
            Suggestions: props.ReservervationNameSuggestions,
            TestId: "tool-reservation-dialog-name-test-id",
            Value: props.ReservationName,
        }), [props.ReservationName, props.ReservervationNameSuggestions, formState])), delay(() => append(singleton_1(createElement(InformationAlert, {
            Label: t("tool.reservation_dialog.reservation_name_tooltip"),
        })), delay(() => {
            let elems_1;
            return append(singleton_1(createElement("div", createObj(singleton((elems_1 = [createElement(DatePicker, {
                ComponentState: "enabled",
                IsClearable: false,
                Label: t("general.start_date"),
                OnChange: (date) => {
                    if (date == null) {
                    }
                    else {
                        const date_1 = date;
                        if (compare(date_1, props.EndDate) > 0) {
                            props.SetEndDate(date_1);
                        }
                        props.SetStartDate(date_1);
                    }
                },
                TestId: "tool-reservation-dialog-start-date-test-id",
                Value: props.StartDate,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))]))))), delay(() => {
                let elems_2;
                return append(singleton_1(createElement("div", createObj(singleton((elems_2 = [createElement(DatePicker, {
                    ComponentState: "enabled",
                    IsClearable: false,
                    Label: t("general.end_date"),
                    MinDate: props.StartDate,
                    OnChange: (date_2) => {
                        if (date_2 == null) {
                        }
                        else {
                            props.SetEndDate(date_2);
                        }
                    },
                    TestId: "tool-reservation-dialog-end-date-test-id",
                    Value: props.EndDate,
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))]))))), delay(() => append(singleton_1(createElement(TextArea, {
                    ComponentState: formState,
                    Label: t("general.description"),
                    OnChange: props.SetDescription,
                    TestId: "tool-reservation-dialog-description-test-id",
                    Value: props.Description,
                })), delay(() => ((props.Tool.AssignedReceiverInformation == null) ? singleton_1(defaultOf()) : append(singleton_1(Checkbox({
                    ComponentState: formState,
                    IsChecked: props.IsReceiverNotificationSet,
                    Label: t("tool.user_reservation_notification"),
                    OnCheck: props.SetIsReceiverNotificationSet,
                    TestId: "tool-reservation-dialog-notification-test-id",
                })), delay(() => singleton_1(createElement(InformationAlert, {
                    Label: t("tool.user_reservation_notification_info"),
                })))))))));
            }));
        }))))))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
}

export function EditReservation_EditToolReservationDialog(props) {
    let Title;
    const matchValue = props.Reservation;
    if (matchValue != null) {
        const reservation = matchValue;
        const t = useTranslation()[0];
        let patternInput_1;
        const init = EditReservation_init({
            IsUserNotificationSet: false,
            OnClose: props.OnClose,
            SuccessCallback: props.SuccessCallback,
            ToolId: props.ToolId,
            ToolReservation: reservation,
        });
        patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, EditReservation_update, (_arg, _arg_1) => {
        }), undefined, [props.IsOpen]);
        const state_1 = patternInput_1[0];
        const dispatch = patternInput_1[1];
        return createElement(Dialog, (Title = t("dialog.reservation.edit_title"), {
            Body: toList(delay(() => {
                const matchValue_1 = state_1.Receivers;
                const matchValue_2 = state_1.Tool;
                let matchResult, receivers, tool;
                if (matchValue_1.tag === 0) {
                    matchResult = 1;
                }
                else if (matchValue_2.tag === 0) {
                    matchResult = 1;
                }
                else {
                    matchResult = 0;
                    receivers = matchValue_1.fields[0];
                    tool = matchValue_2.fields[0];
                }
                switch (matchResult) {
                    case 0:
                        return singleton_1(createElement(EditReservation_DialogBody, {
                            Description: state_1.Description,
                            EndDate: state_1.EndDate,
                            IsLoading: state_1.Loading,
                            IsReceiverNotificationSet: state_1.IsUserNotificationSet,
                            ReminderDates: tool.ReminderDates,
                            ReservationName: state_1.Name,
                            ReservervationNameSuggestions: state_1.ReservationNameSuggestions,
                            SelectedReceiver: unwrap(state_1.SelectedUser),
                            SetDescription: (value_2) => {
                                dispatch(new EditReservation_Msg(5, [value_2]));
                            },
                            SetEndDate: (value_5) => {
                                dispatch(new EditReservation_Msg(7, [value_5]));
                            },
                            SetIsReceiverNotificationSet: (value_3) => {
                                dispatch(new EditReservation_Msg(4, [value_3]));
                            },
                            SetReservationName: (value_1) => {
                                dispatch(new EditReservation_Msg(3, [value_1]));
                            },
                            SetSelectedReceiver: (value) => {
                                dispatch(new EditReservation_Msg(2, [value]));
                            },
                            SetStartDate: (value_4) => {
                                dispatch(new EditReservation_Msg(6, [value_4]));
                            },
                            StartDate: state_1.StartDate,
                            SuccessCallback: props.SuccessCallback,
                            Tool: tool,
                            UserData: props.UserData,
                            Users: receivers,
                        }));
                    default:
                        return singleton_1(createElement(Skeleton, {
                            Variant: "normal",
                        }));
                }
            })),
            Controls: toList(delay(() => {
                const matchValue_4 = state_1.Receivers;
                const matchValue_5 = state_1.Tool;
                let matchResult_1, receivers_1, tool_1;
                if (matchValue_4.tag === 1) {
                    if (matchValue_5.tag === 1) {
                        matchResult_1 = 0;
                        receivers_1 = matchValue_4.fields[0];
                        tool_1 = matchValue_5.fields[0];
                    }
                    else {
                        matchResult_1 = 1;
                    }
                }
                else {
                    matchResult_1 = 1;
                }
                switch (matchResult_1) {
                    case 0:
                        return append(singleton_1(createElement(TextButton, {
                            ComponentState: "enabled",
                            Label: t("general.delete"),
                            OnClick: () => {
                                dispatch(new EditReservation_Msg(1, []));
                            },
                            TestId: "delete-reservation-dialog-test-id",
                            Variant: "redButton",
                        })), delay(() => append(singleton_1(createElement(TextButton, {
                            ComponentState: "enabled",
                            Label: t("general.cancel"),
                            OnClick: props.OnClose,
                            TestId: "close-reservation-dialog-test-id",
                            Variant: "blueButton",
                        })), delay(() => {
                            let Label_2;
                            return singleton_1(createElement(TextButton, (Label_2 = t("general.save"), {
                                ComponentState: (state_1.SelectedUser == null) ? "disabled" : "enabled",
                                Label: Label_2,
                                OnClick: () => {
                                    dispatch(new EditReservation_Msg(8, []));
                                },
                                TestId: "save-reservation-dialog-test-id",
                                Variant: "blueButton",
                            })));
                        }))));
                    default: {
                        return empty_1();
                    }
                }
            })),
            IsOpen: props.IsOpen,
            OnClose: props.OnClose,
            Title: Title,
        }));
    }
    else {
        return defaultOf();
    }
}

