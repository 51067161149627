import { RequestedValue$1 } from "../../Shared/Shared.js";
import { Msg, State } from "./Types.js";
import { Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { getStocktaking } from "../../Requests/Stocktaking.js";
import { tryFind, singleton } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { map, defaultArg } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { equals } from "../../fable_modules/fable-library-js.4.19.2/Util.js";

export function init(stocktakingId, stocktakingListId) {
    return [new State(stocktakingId, stocktakingListId, new RequestedValue$1(0, []), new RequestedValue$1(0, [])), Cmd_batch(singleton(getStocktaking(stocktakingId, (Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(2, [Item_1])))))];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1: {
            const stocktaking = msg.fields[0];
            return [new State(state.StocktakingId, state.StocktakingListId, new RequestedValue$1(1, [stocktaking]), defaultArg(map((Item_2) => (new RequestedValue$1(1, [Item_2])), tryFind((stocktakingList) => equals(stocktakingList.Id, state.StocktakingListId), stocktaking.StocktakingLists)), new RequestedValue$1(0, []))), Cmd_none()];
        }
        case 2:
            return [state, Cmd_none()];
        default:
            return [state, getStocktaking(state.StocktakingId, (Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(2, [Item_1])))];
    }
}

