import { Union } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { union_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { createElement } from "react";
import React from "react";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { singleton, empty, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { singleton as singleton_1, ofArray, mapIndexed, concat } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";

export class CustomField extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["Link"];
    }
}

export function CustomField_$reflection() {
    return union_type("Widgets.PropertyListWidget.CustomField", [], CustomField, () => [[]]);
}

export function PropertyList(props) {
    let elems_1;
    return createElement("div", createObj(singleton_1((elems_1 = toList(delay(() => {
        let matchValue, title;
        return append((matchValue = props.Title, (matchValue == null) ? (empty()) : ((title = matchValue, singleton(createElement("div", {
            className: join(" ", ["text-sm", "font-semibold", "mb-4"]),
            children: title,
        }))))), delay(() => {
            let elems;
            return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-2"])], (elems = toList(delay(() => concat(mapIndexed((index, property) => toList(delay(() => append((index !== 0) ? singleton(createElement("div", {
                className: join(" ", ["col-span-2", "border-t", "border-divider"]),
            })) : empty(), delay(() => append(singleton(createElement("div", {
                className: join(" ", ["divide-y", "font-semibold", "text-base", "mr-5", "py-4"]),
                children: property.Label,
            })), delay(() => singleton(createElement("div", createObj(toList(delay(() => append(singleton(["className", join(" ", toList(delay(() => append(singleton("text-base"), delay(() => append(singleton("py-4"), delay(() => append(singleton("break-words"), delay(() => append(singleton("whitespace-pre-wrap"), delay(() => {
                const matchValue_1 = property.CustomField;
                if (matchValue_1 == null) {
                    return empty();
                }
                else {
                    const customField = matchValue_1;
                    return singleton("hover:cursor-pointer");
                }
            })))))))))))]), delay(() => append(singleton(["data-test-id", `${property.Label}-property-list-label`]), delay(() => append(singleton(["children", property.Value]), delay(() => append(singleton(["data-test-id", `${property.TestId}`]), delay(() => {
                const matchValue_3 = property.CustomField;
                if (matchValue_3 == null) {
                    return empty();
                }
                else {
                    const customField_1 = matchValue_3;
                    return singleton(["onClick", (_arg) => {
                        window.open(property.Value, "_blank", "noreferrer");
                    }]);
                }
            }))))))))))))))))))), props.Properties)))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))]))));
}

