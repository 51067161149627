import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { update as update_1, init as init_1 } from "./State.js";
import { useTranslation } from "react-i18next";
import { defaultOf } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { equals, createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { InformationAlert } from "../../Components/Alert.js";
import { empty, singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { Checkbox } from "../../Components/Checkbox.js";
import { ReceiverVisibilityConfiguration } from "../../Shared/Shared.js";
import { Msg } from "./Types.js";
import { AppVariant, Variant } from "../../Variant.js";
import { Feature, isFeatureEnabled } from "../../FeatureFlip.js";
import { Interop_reactApi } from "../../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";

export function Receivers() {
    let elems_1, elems;
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init_1, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const dispatch = patternInput[1];
    const t = useTranslation()[0];
    const matchValue = patternInput[0].CustomerConfiguration;
    if (matchValue.tag === 0) {
        return defaultOf();
    }
    else {
        const config = matchValue.fields[0].ReceiverVisibilityConfiguration;
        return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-5"])], (elems_1 = [createElement(InformationAlert, {
            Label: "settings.receivers_description",
        }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-1"])], (elems = toList(delay(() => append(singleton(Checkbox({
            ComponentState: "enabled",
            IsChecked: config.Users,
            Label: t("receivers.users"),
            OnCheck: (value_2) => {
                dispatch(new Msg(0, [new ReceiverVisibilityConfiguration(config.ConstructionSites, config.Employees, config.ExternalPersons, config.Projects, config.Rooms, config.SchoolClasses, config.Students, config.Subcontractos, value_2, config.Vehicles)]));
            },
            TestId: "",
        })), delay(() => append(singleton(Checkbox({
            ComponentState: "enabled",
            IsChecked: config.Employees,
            Label: t("receivers.employees"),
            OnCheck: (value_3) => {
                dispatch(new Msg(0, [new ReceiverVisibilityConfiguration(config.ConstructionSites, value_3, config.ExternalPersons, config.Projects, config.Rooms, config.SchoolClasses, config.Students, config.Subcontractos, config.Users, config.Vehicles)]));
            },
            TestId: "",
        })), delay(() => append(singleton(Checkbox({
            ComponentState: "enabled",
            IsChecked: config.ConstructionSites,
            Label: t("receivers.constructionsites"),
            OnCheck: (value_4) => {
                dispatch(new Msg(0, [new ReceiverVisibilityConfiguration(value_4, config.Employees, config.ExternalPersons, config.Projects, config.Rooms, config.SchoolClasses, config.Students, config.Subcontractos, config.Users, config.Vehicles)]));
            },
            TestId: "",
        })), delay(() => append(singleton(Checkbox({
            ComponentState: "enabled",
            IsChecked: config.Projects,
            Label: t("receivers.projects"),
            OnCheck: (value_5) => {
                dispatch(new Msg(0, [new ReceiverVisibilityConfiguration(config.ConstructionSites, config.Employees, config.ExternalPersons, value_5, config.Rooms, config.SchoolClasses, config.Students, config.Subcontractos, config.Users, config.Vehicles)]));
            },
            TestId: "",
        })), delay(() => append(singleton(Checkbox({
            ComponentState: "enabled",
            IsChecked: config.Rooms,
            Label: t("receivers.rooms"),
            OnCheck: (value_6) => {
                dispatch(new Msg(0, [new ReceiverVisibilityConfiguration(config.ConstructionSites, config.Employees, config.ExternalPersons, config.Projects, value_6, config.SchoolClasses, config.Students, config.Subcontractos, config.Users, config.Vehicles)]));
            },
            TestId: "",
        })), delay(() => append(singleton(Checkbox({
            ComponentState: "enabled",
            IsChecked: config.ExternalPersons,
            Label: t("receivers.externalpersons"),
            OnCheck: (value_7) => {
                dispatch(new Msg(0, [new ReceiverVisibilityConfiguration(config.ConstructionSites, config.Employees, value_7, config.Projects, config.Rooms, config.SchoolClasses, config.Students, config.Subcontractos, config.Users, config.Vehicles)]));
            },
            TestId: "",
        })), delay(() => append(singleton(Checkbox({
            ComponentState: "enabled",
            IsChecked: config.Subcontractos,
            Label: t("receivers.subcontractors"),
            OnCheck: (value_8) => {
                dispatch(new Msg(0, [new ReceiverVisibilityConfiguration(config.ConstructionSites, config.Employees, config.ExternalPersons, config.Projects, config.Rooms, config.SchoolClasses, config.Students, value_8, config.Users, config.Vehicles)]));
            },
            TestId: "",
        })), delay(() => append(equals(Variant, new AppVariant(1, [])) ? append(singleton(Checkbox({
            ComponentState: "enabled",
            IsChecked: config.SchoolClasses,
            Label: t("receivers.school_classes"),
            OnCheck: (value_9) => {
                dispatch(new Msg(0, [new ReceiverVisibilityConfiguration(config.ConstructionSites, config.Employees, config.ExternalPersons, config.Projects, config.Rooms, value_9, config.Students, config.Subcontractos, config.Users, config.Vehicles)]));
            },
            TestId: "",
        })), delay(() => singleton(Checkbox({
            ComponentState: "enabled",
            IsChecked: config.Students,
            Label: t("receivers.students"),
            OnCheck: (value_10) => {
                dispatch(new Msg(0, [new ReceiverVisibilityConfiguration(config.ConstructionSites, config.Employees, config.ExternalPersons, config.Projects, config.Rooms, config.SchoolClasses, value_10, config.Subcontractos, config.Users, config.Vehicles)]));
            },
            TestId: "",
        })))) : empty(), delay(() => (isFeatureEnabled(new Feature(11, [])) ? singleton(Checkbox({
            ComponentState: "enabled",
            IsChecked: config.Vehicles,
            Label: t("receivers.vehicles"),
            OnCheck: (value_11) => {
                dispatch(new Msg(0, [new ReceiverVisibilityConfiguration(config.ConstructionSites, config.Employees, config.ExternalPersons, config.Projects, config.Rooms, config.SchoolClasses, config.Students, config.Subcontractos, config.Users, value_11)]));
            },
            TestId: "",
        })) : empty()))))))))))))))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
    }
}

