import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { update as update_1, init as init_1 } from "./State.js";
import { TemplateType, Importer } from "../../Widgets/Importer.js";
import { Msg } from "./Types.js";
import { unwrap } from "../../fable_modules/fable-library-js.4.19.2/Option.js";

export function View() {
    let matchValue;
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init_1, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return createElement(Importer, {
        CSVFileName: new TemplateType(1, [() => {
            dispatch(new Msg(4, []));
        }]),
        HeaderTitle: "importer.header_employee",
        ImportData: unwrap(state_1.Import),
        IsImportActive: state_1.ImportActive,
        IsImportBtnEnabled: (matchValue = state_1.Import, (matchValue != null) && (matchValue.IsValid && !state_1.ImportActive)),
        OnImportData: () => {
            dispatch(new Msg(0, []));
        },
        ValidateErrorMessage: unwrap(state_1.ValidateErrorMessage),
        ValidateFormData: (formData) => {
            dispatch(new Msg(2, [formData]));
        },
    });
}

