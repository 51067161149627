import { Union } from "./fable_modules/fable-library-js.4.19.2/Types.js";
import { union_type } from "./fable_modules/fable-library-js.4.19.2/Reflection.js";
import { find, ofSeq } from "./fable_modules/fable-library-js.4.19.2/Map.js";
import { compare } from "./fable_modules/fable-library-js.4.19.2/Util.js";
import { map, reduce } from "./fable_modules/fable-library-js.4.19.2/List.js";

export class Feature extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Dispolist", "Sets", "ImportExport", "QuickOverview", "Stocktaking", "FormImageUpload", "Archived", "UserSettings", "ToolWithQuantityReminder", "ToolWithQuantityReservation", "SupportButton", "Vehicle", "ConsumableReminder", "VisibleReceiverForUser", "FullDataBackup"];
    }
}

export function Feature_$reflection() {
    return union_type("FeatureFlip.Feature", [], Feature, () => [[], [], [], [], [], [], [], [], [], [], [], [], [], [], []]);
}

export const featureDefinition = ofSeq([[new Feature(0, []), true], [new Feature(1, []), true], [new Feature(2, []), true], [new Feature(3, []), true], [new Feature(5, []), true], [new Feature(8, []), true], [new Feature(9, []), true], [new Feature(6, []), true], [new Feature(7, []), true], [new Feature(10, []), true], [new Feature(11, []), true], [new Feature(12, []), true], [new Feature(4, []), false], [new Feature(13, []), true], [new Feature(14, []), true]], {
    Compare: compare,
});

export function isFeatureEnabled(feature) {
    return find(feature, featureDefinition);
}

export function areFeaturesEnabled(features) {
    return reduce((e, e_1) => (e && e_1), map(isFeatureEnabled, features));
}

