import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { SectionContainer } from "../../Widgets/Container.js";
import { int32ToString, createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { PropertyList } from "../../Widgets/PropertyListWidget.js";
import { singleton as singleton_1, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { unwrap } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { Interop_reactApi } from "../../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { append, singleton, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { Button } from "../../Components/Button.js";
import { RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { update as update_1, init as init_1 } from "./State.js";
import { Skeleton } from "../../Components/Skeleton.js";

function AccountStatistic(props) {
    let Title, elems, Title_1, Title_2;
    const t = useTranslation()[0];
    const totalUsedInventory = (((props.AccountStatistic.NumberOfTools + props.AccountStatistic.NumberOfToolsWithQuantity) + props.AccountStatistic.NumberOfConsumables) + props.AccountStatistic.NumberOfSets) | 0;
    return createElement(SectionContainer, (Title = t("customerdata.statistic.title"), {
        Content: createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-12"])], (elems = [createElement(PropertyList, (Title_1 = t("customerdata.statistic.overview"), {
            Properties: ofArray([{
                Label: t("customerdata.statistic.total_used_inventory"),
                TestId: "",
                Value: `${totalUsedInventory}/${props.AccountStatistic.IncludedInventory}`,
            }, {
                Label: t("customerdata.statistic.total_used_users"),
                TestId: "",
                Value: `${props.AccountStatistic.NumberOfUsers}/${props.AccountStatistic.IncludedUsers}`,
            }]),
            Title: unwrap(Title_1),
        })), createElement(PropertyList, (Title_2 = t("customerdata.statistic.details"), {
            Properties: ofArray([{
                Label: t("general.tool"),
                TestId: "",
                Value: int32ToString(props.AccountStatistic.NumberOfTools),
            }, {
                Label: t("general.tool_with_quantity"),
                TestId: "",
                Value: int32ToString(props.AccountStatistic.NumberOfToolsWithQuantity),
            }, {
                Label: t("set.overview.title"),
                TestId: "",
                Value: int32ToString(props.AccountStatistic.NumberOfSets),
            }, {
                Label: t("general.consumable"),
                TestId: "",
                Value: int32ToString(props.AccountStatistic.NumberOfConsumables),
            }, {
                Label: t("receivers.user"),
                TestId: "",
                Value: int32ToString(props.AccountStatistic.NumberOfUsers),
            }]),
            Title: unwrap(Title_2),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))),
        Title: unwrap(Title),
    }));
}

function ContractInformation(props) {
    let elems_7, elems_6;
    const t = useTranslation()[0];
    const phoneNumber = t("global.phone_number");
    return createElement("div", createObj(ofArray([["className", join(" ", ["p-3"])], (elems_7 = [createElement("div", createObj(singleton_1((elems_6 = toList(delay(() => {
        let Title_1, elems_3, Title_2, elems_5, value_25, children_6, elems_4, children_8, Title, children_4, elems, elems_1, children, elems_2, children_2;
        const matchValue = props.AccountSettings.AccountState;
        switch (matchValue) {
            case "active":
            case "canceled": {
                const matchValue_1 = props.CustomerPortalUrl;
                return (matchValue_1 !== "") ? singleton(createElement(SectionContainer, (Title_1 = t("customerdata.portal_description"), {
                    Content: createElement("div", createObj(ofArray([["className", join(" ", ["flex"])], (elems_3 = [createElement(Button, {
                        ComponentState: "enabled",
                        Label: "Kundenportal öffnen",
                        OnClick: () => {
                            window.open(matchValue_1, "_blank").focus();
                        },
                        TestId: "customer-portal",
                        Variant: "primary",
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))),
                    Title: unwrap(Title_1),
                }))) : singleton(createElement(SectionContainer, (Title_2 = t("customerdata.account_data"), {
                    Content: createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-4"])], (elems_5 = [(value_25 = "Um Änderungen in Ihrem gebuchten Paket vorzunhemen oder Ihr Abonnement zu kündigen, nehmen Sie bitte kontakt mit unserem Support-Team auf.", createElement("div", {
                        children: [value_25],
                    })), (children_6 = ofArray([createElement("span", createObj(ofArray([["className", "category mr-1"], (elems_4 = [t("user.phone_number")], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("a", {
                        href: `tel:${phoneNumber}`,
                        children: phoneNumber,
                    })]), createElement("div", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_6)),
                    })), (children_8 = ofArray([createElement("span", {
                        className: "category mr-1",
                        children: "E-Mail:",
                    }), createElement("a", {
                        href: "mailto:support@inventory-one.com",
                        children: "support@inventory-one.com",
                    })]), createElement("div", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_8)),
                    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))),
                    Title: unwrap(Title_2),
                })));
            }
            default:
                return singleton(createElement(SectionContainer, (Title = t("customerdata.account_data"), {
                    Content: (children_4 = ofArray([createElement("div", createObj(ofArray([["className", "mb-3"], (elems = [createElement(Button, {
                        ComponentState: "enabled",
                        Label: t("test_phase.become_customer"),
                        OnClick: () => {
                            RouterModule_nav(singleton_1("/customer/wizard"), 1, 1);
                        },
                        TestId: "customerdata-contractinformation-",
                        Variant: "primary",
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("h5", createObj(singleton_1((elems_1 = [t("test_phase.contact_question")], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])))), (children = ofArray([createElement("span", createObj(ofArray([["className", "category mr-1"], (elems_2 = [t("support.phone")], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("a", {
                        href: `tel:${phoneNumber}`,
                        children: phoneNumber,
                    })]), createElement("div", {
                        children: Interop_reactApi.Children.toArray(Array.from(children)),
                    })), (children_2 = ofArray([createElement("span", {
                        className: "category mr-1",
                        children: "E-Mail:",
                    }), createElement("a", {
                        href: "mailto:support@inventory-one.com",
                        children: "support@inventory-one.com",
                    })]), createElement("div", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                    }))]), createElement("div", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                    })),
                    Title: unwrap(Title),
                })));
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])));
}

export function CustomerDataView(props) {
    let elems;
    const patternInput = useTranslation();
    const patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput_1[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["max-w-[80rem]"])], (elems = toList(delay(() => {
        const matchValue = state_1.AccountSettings;
        const matchValue_1 = state_1.CustomerPortalUrl;
        const matchValue_2 = state_1.AccountStatistic;
        let matchResult, accountStatistic, customerData, customerPortalUrl;
        if (matchValue.tag === 0) {
            matchResult = 1;
        }
        else if (matchValue_1.tag === 0) {
            matchResult = 1;
        }
        else if (matchValue_2.tag === 0) {
            matchResult = 1;
        }
        else {
            matchResult = 0;
            accountStatistic = matchValue_2.fields[0];
            customerData = matchValue.fields[0];
            customerPortalUrl = matchValue_1.fields[0];
        }
        switch (matchResult) {
            case 0:
                return append(singleton(createElement(ContractInformation, {
                    AccountSettings: customerData,
                    CustomerPortalUrl: customerPortalUrl,
                    Dispatch: patternInput_1[1],
                    UserData: props.UserData,
                })), delay(() => singleton(createElement(AccountStatistic, {
                    AccountStatistic: accountStatistic,
                }))));
            default:
                return singleton(createElement(Skeleton, {
                    Variant: "normal",
                }));
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

