import { createElement } from "react";
import React from "react";
import { singleton, ofArray, fold } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { Dialog as Dialog_1 } from "@headlessui/react";
import { singleton as singleton_1, empty, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { Interop_reactApi as Interop_reactApi_1 } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { useTranslation } from "react-i18next";
import { FormState__getValidation } from "../Shared/Shared.js";
import { ErrorAlert } from "./Alert.js";
import { TextButton } from "./Button.js";

export function Dialog(props) {
    let value_18, elems_2, props_6, value_16, props_3, value_11, elems, elems_1;
    const props_8 = ofArray([["open", props.IsOpen], ["onClose", props.OnClose], ["className", fold((a, b) => (`${a} ${b}`), "", ofArray(["relative", "z-50"]))], (value_18 = ofArray([createElement("div", {
        className: join(" ", ["fixed inset-0 bg-black/30"]),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["fixed inset-0 flex items-center justify-center p-4"])], (elems_2 = [(props_6 = ofArray([["key", "dialog-panel"], ["className", fold((a_1, b_1) => (`${a_1} ${b_1}`), "", ofArray(["rounded", "bg-white", "p-8", "min-w-[30rem]", "max-h-[calc(100vh-0.5rem)]", "overflow-auto"]))], (value_16 = ofArray([(props_3 = ofArray([["key", "dialog-title"], (value_11 = singleton(createElement("div", {
        className: join(" ", ["mb-6", "text-lg", "font-bold"]),
        children: props.Title,
    })), ["children", Interop_reactApi.Children.toArray(value_11)])]), Interop_reactApi.createElement(Dialog_1.Title, createObj(props_3))), createElement("div", createObj(ofArray([["className", join(" ", [])], (elems = toList(delay(() => props.Body)), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["mt-7", "flex", "gap-9", "justify-end"])], (elems_1 = toList(delay(() => props.Controls)), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_1))])])))]), ["children", Interop_reactApi.Children.toArray(value_16)])]), Interop_reactApi.createElement(Dialog_1.Panel, createObj(props_6)))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_2))])])))]), ["children", Interop_reactApi.Children.toArray(value_18)])]);
    return Interop_reactApi.createElement(Dialog_1, createObj(props_8));
}

export function ConfirmationDialog(props) {
    let elems_1;
    const t = useTranslation()[0];
    return createElement(Dialog, {
        Body: singleton(createElement("div", createObj(singleton((elems_1 = toList(delay(() => {
            let matchValue, matchValue_1, globalValidationMessage, elems;
            return append((matchValue = props.FormState, (matchValue == null) ? (empty()) : ((matchValue_1 = FormState__getValidation(matchValue, t, "global"), (matchValue_1 == null) ? (empty()) : ((globalValidationMessage = matchValue_1, singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["mb-4"])], (elems = [createElement(ErrorAlert, {
                Label: globalValidationMessage,
            })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))])]))))))))), delay(() => singleton_1(createElement("div", {
                children: props.Description,
            }))));
        })), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_1))]))))),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "confirmation-dialog-onClose-button",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.confirm"),
            OnClick: props.OnConfirm,
            TestId: "confirmation-dialog-onConfirm-button",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: props.Title,
    });
}

