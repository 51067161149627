import { createElement } from "react";
import React from "react";
import * as react from "react";
import { useTranslation } from "react-i18next";
import { useReact_useMemo_10C6A43C, useFeliz_React__React_useState_Static_1505 } from "../../fable_modules/Feliz.2.7.0/React.fs.js";
import { sortBy, tryFind, exists, ofArray, map, singleton, toArray, contains, filter, empty } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { safeHash, comparePrimitives, equals, createObj, stringHash } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { SelectToolModal } from "../Modals/SelectToolModal.js";
import { ConfirmationDialog } from "../../Components/Dialog.js";
import { TableControl$1, ColumnDefinition$1, CellContent, Table } from "../../Widgets/Table/Table.js";
import { ConsumableId__get_unwrap, FormState__getValidation } from "../../Shared/Shared.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { Interop_reactApi } from "../../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { InvalidTextValue } from "../../Components/Text.js";
import Shipment_Add from "../../../public/assets/icons/Shipment_Add.svg";
import Recycle_Bin_1 from "../../../public/assets/icons/Recycle_Bin_1.svg";
import { SelectToolWithQuantityModal } from "../Modals/SelectToolWithQuantityModal.js";
import { AutoSuggestInput, NumberInput } from "../../Components/Input.js";
import { unwrap, map as map_1 } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { numberInputIntMaxValue } from "../../Common.js";
import { SelectConsumableModal } from "../Modals/SelectConsumableModal.js";
import { SelectSetModal } from "../Modals/SelectSetModal.js";
import { Helper_receiverTypeNameTranslation, ReceiverTypeName } from "../../Shared/User.js";
import { FormContainer } from "../../Components/FormContainer.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { Msg } from "./Types.js";
import { GroupedSelect } from "../../Components/Select.js";
import { List_groupBy } from "../../fable_modules/fable-library-js.4.19.2/Seq2.js";
import { DatePicker } from "../../Components/DatePicker.js";
import { TextArea } from "../../Components/TextArea.js";
import { FormSection } from "../../Widgets/FormSection.js";
import { ErrorAlert } from "../../Components/Alert.js";
import { RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { initEdit, update as update_1, initNew } from "./State.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Skeleton } from "../../Components/Skeleton.js";

function ToolTable(props) {
    let Title, TableRows, Options, matchValue_1;
    const t = useTranslation()[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(empty());
    const setSelectedToolIds = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(false);
    const setIsAddToolModalOpen = patternInput_3[1];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(false);
    const setIsRemoveToolIdDialogOpen = patternInput_4[1];
    const assignedTools = filter((tool) => contains(tool.Id, props.ConsumableIds, {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    }), props.Consumables);
    const xs_2 = [createElement(SelectToolModal, {
        Customer: props.Customer,
        IsOpen: patternInput_3[0],
        OnAddToolIds: (toolIds) => {
            props.OnAssignConsumableId(toolIds);
            setIsAddToolModalOpen(false);
        },
        OnClose: () => {
            setIsAddToolModalOpen(false);
        },
        PropertyConfigurations: props.PropertyConfigurations,
        Tools: filter((tool_1) => !contains(tool_1.Id, props.ConsumableIds, {
            Equals: (x_1, y_1) => (x_1 === y_1),
            GetHashCode: stringHash,
        }), props.Consumables),
    }), createElement(ConfirmationDialog, (Title = t("set.tool_remove_dialog.title"), {
        Description: t("set.tool_remove_dialog.description"),
        IsOpen: patternInput_4[0],
        OnClose: () => {
            setIsRemoveToolIdDialogOpen(false);
            setSelectedToolIds(empty());
        },
        OnConfirm: () => {
            props.OnRemoveConsumableId(patternInput_1[0]);
            setIsRemoveToolIdDialogOpen(false);
            setSelectedToolIds(empty());
        },
        Title: Title,
    })), createElement(Table, (TableRows = toArray(assignedTools), (Options = {
        BorderStyle: "row",
        CSVDownload: false,
        ConfigurableColumns: false,
        DefaultSortColumn: ["name", "asc"],
        GlobalSearch: false,
        RowSelection: "multiRow",
    }, {
        ColumnDefinitions: [new ColumnDefinition$1("image", t("general.master_data"), t("general.image"), (dto) => (new CellContent(4, [dto.ImageUrl])), "none", "image"), new ColumnDefinition$1("name", "", t("general.name"), (row) => {
            let elems_1;
            const matchValue = FormState__getValidation(props.FormState, t, `tool-${row.Id}`);
            if (matchValue == null) {
                return new CellContent(0, [row.DisplayName]);
            }
            else {
                const validation = matchValue;
                return new CellContent(1, [createElement("div", createObj(singleton((elems_1 = [createElement("div", {
                    className: join(" ", ["mb-4"]),
                    children: Interop_reactApi.Children.toArray([row.DisplayName]),
                }), createElement(InvalidTextValue, {
                    TestId: "",
                    Text: t(validation),
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))]))))]);
            }
        }, "text", "text"), new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_1) => (new CellContent(0, [row_1.InventoryNumber])), "text", "text"), new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row_2) => (new CellContent(0, [row_2.Manufacturer])), "text", "text"), new ColumnDefinition$1("model", "", t("tool.model"), (row_3) => (new CellContent(0, [row_3.Model])), "text", "text"), new ColumnDefinition$1("serialNumber", "", t("tool.serial_number"), (row_4) => (new CellContent(0, [row_4.SerialNumber])), "text", "text"), new ColumnDefinition$1("qrCodeIds", t("general.master_data"), t("general.qr_code"), (dto_1) => (new CellContent(0, [dto_1.QRCodeIds])), "text", "text"), new ColumnDefinition$1("location", t("general.master_data"), t("general.location"), (dto_2) => (new CellContent(0, [dto_2.Location])), "text", "text"), new ColumnDefinition$1("storage", t("general.master_data"), t("general.storage"), (dto_3) => (new CellContent(0, [dto_3.Storage])), "text", "text"), new ColumnDefinition$1("storageSpace", t("general.master_data"), t("tool.storage_space"), (dto_4) => (new CellContent(0, [dto_4.StorageSpace])), "text", "text"), new ColumnDefinition$1("currentLocation", t("general.master_data"), t("tool.assgined_to"), (dto_5) => (new CellContent(0, [t(dto_5.CurrentLocation)])), "text", "text")],
        Controls: (matchValue_1 = props.UserData.Role, (matchValue_1 === "system") ? ofArray([new TableControl$1(t("general.add"), Shipment_Add(), (_arg_3) => {
            setIsAddToolModalOpen(true);
        }, false, false, "add-tool"), new TableControl$1(t("general.remove"), Recycle_Bin_1(), (tools) => {
            setSelectedToolIds(map((tool_2) => tool_2.Id, tools));
            setIsRemoveToolIdDialogOpen(true);
        }, true, false, "")]) : ((matchValue_1 === "administrator") ? ofArray([new TableControl$1(t("general.add"), Shipment_Add(), (_arg_3) => {
            setIsAddToolModalOpen(true);
        }, false, false, "add-tool"), new TableControl$1(t("general.remove"), Recycle_Bin_1(), (tools) => {
            setSelectedToolIds(map((tool_2) => tool_2.Id, tools));
            setIsRemoveToolIdDialogOpen(true);
        }, true, false, "")]) : ((matchValue_1 === "toolManager") ? ofArray([new TableControl$1(t("general.add"), Shipment_Add(), (_arg_3) => {
            setIsAddToolModalOpen(true);
        }, false, false, "add-tool"), new TableControl$1(t("general.remove"), Recycle_Bin_1(), (tools) => {
            setSelectedToolIds(map((tool_2) => tool_2.Id, tools));
            setIsRemoveToolIdDialogOpen(true);
        }, true, false, "")]) : empty()))),
        Options: Options,
        TableRows: TableRows,
    })))];
    return react.createElement(react.Fragment, {}, ...xs_2);
}

function ToolWithQuantityTable(props) {
    let Title, TableRows, Options, matchValue;
    const t = useTranslation()[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(empty());
    const setSelectedToolIds = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(false);
    const setIsAddToolModalOpen = patternInput_3[1];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(false);
    const setIsRemoveToolIdDialogOpen = patternInput_4[1];
    const componentState = props.FormState.IsLoading ? "disabled" : "enabled";
    const xs = [createElement(SelectToolWithQuantityModal, {
        Customer: props.Customer,
        IsOpen: patternInput_3[0],
        OnAddTool: (toolIds) => {
            props.OnAssignConsumableId(toolIds);
            setIsAddToolModalOpen(false);
        },
        OnClose: () => {
            setIsAddToolModalOpen(false);
        },
        PropertyConfigurations: props.PropertyConfigurations,
        ToolsWithQuantity: filter((tool) => !exists((selectedTool) => {
            if (selectedTool.Tool.Id === tool.Id) {
                return selectedTool.Tool.StorageId === tool.StorageId;
            }
            else {
                return false;
            }
        }, props.SelectedToolsWithQuantity), props.ToolsWithQuantity),
    }), createElement(ConfirmationDialog, (Title = t("set.tool_remove_dialog.title"), {
        Description: t("set.tool_remove_dialog.description"),
        IsOpen: patternInput_4[0],
        OnClose: () => {
            setIsRemoveToolIdDialogOpen(false);
            setSelectedToolIds(empty());
        },
        OnConfirm: () => {
            props.OnRemoveConsumableId(patternInput_1[0]);
            setIsRemoveToolIdDialogOpen(false);
            setSelectedToolIds(empty());
        },
        Title: Title,
    })), createElement(Table, (TableRows = toArray(props.SelectedToolsWithQuantity), (Options = {
        BorderStyle: "row",
        CSVDownload: false,
        ConfigurableColumns: false,
        DefaultSortColumn: ["name", "asc"],
        GlobalSearch: false,
        RowSelection: "multiRow",
    }, {
        ColumnDefinitions: [new ColumnDefinition$1("image", t("general.master_data"), t("general.image"), (dto) => (new CellContent(4, [dto.Tool.ImageUrl])), "none", "image"), new ColumnDefinition$1("quantity", "", t("general.quantity_3"), (row) => {
            let Value;
            return new CellContent(1, [createElement(NumberInput, (Value = map_1((value_1) => value_1, row.Quantity), {
                ComponentState: componentState,
                MaxValue: numberInputIntMaxValue,
                OnChange: (value_2) => {
                    props.OnChangeQuantity([row.Tool, value_2]);
                },
                TestId: "",
                ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, `tool-with-quantity-${row.Tool.Id}`)),
                Value: unwrap(Value),
            }))]);
        }, "none", "text"), new ColumnDefinition$1("name", "", t("general.name"), (row_1) => (new CellContent(0, [row_1.Tool.DisplayName])), "text", "text"), new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_2) => (new CellContent(0, [row_2.Tool.InventoryNumber])), "text", "text"), new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row_3) => (new CellContent(0, [row_3.Tool.Manufacturer])), "text", "text"), new ColumnDefinition$1("model", "", t("tool.model"), (row_4) => (new CellContent(0, [row_4.Tool.Model])), "text", "text"), new ColumnDefinition$1("qrCodeIds", t("general.master_data"), t("general.qr_code"), (row_5) => (new CellContent(0, [row_5.Tool.QRCodeIds])), "text", "text"), new ColumnDefinition$1("location", t("general.master_data"), t("general.location"), (row_6) => (new CellContent(0, [row_6.Tool.Location])), "text", "text"), new ColumnDefinition$1("storage", t("general.master_data"), t("general.storage"), (row_7) => (new CellContent(0, [row_7.Tool.Storage])), "text", "text"), new ColumnDefinition$1("storageSpace", t("general.master_data"), t("tool.storage_space"), (row_8) => (new CellContent(0, [row_8.Tool.StorageSpace])), "text", "text")],
        Controls: (matchValue = props.UserData.Role, (matchValue === "system") ? ofArray([new TableControl$1(t("general.add"), Shipment_Add(), (_arg_3) => {
            setIsAddToolModalOpen(true);
        }, false, false, "add-tool"), new TableControl$1(t("general.remove"), Recycle_Bin_1(), (tools) => {
            setSelectedToolIds(map((tool_1) => tool_1.Tool, tools));
            setIsRemoveToolIdDialogOpen(true);
        }, true, false, "")]) : ((matchValue === "administrator") ? ofArray([new TableControl$1(t("general.add"), Shipment_Add(), (_arg_3) => {
            setIsAddToolModalOpen(true);
        }, false, false, "add-tool"), new TableControl$1(t("general.remove"), Recycle_Bin_1(), (tools) => {
            setSelectedToolIds(map((tool_1) => tool_1.Tool, tools));
            setIsRemoveToolIdDialogOpen(true);
        }, true, false, "")]) : ((matchValue === "toolManager") ? ofArray([new TableControl$1(t("general.add"), Shipment_Add(), (_arg_3) => {
            setIsAddToolModalOpen(true);
        }, false, false, "add-tool"), new TableControl$1(t("general.remove"), Recycle_Bin_1(), (tools) => {
            setSelectedToolIds(map((tool_1) => tool_1.Tool, tools));
            setIsRemoveToolIdDialogOpen(true);
        }, true, false, "")]) : empty()))),
        Options: Options,
        TableRows: TableRows,
    })))];
    return react.createElement(react.Fragment, {}, ...xs);
}

function ConsumableTable(props) {
    let Title, TableRows, Options, matchValue;
    const t = useTranslation()[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(empty());
    const setSelectedConsumableIds = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setIsAddConsumableModalOpen = patternInput_2[1];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(false);
    const setIsRemoveConsumableIdDialogOpen = patternInput_3[1];
    const componentState = props.FormState.IsLoading ? "disabled" : "enabled";
    const xs = [createElement(SelectConsumableModal, {
        Consumables: filter((tool) => !exists((selectedTool) => {
            if (equals(selectedTool.Consumable.Id, tool.Id)) {
                return selectedTool.Consumable.StorageId === tool.StorageId;
            }
            else {
                return false;
            }
        }, props.SelectedConsumables), props.Consumables),
        Customer: props.Customer,
        IsOpen: patternInput_2[0],
        OnAddConsumable: (consumableIds) => {
            props.OnAssignConsumableId(consumableIds);
            setIsAddConsumableModalOpen(false);
        },
        OnClose: () => {
            setIsAddConsumableModalOpen(false);
        },
        PropertyConfigurations: props.PropertyConfigurations,
    }), createElement(ConfirmationDialog, (Title = t("set.tool_remove_dialog.title"), {
        Description: t("set.tool_remove_dialog.description"),
        IsOpen: patternInput_3[0],
        OnClose: () => {
            setIsRemoveConsumableIdDialogOpen(false);
            setSelectedConsumableIds(empty());
        },
        OnConfirm: () => {
            props.OnRemoveConsumableId(patternInput_1[0]);
            setIsRemoveConsumableIdDialogOpen(false);
            setSelectedConsumableIds(empty());
        },
        Title: Title,
    })), createElement(Table, (TableRows = toArray(props.SelectedConsumables), (Options = {
        BorderStyle: "row",
        CSVDownload: false,
        ConfigurableColumns: false,
        DefaultSortColumn: ["name", "asc"],
        GlobalSearch: false,
        RowSelection: "multiRow",
    }, {
        ColumnDefinitions: [new ColumnDefinition$1("image", t("general.master_data"), t("general.image"), (dto) => (new CellContent(4, [dto.Consumable.ImageUrl])), "none", "image"), new ColumnDefinition$1("quantity", "", t("general.quantity_3"), (row) => {
            let Value;
            return new CellContent(1, [createElement(NumberInput, (Value = map_1((value_1) => value_1, row.Quantity), {
                ComponentState: componentState,
                OnChange: (value_2) => {
                    props.OnChangeQuantity([row.Consumable, value_2]);
                },
                TestId: "",
                ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, `consumable-${ConsumableId__get_unwrap(row.Consumable.Id)}`)),
                Value: unwrap(Value),
            }))]);
        }, "none", "text"), new ColumnDefinition$1("name", "", t("general.name"), (row_1) => (new CellContent(0, [row_1.Consumable.Name])), "text", "text"), new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_2) => (new CellContent(0, [row_2.Consumable.InventoryNumber])), "text", "text"), new ColumnDefinition$1("qrCodeIds", t("general.master_data"), t("general.qr_code"), (row_3) => (new CellContent(0, [row_3.Consumable.QRCodeIds])), "text", "text"), new ColumnDefinition$1("storage", t("general.master_data"), t("general.storage"), (row_4) => (new CellContent(0, [row_4.Consumable.Storage])), "text", "text"), new ColumnDefinition$1("storageSpace", t("general.master_data"), t("tool.storage_space"), (row_5) => (new CellContent(0, [row_5.Consumable.StorageSpace])), "text", "text")],
        Controls: (matchValue = props.UserData.Role, (matchValue === "system") ? ofArray([new TableControl$1(t("general.add"), Shipment_Add(), (_arg_3) => {
            setIsAddConsumableModalOpen(true);
        }, false, false, "add-consumable"), new TableControl$1(t("general.remove"), Recycle_Bin_1(), (consumables) => {
            setSelectedConsumableIds(map((consumable) => consumable.Consumable, consumables));
            setIsRemoveConsumableIdDialogOpen(true);
        }, true, false, "")]) : ((matchValue === "administrator") ? ofArray([new TableControl$1(t("general.add"), Shipment_Add(), (_arg_3) => {
            setIsAddConsumableModalOpen(true);
        }, false, false, "add-consumable"), new TableControl$1(t("general.remove"), Recycle_Bin_1(), (consumables) => {
            setSelectedConsumableIds(map((consumable) => consumable.Consumable, consumables));
            setIsRemoveConsumableIdDialogOpen(true);
        }, true, false, "")]) : ((matchValue === "toolManager") ? ofArray([new TableControl$1(t("general.add"), Shipment_Add(), (_arg_3) => {
            setIsAddConsumableModalOpen(true);
        }, false, false, "add-consumable"), new TableControl$1(t("general.remove"), Recycle_Bin_1(), (consumables) => {
            setSelectedConsumableIds(map((consumable) => consumable.Consumable, consumables));
            setIsRemoveConsumableIdDialogOpen(true);
        }, true, false, "")]) : empty()))),
        Options: Options,
        TableRows: TableRows,
    })))];
    return react.createElement(react.Fragment, {}, ...xs);
}

function SetTable(props) {
    let Title, TableRows, Options, matchValue_1;
    const t = useTranslation()[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(empty());
    const setSelectedSetIds = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setIsAddSetModalOpen = patternInput_2[1];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(false);
    const setIsRemoveSetIdDialogOpen = patternInput_3[1];
    const assignedTools = filter((tool) => contains(tool.Id, props.SetIds, {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    }), props.Sets);
    const xs_2 = [createElement(SelectSetModal, {
        Customer: props.Customer,
        IsOpen: patternInput_2[0],
        OnAddSetIds: (toolIds) => {
            props.OnAssignSetId(toolIds);
            setIsAddSetModalOpen(false);
        },
        OnClose: () => {
            setIsAddSetModalOpen(false);
        },
        PropertyConfigurations: props.PropertyConfigurations,
        Sets: filter((set$) => {
            if (set$.ToolOverviewType === "set") {
                return !contains(set$.Id, props.SetIds, {
                    Equals: (x_1, y_1) => (x_1 === y_1),
                    GetHashCode: stringHash,
                });
            }
            else {
                return false;
            }
        }, props.Sets),
    }), createElement(ConfirmationDialog, (Title = t("set.tool_remove_dialog.title"), {
        Description: t("set.tool_remove_dialog.description"),
        IsOpen: patternInput_3[0],
        OnClose: () => {
            setIsRemoveSetIdDialogOpen(false);
            setSelectedSetIds(empty());
        },
        OnConfirm: () => {
            props.OnRemoveSetId(patternInput_1[0]);
            setIsRemoveSetIdDialogOpen(false);
            setSelectedSetIds(empty());
        },
        Title: Title,
    })), createElement(Table, (TableRows = toArray(assignedTools), (Options = {
        BorderStyle: "row",
        CSVDownload: false,
        ConfigurableColumns: false,
        DefaultSortColumn: ["name", "asc"],
        GlobalSearch: false,
        RowSelection: "multiRow",
    }, {
        ColumnDefinitions: [new ColumnDefinition$1("image", t("general.master_data"), t("general.image"), (dto) => (new CellContent(4, [dto.ImageUrl])), "none", "image"), new ColumnDefinition$1("name", "", t("general.name"), (row) => {
            let elems_1;
            const matchValue = FormState__getValidation(props.FormState, t, `set-${row.Id}`);
            if (matchValue == null) {
                return new CellContent(0, [row.DisplayName]);
            }
            else {
                const validation = matchValue;
                return new CellContent(1, [createElement("div", createObj(singleton((elems_1 = [createElement("div", {
                    className: join(" ", ["mb-4"]),
                    children: Interop_reactApi.Children.toArray([row.DisplayName]),
                }), createElement(InvalidTextValue, {
                    TestId: "",
                    Text: t(validation),
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))]))))]);
            }
        }, "text", "text"), new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_1) => (new CellContent(0, [row_1.DisplayName])), "text", "text"), new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row_2) => (new CellContent(0, [row_2.Manufacturer])), "text", "text"), new ColumnDefinition$1("model", "", t("tool.model"), (row_3) => (new CellContent(0, [row_3.Model])), "text", "text"), new ColumnDefinition$1("serialNumber", "", t("tool.serial_number"), (row_4) => (new CellContent(0, [row_4.SerialNumber])), "text", "text"), new ColumnDefinition$1("qrCodeIds", t("general.master_data"), t("general.qr_code"), (row_5) => (new CellContent(0, [row_5.QRCodeIds])), "text", "text"), new ColumnDefinition$1("location", t("general.master_data"), t("general.location"), (row_6) => (new CellContent(0, [row_6.Location])), "text", "text"), new ColumnDefinition$1("storage", t("general.master_data"), t("general.storage"), (row_7) => (new CellContent(0, [row_7.Storage])), "text", "text"), new ColumnDefinition$1("storageSpace", t("general.master_data"), t("tool.storage_space"), (row_8) => (new CellContent(0, [row_8.StorageSpace])), "text", "text"), new ColumnDefinition$1("currentLocation", t("general.master_data"), t("tool.assgined_to"), (row_9) => (new CellContent(0, [t(row_9.CurrentLocation)])), "text", "text")],
        Controls: (matchValue_1 = props.UserData.Role, (matchValue_1 === "system") ? ofArray([new TableControl$1(t("general.add"), Shipment_Add(), (_arg_3) => {
            setIsAddSetModalOpen(true);
        }, false, false, "add-tool"), new TableControl$1(t("general.remove"), Recycle_Bin_1(), (sets) => {
            setSelectedSetIds(map((set$_1) => set$_1.Id, sets));
            setIsRemoveSetIdDialogOpen(true);
        }, true, false, "")]) : ((matchValue_1 === "administrator") ? ofArray([new TableControl$1(t("general.add"), Shipment_Add(), (_arg_3) => {
            setIsAddSetModalOpen(true);
        }, false, false, "add-tool"), new TableControl$1(t("general.remove"), Recycle_Bin_1(), (sets) => {
            setSelectedSetIds(map((set$_1) => set$_1.Id, sets));
            setIsRemoveSetIdDialogOpen(true);
        }, true, false, "")]) : ((matchValue_1 === "toolManager") ? ofArray([new TableControl$1(t("general.add"), Shipment_Add(), (_arg_3) => {
            setIsAddSetModalOpen(true);
        }, false, false, "add-tool"), new TableControl$1(t("general.remove"), Recycle_Bin_1(), (sets) => {
            setSelectedSetIds(map((set$_1) => set$_1.Id, sets));
            setIsRemoveSetIdDialogOpen(true);
        }, true, false, "")]) : empty()))),
        Options: Options,
        TableRows: TableRows,
    })))];
    return react.createElement(react.Fragment, {}, ...xs_2);
}

function ReservationForm(props) {
    const t = useTranslation()[0];
    const componentState = props.FormState.IsLoading ? "disabled" : "enabled";
    const selectedReceiver = map_1((receiver_1) => {
        const matchValue = receiver_1.LocationName;
        if (matchValue != null) {
            const locationName = matchValue;
            if (equals(receiver_1.ReceiverType, new ReceiverTypeName(4, []))) {
                return {
                    label: `${locationName} - ${receiver_1.Name}`,
                    value: receiver_1,
                };
            }
            else {
                return {
                    label: `${receiver_1.Name} - ${locationName}`,
                    value: receiver_1,
                };
            }
        }
        else {
            return {
                label: `${receiver_1.Name}`,
                value: receiver_1,
            };
        }
    }, tryFind((receiver) => (receiver.Id === props.FormData.ReceiverId), props.Receivers));
    return createElement(FormContainer, {
        Children: toList(delay(() => append(singleton_1(useReact_useMemo_10C6A43C(() => createElement(AutoSuggestInput, {
            ComponentState: componentState,
            Label: t("general.name"),
            OnChange: (arg) => {
                props.Dispatch(new Msg(12, [arg]));
            },
            Suggestions: props.ReservationNameSuggestions,
            TestId: "reservation-form-name-test-id",
            ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "name")),
            Value: props.FormData.Name,
        }), [props.ReservationNameSuggestions, props.FormData.Name, componentState])), delay(() => append(singleton_1(createElement(GroupedSelect, (($value) => { $value.key = $value.Key; return $value; })({
            ComponentState: componentState,
            IsClearable: false,
            Key: "assign-receiver-select",
            Label: t("general.user"),
            NoOptionsMessage: "select.employee_no_options",
            OnChange: (value) => {
                if (value == null) {
                }
                else {
                    props.Dispatch(new Msg(11, [value.value.Id]));
                }
            },
            Options: map((tupledArg) => {
                const receiverType = tupledArg[0];
                const receivers_1 = toArray(sortBy((receiver_4) => receiver_4.label.toLowerCase(), map((receiver_3) => {
                    const matchValue_1 = receiver_3.LocationName;
                    if (matchValue_1 != null) {
                        const locationName_1 = matchValue_1;
                        if (equals(receiverType, new ReceiverTypeName(4, []))) {
                            return {
                                label: `${locationName_1} - ${receiver_3.Name}`,
                                value: receiver_3,
                            };
                        }
                        else {
                            return {
                                label: `${receiver_3.Name} - ${locationName_1}`,
                                value: receiver_3,
                            };
                        }
                    }
                    else {
                        return {
                            label: `${receiver_3.Name}`,
                            value: receiver_3,
                        };
                    }
                }, tupledArg[1]), {
                    Compare: comparePrimitives,
                }));
                return {
                    label: Helper_receiverTypeNameTranslation(t, receiverType),
                    options: receivers_1,
                };
            }, List_groupBy((receiver_2) => receiver_2.ReceiverType, props.Receivers, {
                Equals: equals,
                GetHashCode: safeHash,
            })),
            PlaceholderKey: "select.employee_placeholder",
            ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "receiver")),
            Value: unwrap(selectedReceiver),
        }))), delay(() => append(singleton_1(createElement(DatePicker, {
            ComponentState: componentState,
            IsClearable: false,
            Label: t("general.start_date"),
            OnChange: (date) => {
                if (date == null) {
                }
                else {
                    props.Dispatch(new Msg(14, [date]));
                }
            },
            TestId: "reservation-form-start-date-test-id",
            Value: props.FormData.StartDate,
        })), delay(() => append(singleton_1(createElement(DatePicker, {
            ComponentState: componentState,
            IsClearable: false,
            Label: t("general.end_date"),
            MinDate: props.FormData.StartDate,
            OnChange: (date_2) => {
                if (date_2 == null) {
                }
                else {
                    props.Dispatch(new Msg(15, [date_2]));
                }
            },
            TestId: "reservation-form-end-date-test-id",
            Value: props.FormData.EndDate,
        })), delay(() => append(singleton_1(createElement(TextArea, {
            ComponentState: componentState,
            Label: t("general.description"),
            OnChange: (arg_1) => {
                props.Dispatch(new Msg(13, [arg_1]));
            },
            TestId: "reservation-form-description-test-id",
            Value: props.FormData.Description,
        })), delay(() => {
            let Header_1;
            return append(singleton_1(createElement(FormSection, (Header_1 = t("general.tool"), {
                Content: singleton(createElement(ToolTable, {
                    ConsumableIds: props.FormData.ToolIds,
                    Consumables: filter((tool) => (tool.ToolOverviewType === "tool"), props.Tools),
                    Customer: props.UserData.Customer,
                    FormState: props.FormState,
                    OnAssignConsumableId: (arg_2) => {
                        props.Dispatch(new Msg(1, [arg_2]));
                    },
                    OnRemoveConsumableId: (arg_3) => {
                        props.Dispatch(new Msg(2, [arg_3]));
                    },
                    PropertyConfigurations: props.PropertyConfigurations,
                    UserData: props.UserData,
                })),
                Header: Header_1,
                Id: "tools",
            }))), delay(() => {
                let Header_2;
                return append(singleton_1(createElement(FormSection, (Header_2 = t("general.set"), {
                    Content: singleton(createElement(SetTable, {
                        Customer: props.UserData.Customer,
                        FormState: props.FormState,
                        OnAssignSetId: (arg_4) => {
                            props.Dispatch(new Msg(3, [arg_4]));
                        },
                        OnRemoveSetId: (arg_5) => {
                            props.Dispatch(new Msg(4, [arg_5]));
                        },
                        PropertyConfigurations: props.PropertyConfigurations,
                        SetIds: props.FormData.SetIds,
                        Sets: props.Tools,
                        UserData: props.UserData,
                    })),
                    Header: Header_2,
                    Id: "sets",
                }))), delay(() => {
                    let Header_3;
                    return append(singleton_1(createElement(FormSection, (Header_3 = t("general.tool_with_quantity"), {
                        Content: singleton(createElement(ToolWithQuantityTable, {
                            Customer: props.UserData.Customer,
                            FormState: props.FormState,
                            OnAssignConsumableId: (arg_6) => {
                                props.Dispatch(new Msg(5, [arg_6]));
                            },
                            OnChangeQuantity: (arg_8) => {
                                let tupledArg_1;
                                props.Dispatch((tupledArg_1 = arg_8, new Msg(7, [tupledArg_1[0], tupledArg_1[1]])));
                            },
                            OnRemoveConsumableId: (arg_7) => {
                                props.Dispatch(new Msg(6, [arg_7]));
                            },
                            PropertyConfigurations: props.PropertyConfigurations,
                            SelectedToolsWithQuantity: props.FormData.ToolsWithQuantity,
                            ToolsWithQuantity: filter((tool_1) => (tool_1.ToolOverviewType === "toolWithQuantity"), props.Tools),
                            UserData: props.UserData,
                        })),
                        Header: Header_3,
                        Id: "tool-with-quantity",
                    }))), delay(() => {
                        let Header_4;
                        return append(singleton_1(createElement(FormSection, (Header_4 = t("general.consumable"), {
                            Content: singleton(createElement(ConsumableTable, {
                                Consumables: props.Consumables,
                                Customer: props.UserData.Customer,
                                FormState: props.FormState,
                                OnAssignConsumableId: (arg_9) => {
                                    props.Dispatch(new Msg(8, [arg_9]));
                                },
                                OnChangeQuantity: (arg_11) => {
                                    let tupledArg_2;
                                    props.Dispatch((tupledArg_2 = arg_11, new Msg(10, [tupledArg_2[0], tupledArg_2[1]])));
                                },
                                OnRemoveConsumableId: (arg_10) => {
                                    props.Dispatch(new Msg(9, [arg_10]));
                                },
                                PropertyConfigurations: props.PropertyConfigurations,
                                SelectedConsumables: props.FormData.Consumables,
                                UserData: props.UserData,
                            })),
                            Header: Header_4,
                            Id: "consumables",
                        }))), delay(() => {
                            const matchValue_2 = FormState__getValidation(props.FormState, t, "global");
                            if (matchValue_2 == null) {
                                return empty_1();
                            }
                            else {
                                return singleton_1(createElement(ErrorAlert, {
                                    Label: matchValue_2,
                                }));
                            }
                        }));
                    }));
                }));
            }));
        })))))))))))),
        Header: "",
        OnBack: () => {
            RouterModule_nav(ofArray(["reservations"]), 1, 1);
        },
        OnSave: () => {
            props.Dispatch(new Msg(0, []));
        },
    });
}

export function NewReservationForm(props) {
    let patternInput;
    const init = initNew(props.UserData);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    const matchValue = state_1.Tools;
    const matchValue_1 = state_1.Consumables;
    const matchValue_2 = state_1.ReservationNameSuggestions;
    const matchValue_3 = state_1.Receivers;
    const matchValue_4 = state_1.CustomerConfiguration;
    const matchValue_5 = state_1.PropertyConfigurations;
    let matchResult, consumables, customerConfiguration, propertyConfigurations, receivers, reservationNameSuggestions, tools;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_2.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_3.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_4.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_5.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        consumables = matchValue_1.fields[0];
        customerConfiguration = matchValue_4.fields[0];
        propertyConfigurations = matchValue_5.fields[0];
        receivers = matchValue_3.fields[0];
        reservationNameSuggestions = matchValue_2.fields[0];
        tools = matchValue.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement(ReservationForm, {
                Consumables: consumables,
                CustomerConfiguration: customerConfiguration,
                Dispatch: patternInput[1],
                FormData: state_1.FormData,
                FormState: state_1.FormState,
                PropertyConfigurations: propertyConfigurations,
                Receivers: receivers,
                ReservationNameSuggestions: reservationNameSuggestions,
                Tools: tools,
                UserData: props.UserData,
            });
        default:
            return createElement(Skeleton, {
                Variant: "detail",
            });
    }
}

export function EditReservationForm(props) {
    let patternInput;
    const init = initEdit(props.UserData, props.ReservationId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    const matchValue = state_1.Tools;
    const matchValue_1 = state_1.Consumables;
    const matchValue_2 = state_1.ReservationNameSuggestions;
    const matchValue_3 = state_1.Receivers;
    const matchValue_4 = state_1.CustomerConfiguration;
    const matchValue_6 = state_1.PropertyConfigurations;
    let matchResult, consumables, customerConfiguration, propertyConfigurations, receivers, reservationNameSuggestions, tools;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_2.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_3.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_4.tag === 0) {
        matchResult = 1;
    }
    else if (state_1.FormData.ReservationDetailDto == null) {
        matchResult = 1;
    }
    else if (matchValue_6.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        consumables = matchValue_1.fields[0];
        customerConfiguration = matchValue_4.fields[0];
        propertyConfigurations = matchValue_6.fields[0];
        receivers = matchValue_3.fields[0];
        reservationNameSuggestions = matchValue_2.fields[0];
        tools = matchValue.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement(ReservationForm, {
                Consumables: consumables,
                CustomerConfiguration: customerConfiguration,
                Dispatch: patternInput[1],
                FormData: state_1.FormData,
                FormState: state_1.FormState,
                PropertyConfigurations: propertyConfigurations,
                Receivers: receivers,
                ReservationNameSuggestions: reservationNameSuggestions,
                Tools: tools,
                UserData: props.UserData,
            });
        default:
            return createElement(Skeleton, {
                Variant: "detail",
            });
    }
}

