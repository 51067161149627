import { Union } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { union_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { equals, createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { TabsMenu } from "./TabsMenu.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { RouterModule_nav } from "../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { Feature, isFeatureEnabled } from "../FeatureFlip.js";
import { Variant } from "../Variant.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";

export class SelectedTab extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["AllTab", "UsersTab", "EmployeeTab", "SubcontractorTab", "ConsturctionSiteTab", "ExternalPersonTab", "ProjectTab", "RoomTab", "StudentTab", "SchoolClassTab", "VehicleTab"];
    }
}

export function SelectedTab_$reflection() {
    return union_type("Widgets.ToolboxControlWidget.SelectedTab", [], SelectedTab, () => [[], [], [], [], [], [], [], [], [], [], []]);
}

export function ToolboxControl(props) {
    let elems_1, elems;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "justify-between", "mb-4"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["gap-4"])], (elems = [createElement(TabsMenu, {
        Tabs: toList(delay(() => {
            let Label;
            return append(singleton((Label = t("general.all"), {
                IsSelected: equals(props.SelectedTab, new SelectedTab(0, [])),
                Label: Label,
                OnClick: () => {
                    RouterModule_nav(ofArray(["receivers"]), 1, 1);
                },
                TestId: "",
            })), delay(() => {
                let Label_1;
                return append(props.CustomerConfiguration.ReceiverVisibilityConfiguration.Users ? singleton((Label_1 = t("receivers.users"), {
                    IsSelected: equals(props.SelectedTab, new SelectedTab(1, [])),
                    Label: Label_1,
                    OnClick: () => {
                        RouterModule_nav(ofArray(["receivers", "users"]), 1, 1);
                    },
                    TestId: "",
                })) : empty(), delay(() => {
                    let Label_2;
                    return append(props.CustomerConfiguration.ReceiverVisibilityConfiguration.Employees ? singleton((Label_2 = t("receivers.employees"), {
                        IsSelected: equals(props.SelectedTab, new SelectedTab(2, [])),
                        Label: Label_2,
                        OnClick: () => {
                            RouterModule_nav(ofArray(["receivers", "employees"]), 1, 1);
                        },
                        TestId: "",
                    })) : empty(), delay(() => {
                        let Label_3;
                        return append(props.CustomerConfiguration.ReceiverVisibilityConfiguration.ExternalPersons ? singleton((Label_3 = t("receivers.externalpersons"), {
                            IsSelected: equals(props.SelectedTab, new SelectedTab(5, [])),
                            Label: Label_3,
                            OnClick: () => {
                                RouterModule_nav(ofArray(["receivers", "external-persons"]), 1, 1);
                            },
                            TestId: "",
                        })) : empty(), delay(() => {
                            let Label_4;
                            return append(props.CustomerConfiguration.ReceiverVisibilityConfiguration.ConstructionSites ? singleton((Label_4 = t("receivers.constructionsites"), {
                                IsSelected: equals(props.SelectedTab, new SelectedTab(4, [])),
                                Label: Label_4,
                                OnClick: () => {
                                    RouterModule_nav(ofArray(["receivers", "construction-sites"]), 1, 1);
                                },
                                TestId: "",
                            })) : empty(), delay(() => {
                                let Label_5;
                                return append(props.CustomerConfiguration.ReceiverVisibilityConfiguration.Projects ? singleton((Label_5 = t("receivers.projects"), {
                                    IsSelected: equals(props.SelectedTab, new SelectedTab(6, [])),
                                    Label: Label_5,
                                    OnClick: () => {
                                        RouterModule_nav(ofArray(["receivers", "projects"]), 1, 1);
                                    },
                                    TestId: "",
                                })) : empty(), delay(() => {
                                    let Label_6;
                                    return append(props.CustomerConfiguration.ReceiverVisibilityConfiguration.Rooms ? singleton((Label_6 = t("receivers.rooms"), {
                                        IsSelected: equals(props.SelectedTab, new SelectedTab(7, [])),
                                        Label: Label_6,
                                        OnClick: () => {
                                            RouterModule_nav(ofArray(["receivers", "rooms"]), 1, 1);
                                        },
                                        TestId: "",
                                    })) : empty(), delay(() => {
                                        let Label_7;
                                        return append(props.CustomerConfiguration.ReceiverVisibilityConfiguration.Subcontractos ? singleton((Label_7 = t("receivers.subcontractors"), {
                                            IsSelected: equals(props.SelectedTab, new SelectedTab(3, [])),
                                            Label: Label_7,
                                            OnClick: () => {
                                                RouterModule_nav(ofArray(["receivers", "subcontractors"]), 1, 1);
                                            },
                                            TestId: "",
                                        })) : empty(), delay(() => {
                                            let Label_8;
                                            return append(isFeatureEnabled(new Feature(11, [])) ? (props.CustomerConfiguration.ReceiverVisibilityConfiguration.Vehicles ? singleton((Label_8 = t("receivers.vehicles"), {
                                                IsSelected: equals(props.SelectedTab, new SelectedTab(10, [])),
                                                Label: Label_8,
                                                OnClick: () => {
                                                    RouterModule_nav(ofArray(["receivers", "vehicles"]), 1, 1);
                                                },
                                                TestId: "toolbox-control-widget-tabs-menu-vehicle-tab",
                                            })) : empty()) : empty(), delay(() => {
                                                let Label_9;
                                                if (Variant.tag === 0) {
                                                    return empty();
                                                }
                                                else {
                                                    return append(props.CustomerConfiguration.ReceiverVisibilityConfiguration.Students ? singleton((Label_9 = t("receivers.students"), {
                                                        IsSelected: equals(props.SelectedTab, new SelectedTab(8, [])),
                                                        Label: Label_9,
                                                        OnClick: () => {
                                                            RouterModule_nav(ofArray(["receivers", "students"]), 1, 1);
                                                        },
                                                        TestId: "",
                                                    })) : empty(), delay(() => {
                                                        let Label_10;
                                                        return props.CustomerConfiguration.ReceiverVisibilityConfiguration.SchoolClasses ? singleton((Label_10 = t("receivers.school_classes"), {
                                                            IsSelected: equals(props.SelectedTab, new SelectedTab(9, [])),
                                                            Label: Label_10,
                                                            OnClick: () => {
                                                                RouterModule_nav(ofArray(["receivers", "school-classes"]), 1, 1);
                                                            },
                                                            TestId: "",
                                                        })) : empty();
                                                    }));
                                                }
                                            }));
                                        }));
                                    }));
                                }));
                            }));
                        }));
                    }));
                }));
            }));
        })),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

