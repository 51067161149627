import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { Consumable_$reflection, ConsumableUpdateFormDto_$reflection, ConsumableCreateFormDto_$reflection } from "../../Shared/Consumable.js";
import { array_type, string_type, record_type, class_type, float64_type, bool_type, list_type, option_type, union_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { ClientApiResponse$1_$reflection, LabelId_$reflection, StorageId_$reflection, FormState_$reflection, Customer_$reflection, TokenDataDto_$reflection, Label_$reflection, RequestedValue$1_$reflection, ConsumableId_$reflection } from "../../Shared/Shared.js";
import { PropertyConfigurationEnabledResponse_$reflection, CustomProperty_$reflection, PropertyConfiguration_$reflection } from "../../Shared/PropertyConfiguration.js";
import { Storage_$reflection } from "../../Shared/Storage.js";
import { OptionType$1_$reflection } from "../../SharedComponents/ReactSelect.js";
import { FileUploadResponse_$reflection } from "../../Shared/File.js";

export class ConsumableForm extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Create", "Update"];
    }
}

export function ConsumableForm_$reflection() {
    return union_type("Consumables.Form.Types.ConsumableForm", [], ConsumableForm, () => [[["Item", ConsumableCreateFormDto_$reflection()]], [["Item", ConsumableUpdateFormDto_$reflection()]]]);
}

export class State extends Record {
    constructor(ConsumableId, ConsumablesForm, PropertyConfigurations, Storages, Labels, IsCustomPropertiesEnabled, StorageStock, PurchaseDate, WarrantyDate, UserData, Customer, SelectedFile, FormState) {
        super();
        this.ConsumableId = ConsumableId;
        this.ConsumablesForm = ConsumablesForm;
        this.PropertyConfigurations = PropertyConfigurations;
        this.Storages = Storages;
        this.Labels = Labels;
        this.IsCustomPropertiesEnabled = IsCustomPropertiesEnabled;
        this.StorageStock = StorageStock;
        this.PurchaseDate = PurchaseDate;
        this.WarrantyDate = WarrantyDate;
        this.UserData = UserData;
        this.Customer = Customer;
        this.SelectedFile = SelectedFile;
        this.FormState = FormState;
    }
}

export function State_$reflection() {
    return record_type("Consumables.Form.Types.State", [], State, () => [["ConsumableId", option_type(ConsumableId_$reflection())], ["ConsumablesForm", RequestedValue$1_$reflection(ConsumableForm_$reflection())], ["PropertyConfigurations", RequestedValue$1_$reflection(list_type(PropertyConfiguration_$reflection()))], ["Storages", RequestedValue$1_$reflection(list_type(Storage_$reflection()))], ["Labels", RequestedValue$1_$reflection(list_type(Label_$reflection()))], ["IsCustomPropertiesEnabled", RequestedValue$1_$reflection(bool_type)], ["StorageStock", option_type(float64_type)], ["PurchaseDate", option_type(class_type("System.DateTime"))], ["WarrantyDate", option_type(class_type("System.DateTime"))], ["UserData", TokenDataDto_$reflection()], ["Customer", Customer_$reflection()], ["SelectedFile", option_type(class_type("Browser.Types.File", undefined))], ["FormState", FormState_$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetName", "SetAdditionalName", "SetDescription", "SetInventoryNumber", "SetUnit", "SetStorage", "SetStorageSpace", "SetStock", "SaveConsumables", "UpdateConsumables", "AddLabelId", "SetCustomProperties", "FetchCategories", "SetSupplier", "SetArticleNumber", "SetCommercialDataComment", "SetPurchasePrice", "SetPurchaseDate", "SetWarrantyDate", "UpdatedStock", "SetSelectedFile", "ImageUploaded", "FetchConsumable", "ConsumableFetched", "CopyConsumableFetched", "ConsumableUpdated", "ConsumableCreated", "StoragesFetched", "LabelsFetched", "PropertyConfigurationsFetched", "CustomPropertiesEnabledFetched", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Consumables.Form.Types.Msg", [], Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", StorageId_$reflection()]], [["Item", string_type]], [["Item", option_type(float64_type)]], [], [], [["Item", array_type(OptionType$1_$reflection(LabelId_$reflection()))]], [["Item", list_type(CustomProperty_$reflection())]], [], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", option_type(float64_type)]], [["Item", option_type(class_type("System.DateTime"))]], [["Item", option_type(class_type("System.DateTime"))]], [], [["Item", option_type(class_type("Browser.Types.File", undefined))]], [["Item", FileUploadResponse_$reflection()]], [], [["Item", Consumable_$reflection()]], [["Item", Consumable_$reflection()]], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", list_type(Storage_$reflection())]], [["Item", list_type(Label_$reflection())]], [["Item", list_type(PropertyConfiguration_$reflection())]], [["Item", PropertyConfigurationEnabledResponse_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

