import { Variant } from "./Variant.js";
import { some, ofNullable } from "./fable_modules/fable-library-js.4.19.2/Option.js";
import { tokenKey } from "./Constants.js";
import { printf, toText } from "./fable_modules/fable-library-js.4.19.2/String.js";
import { Types_HttpRequestHeaders, Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { keyValueList } from "./fable_modules/fable-library-js.4.19.2/MapUtil.js";
import { singleton, empty, append, delay, toList } from "./fable_modules/fable-library-js.4.19.2/Seq.js";
import { append as append_1, ofArray } from "./fable_modules/fable-library-js.4.19.2/List.js";
import { ValidationResponse_$reflection, ClientApiResponse$1 } from "./Shared/Shared.js";
import { handleFetchError, CouldNotParseValidation, UnexpectedResponseCode, Exception500, Exception404, Exception403, Exception401, Exception400 } from "./ErrorHandling.js";
import { Auto_generateBoxedDecoder_Z6670B51, fromString } from "./fable_modules/Thoth.Json.10.2.0/Decode.fs.js";
import { uncurry2 } from "./fable_modules/fable-library-js.4.19.2/Util.js";
import { CaseStrategy } from "./fable_modules/Thoth.Json.10.2.0/Types.fs.js";
import { singleton as singleton_1 } from "./fable_modules/fable-library-js.4.19.2/AsyncBuilder.js";
import { Http_send, Headers_authorization, Headers_create, Headers_ifModifiedSince, Headers_pragma, Headers_contentType, Headers_cacheControl, Http_header, Http_request, Http_method, Http_content, Http_overrideResponseType } from "./fable_modules/Fable.SimpleHttp.3.6.0/Http.fs.js";
import { BodyContent, ResponseTypes } from "./fable_modules/Fable.SimpleHttp.3.6.0/Types.fs.js";
import { FSharpMap__get_Item } from "./fable_modules/fable-library-js.4.19.2/Map.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { Cmd_OfPromise_either } from "./fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";

export const dataKey = (Variant.tag === 1) ? "i24_data" : "mwk_data";

export function token(_arg) {
    const tokenString = ofNullable(localStorage.getItem(tokenKey));
    let matchResult, tokenString_2;
    if (tokenString != null) {
        if (tokenString !== "") {
            matchResult = 0;
            tokenString_2 = tokenString;
        }
        else {
            matchResult = 1;
        }
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0:
            return some(JSON.parse(tokenString_2).access_token);
        default:
            return undefined;
    }
}

export const addPrefix = (() => {
    const clo = toText(printf("/web%s"));
    return clo;
})();

export function checkBuildDate(buildDate) {
    const oldBuildDate = ofNullable(localStorage.getItem("mwk_build_date"));
    if (oldBuildDate != null) {
        if (oldBuildDate === "") {
            const oldBuildDate_3 = oldBuildDate;
            localStorage.setItem("mwk_build_date", buildDate);
        }
        else if (oldBuildDate !== buildDate) {
            const oldBuildDate_4 = oldBuildDate;
            window.location.reload();
            localStorage.setItem("mwk_build_date", buildDate);
            localStorage.removeItem("app-cache");
        }
    }
    else {
        localStorage.setItem("mwk_build_date", buildDate);
    }
}

export function refreshToken(decode) {
    const props = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(toList(delay(() => {
        let matchValue;
        return append((matchValue = token(undefined), (matchValue == null) ? (empty()) : singleton(new Types_HttpRequestHeaders(5, ["Bearer " + matchValue]))), delay(() => append(singleton(new Types_HttpRequestHeaders(11, ["application/json"])), delay(() => append(singleton(new Types_HttpRequestHeaders(6, ["no-cache"])), delay(() => append(singleton(new Types_HttpRequestHeaders(24, ["no-cache"])), delay(() => append(singleton(new Types_HttpRequestHeaders(18, ["0"])), delay(() => singleton(["Expires", "0"])))))))))));
    })), 0)])]);
    const pr_7 = fetch(addPrefix("/api/refresh"), keyValueList(props, 1));
    return pr_7.then((response) => {
        const matchValue_1 = (response.status) | 0;
        switch (matchValue_1) {
            case 200: {
                let pr_1;
                const pr = response.text();
                pr_1 = (pr.then((token_2) => {
                    localStorage.setItem(tokenKey, token_2);
                    return token_2;
                }));
                return pr_1.then((Item) => (new ClientApiResponse$1(0, [Item])));
            }
            case 400: {
                const pr_2 = response.text();
                return pr_2.then((arg) => {
                    throw new Exception400(arg);
                });
            }
            case 401: {
                localStorage.removeItem(tokenKey);
                localStorage.removeItem(dataKey);
                const pr_3 = response.text();
                return pr_3.then((arg_1) => {
                    throw new Exception401(arg_1);
                });
            }
            case 403: {
                const pr_4 = response.text();
                return pr_4.then((arg_2) => {
                    throw new Exception403(arg_2);
                });
            }
            case 404:
                return Promise.resolve((() => {
                    throw new Exception404("/api/refresh");
                })());
            case 500: {
                const pr_5 = response.text();
                return pr_5.then((arg_3) => {
                    throw new Exception500(arg_3);
                });
            }
            default: {
                const pr_6 = response.text();
                return pr_6.then((body) => {
                    throw new UnexpectedResponseCode(response.status, body);
                });
            }
        }
    });
}

export function fetchWithDecoder(url, init, contentType, decode, refreshOn401) {
    const tenant = ofNullable(localStorage.getItem("tenant"));
    let pr_8;
    const pr_7 = fetch(url, keyValueList(append_1(toList(delay(() => {
        if (contentType == null) {
            return singleton(new Types_RequestProperties(1, [keyValueList(toList(delay(() => {
                let matchValue_2;
                return append((matchValue_2 = token(undefined), (matchValue_2 == null) ? (empty()) : singleton(new Types_HttpRequestHeaders(5, ["Bearer " + matchValue_2]))), delay(() => append(singleton(new Types_HttpRequestHeaders(6, ["no-cache"])), delay(() => append(singleton(new Types_HttpRequestHeaders(24, ["no-cache"])), delay(() => append(singleton(new Types_HttpRequestHeaders(18, ["0"])), delay(() => append(singleton(["Expires", "0"]), delay(() => {
                    const matchValue_3 = tenant;
                    if (matchValue_3 == null) {
                        return empty();
                    }
                    else {
                        return singleton(["X-ONE-Tenant", matchValue_3]);
                    }
                }))))))))));
            })), 0)]));
        }
        else {
            const contentType_1 = contentType;
            return singleton(new Types_RequestProperties(1, [keyValueList(toList(delay(() => {
                let matchValue;
                return append((matchValue = token(undefined), (matchValue == null) ? (empty()) : singleton(new Types_HttpRequestHeaders(5, ["Bearer " + matchValue]))), delay(() => append(singleton(new Types_HttpRequestHeaders(6, ["no-cache"])), delay(() => append(singleton(new Types_HttpRequestHeaders(11, [contentType_1])), delay(() => append(singleton(new Types_HttpRequestHeaders(24, ["no-cache"])), delay(() => append(singleton(new Types_HttpRequestHeaders(18, ["0"])), delay(() => append(singleton(["Expires", "0"]), delay(() => {
                    const matchValue_1 = tenant;
                    if (matchValue_1 == null) {
                        return empty();
                    }
                    else {
                        return singleton(["X-ONE-Tenant", matchValue_1]);
                    }
                }))))))))))));
            })), 0)]));
        }
    })), init), 1));
    pr_8 = (pr_7.then((response) => {
        const matchValue_4 = (response.status) | 0;
        switch (matchValue_4) {
            case 200: {
                checkBuildDate((response.headers).get("build-date"));
                const pr = decode(response);
                return pr.then((Item) => (new ClientApiResponse$1(0, [Item])));
            }
            case 400: {
                const pr_1 = response.text();
                return pr_1.then((arg) => {
                    throw new Exception400(arg);
                });
            }
            case 401: {
                const pr_2 = response.text();
                return pr_2.then((arg_1) => {
                    throw new Exception401(arg_1);
                });
            }
            case 403: {
                const pr_3 = response.text();
                return pr_3.then((arg_2) => {
                    throw new Exception403(arg_2);
                });
            }
            case 404:
                return Promise.resolve((() => {
                    throw new Exception404(url);
                })());
            case 422: {
                const pr_4 = response.text();
                return pr_4.then((body) => {
                    const parsedValidations = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(ValidationResponse_$reflection(), new CaseStrategy(1, []), undefined)), body);
                    if (parsedValidations.tag === 1) {
                        throw new CouldNotParseValidation(parsedValidations.fields[0]);
                    }
                    else {
                        return new ClientApiResponse$1(1, [parsedValidations.fields[0]]);
                    }
                });
            }
            case 500: {
                const pr_5 = response.text();
                return pr_5.then((arg_3) => {
                    throw new Exception500(arg_3);
                });
            }
            default: {
                const pr_6 = response.text();
                return pr_6.then((body_1) => {
                    throw new UnexpectedResponseCode(response.status, body_1);
                });
            }
        }
    }));
    return pr_8.catch(handleFetchError);
}

export function Async_fetchWithDecoder(url, body, method, contentType, decode) {
    const tenant = ofNullable(localStorage.getItem("tenant"));
    return singleton_1.Delay(() => {
        let request, req_6, req_5, req_4, req_3, req_2, request_1, matchValue;
        return singleton_1.Bind((request = Http_overrideResponseType(new ResponseTypes(2, []), (req_6 = ((req_5 = ((req_4 = ((req_3 = ((req_2 = Http_content(new BodyContent(1, [body]), Http_method(method, Http_request(url))), Http_header(Headers_cacheControl("no-cache"), req_2))), Http_header(Headers_contentType(contentType), req_3))), Http_header(Headers_pragma("no-cache"), req_4))), Http_header(Headers_ifModifiedSince("0"), req_5))), Http_header(Headers_create("Expires", "0"), req_6))), (request_1 = ((matchValue = token(undefined), (matchValue == null) ? request : Http_header(Headers_authorization("Bearer " + matchValue), request))), Http_send((tenant == null) ? request_1 : Http_header(Headers_create("X-ONE-Tenant", tenant), request_1)))), (_arg) => {
            let matchValue_1, a;
            const response = _arg;
            return singleton_1.ReturnFrom((matchValue_1 = (response.statusCode | 0), (matchValue_1 === 200) ? ((checkBuildDate(FSharpMap__get_Item(response.responseHeaders, "build-date")), (a = decode(response), singleton_1.Delay(() => singleton_1.Return(new ClientApiResponse$1(0, [a])))))) : ((matchValue_1 === 400) ? (() => {
                throw new Exception400(response.responseText);
            })() : ((matchValue_1 === 401) ? (() => {
                throw new Exception401(response.responseText);
            })() : ((matchValue_1 === 403) ? (() => {
                throw new Exception403(response.responseText);
            })() : ((matchValue_1 === 404) ? (() => {
                throw new Exception404(url);
            })() : ((matchValue_1 === 500) ? (() => {
                throw new Exception500(response.responseText);
            })() : (() => {
                throw new UnexpectedResponseCode(matchValue_1, response.responseText);
            })())))))));
        });
    });
}

export function extractBlob(response, filename) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (response.blob().then((_arg) => (Promise.resolve([_arg, filename]))))));
}

export function getBlob(response, filename, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => extractBlob(tupledArg[0], tupledArg[1]), [response, filename], successMsg, errorMsg);
}

export function onBlobReceived(blob, filename) {
    const url = window.URL.createObjectURL(blob);
    const element = document.createElement("a");
    element.setAttribute("href", url);
    element.setAttribute("download", filename);
    document.body.appendChild(element);
    element.click();
    window.URL.revokeObjectURL(url);
    return element.remove();
}

