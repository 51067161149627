import { toString, Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { DocumentDetail_$reflection, SetId__get_unwrap, PostResponse$1_$reflection, EmptyResponse_$reflection, SetId_$reflection, ConsumableId_$reflection, ToolId_$reflection } from "../../Shared/Shared.js";
import { record_type, lambda_type, unit_type, option_type, bool_type, string_type, class_type, union_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none, Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { singleton as singleton_1, contains, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { Cmd_successToast } from "../../Components/Toast.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { nativeType, useDrop } from "../../Bindings/Fable.ReactDnD.js";
import { item, equalsWith } from "../../fable_modules/fable-library-js.4.19.2/Array.js";
import { createObj, stringHash, defaultOf, equals } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { empty, singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { TextButton } from "../../Components/Button.js";
import Upload_File from "../../../public/assets/icons/Upload_File.svg";
import { Interop_reactApi } from "../../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { TextValue } from "../../Components/Text.js";
import { Input } from "../../Components/Input.js";
import { Checkbox } from "../../Components/Checkbox.js";
import { InformationAlert } from "../../Components/Alert.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { ConfirmationDialog, Dialog } from "../../Components/Dialog.js";
import { unwrap } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { Auto_generateBoxedEncoder_437914C6, toString as toString_1 } from "../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { UpdateDocumentDto, UpdateDocumentDto_$reflection } from "../../Shared/Tool.js";
import { unwrapConsumableId, unwrapToolId, unwrapDocumentId } from "../../Shared/Helper.js";

export class UploadType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Tool", "Consumable", "ToolWithQuantity", "Set"];
    }
}

export function UploadType_$reflection() {
    return union_type("Widgets.Dialogs.FileDialog.UploadType", [], UploadType, () => [[["Item", ToolId_$reflection()]], [["Item", ConsumableId_$reflection()]], [["Item", ToolId_$reflection()]], [["Item", SetId_$reflection()]]]);
}

class Upload_Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SelectFile", "SetIsVisibleForUserRole", "SetDescription", "Upload", "UploadCompleted", "FetchError"];
    }
}

function Upload_Msg_$reflection() {
    return union_type("Widgets.Dialogs.FileDialog.Upload.Msg", [], Upload_Msg, () => [[["Item", class_type("Browser.Types.File", undefined)]], [], [["Item", string_type]], [], [["Item", PostResponse$1_$reflection(EmptyResponse_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

function Upload_saveToolDocumentCmd(toolId, formData) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), undefined, (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(EmptyResponse_$reflection())));
    })))), [`/api/tools/${toolId.fields[0]}/files`, formData], (Item) => (new Upload_Msg(4, [Item])), (Item_1) => (new Upload_Msg(5, [Item_1])));
}

function Upload_saveConsumableDocumentCmd(consumableId, formData) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), undefined, (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(EmptyResponse_$reflection())));
    })))), [`/api/consumables/${consumableId.fields[0]}/files`, formData], (Item) => (new Upload_Msg(4, [Item])), (Item_1) => (new Upload_Msg(5, [Item_1])));
}

function Upload_saveSetDocumentCmd(setId, formData) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), undefined, (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(EmptyResponse_$reflection())));
    })))), [`/api/sets/${SetId__get_unwrap(setId)}/files`, formData], (Item) => (new Upload_Msg(4, [Item])), (Item_1) => (new Upload_Msg(5, [Item_1])));
}

class Upload_State extends Record {
    constructor(IsLoading, SelectedFile, UploadType, SuccessCallback, OnClose, Description, IsVisibleForUserRole) {
        super();
        this.IsLoading = IsLoading;
        this.SelectedFile = SelectedFile;
        this.UploadType = UploadType;
        this.SuccessCallback = SuccessCallback;
        this.OnClose = OnClose;
        this.Description = Description;
        this.IsVisibleForUserRole = IsVisibleForUserRole;
    }
}

function Upload_State_$reflection() {
    return record_type("Widgets.Dialogs.FileDialog.Upload.State", [], Upload_State, () => [["IsLoading", bool_type], ["SelectedFile", option_type(class_type("Browser.Types.File", undefined))], ["UploadType", UploadType_$reflection()], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["OnClose", lambda_type(unit_type, unit_type)], ["Description", string_type], ["IsVisibleForUserRole", bool_type]]);
}

function Upload_init(uploadType, successCallback, onClose) {
    return [new Upload_State(false, undefined, uploadType, successCallback, onClose, "", false), Cmd_none()];
}

function Upload_update(msg, state) {
    let matchValue_1;
    switch (msg.tag) {
        case 2:
            return [new Upload_State(state.IsLoading, state.SelectedFile, state.UploadType, state.SuccessCallback, state.OnClose, msg.fields[0], state.IsVisibleForUserRole), Cmd_none()];
        case 1:
            return [new Upload_State(state.IsLoading, state.SelectedFile, state.UploadType, state.SuccessCallback, state.OnClose, state.Description, !state.IsVisibleForUserRole), Cmd_none()];
        case 3: {
            const matchValue = state.SelectedFile;
            if (matchValue == null) {
                return [state, Cmd_none()];
            }
            else {
                const file_1 = matchValue;
                const formData = new FormData();
                formData.append(file_1.name, file_1);
                formData.append("Description", state.Description);
                formData.append("IsVisibleForUserRole", toString(state.IsVisibleForUserRole));
                return [new Upload_State(true, state.SelectedFile, state.UploadType, state.SuccessCallback, state.OnClose, state.Description, state.IsVisibleForUserRole), (matchValue_1 = state.UploadType, (matchValue_1.tag === 1) ? Upload_saveConsumableDocumentCmd(matchValue_1.fields[0], formData) : ((matchValue_1.tag === 2) ? Upload_saveToolDocumentCmd(matchValue_1.fields[0], formData) : ((matchValue_1.tag === 3) ? Upload_saveSetDocumentCmd(matchValue_1.fields[0], formData) : Upload_saveToolDocumentCmd(matchValue_1.fields[0], formData))))];
            }
        }
        case 4:
            return [new Upload_State(false, state.SelectedFile, state.UploadType, state.SuccessCallback, state.OnClose, state.Description, state.IsVisibleForUserRole), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                state.SuccessCallback();
            }), Cmd_ofEffect((_arg_1) => {
                state.OnClose();
            }), Cmd_successToast("tool.dialog_upload_succes")]))];
        case 5:
            return [new Upload_State(false, state.SelectedFile, state.UploadType, state.SuccessCallback, state.OnClose, state.Description, state.IsVisibleForUserRole), Cmd_none()];
        default:
            return [new Upload_State(state.IsLoading, msg.fields[0], state.UploadType, state.SuccessCallback, state.OnClose, state.Description, state.IsVisibleForUserRole), Cmd_none()];
    }
}

function Upload_DialogBody(props) {
    let elems_4, elems_2, elems_3;
    const t = useTranslation()[0];
    const patternInput_1 = useDrop({
        accept: nativeType.FILE,
        collect: (monitor) => {
            const isOver = monitor.isOver();
            return {
                canDrop: monitor.canDrop(),
                isOver: isOver,
            };
        },
        drop: (draggedFile) => {
            let testExpr, testExpr_1;
            const matchValue = draggedFile.files;
            if (matchValue == null) {
            }
            else if ((testExpr = matchValue, !equalsWith(equals, testExpr, defaultOf()) && (testExpr.length === 0))) {
            }
            else if ((testExpr_1 = matchValue, !equalsWith(equals, testExpr_1, defaultOf()) && (testExpr_1.length === 1))) {
                const file = item(0, matchValue);
                if (contains(file.type, ofArray(["image/jpeg", "image/png", "application/pdf"]), {
                    Equals: (x_2, y_2) => (x_2 === y_2),
                    GetHashCode: stringHash,
                })) {
                    props.OnSelectFile(file);
                }
            }
        },
    });
    const dropData = patternInput_1[0];
    const formState = props.IsLoading ? "disabled" : "enabled";
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-4"])], (elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("flex"), delay(() => append(singleton("flex-col"), delay(() => append(singleton("justify-center"), delay(() => append(singleton("items-center"), delay(() => append(singleton("mt-4"), delay(() => append(singleton("mb-4"), delay(() => append(singleton("rounded-lg"), delay(() => append(singleton("border"), delay(() => append(singleton("border-dashed"), delay(() => append(singleton("border-primary"), delay(() => ((dropData.canDrop && dropData.isOver) ? singleton("bg-background") : empty()))))))))))))))))))))))))], ["ref", patternInput_1[1]], (elems_2 = toList(delay(() => {
        let elems, Label;
        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mt-4", "mb-4"])], (elems = [createElement(TextButton, (Label = t("general.select_file"), {
            ComponentState: "enabled",
            Icon: Upload_File(),
            Label: Label,
            OnClick: () => {
                const element = document.getElementById("file-upload");
                element.click();
            },
            TestId: "open-file-explorer-test-id",
            Variant: "blueButton",
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let elems_1;
            const matchValue_1 = props.SelectedFile;
            if (matchValue_1 != null) {
                const selectedFile = matchValue_1;
                return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "justify-center", "items-center", "mx-4", "mb-4"])], (elems_1 = [createElement(TextValue, {
                    FontWeight: "small",
                    TestId: "",
                    Text: selectedFile.name,
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))));
            }
            else {
                return empty();
            }
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "justify-center"])], (elems_3 = [createElement("input", {
        id: "file-upload",
        className: join(" ", ["hidden"]),
        type: "file",
        accept: ".PNG,.JPEG,.JPG,.png,.jpg,.jpeg,.pdf,.PDF",
        onChange: (ev) => {
            const files = ev.target.files;
            if (!(files == null) && (files.length > 0)) {
                props.OnSelectFile(files.item(0));
            }
        },
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), createElement(Input, {
        ComponentState: formState,
        Label: t("general.description"),
        OnChange: props.SetDescription,
        TestId: "",
        Value: props.Description,
    }), Checkbox({
        ComponentState: formState,
        IsChecked: props.VisibleForUserRole,
        Label: t("tool.document_visible_for_user_role"),
        OnCheck: props.SetVisibleForUserRole,
        TestId: "",
    }), createElement(InformationAlert, {
        Label: t("tool.document_visible_for_user_role_upload_info"),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])));
}

export function Upload_FileUploadDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init = Upload_init(props.UploadType, props.SuccessCallback, props.OnClose);
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, Upload_update, (_arg, _arg_1) => {
    }), undefined, [props.OnClose]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    return createElement(Dialog, (Title = t("general.upload_file"), {
        Body: singleton_1(createElement(Upload_DialogBody, {
            Description: state_1.Description,
            IsLoading: state_1.IsLoading,
            OnSelectFile: (value_1) => {
                dispatch(new Upload_Msg(0, [value_1]));
            },
            SelectedFile: unwrap(state_1.SelectedFile),
            SetDescription: (value) => {
                dispatch(new Upload_Msg(2, [value]));
            },
            SetVisibleForUserRole: (_arg_2) => {
                dispatch(new Upload_Msg(1, []));
            },
            VisibleForUserRole: state_1.IsVisibleForUserRole,
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-document-dialog-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: (state_1.IsLoading ? true : (state_1.SelectedFile == null)) ? "disabled" : "enabled",
            Label: t("general.upload"),
            OnClick: () => {
                dispatch(new Upload_Msg(3, []));
            },
            TestId: "save-document-dialog-test-id",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

class Edit_Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetIsVisibleForUserRole", "SetDescription", "UpdateDocument", "DocumentUpdated", "FetchError"];
    }
}

function Edit_Msg_$reflection() {
    return union_type("Widgets.Dialogs.FileDialog.Edit.Msg", [], Edit_Msg, () => [[], [["Item", string_type]], [], [["Item", PostResponse$1_$reflection(EmptyResponse_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

function Edit_postUpdateToolCmd(toolId, dto) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(EmptyResponse_$reflection())));
    })))), [`/api/tools/${toolId.fields[0]}/files/update`, toString_1(0, Auto_generateBoxedEncoder_437914C6(UpdateDocumentDto_$reflection(), undefined, undefined, undefined)(dto))], (Item) => (new Edit_Msg(3, [Item])), (Item_1) => (new Edit_Msg(4, [Item_1])));
}

function Edit_postUpdateConsumableCmd(consumableId, dto) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(EmptyResponse_$reflection())));
    })))), [`/api/consumables/${consumableId.fields[0]}/files/update`, toString_1(0, Auto_generateBoxedEncoder_437914C6(UpdateDocumentDto_$reflection(), undefined, undefined, undefined)(dto))], (Item) => (new Edit_Msg(3, [Item])), (Item_1) => (new Edit_Msg(4, [Item_1])));
}

function Edit_postUpdateSetCmd(setId, dto) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(EmptyResponse_$reflection())));
    })))), [`/api/sets/${SetId__get_unwrap(setId)}/files/update`, toString_1(0, Auto_generateBoxedEncoder_437914C6(UpdateDocumentDto_$reflection(), undefined, undefined, undefined)(dto))], (Item) => (new Edit_Msg(3, [Item])), (Item_1) => (new Edit_Msg(4, [Item_1])));
}

class Edit_State extends Record {
    constructor(IsLoading, Document$, UploadType, SuccessCallback, OnClose, Dto) {
        super();
        this.IsLoading = IsLoading;
        this.Document = Document$;
        this.UploadType = UploadType;
        this.SuccessCallback = SuccessCallback;
        this.OnClose = OnClose;
        this.Dto = Dto;
    }
}

function Edit_State_$reflection() {
    return record_type("Widgets.Dialogs.FileDialog.Edit.State", [], Edit_State, () => [["IsLoading", bool_type], ["Document", DocumentDetail_$reflection()], ["UploadType", UploadType_$reflection()], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["OnClose", lambda_type(unit_type, unit_type)], ["Dto", UpdateDocumentDto_$reflection()]]);
}

function Edit_init(uploadType, document$, successCallback, onClose) {
    return [new Edit_State(false, document$, uploadType, successCallback, onClose, new UpdateDocumentDto(document$.IsVisibleForUserRole, document$.Description, unwrapDocumentId(document$.Id))), Cmd_none()];
}

function Edit_update(msg, state) {
    let bind$0040, bind$0040_1, matchValue;
    switch (msg.tag) {
        case 1:
            return [new Edit_State(state.IsLoading, state.Document, state.UploadType, state.SuccessCallback, state.OnClose, (bind$0040 = state.Dto, new UpdateDocumentDto(bind$0040.IsVisibleForUserRole, msg.fields[0], bind$0040.DocumentId))), Cmd_none()];
        case 0:
            return [new Edit_State(state.IsLoading, state.Document, state.UploadType, state.SuccessCallback, state.OnClose, (bind$0040_1 = state.Dto, new UpdateDocumentDto(!state.Dto.IsVisibleForUserRole, bind$0040_1.Description, bind$0040_1.DocumentId))), Cmd_none()];
        case 3:
            return [new Edit_State(false, state.Document, state.UploadType, state.SuccessCallback, state.OnClose, state.Dto), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                state.SuccessCallback();
            }), Cmd_ofEffect((_arg_1) => {
                state.OnClose();
            }), Cmd_successToast("tool.dialog_document_edit_success")]))];
        case 4:
            return [new Edit_State(false, state.Document, state.UploadType, state.SuccessCallback, state.OnClose, state.Dto), Cmd_none()];
        default:
            return [new Edit_State(true, state.Document, state.UploadType, state.SuccessCallback, state.OnClose, state.Dto), (matchValue = state.UploadType, (matchValue.tag === 2) ? Edit_postUpdateToolCmd(matchValue.fields[0], state.Dto) : ((matchValue.tag === 1) ? Edit_postUpdateConsumableCmd(matchValue.fields[0], state.Dto) : ((matchValue.tag === 3) ? Edit_postUpdateSetCmd(matchValue.fields[0], state.Dto) : Edit_postUpdateToolCmd(matchValue.fields[0], state.Dto))))];
    }
}

function Edit_DialogBody(props) {
    let elems;
    const t = useTranslation()[0];
    const formState = props.IsLoading ? "disabled" : (props.IsUserAuthorized ? "enabled" : "disabled");
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-4"])], (elems = [createElement(Input, {
        ComponentState: formState,
        Label: t("general.description"),
        OnChange: props.SetDescription,
        TestId: "",
        Value: props.Description,
    }), Checkbox({
        ComponentState: formState,
        IsChecked: props.VisibleForUserRole,
        Label: t("tool.document_visible_for_user_role"),
        OnCheck: props.SetVisibleForUserRole,
        TestId: "",
    }), createElement(InformationAlert, {
        Label: t("tool.document_visible_for_user_role_upload_info"),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function Edit_FileEditDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init = Edit_init(props.UploadType, props.Document, props.SuccessCallback, props.OnClose);
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, Edit_update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    return createElement(Dialog, (Title = t("tool.dialog_document_edit_button"), {
        Body: singleton_1(createElement(Edit_DialogBody, {
            Description: state_1.Dto.Description,
            IsLoading: state_1.IsLoading,
            IsUserAuthorized: props.IsUserAuthorized,
            SetDescription: (value) => {
                dispatch(new Edit_Msg(1, [value]));
            },
            SetVisibleForUserRole: (_arg_2) => {
                dispatch(new Edit_Msg(0, []));
            },
            VisibleForUserRole: state_1.Dto.IsVisibleForUserRole,
        })),
        Controls: toList(delay(() => append(singleton(createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-document-dialog-test-id",
            Variant: "blueButton",
        })), delay(() => (props.IsUserAuthorized ? singleton(createElement(TextButton, {
            ComponentState: state_1.IsLoading ? "disabled" : "enabled",
            Label: t("general.save"),
            OnClick: () => {
                dispatch(new Edit_Msg(2, []));
            },
            TestId: "save-document-dialog-test-id",
            Variant: "blueButton",
        })) : empty()))))),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

class Delete_Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["DeleteDocument", "DocumentDeleted", "FetchError"];
    }
}

function Delete_Msg_$reflection() {
    return union_type("Widgets.Dialogs.FileDialog.Delete.Msg", [], Delete_Msg, () => [[], [["Item", EmptyResponse_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

function Delete_deleteToolDocumentCmd(toolId, documentId) {
    return Cmd_OfPromise_either((url_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_1), singleton_1(new Types_RequestProperties(0, ["DELETE"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), EmptyResponse_$reflection()));
    })))), `/api/tools/${unwrapToolId(toolId)}/files/${unwrapDocumentId(documentId)}`, (Item) => (new Delete_Msg(1, [Item])), (Item_1) => (new Delete_Msg(2, [Item_1])));
}

function Delete_deleteConsumableDocumentCmd(consumableId, documentId) {
    return Cmd_OfPromise_either((url_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_1), singleton_1(new Types_RequestProperties(0, ["DELETE"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), EmptyResponse_$reflection()));
    })))), `/api/consumables/${unwrapConsumableId(consumableId)}/files/${unwrapDocumentId(documentId)}`, (Item) => (new Delete_Msg(1, [Item])), (Item_1) => (new Delete_Msg(2, [Item_1])));
}

function Delete_deleteSetDocumentCmd(setId, documentId) {
    const docId = unwrapDocumentId(documentId);
    return Cmd_OfPromise_either((url_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_1), singleton_1(new Types_RequestProperties(0, ["DELETE"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), EmptyResponse_$reflection()));
    })))), `/api/sets/${SetId__get_unwrap(setId)}/files/${docId}`, (Item) => (new Delete_Msg(1, [Item])), (Item_1) => (new Delete_Msg(2, [Item_1])));
}

class Delete_State extends Record {
    constructor(IsLoading, Document$, UploadType, SuccessCallback, OnClose, Dto) {
        super();
        this.IsLoading = IsLoading;
        this.Document = Document$;
        this.UploadType = UploadType;
        this.SuccessCallback = SuccessCallback;
        this.OnClose = OnClose;
        this.Dto = Dto;
    }
}

function Delete_State_$reflection() {
    return record_type("Widgets.Dialogs.FileDialog.Delete.State", [], Delete_State, () => [["IsLoading", bool_type], ["Document", DocumentDetail_$reflection()], ["UploadType", UploadType_$reflection()], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["OnClose", lambda_type(unit_type, unit_type)], ["Dto", UpdateDocumentDto_$reflection()]]);
}

function Delete_init(uploadType, document$, successCallback, onClose) {
    return [new Delete_State(false, document$, uploadType, successCallback, onClose, new UpdateDocumentDto(document$.IsVisibleForUserRole, document$.Description, unwrapDocumentId(document$.Id))), Cmd_none()];
}

function Delete_update(msg, state) {
    let matchValue;
    switch (msg.tag) {
        case 1:
            return [new Delete_State(false, state.Document, state.UploadType, state.SuccessCallback, state.OnClose, state.Dto), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                state.SuccessCallback();
            }), Cmd_ofEffect((_arg_1) => {
                state.OnClose();
            }), Cmd_successToast("tool.dialog_document_delete_success")]))];
        case 2:
            return [new Delete_State(false, state.Document, state.UploadType, state.SuccessCallback, state.OnClose, state.Dto), Cmd_none()];
        default:
            return [new Delete_State(true, state.Document, state.UploadType, state.SuccessCallback, state.OnClose, state.Dto), (matchValue = state.UploadType, (matchValue.tag === 2) ? Delete_deleteToolDocumentCmd(matchValue.fields[0], state.Document.Id) : ((matchValue.tag === 1) ? Delete_deleteConsumableDocumentCmd(matchValue.fields[0], state.Document.Id) : ((matchValue.tag === 3) ? Delete_deleteSetDocumentCmd(matchValue.fields[0], state.Document.Id) : Delete_deleteToolDocumentCmd(matchValue.fields[0], state.Document.Id))))];
    }
}

export function Delete_FileDeleteDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init = Delete_init(props.UploadType, props.Document, props.SuccessCallback, props.OnClose);
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, Delete_update, (_arg, _arg_1) => {
    }), undefined, []);
    return createElement(ConfirmationDialog, (Title = t("general.delete"), {
        Description: t("tool.dialog_document_delete_msg"),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        OnConfirm: patternInput_1[0].IsLoading ? (() => {
        }) : (() => {
            patternInput_1[1](new Delete_Msg(0, []));
        }),
        Title: Title,
    }));
}

