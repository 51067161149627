import { contains, filter, append, ofArray, singleton, empty } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Requests_RoomFormDto_$reflection, Room_$reflection, User_$reflection, Requests_RoomFormDto } from "../../Shared/User.js";
import { FormState__setValidationResponse_5219762A, FormState__startLoading, FormState_get_empty, RequestedValue$1 } from "../../Shared/Shared.js";
import { Msg, State } from "./Types.js";
import { Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { Location_$reflection } from "../../Shared/Location.js";
import { string_type, list_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { fetchWithDecoder as fetchWithDecoder_1, addPrefix as addPrefix_1 } from "../../CommunicationV2.js";
import { PropertyConfigurationEnabledResponse, PropertyConfiguration_$reflection, PropertyConfigurationEnabledResponse_$reflection } from "../../Shared/PropertyConfiguration.js";
import { ValidationOnGetRequest } from "../../ErrorHandling.js";
import { unwrapUserId } from "../../Shared/Helper.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { equals, stringHash } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { Cmd_errorToast } from "../../Components/Toast.js";
import { RouterModule_nav, RouterModule_encodeParts } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { Cmd_ofEffect } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";

export const emptyRoom = new Requests_RoomFormDto("", "", undefined, "", empty(), empty(), empty());

export const initialState = new State(undefined, new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), false, FormState_get_empty());

export function initNew() {
    return [new State(initialState.RoomId, new RequestedValue$1(1, [emptyRoom]), initialState.Locations, initialState.IsCustomPropertiesEnabled, initialState.PropertyConfigurations, initialState.Users, initialState.VisibleUserModalIsOpen, initialState.FormState), Cmd_batch(ofArray([Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(Location_$reflection())));
    })))), "/api/locations", (Item) => (new Msg(11, [Item])), (Item_1) => (new Msg(15, [Item_1]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_5;
        return ((url_5 = addPrefix_1(url_3), (refreshOn) => fetchWithDecoder_1(url_5, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
            const pr_1 = response_1.arrayBuffer();
            return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), PropertyConfigurationEnabledResponse_$reflection()));
        }, refreshOn)))(true).then((_arg) => {
            const result_2 = _arg;
            return (result_2.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_2.fields[0]);
            })())) : (Promise.resolve(result_2.fields[0]));
        });
    })), "/api/property-configurations/enabled", (Item_2) => (new Msg(12, [Item_2])), (Item_3) => (new Msg(15, [Item_3]))), Cmd_OfPromise_either((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_8;
        return ((url_8 = addPrefix_1(url_6), (refreshOn_1) => fetchWithDecoder_1(url_8, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
            const pr_2 = response_2.arrayBuffer();
            return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), list_type(PropertyConfiguration_$reflection())));
        }, refreshOn_1)))(true).then((_arg_1) => {
            const result_4 = _arg_1;
            return (result_4.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_4.fields[0]);
            })())) : (Promise.resolve(result_4.fields[0]));
        });
    })), "/api/property-configurations", (Item_4) => (new Msg(13, [Item_4])), (Item_5) => (new Msg(15, [Item_5]))), Cmd_OfPromise_either((url_9) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_9), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_3) => {
        const pr_3 = response_3.arrayBuffer();
        return pr_3.then((blob_3) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_3)), list_type(User_$reflection())));
    })))), "/api/receivers/users/active", (Item_6) => (new Msg(14, [Item_6])), (Item_7) => (new Msg(15, [Item_7])))]))];
}

export function initEdit(roomId) {
    return [initialState, Cmd_batch(ofArray([Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), Room_$reflection()));
    })))), `/api/receivers/rooms/${unwrapUserId(roomId)}`, (Item) => (new Msg(6, [Item])), (Item_1) => (new Msg(15, [Item_1]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(Location_$reflection())));
    })))), "/api/locations", (Item_2) => (new Msg(11, [Item_2])), (Item_3) => (new Msg(15, [Item_3]))), Cmd_OfPromise_either((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_8;
        return ((url_8 = addPrefix_1(url_6), (refreshOn) => fetchWithDecoder_1(url_8, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
            const pr_2 = response_2.arrayBuffer();
            return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), PropertyConfigurationEnabledResponse_$reflection()));
        }, refreshOn)))(true).then((_arg) => {
            const result_3 = _arg;
            return (result_3.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_3.fields[0]);
            })())) : (Promise.resolve(result_3.fields[0]));
        });
    })), "/api/property-configurations/enabled", (Item_4) => (new Msg(12, [Item_4])), (Item_5) => (new Msg(15, [Item_5]))), Cmd_OfPromise_either((url_9) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_11;
        return ((url_11 = addPrefix_1(url_9), (refreshOn_1) => fetchWithDecoder_1(url_11, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_3) => {
            const pr_3 = response_3.arrayBuffer();
            return pr_3.then((blob_3) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_3)), list_type(PropertyConfiguration_$reflection())));
        }, refreshOn_1)))(true).then((_arg_1) => {
            const result_5 = _arg_1;
            return (result_5.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_5.fields[0]);
            })())) : (Promise.resolve(result_5.fields[0]));
        });
    })), "/api/property-configurations", (Item_6) => (new Msg(13, [Item_6])), (Item_7) => (new Msg(15, [Item_7]))), Cmd_OfPromise_either((url_12) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_12), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_4) => {
        const pr_4 = response_4.arrayBuffer();
        return pr_4.then((blob_4) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_4)), list_type(User_$reflection())));
    })))), "/api/receivers/users/active", (Item_8) => (new Msg(14, [Item_8])), (Item_9) => (new Msg(15, [Item_9])))]))];
}

export function update(msg, state) {
    let fullPath_1;
    switch (msg.tag) {
        case 1: {
            const matchValue_1 = state.Form;
            if (matchValue_1.tag === 1) {
                const room_1 = matchValue_1.fields[0];
                return [new State(state.RoomId, new RequestedValue$1(1, [new Requests_RoomFormDto(room_1.Name, msg.fields[0], room_1.LocationId, room_1.ImageUrl, room_1.QRCodeIds, room_1.VisibleForUserIds, room_1.CustomProperties)]), state.Locations, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.Users, state.VisibleUserModalIsOpen, state.FormState), Cmd_none()];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        case 2: {
            const location = msg.fields[0];
            const matchValue_2 = state.Form;
            if (matchValue_2.tag === 1) {
                const room_2 = matchValue_2.fields[0];
                if (location == null) {
                    return [new State(state.RoomId, new RequestedValue$1(1, [new Requests_RoomFormDto(room_2.Name, room_2.Number, undefined, room_2.ImageUrl, room_2.QRCodeIds, room_2.VisibleForUserIds, room_2.CustomProperties)]), state.Locations, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.Users, state.VisibleUserModalIsOpen, state.FormState), Cmd_none()];
                }
                else {
                    return [new State(state.RoomId, new RequestedValue$1(1, [new Requests_RoomFormDto(room_2.Name, room_2.Number, location.value.Id, room_2.ImageUrl, room_2.QRCodeIds, room_2.VisibleForUserIds, room_2.CustomProperties)]), state.Locations, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.Users, state.VisibleUserModalIsOpen, state.FormState), Cmd_none()];
                }
            }
            else {
                return [state, Cmd_none()];
            }
        }
        case 3: {
            const matchValue_3 = state.Form;
            if (matchValue_3.tag === 1) {
                const room_3 = matchValue_3.fields[0];
                return [new State(state.RoomId, new RequestedValue$1(1, [new Requests_RoomFormDto(room_3.Name, room_3.Number, room_3.LocationId, room_3.ImageUrl, room_3.QRCodeIds, room_3.VisibleForUserIds, msg.fields[0])]), state.Locations, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.Users, state.VisibleUserModalIsOpen, state.FormState), Cmd_none()];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        case 4: {
            const matchValue_4 = state.Form;
            if (matchValue_4.tag === 1) {
                return [new State(state.RoomId, state.Form, state.Locations, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.Users, state.VisibleUserModalIsOpen, FormState__startLoading(state.FormState)), Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    let url_2;
                    return ((url_2 = addPrefix_1(tupledArg[0]), (refreshOn) => fetchWithDecoder_1(url_2, ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
                        const pr = response.arrayBuffer();
                        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
                    }, refreshOn)))(true);
                })), ["/api/receivers/rooms", toString(0, Auto_generateBoxedEncoder_437914C6(Requests_RoomFormDto_$reflection(), undefined, undefined, undefined)(matchValue_4.fields[0]))], (Item) => (new Msg(7, [Item])), (Item_1) => (new Msg(15, [Item_1])))];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        case 5: {
            const matchValue_5 = state.RoomId;
            const matchValue_6 = state.Form;
            let matchResult, room_6, roomId;
            if (matchValue_5 != null) {
                if (matchValue_6.tag === 1) {
                    matchResult = 1;
                    room_6 = matchValue_6.fields[0];
                    roomId = matchValue_5;
                }
                else {
                    matchResult = 0;
                }
            }
            else {
                matchResult = 0;
            }
            switch (matchResult) {
                case 0:
                    return [state, Cmd_none()];
                default:
                    return [new State(state.RoomId, state.Form, state.Locations, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.Users, state.VisibleUserModalIsOpen, FormState__startLoading(state.FormState)), Cmd_OfPromise_either((tupledArg_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        let url_5;
                        return ((url_5 = addPrefix_1(tupledArg_1[0]), (refreshOn_1) => fetchWithDecoder_1(url_5, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg_1[1]])]), "application/json", (response_1) => {
                            const pr_1 = response_1.arrayBuffer();
                            return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), string_type));
                        }, refreshOn_1)))(true);
                    })), [`/api/receivers/rooms/${roomId}`, toString(0, Auto_generateBoxedEncoder_437914C6(Requests_RoomFormDto_$reflection(), undefined, undefined, undefined)(room_6))], (Item_2) => (new Msg(7, [Item_2])), (Item_3) => (new Msg(15, [Item_3])))];
            }
        }
        case 8:
            return [new State(state.RoomId, state.Form, state.Locations, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.Users, !state.VisibleUserModalIsOpen, state.FormState), Cmd_none()];
        case 9: {
            const matchValue_8 = state.Form;
            if (matchValue_8.tag === 1) {
                const room_8 = matchValue_8.fields[0];
                return [new State(state.RoomId, new RequestedValue$1(1, [new Requests_RoomFormDto(room_8.Name, room_8.Number, room_8.LocationId, room_8.ImageUrl, room_8.QRCodeIds, append(room_8.VisibleForUserIds, msg.fields[0]), room_8.CustomProperties)]), state.Locations, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.Users, false, state.FormState), Cmd_none()];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        case 10: {
            const matchValue_9 = state.Form;
            if (matchValue_9.tag === 1) {
                const room_9 = matchValue_9.fields[0];
                return [new State(state.RoomId, new RequestedValue$1(1, [new Requests_RoomFormDto(room_9.Name, room_9.Number, room_9.LocationId, room_9.ImageUrl, room_9.QRCodeIds, filter((userId) => !contains(userId, msg.fields[0], {
                    Equals: (x_2, y) => (x_2 === y),
                    GetHashCode: stringHash,
                }), room_9.VisibleForUserIds), room_9.CustomProperties)]), state.Locations, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.Users, false, state.FormState), Cmd_none()];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        case 6: {
            const room_10 = msg.fields[0];
            return [new State(room_10.Id, new RequestedValue$1(1, [new Requests_RoomFormDto(room_10.Name, room_10.Number, room_10.LocationId, room_10.ImageUrl, room_10.QRCodeIds, room_10.VisibleForUserIds, room_10.CustomProperties)]), state.Locations, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.Users, state.VisibleUserModalIsOpen, state.FormState), Cmd_none()];
        }
        case 7: {
            const response_2 = msg.fields[0];
            if (response_2.tag === 1) {
                return [new State(state.RoomId, state.Form, state.Locations, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.Users, state.VisibleUserModalIsOpen, FormState__setValidationResponse_5219762A(state.FormState, response_2.fields[0])), Cmd_errorToast("validation.toast")];
            }
            else {
                return [state, (fullPath_1 = RouterModule_encodeParts(ofArray(["receivers", response_2.fields[0]]), 1), Cmd_ofEffect((_arg) => {
                    RouterModule_nav(singleton(fullPath_1), 1, 1);
                }))];
            }
        }
        case 11:
            return [new State(state.RoomId, state.Form, new RequestedValue$1(1, [msg.fields[0]]), state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.Users, state.VisibleUserModalIsOpen, state.FormState), Cmd_none()];
        case 12:
            return [new State(state.RoomId, state.Form, state.Locations, new RequestedValue$1(1, [equals(msg.fields[0], new PropertyConfigurationEnabledResponse(0, []))]), state.PropertyConfigurations, state.Users, state.VisibleUserModalIsOpen, state.FormState), Cmd_none()];
        case 13:
            return [new State(state.RoomId, state.Form, state.Locations, state.IsCustomPropertiesEnabled, new RequestedValue$1(1, [filter((propertyConfiguration) => {
                const matchValue_10 = propertyConfiguration.Visibility;
                switch (matchValue_10.tag) {
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                        return false;
                    default:
                        return true;
                }
            }, msg.fields[0])]), state.Users, state.VisibleUserModalIsOpen, state.FormState), Cmd_none()];
        case 14:
            return [new State(state.RoomId, state.Form, state.Locations, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, new RequestedValue$1(1, [msg.fields[0]]), state.VisibleUserModalIsOpen, state.FormState), Cmd_none()];
        case 15:
            return [state, Cmd_none()];
        default: {
            const matchValue = state.Form;
            if (matchValue.tag === 1) {
                const room = matchValue.fields[0];
                return [new State(state.RoomId, new RequestedValue$1(1, [new Requests_RoomFormDto(msg.fields[0], room.Number, room.LocationId, room.ImageUrl, room.QRCodeIds, room.VisibleForUserIds, room.CustomProperties)]), state.Locations, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.Users, state.VisibleUserModalIsOpen, state.FormState), Cmd_none()];
            }
            else {
                return [state, Cmd_none()];
            }
        }
    }
}

