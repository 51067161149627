import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, lambda_type, unit_type, union_type, class_type, bool_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { FormState__getValidation, FormState__setValidationResponse_5219762A, FormState__startLoading, FormState_get_empty, RequestedValue$1, FormState_$reflection, RequestedValue$1_$reflection, ClientApiResponse$1_$reflection } from "../../Shared/Shared.js";
import { ReservationAssignDto, ReservationAssignDto_$reflection, ReservationDetailDto_$reflection } from "../../Shared/Planning.js";
import { getReservation } from "../../Requests/Reservation.js";
import { parse } from "../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { fetchWithDecoder, addPrefix } from "../../CommunicationV2.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { map as map_1, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { map, defaultArg } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { Cmd_errorToast, Cmd_successToast } from "../../Components/Toast.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { empty, singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { TextArea } from "../../Components/TextArea.js";
import { Checkbox } from "../../Components/Checkbox.js";
import { ErrorAlert } from "../../Components/Alert.js";
import { defaultOf } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { Interop_reactApi } from "../../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Dialog } from "../../Components/Dialog.js";
import { Skeleton } from "../../Components/Skeleton.js";
import { TextButton } from "../../Components/Button.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["AssignedResponse", "OnAssign", "SetIsCompleted", "SetComment", "ReservationFetched", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Reservation.Dialogs.AssignReservationDialog.Msg", [], Msg, () => [[["Item", ClientApiResponse$1_$reflection(string_type)]], [], [["Item", bool_type]], [["Item", string_type]], [["Item", ReservationDetailDto_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

class State extends Record {
    constructor(Reservation, Dto, FormState, SuccessCallback, OnClose) {
        super();
        this.Reservation = Reservation;
        this.Dto = Dto;
        this.FormState = FormState;
        this.SuccessCallback = SuccessCallback;
        this.OnClose = OnClose;
    }
}

function State_$reflection() {
    return record_type("Reservation.Dialogs.AssignReservationDialog.State", [], State, () => [["Reservation", RequestedValue$1_$reflection(ReservationDetailDto_$reflection())], ["Dto", ReservationAssignDto_$reflection()], ["FormState", FormState_$reflection()], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["OnClose", lambda_type(unit_type, unit_type)]]);
}

function init(reservationId, successCallback, onClose) {
    return [new State(new RequestedValue$1(0, []), new ReservationAssignDto("", true, ""), FormState_get_empty(), successCallback, onClose), getReservation(parse(reservationId), (Item) => (new Msg(4, [Item])), (Item_1) => (new Msg(5, [Item_1])))];
}

function update(msg, state) {
    let bind$0040_1, bind$0040_2;
    switch (msg.tag) {
        case 1: {
            const matchValue = state.Reservation;
            if (matchValue.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const reservation = matchValue.fields[0];
                let dto;
                const bind$0040 = state.Dto;
                dto = (new ReservationAssignDto(reservation.ReceiverInformation.ReceiverId, bind$0040.IsStateCompleted, bind$0040.Comment));
                return [new State(state.Reservation, state.Dto, FormState__startLoading(state.FormState), state.SuccessCallback, state.OnClose), Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    let url_2;
                    return ((url_2 = addPrefix(tupledArg[0]), (refreshOn) => fetchWithDecoder(url_2, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response_1) => {
                        const pr = response_1.arrayBuffer();
                        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
                    }, refreshOn)))(true);
                })), [`/api/reservations/${defaultArg(map((x) => x, reservation.GroupId), reservation.Id)}/assign`, toString(0, Auto_generateBoxedEncoder_437914C6(ReservationAssignDto_$reflection(), undefined, undefined, undefined)(dto))], (Item) => (new Msg(0, [Item])), (Item_1) => (new Msg(5, [Item_1])))];
            }
        }
        case 3:
            return [new State(state.Reservation, (bind$0040_1 = state.Dto, new ReservationAssignDto(bind$0040_1.ReceiverId, bind$0040_1.IsStateCompleted, msg.fields[0])), state.FormState, state.SuccessCallback, state.OnClose), Cmd_none()];
        case 2:
            return [new State(state.Reservation, (bind$0040_2 = state.Dto, new ReservationAssignDto(bind$0040_2.ReceiverId, msg.fields[0], bind$0040_2.Comment)), state.FormState, state.SuccessCallback, state.OnClose), Cmd_none()];
        case 4:
            return [new State(new RequestedValue$1(1, [msg.fields[0]]), state.Dto, state.FormState, state.SuccessCallback, state.OnClose), Cmd_none()];
        case 5:
            return [state, Cmd_none()];
        default: {
            const response = msg.fields[0];
            if (response.tag === 0) {
                return [new State(state.Reservation, state.Dto, FormState_get_empty(), state.SuccessCallback, state.OnClose), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                    state.SuccessCallback();
                }), Cmd_successToast("general.saved_successfully"), Cmd_ofEffect((_arg_1) => {
                    state.OnClose();
                })]))];
            }
            else {
                return [new State(state.Reservation, state.Dto, FormState__setValidationResponse_5219762A(state.FormState, response.fields[0]), state.SuccessCallback, state.OnClose), Cmd_errorToast("validation.toast")];
            }
        }
    }
}

function DialogBody(props) {
    let elems;
    const t = useTranslation()[0];
    const componentState = props.FormState.IsLoading ? "disabled" : "enabled";
    return createElement("div", createObj(ofArray([["id", "assign-reservation-dialog"], ["className", join(" ", ["grid", "gap-5"])], (elems = toList(delay(() => append(singleton(createElement(TextArea, {
        ComponentState: componentState,
        Label: t("general.comment"),
        OnChange: (arg) => {
            props.Dispatch(new Msg(3, [arg]));
        },
        TestId: "assign-reservation-dialog-comment-test-id",
        Value: props.Dto.Comment,
    })), delay(() => append(singleton(Checkbox({
        ComponentState: componentState,
        IsChecked: props.Dto.IsStateCompleted,
        Label: t("reservation.dialogs.assign_dialog.complete_reservation"),
        OnCheck: (arg_1) => {
            props.Dispatch(new Msg(2, [arg_1]));
        },
        TestId: "assign-reservation-dialog-test-id",
    })), delay(() => {
        let matchValue;
        return append((matchValue = FormState__getValidation(props.FormState, t, "global"), (matchValue == null) ? (empty()) : singleton(createElement(ErrorAlert, {
            Label: matchValue,
        }))), delay(() => append(map_1((consumable) => {
            const matchValue_1 = FormState__getValidation(props.FormState, t, `${consumable.Id}-stock`);
            if (matchValue_1 == null) {
                return defaultOf();
            }
            else {
                return createElement(ErrorAlert, {
                    Label: `${consumable.Name}: ${matchValue_1}`,
                });
            }
        }, props.Reservation.ConsumablesInformation), delay(() => map_1((tool) => {
            const matchValue_2 = FormState__getValidation(props.FormState, t, `${tool.Id}-stock`);
            if (matchValue_2 == null) {
                return defaultOf();
            }
            else {
                return createElement(ErrorAlert, {
                    Label: `${tool.DisplayName}: ${matchValue_2}`,
                });
            }
        }, props.Reservation.ToolsWithQuantityInformation)))));
    })))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function AssignReservationDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init(props.ReservationId, props.SuccessCallback, props.OnClose);
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    return createElement(Dialog, (Title = t("tool.assign_now"), {
        Body: toList(delay(() => {
            const matchValue = state_1.Reservation;
            return (matchValue.tag === 0) ? singleton(createElement(Skeleton, {
                Variant: "normal",
            })) : singleton(createElement(DialogBody, {
                Dispatch: dispatch,
                Dto: state_1.Dto,
                FormState: state_1.FormState,
                Reservation: matchValue.fields[0],
            }));
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-assign-reservation-dialog-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: state_1.FormState.IsLoading ? "disabled" : "enabled",
            Label: t("general.save"),
            OnClick: () => {
                dispatch(new Msg(1, []));
            },
            TestId: "save-assign-reservation-dialog-test-id",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

