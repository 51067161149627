import { FormState__setValidationResponse_5219762A, FormState_get_empty, RequestedValue$1 } from "../../Shared/Shared.js";
import { Msg, State, DialogOpenState } from "./Types.js";
import { Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { getStocktaking } from "../../Requests/Stocktaking.js";
import { Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { ofArray, singleton } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { User_$reflection } from "../../Shared/User.js";
import { string_type, list_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { fetchWithDecoder as fetchWithDecoder_1, addPrefix as addPrefix_1 } from "../../CommunicationV2.js";
import { unwrapStocktakingId } from "../../Shared/Helper.js";
import { Cmd_errorToast, Cmd_successToast } from "../../Components/Toast.js";
import { Cmd_ofEffect } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { StocktakingId__get_unwrap } from "../../Shared/Shared.js";
import { defaultOf } from "../../fable_modules/fable-library-js.4.19.2/Util.js";

export function initViewStocktaking(stocktakingId) {
    return [new State(stocktakingId, new RequestedValue$1(0, []), new RequestedValue$1(0, []), FormState_get_empty(), new DialogOpenState(false, false, false)), Cmd_batch(ofArray([getStocktaking(stocktakingId, (Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(12, [Item_1]))), Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(User_$reflection())));
    })))), "/api/receivers/users", (Item_2) => (new Msg(11, [Item_2])), (Item_3) => (new Msg(12, [Item_3])))]))];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [new State(state.StocktakingId, new RequestedValue$1(1, [msg.fields[0]]), state.Users, state.FormState, state.DialogOpenState), Cmd_none()];
        case 2: {
            const matchValue = state.Stocktaking;
            if (matchValue.tag === 1) {
                return [state, Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    let url_2;
                    return ((url_2 = addPrefix_1(url), (refreshOn) => fetchWithDecoder_1(url_2, singleton(new Types_RequestProperties(0, ["DELETE"])), "application/json", (response) => {
                        const pr = response.arrayBuffer();
                        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
                    }, refreshOn)))(true);
                })), `/api/stocktakings/${unwrapStocktakingId(matchValue.fields[0].Id)}`, (Item_2) => (new Msg(3, [Item_2])), (Item_3) => (new Msg(12, [Item_3])))];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        case 3:
            if (msg.fields[0].tag === 1) {
                return [state, Cmd_none()];
            }
            else {
                return [state, Cmd_batch(ofArray([Cmd_successToast("stocktaking.success_delete_stocktaking"), Cmd_ofEffect((_arg) => {
                    RouterModule_nav(singleton("stocktaking"), 1, 1);
                })]))];
            }
        case 7: {
            const updatedDialogOpenState = new DialogOpenState(msg.fields[0], false, false);
            return [new State(state.StocktakingId, state.Stocktaking, state.Users, FormState_get_empty(), updatedDialogOpenState), Cmd_none()];
        }
        case 8: {
            const updatedDialogOpenState_1 = new DialogOpenState(false, msg.fields[0], false);
            return [new State(state.StocktakingId, state.Stocktaking, state.Users, FormState_get_empty(), updatedDialogOpenState_1), Cmd_none()];
        }
        case 9: {
            const updatedDialogOpenState_2 = new DialogOpenState(false, false, msg.fields[0]);
            return [new State(state.StocktakingId, state.Stocktaking, state.Users, FormState_get_empty(), updatedDialogOpenState_2), Cmd_none()];
        }
        case 4:
            return [new State(state.StocktakingId, state.Stocktaking, state.Users, FormState_get_empty(), state.DialogOpenState), Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                let url_5;
                return ((url_5 = addPrefix_1(tupledArg[0]), (refreshOn_1) => fetchWithDecoder_1(url_5, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response_1) => {
                    const pr_1 = response_1.arrayBuffer();
                    return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), string_type));
                }, refreshOn_1)))(true);
            })), [`/api/stocktakings/${StocktakingId__get_unwrap(state.StocktakingId)}/state/fixed`, defaultOf()], (Item_4) => (new Msg(10, [Item_4])), (Item_5) => (new Msg(12, [Item_5])))];
        case 5:
            return [new State(state.StocktakingId, state.Stocktaking, state.Users, FormState_get_empty(), state.DialogOpenState), Cmd_OfPromise_either((tupledArg_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                let url_8;
                return ((url_8 = addPrefix_1(tupledArg_1[0]), (refreshOn_2) => fetchWithDecoder_1(url_8, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg_1[1]])]), "application/json", (response_2) => {
                    const pr_2 = response_2.arrayBuffer();
                    return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), string_type));
                }, refreshOn_2)))(true);
            })), [`/api/stocktakings/${StocktakingId__get_unwrap(state.StocktakingId)}/state/start`, defaultOf()], (Item_6) => (new Msg(10, [Item_6])), (Item_7) => (new Msg(12, [Item_7])))];
        case 6:
            return [new State(state.StocktakingId, state.Stocktaking, state.Users, FormState_get_empty(), state.DialogOpenState), Cmd_OfPromise_either((tupledArg_2) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                let url_11;
                return ((url_11 = addPrefix_1(tupledArg_2[0]), (refreshOn_3) => fetchWithDecoder_1(url_11, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg_2[1]])]), "application/json", (response_3) => {
                    const pr_3 = response_3.arrayBuffer();
                    return pr_3.then((blob_3) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_3)), string_type));
                }, refreshOn_3)))(true);
            })), [`/api/stocktakings/${StocktakingId__get_unwrap(state.StocktakingId)}/state/done`, defaultOf()], (Item_8) => (new Msg(10, [Item_8])), (Item_9) => (new Msg(12, [Item_9])))];
        case 10: {
            const response_4 = msg.fields[0];
            if (response_4.tag === 1) {
                return [new State(state.StocktakingId, state.Stocktaking, state.Users, FormState__setValidationResponse_5219762A(state.FormState, response_4.fields[0]), state.DialogOpenState), Cmd_errorToast("validation.toast")];
            }
            else {
                const updatedDialogOpenState_3 = new DialogOpenState(false, false, false);
                return [new State(state.StocktakingId, state.Stocktaking, state.Users, FormState_get_empty(), updatedDialogOpenState_3), singleton((dispatch) => {
                    dispatch(new Msg(0, []));
                })];
            }
        }
        case 11:
            return [new State(state.StocktakingId, state.Stocktaking, new RequestedValue$1(1, [msg.fields[0]]), state.FormState, state.DialogOpenState), Cmd_none()];
        case 12:
            return [state, Cmd_none()];
        default:
            return [state, getStocktaking(state.StocktakingId, (Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(12, [Item_1])))];
    }
}

