import { Union, Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { obj_type, union_type, class_type, bool_type, lambda_type, unit_type, option_type, array_type, record_type, string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { useTranslation } from "react-i18next";
import { tryPick, pick, map, append, sortWith } from "../fable_modules/fable-library-js.4.19.2/Array.js";
import { sortAsc } from "./NaturalOrder.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.19.2/MapUtil.js";
import { defaultArg, map as map_1 } from "../fable_modules/fable-library-js.4.19.2/Option.js";
import { join, printf, toText } from "../fable_modules/fable-library-js.4.19.2/String.js";
import react_select from "react-select";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { empty, singleton, append as append_1, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";

export class OptionType$1 extends Record {
    constructor(label, value) {
        super();
        this.label = label;
        this.value = value;
    }
}

export function OptionType$1_$reflection(gen0) {
    return record_type("SharedComponents.ReactSelect.OptionType`1", [gen0], OptionType$1, () => [["label", string_type], ["value", gen0]]);
}

export class CommonProps$1 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Options", "TranslatedOptions", "OnChange", "Value", "TranslatedValue", "IsSearchable", "Placeholder", "IsDisabled", "IsMulti", "IsClearable", "NoOptionsMessage", "ClassName", "ClassNamePrefix", "IsValid", "ValidationMessage"];
    }
}

export function CommonProps$1_$reflection(gen0) {
    return union_type("SharedComponents.ReactSelect.CommonProps`1", [gen0], CommonProps$1, () => [[["Item", array_type(OptionType$1_$reflection(gen0))]], [["Item", array_type(OptionType$1_$reflection(gen0))]], [["Item", lambda_type(option_type(OptionType$1_$reflection(gen0)), unit_type)]], [["Item", option_type(OptionType$1_$reflection(gen0))]], [["Item", option_type(OptionType$1_$reflection(gen0))]], [["Item", bool_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", lambda_type(string_type, class_type("Fable.React.ReactElement"))]], [["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", string_type]]]);
}

export class CommonPropsMultiSelect$1 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Options", "TranslatedOptions", "OnChange", "Value", "TranslatedValues", "IsSearchable", "Placeholder", "IsDisabled", "IsMulti", "IsClearable", "IsOrdered", "NoOptionsMessage", "ClassName", "ClassNamePrefix", "MenuPortalTarget"];
    }
}

export function CommonPropsMultiSelect$1_$reflection(gen0) {
    return union_type("SharedComponents.ReactSelect.CommonPropsMultiSelect`1", [gen0], CommonPropsMultiSelect$1, () => [[["Item", array_type(OptionType$1_$reflection(gen0))]], [["Item", array_type(OptionType$1_$reflection(gen0))]], [["Item", lambda_type(array_type(OptionType$1_$reflection(gen0)), unit_type)]], [["Item", array_type(OptionType$1_$reflection(gen0))]], [["Item", array_type(OptionType$1_$reflection(gen0))]], [["Item", bool_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", lambda_type(string_type, class_type("Fable.React.ReactElement"))]], [["Item", string_type]], [["Item", string_type]], [["Item", obj_type]]]);
}

export function ToNoOptionsMessage$0027(props) {
    return createElement("div", {
        className: "mr-auto p-2",
        children: useTranslation()[0](props.MessageKey),
    });
}

export function toNoOptionsMessage(messageKey, _arg) {
    return createElement(ToNoOptionsMessage$0027, {
        MessageKey: messageKey,
    });
}

export function orderOptions(options) {
    return sortWith((a, b) => sortAsc(a.label, b.label), options);
}

export function translateOption(t, option) {
    return new OptionType$1(t(option.label), option.value);
}

export function SelectLine$0027(props) {
    const t = useTranslation()[0];
    const props_2 = keyValueList(append([new CommonProps$1(12, ["react-select"])], map((prop) => {
        switch (prop.tag) {
            case 0:
                return new CommonProps$1(0, [orderOptions(prop.fields[0])]);
            case 1:
                return new CommonProps$1(0, [orderOptions(map((option) => translateOption(t, option), prop.fields[0]))]);
            case 4:
                return new CommonProps$1(3, [map_1((option_1) => translateOption(t, option_1), prop.fields[0])]);
            case 6:
                return new CommonProps$1(6, [t(prop.fields[0])]);
            case 14:
                return new CommonProps$1(14, [t(prop.fields[0])]);
            case 11:
                return new CommonProps$1(11, [toText(printf("%s %s"))(prop.fields[0])(props.ClassName)]);
            default:
                return prop;
        }
    }, props.Elements)), 1);
    return react.createElement(react_select, props_2);
}

export function SelectWithLabel$0027(props) {
    let elems;
    const patternInput = useTranslation();
    const isValid = !props.Props.some((_arg) => {
        let matchResult, valid_1;
        if (_arg.tag === 13) {
            if (!_arg.fields[0]) {
                matchResult = 0;
                valid_1 = _arg.fields[0];
            }
            else {
                matchResult = 1;
            }
        }
        else {
            matchResult = 1;
        }
        switch (matchResult) {
            case 0:
                return true;
            default:
                return false;
        }
    });
    return createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append_1(singleton("select-with-label form-group flex-grow-1"), delay(() => (!isValid ? singleton("is-invalid") : empty()))))))], (elems = toList(delay(() => append_1(singleton(createElement("label", {
        className: join(" ", ["text-sm", "font-bold"]),
        children: props.LabelText,
    })), delay(() => append_1(singleton(createElement(SelectLine$0027, {
        ClassName: isValid ? "" : "is-invalid",
        Elements: props.Props,
    })), delay(() => (!isValid ? singleton(createElement("div", {
        className: "invalid-feedback",
        children: pick((_arg_1) => {
            if (_arg_1.tag === 14) {
                return patternInput[0](_arg_1.fields[0]);
            }
            else {
                return undefined;
            }
        }, props.Props),
    })) : empty()))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function selectWithLabel(props, labelText, labelClassName) {
    return createElement(SelectWithLabel$0027, {
        LabelClassName: labelClassName,
        LabelText: labelText,
        Props: props,
    });
}

export function selectWithoutLabel(props) {
    const children = [createElement(SelectLine$0027, {
        ClassName: "",
        Elements: props,
    })];
    return react.createElement("div", {
        className: "",
    }, ...children);
}

export function MultiSelect$0027(props) {
    let array_5;
    const t = useTranslation()[0];
    const isOrdered = defaultArg(tryPick((_arg) => {
        if (_arg.tag === 10) {
            return _arg.fields[0];
        }
        else {
            return undefined;
        }
    }, props.Props), true);
    const props_2 = keyValueList((array_5 = map((prop) => {
        let options_3;
        switch (prop.tag) {
            case 0:
                return new CommonPropsMultiSelect$1(0, [orderOptions(prop.fields[0])]);
            case 1:
                return new CommonPropsMultiSelect$1(0, [(options_3 = map((a) => (new OptionType$1(t(a.label), a.value)), prop.fields[0]), isOrdered ? orderOptions(options_3) : options_3)]);
            case 4:
                return new CommonPropsMultiSelect$1(3, [map((a_1) => (new OptionType$1(t(a_1.label), a_1.value)), prop.fields[0])]);
            case 6:
                return new CommonPropsMultiSelect$1(6, [t(prop.fields[0])]);
            default:
                return prop;
        }
    }, props.Props), append([new CommonPropsMultiSelect$1(13, ["react-select"]), new CommonPropsMultiSelect$1(14, [document.body])], array_5)), 1);
    return react.createElement(react_select, props_2);
}

export function multiSelectWithLabel(props, labelText, labelClassName) {
    const children = [createElement("label", {
        className: join(" ", ["text-sm", "font-bold"]),
        children: labelText,
    }), createElement(MultiSelect$0027, {
        Props: props,
    })];
    return react.createElement("div", {
        className: "select-with-label form-group flex-grow-1",
    }, ...children);
}

export function multiSelectWithoutLabel(props) {
    const children = [createElement(MultiSelect$0027, {
        Props: props,
    })];
    return react.createElement("div", {
        className: "",
        onClick: (e) => {
            e.stopPropagation();
        },
    }, ...children);
}

