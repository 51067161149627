import { Union, Record } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { RequestedValue$1_$reflection, StocktakingListId_$reflection, StocktakingId_$reflection } from "../../Shared/Shared.js";
import { StocktakingListDto_$reflection, StocktakingDetailDto_$reflection } from "../../Shared/Stocktaking.js";
import { union_type, class_type, record_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";

export class State extends Record {
    constructor(StocktakingId, StocktakingListId, Stocktaking, StocktakingList) {
        super();
        this.StocktakingId = StocktakingId;
        this.StocktakingListId = StocktakingListId;
        this.Stocktaking = Stocktaking;
        this.StocktakingList = StocktakingList;
    }
}

export function State_$reflection() {
    return record_type("Stocktaking.StocktakingList.Types.State", [], State, () => [["StocktakingId", StocktakingId_$reflection()], ["StocktakingListId", StocktakingListId_$reflection()], ["Stocktaking", RequestedValue$1_$reflection(StocktakingDetailDto_$reflection())], ["StocktakingList", RequestedValue$1_$reflection(StocktakingListDto_$reflection())]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["FetchStocktaking", "StocktakingFetched", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Stocktaking.StocktakingList.Types.Msg", [], Msg, () => [[], [["Item", StocktakingDetailDto_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

