import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, lambda_type, unit_type, union_type, list_type, class_type, option_type, anonRecord_type, bool_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { FormState__getValidation, FormState__startLoading, AssignToolWithoutNotificationDto, ToolId as ToolId_1, RequestedValue$1, FormState_get_empty, RequestedValue$1_$reflection, FormState_$reflection, ClientApiResponse$1_$reflection } from "../../Shared/Shared.js";
import { Helper_receiverTypeNameTranslation, ReceiverTypeName, Receiver_$reflection } from "../../Shared/User.js";
import { AssignToolNotification, ToolDetailDto_$reflection } from "../../Shared/Tool.js";
import { Cmd_ofEffect, Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { filter, sortBy, toArray, map as map_1, ofArray, singleton } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { unwrap, map } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { toISOString } from "../../Common.js";
import { Cmd_assignCmd, Cmd_assignWithNotificationCmd } from "../../Requests/Tool.js";
import { parse } from "../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { Cmd_successToast } from "../../Components/Toast.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { utcNow, date } from "../../fable_modules/fable-library-js.4.19.2/Date.js";
import { safeHash, comparePrimitives, equals, createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { ToolInfo } from "../../Widgets/ToolInfoWidget.js";
import { Interop_reactApi } from "../../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { GroupedSelect } from "../../Components/Select.js";
import { List_groupBy } from "../../fable_modules/fable-library-js.4.19.2/Seq2.js";
import { TextArea } from "../../Components/TextArea.js";
import { Checkbox } from "../../Components/Checkbox.js";
import { ErrorAlert, InformationAlert } from "../../Components/Alert.js";
import { DatePicker } from "../../Components/DatePicker.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Dialog } from "../../Components/Dialog.js";
import { Skeleton } from "../../Components/Skeleton.js";
import { TextButton } from "../../Components/Button.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["AssignedToolResponse", "OnAssignTool", "SetWithNotification", "SetWithSignature", "SetComment", "SetSelectedUser", "SetDate", "ReceiversFetched", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Tools.AssignToolDialog.Msg", [], Msg, () => [[["Item", ClientApiResponse$1_$reflection(string_type)]], [], [["Item", bool_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", option_type(anonRecord_type(["label", string_type], ["value", Receiver_$reflection()]))]], [["Item", option_type(class_type("System.DateTime"))]], [["Item", list_type(Receiver_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

class State extends Record {
    constructor(Tool, Comment$, UserId, FormState, SuccessCallback, SelectedUser, Date$, WithNotification, WithSignature, OnClose, Receivers) {
        super();
        this.Tool = Tool;
        this.Comment = Comment$;
        this.UserId = UserId;
        this.FormState = FormState;
        this.SuccessCallback = SuccessCallback;
        this.SelectedUser = SelectedUser;
        this.Date = Date$;
        this.WithNotification = WithNotification;
        this.WithSignature = WithSignature;
        this.OnClose = OnClose;
        this.Receivers = Receivers;
    }
}

function State_$reflection() {
    return record_type("Tools.AssignToolDialog.State", [], State, () => [["Tool", ToolDetailDto_$reflection()], ["Comment", string_type], ["UserId", string_type], ["FormState", FormState_$reflection()], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["SelectedUser", option_type(anonRecord_type(["label", string_type], ["value", Receiver_$reflection()]))], ["Date", option_type(class_type("System.DateTime"))], ["WithNotification", bool_type], ["WithSignature", bool_type], ["OnClose", lambda_type(unit_type, unit_type)], ["Receivers", RequestedValue$1_$reflection(list_type(Receiver_$reflection()))]]);
}

function init(tool, userId, successCallback, onClose) {
    return [new State(tool, "", userId, FormState_get_empty(), successCallback, undefined, undefined, false, false, onClose, new RequestedValue$1(0, [])), Cmd_batch(singleton(Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(Receiver_$reflection())));
    })))), "/api/receivers/active", (Item) => (new Msg(7, [Item])), (Item_1) => (new Msg(8, [Item_1])))))];
}

function update(msg, state) {
    switch (msg.tag) {
        case 1: {
            const matchValue_1 = state.SelectedUser;
            let matchResult, receiver;
            if (state.Tool.AssignedReceiverInformation != null) {
                if (matchValue_1 != null) {
                    matchResult = 1;
                }
                else {
                    matchResult = 1;
                }
            }
            else if (matchValue_1 == null) {
                matchResult = 1;
            }
            else {
                matchResult = 0;
                receiver = matchValue_1;
            }
            switch (matchResult) {
                case 0: {
                    let command;
                    if (state.WithNotification) {
                        const dto = new AssignToolNotification(receiver.value.Id, state.Comment, "storageToUser", state.WithSignature, map(toISOString, state.Date));
                        command = Cmd_assignWithNotificationCmd(new ToolId_1(parse(state.Tool.Id)), dto, (Item_1) => (new Msg(0, [Item_1])), (Item_2) => (new Msg(8, [Item_2])));
                    }
                    else {
                        const dto_1 = new AssignToolWithoutNotificationDto(receiver.value.Id, state.Comment, undefined, map(toISOString, state.Date));
                        command = Cmd_assignCmd(new ToolId_1(parse(state.Tool.Id)), dto_1, (Item_4) => (new Msg(0, [Item_4])), (Item_5) => (new Msg(8, [Item_5])));
                    }
                    return [new State(state.Tool, state.Comment, state.UserId, FormState__startLoading(state.FormState), state.SuccessCallback, state.SelectedUser, state.Date, state.WithNotification, state.WithSignature, state.OnClose, state.Receivers), command];
                }
                default:
                    return [state, Cmd_none()];
            }
        }
        case 2: {
            const withNotification = msg.fields[0];
            if (!withNotification) {
                return [new State(state.Tool, state.Comment, state.UserId, state.FormState, state.SuccessCallback, state.SelectedUser, state.Date, withNotification, false, state.OnClose, state.Receivers), Cmd_none()];
            }
            else {
                return [new State(state.Tool, state.Comment, state.UserId, state.FormState, state.SuccessCallback, state.SelectedUser, state.Date, withNotification, state.WithSignature, state.OnClose, state.Receivers), Cmd_none()];
            }
        }
        case 3: {
            const withSignature = msg.fields[0];
            if (withSignature) {
                return [new State(state.Tool, state.Comment, state.UserId, state.FormState, state.SuccessCallback, state.SelectedUser, state.Date, true, withSignature, state.OnClose, state.Receivers), Cmd_none()];
            }
            else {
                return [new State(state.Tool, state.Comment, state.UserId, state.FormState, state.SuccessCallback, state.SelectedUser, state.Date, state.WithNotification, withSignature, state.OnClose, state.Receivers), Cmd_none()];
            }
        }
        case 4:
            return [new State(state.Tool, msg.fields[0], state.UserId, state.FormState, state.SuccessCallback, state.SelectedUser, state.Date, state.WithNotification, state.WithSignature, state.OnClose, state.Receivers), Cmd_none()];
        case 5:
            return [new State(state.Tool, state.Comment, state.UserId, state.FormState, state.SuccessCallback, msg.fields[0], state.Date, state.WithNotification, state.WithSignature, state.OnClose, state.Receivers), Cmd_none()];
        case 6:
            return [new State(state.Tool, state.Comment, state.UserId, state.FormState, state.SuccessCallback, state.SelectedUser, msg.fields[0], state.WithNotification, state.WithSignature, state.OnClose, state.Receivers), Cmd_none()];
        case 7:
            return [new State(state.Tool, state.Comment, state.UserId, state.FormState, state.SuccessCallback, state.SelectedUser, state.Date, state.WithNotification, state.WithSignature, state.OnClose, new RequestedValue$1(1, [msg.fields[0]])), Cmd_none()];
        case 8:
            return [state, Cmd_none()];
        default:
            return [new State(state.Tool, state.Comment, state.UserId, FormState_get_empty(), state.SuccessCallback, state.SelectedUser, state.Date, state.WithNotification, state.WithSignature, state.OnClose, state.Receivers), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                state.SuccessCallback();
            }), Cmd_successToast("general.saved_successfully"), Cmd_ofEffect((_arg_1) => {
                state.OnClose();
            })]))];
    }
}

function DialogBody(props) {
    let elems_2;
    const t = useTranslation()[0];
    const today = date(utcNow());
    const componentState = props.FormState.IsLoading ? "disabled" : "enabled";
    return createElement("div", createObj(ofArray([["id", "assign-tool-dialog"], ["className", join(" ", ["grid", "gap-5"])], (elems_2 = toList(delay(() => {
        let elems;
        return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", [])], (elems = [createElement(ToolInfo, {
            DisplayReservationAlert: true,
            ToolId: new ToolId_1(parse(props.Tool.Id)),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => append(singleton_1(createElement(GroupedSelect, (($value) => { $value.key = $value.Key; return $value; })({
            ComponentState: componentState,
            IsClearable: false,
            Key: "assign-tool-select",
            Label: t("tool.assign_tool"),
            NoOptionsMessage: "select.employee_no_options",
            OnChange: (arg) => {
                props.Dispatch(new Msg(5, [arg]));
            },
            Options: map_1((tupledArg) => {
                const receiverType = tupledArg[0];
                const receivers_1 = toArray(sortBy((receiver_2) => receiver_2.label.toLowerCase(), map_1((receiver_1) => {
                    const matchValue_1 = receiver_1.LocationName;
                    if (matchValue_1 != null) {
                        const locationName = matchValue_1;
                        if (equals(receiverType, new ReceiverTypeName(4, []))) {
                            return {
                                label: `${locationName} - ${receiver_1.Name}`,
                                value: receiver_1,
                            };
                        }
                        else {
                            return {
                                label: `${receiver_1.Name} - ${locationName}`,
                                value: receiver_1,
                            };
                        }
                    }
                    else {
                        return {
                            label: `${receiver_1.Name}`,
                            value: receiver_1,
                        };
                    }
                }, tupledArg[1]), {
                    Compare: comparePrimitives,
                }));
                return {
                    label: Helper_receiverTypeNameTranslation(t, receiverType),
                    options: receivers_1,
                };
            }, List_groupBy((receiver) => receiver.ReceiverType, filter((u) => {
                if (props.WithNotification) {
                    const matchValue = u.ReceiverType;
                    switch (matchValue.tag) {
                        case 1:
                        case 5:
                        case 8:
                        case 6:
                        case 2:
                        case 7:
                        case 3:
                        case 9:
                        case 4:
                            return false;
                        default:
                            return true;
                    }
                }
                else {
                    return true;
                }
            }, props.Receivers), {
                Equals: equals,
                GetHashCode: safeHash,
            })),
            PlaceholderKey: "select.employee_placeholder",
            Value: unwrap(props.SelectedUser),
        }))), delay(() => append(singleton_1(createElement(TextArea, {
            ComponentState: componentState,
            Label: t("general.comment"),
            OnChange: (arg_1) => {
                props.Dispatch(new Msg(4, [arg_1]));
            },
            TestId: "assign-tool-comment-test-id",
            Value: props.Comment,
        })), delay(() => {
            let Label_2, matchValue_2, selectedUser;
            return append(singleton_1(Checkbox((Label_2 = t("tool.dialog_assign_with_notification"), {
                ComponentState: (matchValue_2 = props.SelectedUser, (matchValue_2 != null) ? ((selectedUser = matchValue_2, (componentState === "enabled") ? (equals(selectedUser.value.ReceiverType, new ReceiverTypeName(0, [])) ? "enabled" : "disabled") : componentState)) : componentState),
                IsChecked: props.WithNotification,
                Label: Label_2,
                OnCheck: (arg_2) => {
                    props.Dispatch(new Msg(2, [arg_2]));
                },
                TestId: "assign-tool-dialog-with-notification-test-id",
            }))), delay(() => {
                let elems_1;
                return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_1 = [createElement(InformationAlert, {
                    Label: t("tool.dialog_assign_with_notification_info"),
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => {
                    let Label_3, matchValue_3, selectedUser_1;
                    return append(singleton_1(Checkbox((Label_3 = t("tool.dialog_assign_with_signature"), {
                        ComponentState: (matchValue_3 = props.SelectedUser, (matchValue_3 != null) ? ((selectedUser_1 = matchValue_3, (componentState === "enabled") ? (equals(selectedUser_1.value.ReceiverType, new ReceiverTypeName(0, [])) ? "enabled" : "disabled") : componentState)) : componentState),
                        IsChecked: props.WithSignature,
                        Label: Label_3,
                        OnCheck: (arg_3) => {
                            props.Dispatch(new Msg(3, [arg_3]));
                        },
                        TestId: "assign-tool-dialog-with-notification-test-id",
                    }))), delay(() => append(singleton_1(createElement(DatePicker, {
                        ComponentState: componentState,
                        IsClearable: true,
                        Label: t("tool.dialog_planned_return_date"),
                        OnChange: (arg_4) => {
                            props.Dispatch(new Msg(6, [arg_4]));
                        },
                        TestId: "assign-tool-dialog-planned-return-date-test-id",
                        Value: unwrap(props.Date),
                    })), delay(() => {
                        const matchValue_4 = FormState__getValidation(props.FormState, t, "global");
                        if (matchValue_4 == null) {
                            return empty();
                        }
                        else {
                            return singleton_1(createElement(ErrorAlert, {
                                Label: matchValue_4,
                            }));
                        }
                    }))));
                }));
            }));
        }))))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

export function AssignToolDialog(props) {
    let Title, Label_1, matchValue, matchValue_1;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init(props.Tool, props.UserId, props.SuccessCallback, props.OnClose);
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    return createElement(Dialog, (Title = t("tool.assign_now"), {
        Body: toList(delay(() => {
            const matchValue_2 = state_1.Receivers;
            return (matchValue_2.tag === 0) ? singleton_1(createElement(Skeleton, {
                Variant: "normal",
            })) : singleton_1(createElement(DialogBody, {
                Comment: state_1.Comment,
                Date: unwrap(state_1.Date),
                Dispatch: dispatch,
                FormState: state_1.FormState,
                Receivers: matchValue_2.fields[0],
                SelectedUser: unwrap(state_1.SelectedUser),
                Tool: props.Tool,
                WithNotification: state_1.WithNotification,
                WithSignature: state_1.WithSignature,
            }));
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-reservation-dialog-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, (Label_1 = t("general.save"), {
            ComponentState: state_1.FormState.IsLoading ? "disabled" : (((state_1.SelectedUser == null) ? true : (state_1.WithNotification && ((matchValue = state_1.SelectedUser, (matchValue == null) ? true : ((matchValue_1 = matchValue.value.ReceiverType, (matchValue_1.tag === 1) ? true : ((matchValue_1.tag === 5) ? true : ((matchValue_1.tag === 8) ? true : ((matchValue_1.tag === 6) ? true : ((matchValue_1.tag === 2) ? true : ((matchValue_1.tag === 7) ? true : ((matchValue_1.tag === 3) ? true : ((matchValue_1.tag === 9) ? true : (matchValue_1.tag === 4)))))))))))))) ? "disabled" : "enabled"),
            Label: Label_1,
            OnClick: () => {
                dispatch(new Msg(1, []));
            },
            TestId: "save-reservation-dialog-test-id",
            Variant: "blueButton",
        }))]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

