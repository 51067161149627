import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useFeliz_React__React_useState_Static_1505 } from "../../fable_modules/Feliz.2.7.0/React.fs.js";
import { filter, ofArray, singleton, map, toArray, empty } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { TableControl$1, ColumnDefinition$1, CellContent, Table } from "../../Widgets/Table/Table.js";
import Shipment_Add from "../../../public/assets/icons/Shipment_Add.svg";
import { Interop_reactApi } from "../../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { ModalSheet } from "../../Components/ModalSheet.js";
import { WarningAlert } from "../../Components/Alert.js";

function ModalContent(props) {
    let elems, TableRows, Options;
    const t = useTranslation()[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(empty());
    return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems = [createElement(Table, (TableRows = toArray(props.Tools), (Options = {
        BorderStyle: "full",
        CSVDownload: false,
        ConfigurableColumns: false,
        DefaultSortColumn: ["name", "asc"],
        GlobalSearch: false,
        RowSelection: "multiRow",
    }, {
        ColumnDefinitions: [new ColumnDefinition$1("image", t("general.master_data"), t("general.image"), (dto) => (new CellContent(4, [dto.ImageUrl])), "none", "image"), new ColumnDefinition$1("name", "", t("general.name"), (row) => (new CellContent(0, [row.DisplayName])), "text", "text"), new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_1) => (new CellContent(0, [row_1.InventoryNumber])), "text", "text"), new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row_2) => (new CellContent(0, [row_2.Manufacturer])), "text", "text"), new ColumnDefinition$1("model", "", t("tool.model"), (row_3) => (new CellContent(0, [row_3.Model])), "text", "text"), new ColumnDefinition$1("serialNumber", "", t("tool.serial_number"), (row_4) => (new CellContent(0, [row_4.SerialNumber])), "text", "text")],
        Controls: singleton(new TableControl$1(t("general.add"), Shipment_Add(), (row_5) => {
            props.OnAddToolIds(map((row_6) => row_6.ToolId, row_5));
        }, false, false, "")),
        Options: Options,
        TableRows: TableRows,
    })))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function SelectToolModal(props) {
    let matchValue, elems, selectedStorageId;
    const patternInput = useTranslation();
    return createElement(ModalSheet, {
        CloseButtonTestId: "",
        Content: (matchValue = props.SelectedStorageId, (matchValue == null) ? createElement("div", createObj(ofArray([["className", join(" ", ["px-5"])], (elems = [createElement(WarningAlert, {
            Label: patternInput[0]("set.add_tool_dialog.storage_not_selected_information"),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))) : ((selectedStorageId = matchValue, createElement(ModalContent, {
            OnAddToolIds: props.OnAddToolIds,
            SelectedStorageId: selectedStorageId,
            Tools: filter((tool) => (tool.StorageId === selectedStorageId), props.Tools),
        })))),
        Controls: empty(),
        IsModalOpen: props.IsOpen,
        OnClose: props.OnClose,
    });
}

