import { createElement } from "react";
import React from "react";
import { ToastAlert } from "./ToastAlert.js";
import { defaultOf } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { toast, Toaster as Toaster_1 } from "react-hot-toast";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { Cmd_ofEffect } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";

export function Toaster() {
    const props = ofArray([["position", "bottom-center"], ["toastOptions", {
        duration: 4000,
    }], ["children", (toastprops) => {
        const matchValue = toastprops.type;
        switch (matchValue) {
            case "success":
                return createElement(ToastAlert, {
                    Text: toastprops.message,
                    ToastType: "success",
                });
            case "error":
                return createElement(ToastAlert, {
                    Text: toastprops.message,
                    ToastType: "error",
                });
            default:
                return defaultOf();
        }
    }]]);
    return Interop_reactApi.createElement(Toaster_1, createObj(props));
}

export function Cmd_successToast(successMsg) {
    return Cmd_ofEffect((_arg) => {
        toast.success(successMsg);
    });
}

export function Cmd_errorToast(errorMsg) {
    return Cmd_ofEffect((_arg) => {
        toast.error(errorMsg);
    });
}

