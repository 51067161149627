import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { BigCalendar } from "../Components/BigCalendar.js";
import { singleton, ofArray, tryFind, map } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { isNullOrWhiteSpace, isNullOrEmpty } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { Event$ } from "../SharedComponents/ReactBigCalendar.js";
import { RouterModule_nav } from "../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { update as update_1, init as init_1 } from "./State.js";
import { React_useElmish_Z6C327F2E } from "../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Skeleton } from "../Components/Skeleton.js";

export function CalenderContent(props) {
    let elems;
    const t = useTranslation()[0];
    return createElement("div", createObj(singleton((elems = [createElement(BigCalendar, {
        Events: map((data) => {
            let matchValue_1;
            return new Event$(data.ReservationSubject.Id, (data.DateType.tag === 1) ? (`${t("tool.planning.reminder")}: ${isNullOrEmpty(data.Title) ? "" : (`${data.Title} |`)} ${isNullOrEmpty(data.Receiver.Name) ? "" : (isNullOrWhiteSpace(data.Receiver.Name) ? (`${data.ReservationSubject.Name}`) : (`${data.ReservationSubject.Name} |`))} ${data.ReservationSubject.Name}`) : (`${t("tool.planning.reservation")}: ${isNullOrEmpty(data.Title) ? "" : (isNullOrWhiteSpace(data.ReservationSubject.Name) ? (`${data.Title}`) : (`${data.Title} |`))} ${data.ReservationSubject.Name} ${isNullOrEmpty(data.Receiver.Name) ? "" : (isNullOrWhiteSpace(data.Receiver.Name) ? (`${data.ReservationSubject.Name}`) : (`${data.ReservationSubject.Name} |`))}`), data.StartDate, (matchValue_1 = data.EndDate, (matchValue_1 != null) ? matchValue_1 : data.StartDate), true);
        }, props.CalendarData),
        OnSelect: (calendarInfo) => {
            const calendarData = tryFind((tool) => (tool.ReservationSubject.Id === calendarInfo.id), props.CalendarData);
            if (calendarData != null) {
                const matchValue_2 = calendarData.ReservationSubject.SubjectType;
                switch (matchValue_2) {
                    case "toolWithQuantity": {
                        RouterModule_nav(ofArray(["toolswithquantity", calendarInfo.id]), 1, 1);
                        break;
                    }
                    case "set": {
                        RouterModule_nav(ofArray(["sets", calendarInfo.id]), 1, 1);
                        break;
                    }
                    default:
                        RouterModule_nav(ofArray(["tools", calendarInfo.id]), 1, 1);
                }
            }
        },
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]))));
}

export function CalendarView(props) {
    let init;
    const matchValue = ((init = init_1(props.UserData), React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, undefined)))[0].CalendarData;
    if (matchValue.tag === 1) {
        return createElement(CalenderContent, {
            CalendarData: matchValue.fields[0],
        });
    }
    else {
        return createElement(Skeleton, {
            Variant: "table",
        });
    }
}

