import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, lambda_type, unit_type, union_type, list_type, class_type, option_type, anonRecord_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { Helper_receiverTypeNameTranslation, ReceiverTypeName, Receiver_$reflection } from "../../Shared/User.js";
import { FormState__getValidation, FormState__startLoading, FormState__setValidationResponse_5219762A, ToolId as ToolId_1, RequestedValue$1, FormState_get_empty, AssignToolWithoutNotificationDto, ToolId_$reflection, RequestedValue$1_$reflection, FormState_$reflection, AssignToolWithoutNotificationDto_$reflection, ClientApiResponse$1_$reflection } from "../../Shared/Shared.js";
import { parse } from "../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { Cmd_ofEffect, Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { filter, sortBy, toArray, map as map_1, ofArray, singleton } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";
import { unwrap, map } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { toISOString } from "../../Common.js";
import { Cmd_assignCmd } from "../../Requests/Tool.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { utcNow, date as date_1 } from "../../fable_modules/fable-library-js.4.19.2/Date.js";
import { safeHash, comparePrimitives, equals, createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { ToolInfo } from "../../Widgets/ToolInfoWidget.js";
import { Interop_reactApi } from "../../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { GroupedSelect } from "../../Components/Select.js";
import { List_groupBy } from "../../fable_modules/fable-library-js.4.19.2/Seq2.js";
import { TextArea } from "../../Components/TextArea.js";
import { DatePicker } from "../../Components/DatePicker.js";
import { ErrorAlert } from "../../Components/Alert.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Dialog } from "../../Components/Dialog.js";
import { Skeleton } from "../../Components/Skeleton.js";
import { TextButton } from "../../Components/Button.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SelectReceiver", "SetComment", "SelectReturnDate", "AssignedToolResponse", "OnAssignTool", "ReceiversFetched", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Tools.AssignToolFromUserToUserDialog.Msg", [], Msg, () => [[["Item", option_type(anonRecord_type(["label", string_type], ["value", Receiver_$reflection()]))]], [["Item", string_type]], [["Item", option_type(class_type("System.DateTime"))]], [["Item", ClientApiResponse$1_$reflection(string_type)]], [], [["Item", list_type(Receiver_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

class State extends Record {
    constructor(Dto, SelectedReturnDate, SelectedReceiver, FormState, SuccessCallback, OnClose, Receivers, ToolId) {
        super();
        this.Dto = Dto;
        this.SelectedReturnDate = SelectedReturnDate;
        this.SelectedReceiver = SelectedReceiver;
        this.FormState = FormState;
        this.SuccessCallback = SuccessCallback;
        this.OnClose = OnClose;
        this.Receivers = Receivers;
        this.ToolId = ToolId;
    }
}

function State_$reflection() {
    return record_type("Tools.AssignToolFromUserToUserDialog.State", [], State, () => [["Dto", AssignToolWithoutNotificationDto_$reflection()], ["SelectedReturnDate", option_type(class_type("System.DateTime"))], ["SelectedReceiver", option_type(anonRecord_type(["label", string_type], ["value", Receiver_$reflection()]))], ["FormState", FormState_$reflection()], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["OnClose", lambda_type(unit_type, unit_type)], ["Receivers", RequestedValue$1_$reflection(list_type(Receiver_$reflection()))], ["ToolId", ToolId_$reflection()]]);
}

function init(props) {
    return [new State(new AssignToolWithoutNotificationDto("", "", undefined, undefined), undefined, undefined, FormState_get_empty(), props.SuccessCallback, props.OnClose, new RequestedValue$1(0, []), new ToolId_1(parse(props.Tool.Id))), Cmd_batch(singleton(Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(Receiver_$reflection())));
    })))), "/api/receivers/active", (Item_1) => (new Msg(5, [Item_1])), (Item_2) => (new Msg(6, [Item_2])))))];
}

function update(msg, state) {
    let bind$0040;
    switch (msg.tag) {
        case 1:
            return [new State((bind$0040 = state.Dto, new AssignToolWithoutNotificationDto(bind$0040.ReceiverId, msg.fields[0], bind$0040.SignatureUrl, bind$0040.PlannedReturnDate)), state.SelectedReturnDate, state.SelectedReceiver, state.FormState, state.SuccessCallback, state.OnClose, state.Receivers, state.ToolId), Cmd_none()];
        case 2:
            return [new State(state.Dto, msg.fields[0], state.SelectedReceiver, state.FormState, state.SuccessCallback, state.OnClose, state.Receivers, state.ToolId), Cmd_none()];
        case 3: {
            const response = msg.fields[0];
            if (response.tag === 1) {
                return [new State(state.Dto, state.SelectedReturnDate, state.SelectedReceiver, FormState__setValidationResponse_5219762A(state.FormState, response.fields[0]), state.SuccessCallback, state.OnClose, state.Receivers, state.ToolId), Cmd_errorToast("validation.toast")];
            }
            else {
                return [new State(state.Dto, state.SelectedReturnDate, state.SelectedReceiver, FormState_get_empty(), state.SuccessCallback, state.OnClose, state.Receivers, state.ToolId), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                    state.SuccessCallback();
                }), Cmd_successToast("general.saved_successfully"), Cmd_ofEffect((_arg_1) => {
                    state.OnClose();
                })]))];
            }
        }
        case 4: {
            const matchValue = state.SelectedReceiver;
            if (matchValue == null) {
                return [state, Cmd_none()];
            }
            else {
                let dto;
                const bind$0040_1 = state.Dto;
                dto = (new AssignToolWithoutNotificationDto(matchValue.value.Id, bind$0040_1.Comment, bind$0040_1.SignatureUrl, map(toISOString, state.SelectedReturnDate)));
                return [new State(state.Dto, state.SelectedReturnDate, state.SelectedReceiver, FormState__startLoading(state.FormState), state.SuccessCallback, state.OnClose, state.Receivers, state.ToolId), Cmd_assignCmd(state.ToolId, dto, (Item) => (new Msg(3, [Item])), (Item_1) => (new Msg(6, [Item_1])))];
            }
        }
        case 5:
            return [new State(state.Dto, state.SelectedReturnDate, state.SelectedReceiver, state.FormState, state.SuccessCallback, state.OnClose, new RequestedValue$1(1, [msg.fields[0]]), state.ToolId), Cmd_none()];
        case 6:
            return [state, Cmd_none()];
        default:
            return [new State(state.Dto, state.SelectedReturnDate, msg.fields[0], state.FormState, state.SuccessCallback, state.OnClose, state.Receivers, state.ToolId), Cmd_none()];
    }
}

function DialogBody(props) {
    let elems_1;
    const t = useTranslation()[0];
    const today = date_1(utcNow());
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-4"])], (elems_1 = toList(delay(() => {
        let elems;
        return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", [])], (elems = [createElement(ToolInfo, {
            DisplayReservationAlert: true,
            ToolId: new ToolId_1(parse(props.Tool.Id)),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => append(singleton_1(createElement(GroupedSelect, (($value) => { $value.key = $value.Key; return $value; })({
            ComponentState: "enabled",
            IsClearable: false,
            Key: "assign-tool-select-user",
            NoOptionsMessage: "select.employee_no_options",
            OnChange: props.SetSelectedReceiver,
            Options: map_1((tupledArg) => {
                const receiverType = tupledArg[0];
                const receivers_1 = toArray(sortBy((receiver_2) => receiver_2.label.toLowerCase(), map_1((receiver_1) => {
                    const matchValue = receiver_1.LocationName;
                    if (matchValue != null) {
                        const locationName = matchValue;
                        if (equals(receiverType, new ReceiverTypeName(4, []))) {
                            return {
                                label: `${locationName} - ${receiver_1.Name}`,
                                value: receiver_1,
                            };
                        }
                        else {
                            return {
                                label: `${receiver_1.Name} - ${locationName}`,
                                value: receiver_1,
                            };
                        }
                    }
                    else {
                        return {
                            label: `${receiver_1.Name}`,
                            value: receiver_1,
                        };
                    }
                }, tupledArg[1]), {
                    Compare: comparePrimitives,
                }));
                return {
                    label: Helper_receiverTypeNameTranslation(t, receiverType),
                    options: receivers_1,
                };
            }, List_groupBy((receiver) => receiver.ReceiverType, filter((r) => (r.Id !== props.AssignedReceiverId), props.Receivers), {
                Equals: equals,
                GetHashCode: safeHash,
            })),
            PlaceholderKey: "select.employee_placeholder",
            Value: unwrap(props.SelectedReceiver),
        }))), delay(() => append(singleton_1(createElement(TextArea, {
            ComponentState: props.ComponentState,
            Label: t("general.comment"),
            OnChange: props.SetComment,
            TestId: "assign-tool-comment",
            Value: props.Comment,
        })), delay(() => append(singleton_1(createElement(DatePicker, {
            ComponentState: (props.ComponentState === "disabled") ? "disabled" : "enabled",
            IsClearable: true,
            Label: t("tool.dialog_planned_return_date"),
            OnChange: props.SetReturnDate,
            TestId: "assign-tool-from-user-to-user-planned-returned-date-test-id",
            Value: unwrap(props.ReturnDate),
        })), delay(() => {
            const matchValue_2 = FormState__getValidation(props.FormState, t, "global");
            if (matchValue_2 == null) {
                return empty();
            }
            else {
                return singleton_1(createElement(ErrorAlert, {
                    Label: matchValue_2,
                }));
            }
        }))))))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function OpenDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init({
        OnClose: props.OnClose,
        SuccessCallback: props.SuccessCallback,
        Tool: props.Tool,
    });
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    return createElement(Dialog, (Title = t("tool.assign_now_to_other_user"), {
        Body: toList(delay(() => {
            const matchValue = state_1.Receivers;
            return (matchValue.tag === 0) ? singleton_1(createElement(Skeleton, {
                Variant: "normal",
            })) : singleton_1(createElement(DialogBody, {
                AssignedReceiverId: props.AssignedReceiverId,
                Comment: state_1.Dto.Comment,
                ComponentState: state_1.FormState.IsLoading ? "disabled" : "enabled",
                FormState: state_1.FormState,
                Receivers: matchValue.fields[0],
                ReturnDate: unwrap(state_1.SelectedReturnDate),
                SelectedReceiver: unwrap(state_1.SelectedReceiver),
                SetComment: (value) => {
                    dispatch(new Msg(1, [value]));
                },
                SetReturnDate: (value_1) => {
                    dispatch(new Msg(2, [value_1]));
                },
                SetSelectedReceiver: (value_2) => {
                    dispatch(new Msg(0, [value_2]));
                },
                Tool: props.Tool,
            }));
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-reservation-dialog-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: (state_1.FormState.IsLoading ? true : (state_1.SelectedReceiver == null)) ? "disabled" : "enabled",
            Label: t("general.save"),
            OnClick: () => {
                dispatch(new Msg(4, []));
            },
            TestId: "save-assign-dialog-test-id",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

