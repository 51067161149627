import { Union, Record } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { union_type, class_type, string_type, list_type, record_type, bool_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { ClientApiResponse$1_$reflection, FormState_$reflection, RequestedValue$1_$reflection, StocktakingId_$reflection } from "../../Shared/Shared.js";
import { StocktakingDetailDto_$reflection } from "../../Shared/Stocktaking.js";
import { User_$reflection } from "../../Shared/User.js";

export class DialogOpenState extends Record {
    constructor(IsConfirmationStateFixedDialogOpen, IsConfirmationStateStartDialogOpen, IsConfirmationStateDoneDialogOpen) {
        super();
        this.IsConfirmationStateFixedDialogOpen = IsConfirmationStateFixedDialogOpen;
        this.IsConfirmationStateStartDialogOpen = IsConfirmationStateStartDialogOpen;
        this.IsConfirmationStateDoneDialogOpen = IsConfirmationStateDoneDialogOpen;
    }
}

export function DialogOpenState_$reflection() {
    return record_type("Stocktaking.Detail.Types.DialogOpenState", [], DialogOpenState, () => [["IsConfirmationStateFixedDialogOpen", bool_type], ["IsConfirmationStateStartDialogOpen", bool_type], ["IsConfirmationStateDoneDialogOpen", bool_type]]);
}

export class State extends Record {
    constructor(StocktakingId, Stocktaking, Users, FormState, DialogOpenState) {
        super();
        this.StocktakingId = StocktakingId;
        this.Stocktaking = Stocktaking;
        this.Users = Users;
        this.FormState = FormState;
        this.DialogOpenState = DialogOpenState;
    }
}

export function State_$reflection() {
    return record_type("Stocktaking.Detail.Types.State", [], State, () => [["StocktakingId", StocktakingId_$reflection()], ["Stocktaking", RequestedValue$1_$reflection(StocktakingDetailDto_$reflection())], ["Users", RequestedValue$1_$reflection(list_type(User_$reflection()))], ["FormState", FormState_$reflection()], ["DialogOpenState", DialogOpenState_$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["FetchStocktaking", "StocktakingFetched", "DeleteStocktaking", "StocktakingDeleted", "SetStocktakingStateFixed", "SetStocktakingStateStart", "SetStocktakingStateDone", "OnCloseConfirmationStateFixedDialog", "OnCloseConfirmationStateStartDialog", "OnCloseConfirmationStateDoneDialog", "StocktakingStateSet", "UsersFetched", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Stocktaking.Detail.Types.Msg", [], Msg, () => [[], [["Item", StocktakingDetailDto_$reflection()]], [], [["Item", ClientApiResponse$1_$reflection(string_type)]], [], [], [], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", list_type(User_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

