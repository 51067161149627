import { Record, Union } from "../../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, lambda_type, unit_type, bool_type, union_type, class_type, option_type, int32_type } from "../../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { UpdateNotificationStockDto, StorageId_$reflection, ToolId_$reflection, UpdateNotificationStockDto_$reflection, PostResponse$1_$reflection, UpdateNotificationStockResult_$reflection } from "../../../Shared/Shared.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none, Cmd_OfPromise_either } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../../Communication.js";
import { Types_RequestProperties } from "../../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { singleton, ofArray } from "../../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { unwrapStorageId } from "../../../Shared/Helper.js";
import { Cmd_successToast } from "../../../Components/Toast.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { createObj } from "../../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.2/String.js";
import { NumberInput } from "../../../Components/Input.js";
import { unwrap, map } from "../../../fable_modules/fable-library-js.4.19.2/Option.js";
import { numberInputIntMaxValue } from "../../../Common.js";
import { Interop_reactApi } from "../../../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Dialog } from "../../../Components/Dialog.js";
import { TextButton } from "../../../Components/Button.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetQuantity", "UpdateNotificationStock", "UpdatedNotificationStockResponse", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Tools.MinimumStockQuantityDialog.Msg", [], Msg, () => [[["Item", option_type(int32_type)]], [], [["Item", PostResponse$1_$reflection(UpdateNotificationStockResult_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

function postNotificationStockCmd(toolId, dto) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(UpdateNotificationStockResult_$reflection())));
    })))), [`/api/tools/${toolId.fields[0]}/notificationstock`, toString(0, Auto_generateBoxedEncoder_437914C6(UpdateNotificationStockDto_$reflection(), undefined, undefined, undefined)(dto))], (Item) => (new Msg(2, [Item])), (Item_1) => (new Msg(3, [Item_1])));
}

class State extends Record {
    constructor(ToolId, StorageId, IsLoading, SuccessCallback, OnClose, IsStockNegative, IsQuantityInvalid, Quantity) {
        super();
        this.ToolId = ToolId;
        this.StorageId = StorageId;
        this.IsLoading = IsLoading;
        this.SuccessCallback = SuccessCallback;
        this.OnClose = OnClose;
        this.IsStockNegative = IsStockNegative;
        this.IsQuantityInvalid = IsQuantityInvalid;
        this.Quantity = Quantity;
    }
}

function State_$reflection() {
    return record_type("Tools.MinimumStockQuantityDialog.State", [], State, () => [["ToolId", ToolId_$reflection()], ["StorageId", StorageId_$reflection()], ["IsLoading", bool_type], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["OnClose", lambda_type(unit_type, unit_type)], ["IsStockNegative", bool_type], ["IsQuantityInvalid", bool_type], ["Quantity", option_type(int32_type)]]);
}

function init(props) {
    return [new State(props.ToolId, props.StorageId, false, props.SuccessCallback, props.OnClose, false, false, undefined), Cmd_none()];
}

function update(msg, state) {
    switch (msg.tag) {
        case 1: {
            const matchValue = state.Quantity;
            if (matchValue == null) {
                return [new State(state.ToolId, state.StorageId, state.IsLoading, state.SuccessCallback, state.OnClose, state.IsStockNegative, true, state.Quantity), Cmd_none()];
            }
            else {
                const quantity = matchValue | 0;
                return [new State(state.ToolId, state.StorageId, true, state.SuccessCallback, state.OnClose, false, false, state.Quantity), postNotificationStockCmd(state.ToolId, new UpdateNotificationStockDto(unwrapStorageId(state.StorageId), quantity))];
            }
        }
        case 2:
            if (msg.fields[0].Result.tag === 1) {
                return [new State(state.ToolId, state.StorageId, false, state.SuccessCallback, state.OnClose, true, state.IsQuantityInvalid, state.Quantity), Cmd_none()];
            }
            else {
                return [new State(state.ToolId, state.StorageId, false, state.SuccessCallback, state.OnClose, state.IsStockNegative, state.IsQuantityInvalid, state.Quantity), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                    state.SuccessCallback();
                }), Cmd_successToast("general.saved_successfully"), Cmd_ofEffect((_arg_1) => {
                    state.OnClose();
                })]))];
            }
        case 3:
            return [new State(state.ToolId, state.StorageId, false, state.SuccessCallback, state.OnClose, state.IsStockNegative, state.IsQuantityInvalid, state.Quantity), Cmd_none()];
        default:
            return [new State(state.ToolId, state.StorageId, state.IsLoading, state.SuccessCallback, state.OnClose, state.IsStockNegative, state.IsQuantityInvalid, msg.fields[0]), Cmd_none()];
    }
}

function DialogBody(props) {
    let elems, Label, Value;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-4"])], (elems = [createElement(NumberInput, (Label = t("general.minimum_quantity"), (Value = map((value_1) => value_1, props.Quantity), {
        ComponentState: props.FormState,
        Label: unwrap(Label),
        MaxValue: numberInputIntMaxValue,
        OnChange: (arg) => {
            props.SetQuantity(map((value_2) => ~~value_2, arg));
        },
        TestId: "",
        ValidationMessage: unwrap(props.IsQuantityInvalid ? t("tool.change_in_stock_invalid_msg") : (props.IsStockNegative ? t("tool.change_in_stock_negative_msg") : undefined)),
        Value: unwrap(Value),
    })))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function MinimumStockQuantityDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init({
        OnClose: props.OnClose,
        StorageId: props.StorageId,
        SuccessCallback: props.SuccessCallback,
        ToolId: props.ToolId,
    });
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    const formState = state_1.IsLoading ? "disabled" : "enabled";
    return createElement(Dialog, (Title = t("tool.update_notification_stock"), {
        Body: singleton(createElement(DialogBody, {
            FormState: formState,
            IsQuantityInvalid: state_1.IsQuantityInvalid,
            IsStockNegative: state_1.IsStockNegative,
            Quantity: unwrap(state_1.Quantity),
            SetQuantity: (value) => {
                dispatch(new Msg(0, [value]));
            },
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: formState,
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-minimum-stock-quantity-dialog-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: formState,
            Label: t("general.save"),
            OnClick: () => {
                dispatch(new Msg(1, []));
            },
            TestId: "save-minimum-stock-quantity-dialog-test-id",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

