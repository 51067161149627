import { Record, Union } from "../../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, bool_type, lambda_type, unit_type, union_type, class_type, list_type, anonRecord_type, option_type, int32_type, string_type } from "../../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { Helper_receiverTypeNameTranslation, ReceiverTypeName, Receiver_$reflection } from "../../../Shared/User.js";
import { Storage_$reflection } from "../../../Shared/Storage.js";
import { FormState__getValidation, ToolId, FormState__startLoading, FormState__setValidationResponse_5219762A, UnassignToolWithQuantityWithoutNotificationDto, FormState_get_empty, RequestedValue$1, UnassignToolWithQuantityWithoutNotificationDto_$reflection, FormState_$reflection, RequestedValue$1_$reflection, ClientApiResponse$1_$reflection } from "../../../Shared/Shared.js";
import { ToolWithQuantityDetailDto_$reflection } from "../../../Shared/Tool.js";
import { Cmd_OfPromise_either } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../../Communication.js";
import { Types_RequestProperties } from "../../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { sortBy, toArray, isEmpty, contains, filter, map, tryFind, ofArray, singleton } from "../../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_successToast, Cmd_errorToast } from "../../../Components/Toast.js";
import { unwrapStorageId } from "../../../Shared/Helper.js";
import { map as map_1, unwrap, defaultArg } from "../../../fable_modules/fable-library-js.4.19.2/Option.js";
import { unassignCmd } from "../../../Requests/ToolWithQuantity.js";
import { parse } from "../../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { comparePrimitives, createObj, safeHash, equals, stringHash } from "../../../fable_modules/fable-library-js.4.19.2/Util.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Interop_reactApi } from "../../../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.2/String.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { GroupedSelect, Select } from "../../../Components/Select.js";
import { List_groupBy } from "../../../fable_modules/fable-library-js.4.19.2/Seq2.js";
import { TextArea } from "../../../Components/TextArea.js";
import { NumberInput } from "../../../Components/Input.js";
import { numberInputIntMaxValue } from "../../../Common.js";
import { ErrorAlert } from "../../../Components/Alert.js";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Dialog } from "../../../Components/Dialog.js";
import { Skeleton } from "../../../Components/Skeleton.js";
import { TextButton } from "../../../Components/Button.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetComment", "SetQuantity", "SetSelectedReceiver", "SetSelectedStorage", "UnassignedToolResponse", "OnUnassignTool", "ReceiversFetched", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Tools.UnassignToolWithQuantityDialog.Msg", [], Msg, () => [[["Item", string_type]], [["Item", option_type(int32_type)]], [["Item", option_type(anonRecord_type(["label", string_type], ["value", Receiver_$reflection()]))]], [["Item", option_type(anonRecord_type(["label", string_type], ["value", Storage_$reflection()]))]], [["Item", ClientApiResponse$1_$reflection(string_type)]], [], [["Item", list_type(Receiver_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

class State extends Record {
    constructor(Tool, UserId, Receivers, Storages, SuccessCallback, OnClose, FormState, Dto, SelectedReceiver, SelectedStorage, Quantity, IsQuantityInvalid, IsUserStockInvalid) {
        super();
        this.Tool = Tool;
        this.UserId = UserId;
        this.Receivers = Receivers;
        this.Storages = Storages;
        this.SuccessCallback = SuccessCallback;
        this.OnClose = OnClose;
        this.FormState = FormState;
        this.Dto = Dto;
        this.SelectedReceiver = SelectedReceiver;
        this.SelectedStorage = SelectedStorage;
        this.Quantity = Quantity;
        this.IsQuantityInvalid = IsQuantityInvalid;
        this.IsUserStockInvalid = IsUserStockInvalid;
    }
}

function State_$reflection() {
    return record_type("Tools.UnassignToolWithQuantityDialog.State", [], State, () => [["Tool", ToolWithQuantityDetailDto_$reflection()], ["UserId", string_type], ["Receivers", RequestedValue$1_$reflection(list_type(Receiver_$reflection()))], ["Storages", list_type(Storage_$reflection())], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["OnClose", lambda_type(unit_type, unit_type)], ["FormState", FormState_$reflection()], ["Dto", UnassignToolWithQuantityWithoutNotificationDto_$reflection()], ["SelectedReceiver", option_type(anonRecord_type(["label", string_type], ["value", Receiver_$reflection()]))], ["SelectedStorage", option_type(anonRecord_type(["label", string_type], ["value", Storage_$reflection()]))], ["Quantity", option_type(int32_type)], ["IsQuantityInvalid", bool_type], ["IsUserStockInvalid", option_type(int32_type)]]);
}

function init(props) {
    return [new State(props.Tool, props.UserId, new RequestedValue$1(0, []), props.Storages, props.SuccessCallback, props.OnClose, FormState_get_empty(), new UnassignToolWithQuantityWithoutNotificationDto(props.UserId, "", 0, ""), undefined, undefined, undefined, false, undefined), Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(Receiver_$reflection())));
    })))), "/api/receivers/active", (Item) => (new Msg(6, [Item])), (Item_1) => (new Msg(7, [Item_1])))];
}

function update(msg, state) {
    let bind$0040;
    switch (msg.tag) {
        case 1:
            return [new State(state.Tool, state.UserId, state.Receivers, state.Storages, state.SuccessCallback, state.OnClose, state.FormState, state.Dto, state.SelectedReceiver, state.SelectedStorage, msg.fields[0], state.IsQuantityInvalid, state.IsUserStockInvalid), Cmd_none()];
        case 3:
            return [new State(state.Tool, state.UserId, state.Receivers, state.Storages, state.SuccessCallback, state.OnClose, state.FormState, state.Dto, state.SelectedReceiver, msg.fields[0], state.Quantity, state.IsQuantityInvalid, state.IsUserStockInvalid), Cmd_none()];
        case 2:
            return [new State(state.Tool, state.UserId, state.Receivers, state.Storages, state.SuccessCallback, state.OnClose, state.FormState, state.Dto, msg.fields[0], state.SelectedStorage, state.Quantity, state.IsQuantityInvalid, state.IsUserStockInvalid), Cmd_none()];
        case 4: {
            const response = msg.fields[0];
            if (response.tag === 1) {
                return [new State(state.Tool, state.UserId, state.Receivers, state.Storages, state.SuccessCallback, state.OnClose, FormState__setValidationResponse_5219762A(state.FormState, response.fields[0]), state.Dto, state.SelectedReceiver, state.SelectedStorage, state.Quantity, state.IsQuantityInvalid, state.IsUserStockInvalid), Cmd_errorToast("validation.toast")];
            }
            else {
                return [new State(state.Tool, state.UserId, state.Receivers, state.Storages, state.SuccessCallback, state.OnClose, FormState_get_empty(), state.Dto, state.SelectedReceiver, state.SelectedStorage, state.Quantity, state.IsQuantityInvalid, state.IsUserStockInvalid), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                    state.SuccessCallback();
                }), Cmd_successToast("general.saved_successfully"), Cmd_ofEffect((_arg_1) => {
                    state.OnClose();
                })]))];
            }
        }
        case 5: {
            const matchValue = state.SelectedStorage;
            const matchValue_1 = state.SelectedReceiver;
            const matchValue_2 = state.Quantity;
            let matchResult, quantity_1, quantity_2, receiver, storage;
            if (matchValue != null) {
                if (matchValue_1 != null) {
                    if (matchValue_2 == null) {
                        matchResult = 2;
                    }
                    else if (matchValue_2 === 0) {
                        matchResult = 0;
                        quantity_1 = matchValue_2;
                    }
                    else {
                        matchResult = 1;
                        quantity_2 = matchValue_2;
                        receiver = matchValue_1;
                        storage = matchValue;
                    }
                }
                else {
                    matchResult = 3;
                }
            }
            else {
                matchResult = 3;
            }
            switch (matchResult) {
                case 0:
                    return [new State(state.Tool, state.UserId, state.Receivers, state.Storages, state.SuccessCallback, state.OnClose, state.FormState, state.Dto, state.SelectedReceiver, state.SelectedStorage, state.Quantity, true, state.IsUserStockInvalid), Cmd_none()];
                case 1: {
                    const matchValue_4 = tryFind((ua) => (ua.ReceiverId === receiver.value.Id), state.Tool.UserAssignments);
                    if (matchValue_4 == null) {
                        return [state, Cmd_none()];
                    }
                    else {
                        const userAssignment = matchValue_4;
                        if (userAssignment.Quantity >= quantity_2) {
                            const dto = new UnassignToolWithQuantityWithoutNotificationDto(receiver.value.Id, unwrapStorageId(storage.value.Id), defaultArg(state.Quantity, 0), state.Dto.Comment);
                            return [new State(state.Tool, state.UserId, state.Receivers, state.Storages, state.SuccessCallback, state.OnClose, FormState__startLoading(state.FormState), state.Dto, state.SelectedReceiver, state.SelectedStorage, state.Quantity, false, state.IsUserStockInvalid), unassignCmd(new ToolId(parse(state.Tool.Id)), dto, (Item_1) => (new Msg(4, [Item_1])), (Item_2) => (new Msg(7, [Item_2])))];
                        }
                        else {
                            return [new State(state.Tool, state.UserId, state.Receivers, state.Storages, state.SuccessCallback, state.OnClose, state.FormState, state.Dto, state.SelectedReceiver, state.SelectedStorage, state.Quantity, state.IsQuantityInvalid, userAssignment.Quantity), Cmd_none()];
                        }
                    }
                }
                case 2:
                    return [new State(state.Tool, state.UserId, state.Receivers, state.Storages, state.SuccessCallback, state.OnClose, state.FormState, state.Dto, state.SelectedReceiver, state.SelectedStorage, state.Quantity, true, state.IsUserStockInvalid), Cmd_none()];
                default:
                    return [state, Cmd_none()];
            }
        }
        case 6: {
            const userIdsWithAssignment = map((u) => u.ReceiverId, state.Tool.UserAssignments);
            return [new State(state.Tool, state.UserId, new RequestedValue$1(1, [filter((u_1) => contains(u_1.Id, userIdsWithAssignment, {
                Equals: (x, y) => (x === y),
                GetHashCode: stringHash,
            }), msg.fields[0])]), state.Storages, state.SuccessCallback, state.OnClose, state.FormState, state.Dto, state.SelectedReceiver, state.SelectedStorage, state.Quantity, state.IsQuantityInvalid, state.IsUserStockInvalid), Cmd_none()];
        }
        case 7:
            return [state, Cmd_none()];
        default:
            return [new State(state.Tool, state.UserId, state.Receivers, state.Storages, state.SuccessCallback, state.OnClose, state.FormState, (bind$0040 = state.Dto, new UnassignToolWithQuantityWithoutNotificationDto(bind$0040.ReceiverId, bind$0040.StorageId, bind$0040.Quantity, msg.fields[0])), state.SelectedReceiver, state.SelectedStorage, state.Quantity, state.IsQuantityInvalid, state.IsUserStockInvalid), Cmd_none()];
    }
}

export function targetStorages(props) {
    const toolStorages = filter((s) => {
        const source = map((sps) => sps.StorageId, props.Tool.StockPerStorage);
        return contains(unwrapStorageId(s.Id), source, {
            Equals: (x, y) => (x === y),
            GetHashCode: stringHash,
        });
    }, props.Storages);
    const userStorageIds = map((s_2) => s_2.Id, filter((s_1) => contains(props.UserId, s_1.AssignedUserIds, {
        Equals: (x_1, y_1) => (x_1 === y_1),
        GetHashCode: stringHash,
    }), props.Storages));
    return filter((s_3) => contains(s_3.Id, userStorageIds, {
        Equals: equals,
        GetHashCode: safeHash,
    }), toolStorages);
}

export function DialogBody(props) {
    let elems_2, elems, elems_1, elems_3;
    const patternInput = useTranslation();
    const t = patternInput[0];
    const targetStorages_1 = targetStorages({
        Storages: props.Storages,
        Tool: props.Tool,
        UserId: props.UserId,
    });
    const selectStorageProps = map((storage) => ({
        label: storage.Name,
        value: storage,
    }), targetStorages_1);
    if (isEmpty(targetStorages_1)) {
        return createElement("div", createObj(singleton((elems_2 = [createElement("strong", createObj(singleton((elems = [t("tool.not_responsible_storage_msg ")], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])))), createElement("p", createObj(singleton((elems_1 = [t("tool.not_responsible_storage_info_msg")], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))]))));
    }
    else {
        return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-4"])], (elems_3 = toList(delay(() => append(singleton_1(createElement(Select, {
            ComponentState: props.ComponentState,
            IsClearable: false,
            Label: t("tool.switch_storage_target"),
            NoOptionsMessage: "",
            OnChange: props.SetSelectedStorage,
            Options: selectStorageProps,
            PlaceholderKey: "",
            TestId: "unassign-toolwithquantity-storage-target-input-test-id",
            Value: unwrap(props.SelectedStorage),
        })), delay(() => append(singleton_1(createElement(GroupedSelect, (($value) => { $value.key = $value.Key; return $value; })({
            ComponentState: props.ComponentState,
            IsClearable: false,
            Key: "assign-tool-select",
            Label: t("tool.choose_sender"),
            NoOptionsMessage: "select.employee_no_options",
            OnChange: props.SetSelectedReceiver,
            Options: map((tupledArg) => {
                const receiverType = tupledArg[0];
                const receivers_1 = toArray(sortBy((receiver_2) => receiver_2.label.toLowerCase(), map((receiver_1) => {
                    const matchValue = receiver_1.LocationName;
                    if (matchValue != null) {
                        const locationName = matchValue;
                        if (equals(receiverType, new ReceiverTypeName(4, []))) {
                            return {
                                label: `${locationName} - ${receiver_1.Name}`,
                                value: receiver_1,
                            };
                        }
                        else {
                            return {
                                label: `${receiver_1.Name} - ${locationName}`,
                                value: receiver_1,
                            };
                        }
                    }
                    else {
                        return {
                            label: `${receiver_1.Name}`,
                            value: receiver_1,
                        };
                    }
                }, tupledArg[1]), {
                    Compare: comparePrimitives,
                }));
                return {
                    label: Helper_receiverTypeNameTranslation(t, receiverType),
                    options: receivers_1,
                };
            }, List_groupBy((receiver) => receiver.ReceiverType, props.Receivers, {
                Equals: equals,
                GetHashCode: safeHash,
            })),
            PlaceholderKey: "select.employee_placeholder",
            Value: unwrap(props.SelectedReceiver),
        }))), delay(() => append(singleton_1(createElement(TextArea, {
            ComponentState: props.ComponentState,
            Label: t("general.comment"),
            OnChange: props.SetComment,
            TestId: "unassign-toolwithquantity-comment-input-test-id",
            Value: props.Comment,
        })), delay(() => {
            let Label_3, Value_3, matchValue_1, arg_2;
            return append(singleton_1(createElement(NumberInput, (Label_3 = t("general.quantity_3"), (Value_3 = map_1((value_6) => value_6, props.Quantity), {
                ComponentState: props.ComponentState,
                Label: unwrap(Label_3),
                MaxValue: numberInputIntMaxValue,
                OnChange: (arg) => {
                    props.SetQuantity(map_1((value_7) => ~~value_7, arg));
                },
                TestId: "unassign-toolwithquantity-number-input-test-id",
                ValidationMessage: unwrap(props.IsQuantityInvalid ? t("tool.dialog_assign_quantity_empty_validation") : ((matchValue_1 = props.IsUserStockInvalid, (matchValue_1 == null) ? (props.IsQuantityInvalid ? t("tool.dialog_assign_quantity_empty_validation") : undefined) : ((arg_2 = {
                    quantity: matchValue_1,
                }, patternInput[1].t("tool.dialog_unassign_tool_validation", arg_2)))))),
                Value: unwrap(Value_3),
            })))), delay(() => {
                const matchValue_2 = FormState__getValidation(props.FormState, t, "global");
                if (matchValue_2 == null) {
                    return empty();
                }
                else {
                    return singleton_1(createElement(ErrorAlert, {
                        Label: matchValue_2,
                    }));
                }
            }));
        })))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
    }
}

export function UnassignToolWithQuantityDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init({
        OnClose: props.OnClose,
        Storages: props.Storages,
        SuccessCallback: props.SuccessCallback,
        Tool: props.Tool,
        UserId: props.UserId,
    });
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    const componentState = state_1.FormState.IsLoading ? "disabled" : "enabled";
    return createElement(Dialog, (Title = t("tool.unassign_tool"), {
        Body: toList(delay(() => {
            const matchValue = state_1.Receivers;
            return (matchValue.tag === 1) ? singleton_1(createElement(DialogBody, {
                Comment: state_1.Dto.Comment,
                ComponentState: componentState,
                FormState: state_1.FormState,
                IsQuantityInvalid: state_1.IsQuantityInvalid,
                IsUserStockInvalid: unwrap(state_1.IsUserStockInvalid),
                Quantity: unwrap(state_1.Quantity),
                Receivers: matchValue.fields[0],
                SelectedReceiver: unwrap(state_1.SelectedReceiver),
                SelectedStorage: unwrap(state_1.SelectedStorage),
                SetComment: (value_1) => {
                    dispatch(new Msg(0, [value_1]));
                },
                SetQuantity: (value) => {
                    dispatch(new Msg(1, [value]));
                },
                SetSelectedReceiver: (value_2) => {
                    dispatch(new Msg(2, [value_2]));
                },
                SetSelectedStorage: (value_3) => {
                    dispatch(new Msg(3, [value_3]));
                },
                Storages: state_1.Storages,
                Tool: state_1.Tool,
                UserId: state_1.UserId,
            })) : singleton_1(createElement(Skeleton, {
                Variant: "normal",
            }));
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: componentState,
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-unassign-tool-with-quantity-dialog-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: componentState,
            Label: t("general.save"),
            OnClick: () => {
                dispatch(new Msg(5, []));
            },
            TestId: "save-unassign-tool-with-quantity-dialog-test-id",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

