import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormContainer } from "../../Components/FormContainer.js";
import { empty, singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { Input } from "../../Components/Input.js";
import { Msg } from "./Types.js";
import { unwrap } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { FormState__getValidation } from "../../Shared/Shared.js";
import { NewReceiverCustomProperties } from "../../Widgets/CustomProperties.js";
import { PropertyConfigurationVisibility } from "../../Shared/PropertyConfiguration.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { initEdit, update as update_1, initNew } from "./State.js";
import { RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Skeleton } from "../../Components/Skeleton.js";
import { unwrapUserId } from "../../Shared/Helper.js";

export function SchoolClassForm(props) {
    const t = useTranslation()[0];
    const componentState = props.FormState.IsLoading ? "disabled" : "enabled";
    return createElement(FormContainer, {
        Children: toList(delay(() => append(singleton(createElement(Input, {
            ComponentState: componentState,
            Label: t("general.name"),
            OnChange: (arg) => {
                props.Dispatch(new Msg(0, [arg]));
            },
            TestId: "",
            ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "name")),
            Value: props.SchoolClass.Name,
        })), delay(() => append(singleton(createElement(Input, {
            ComponentState: componentState,
            Label: t("general.description"),
            OnChange: (arg_1) => {
                props.Dispatch(new Msg(1, [arg_1]));
            },
            TestId: "",
            ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "description")),
            Value: props.SchoolClass.Description,
        })), delay(() => (props.IsCustomPropertiesEnabled ? singleton(createElement(NewReceiverCustomProperties, {
            CustomProperties: props.SchoolClass.CustomProperties,
            FormState: props.FormState,
            OnSetCustomProperty: (arg_2) => {
                props.Dispatch(new Msg(2, [arg_2]));
            },
            PropertyConfigurationType: new PropertyConfigurationVisibility(11, []),
            PropertyConfigurations: props.PropertyConfigurations,
        })) : empty()))))))),
        Header: props.Header,
        OnBack: props.OnBack,
        OnSave: props.OnSave,
    });
}

export function NewSchoolClassView() {
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(initNew, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useTranslation();
    const matchValue = state_1.Form;
    const matchValue_1 = state_1.IsCustomPropertiesEnabled;
    const matchValue_2 = state_1.PropertyConfigurations;
    let matchResult, isCustomPropertiesEnabled, propertyConfigurations, schoolClass;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_2.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        isCustomPropertiesEnabled = matchValue_1.fields[0];
        propertyConfigurations = matchValue_2.fields[0];
        schoolClass = matchValue.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement(SchoolClassForm, {
                Dispatch: dispatch,
                FormState: state_1.FormState,
                Header: patternInput_1[0]("school_class.create_new_school_class"),
                IsCustomPropertiesEnabled: isCustomPropertiesEnabled,
                OnBack: () => {
                    RouterModule_nav(ofArray(["receivers", "school-classes"]), 1, 1);
                },
                OnSave: () => {
                    dispatch(new Msg(3, []));
                },
                PropertyConfigurations: propertyConfigurations,
                SchoolClass: schoolClass,
            });
        default:
            return createElement(Skeleton, {
                Variant: "detail",
            });
    }
}

export function EditSchoolClassView(props) {
    let patternInput;
    const init = initEdit(props.SchoolClassId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, [props.SchoolClassId]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useTranslation();
    const matchValue = state_1.Form;
    const matchValue_1 = state_1.IsCustomPropertiesEnabled;
    const matchValue_2 = state_1.PropertyConfigurations;
    let matchResult, isCustomPropertiesEnabled, propertyConfigurations, schoolClass;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_2.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        isCustomPropertiesEnabled = matchValue_1.fields[0];
        propertyConfigurations = matchValue_2.fields[0];
        schoolClass = matchValue.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement(SchoolClassForm, {
                Dispatch: dispatch,
                FormState: state_1.FormState,
                Header: patternInput_1[0]("school_class.edit_school_class"),
                IsCustomPropertiesEnabled: isCustomPropertiesEnabled,
                OnBack: () => {
                    RouterModule_nav(ofArray(["receivers", unwrapUserId(props.SchoolClassId)]), 1, 1);
                },
                OnSave: () => {
                    dispatch(new Msg(4, []));
                },
                PropertyConfigurations: propertyConfigurations,
                SchoolClass: schoolClass,
            });
        default:
            return createElement(Skeleton, {
                Variant: "detail",
            });
    }
}

