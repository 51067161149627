import { now } from "../../fable_modules/fable-library-js.4.19.2/Date.js";
import { CreateOrUpdateStocktakingDto_$reflection, CreateOrUpdateStocktakingDto, StocktakingTypeScope, StocktakingLocationScope } from "../../Shared/Stocktaking.js";
import { contains, filter, map, append, singleton, empty, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { FormState__setValidationResponse_5219762A, FormState__startLoading, FormState_get_empty, RequestedValue$1 } from "../../Shared/Shared.js";
import { Msg, State } from "./Types.js";
import { Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { Receiver_$reflection, User_$reflection } from "../../Shared/User.js";
import { string_type, list_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { Storage_$reflection } from "../../Shared/Storage.js";
import { getStocktakingRaw } from "../../Requests/Stocktaking.js";
import { fetchWithDecoder as fetchWithDecoder_1, addPrefix as addPrefix_1 } from "../../CommunicationV2.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { unwrapStocktakingId } from "../../Shared/Helper.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";
import { Cmd_ofEffect } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { stringHash } from "../../fable_modules/fable-library-js.4.19.2/Util.js";

export const emptyStocktaking = new CreateOrUpdateStocktakingDto("", "", now(), now(), new StocktakingLocationScope(0, []), ofArray([new StocktakingTypeScope(0, []), new StocktakingTypeScope(1, []), new StocktakingTypeScope(2, [])]), false, empty(), empty());

export const initialModel = new State(undefined, new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), FormState_get_empty());

export const initNewView = [new State(initialModel.StocktakingId, new RequestedValue$1(1, [emptyStocktaking]), initialModel.Users, initialModel.Storages, initialModel.Receiver, initialModel.FormState), Cmd_batch(ofArray([Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
    const pr = response.arrayBuffer();
    return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(User_$reflection())));
})))), "/api/receivers/users", (Item) => (new Msg(16, [Item])), (Item_1) => (new Msg(19, [Item_1]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
    const pr_1 = response_1.arrayBuffer();
    return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(Storage_$reflection())));
})))), "/api/storages", (Item_2) => (new Msg(17, [Item_2])), (Item_3) => (new Msg(19, [Item_3]))), Cmd_OfPromise_either((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_6), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
    const pr_2 = response_2.arrayBuffer();
    return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), list_type(Receiver_$reflection())));
})))), "/api/receivers", (Item_4) => (new Msg(18, [Item_4])), (Item_5) => (new Msg(19, [Item_5])))]))];

export function initEditStocktaking(stocktakingId) {
    return [new State(stocktakingId, initialModel.StocktakingForm, initialModel.Users, initialModel.Storages, initialModel.Receiver, initialModel.FormState), Cmd_batch(ofArray([Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(User_$reflection())));
    })))), "/api/receivers/users", (Item) => (new Msg(16, [Item])), (Item_1) => (new Msg(19, [Item_1]))), getStocktakingRaw(stocktakingId, (Item_2) => (new Msg(0, [Item_2])), (Item_3) => (new Msg(19, [Item_3]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(Storage_$reflection())));
    })))), "/api/storages", (Item_4) => (new Msg(17, [Item_4])), (Item_5) => (new Msg(19, [Item_5]))), Cmd_OfPromise_either((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_6), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
        const pr_2 = response_2.arrayBuffer();
        return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), list_type(Receiver_$reflection())));
    })))), "/api/receivers", (Item_6) => (new Msg(18, [Item_6])), (Item_7) => (new Msg(19, [Item_7])))]))];
}

export function initCopyStocktaking(stocktakingId) {
    return [new State(stocktakingId, initialModel.StocktakingForm, initialModel.Users, initialModel.Storages, initialModel.Receiver, initialModel.FormState), Cmd_batch(ofArray([Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(User_$reflection())));
    })))), "/api/receivers/users", (Item) => (new Msg(16, [Item])), (Item_1) => (new Msg(19, [Item_1]))), getStocktakingRaw(stocktakingId, (Item_2) => (new Msg(1, [Item_2])), (Item_3) => (new Msg(19, [Item_3]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(Storage_$reflection())));
    })))), "/api/storages", (Item_4) => (new Msg(17, [Item_4])), (Item_5) => (new Msg(19, [Item_5]))), Cmd_OfPromise_either((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_6), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
        const pr_2 = response_2.arrayBuffer();
        return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), list_type(Receiver_$reflection())));
    })))), "/api/receivers", (Item_6) => (new Msg(18, [Item_6])), (Item_7) => (new Msg(19, [Item_7])))]))];
}

export function update(msg, state) {
    let body_3, xs;
    switch (msg.tag) {
        case 0: {
            const stocktaking_1 = msg.fields[0];
            return [new State(state.StocktakingId, new RequestedValue$1(1, [new CreateOrUpdateStocktakingDto(stocktaking_1.Name, stocktaking_1.Description, stocktaking_1.StartDate, stocktaking_1.EndDate, stocktaking_1.LocationScope, stocktaking_1.TypeScope, stocktaking_1.ToolsCanBeTaken, stocktaking_1.HelperIds, stocktaking_1.ResponsiblePersonIds)]), state.Users, state.Storages, state.Receiver, state.FormState), Cmd_none()];
        }
        case 16:
            return [new State(state.StocktakingId, state.StocktakingForm, new RequestedValue$1(1, [msg.fields[0]]), state.Storages, state.Receiver, state.FormState), Cmd_none()];
        case 2: {
            const matchValue = state.StocktakingForm;
            if (matchValue.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                return [new State(state.StocktakingId, state.StocktakingForm, state.Users, state.Storages, state.Receiver, FormState__startLoading(state.FormState)), Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    let url_2;
                    return ((url_2 = addPrefix_1(tupledArg[0]), (refreshOn) => fetchWithDecoder_1(url_2, ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
                        const pr = response.arrayBuffer();
                        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
                    }, refreshOn)))(true);
                })), ["/api/stocktakings", toString(0, Auto_generateBoxedEncoder_437914C6(CreateOrUpdateStocktakingDto_$reflection(), undefined, undefined, undefined)(matchValue.fields[0]))], (Item) => (new Msg(4, [Item])), (Item_1) => (new Msg(19, [Item_1])))];
            }
        }
        case 3: {
            const matchValue_1 = state.StocktakingId;
            const matchValue_2 = state.StocktakingForm;
            let matchResult, stocktakingForm_1, stocktakingId;
            if (matchValue_1 == null) {
                matchResult = 1;
            }
            else if (matchValue_2.tag === 0) {
                matchResult = 1;
            }
            else {
                matchResult = 0;
                stocktakingForm_1 = matchValue_2.fields[0];
                stocktakingId = matchValue_1;
            }
            switch (matchResult) {
                case 0:
                    return [new State(state.StocktakingId, state.StocktakingForm, state.Users, state.Storages, state.Receiver, FormState__startLoading(state.FormState)), (body_3 = toString(0, Auto_generateBoxedEncoder_437914C6(CreateOrUpdateStocktakingDto_$reflection(), undefined, undefined, undefined)(stocktakingForm_1)), Cmd_OfPromise_either((tupledArg_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        let url_5;
                        return ((url_5 = addPrefix_1(tupledArg_1[0]), (refreshOn_1) => fetchWithDecoder_1(url_5, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg_1[1]])]), "application/json", (response_1) => {
                            const pr_1 = response_1.arrayBuffer();
                            return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), string_type));
                        }, refreshOn_1)))(true);
                    })), [`/api/stocktakings/${unwrapStocktakingId(stocktakingId)}`, body_3], (Item_2) => (new Msg(4, [Item_2])), (Item_3) => (new Msg(19, [Item_3]))))];
                default:
                    return [state, Cmd_none()];
            }
        }
        case 4: {
            const response_2 = msg.fields[0];
            if (response_2.tag === 1) {
                return [new State(state.StocktakingId, state.StocktakingForm, state.Users, state.Storages, state.Receiver, FormState__setValidationResponse_5219762A(state.FormState, response_2.fields[0])), Cmd_errorToast("validation.toast")];
            }
            else {
                return [new State(state.StocktakingId, state.StocktakingForm, state.Users, state.Storages, state.Receiver, FormState_get_empty()), Cmd_batch(ofArray([Cmd_successToast("stocktaking.success_created_stocktaking"), (xs = ["stocktaking", response_2.fields[0]], Cmd_ofEffect((_arg) => {
                    RouterModule_nav(ofArray(xs), 1, 1);
                }))]))];
            }
        }
        case 5: {
            const matchValue_4 = state.StocktakingForm;
            if (matchValue_4.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const stocktakingForm_2 = matchValue_4.fields[0];
                return [new State(state.StocktakingId, new RequestedValue$1(1, [new CreateOrUpdateStocktakingDto(msg.fields[0], stocktakingForm_2.Description, stocktakingForm_2.StartDate, stocktakingForm_2.EndDate, stocktakingForm_2.LocationScope, stocktakingForm_2.TypeScope, stocktakingForm_2.ToolsCanBeTaken, stocktakingForm_2.HelperIds, stocktakingForm_2.ResponsiblePersonIds)]), state.Users, state.Storages, state.Receiver, state.FormState), Cmd_none()];
            }
        }
        case 6: {
            const matchValue_5 = state.StocktakingForm;
            if (matchValue_5.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const stocktakingForm_3 = matchValue_5.fields[0];
                return [new State(state.StocktakingId, new RequestedValue$1(1, [new CreateOrUpdateStocktakingDto(stocktakingForm_3.Name, stocktakingForm_3.Description, msg.fields[0], stocktakingForm_3.EndDate, stocktakingForm_3.LocationScope, stocktakingForm_3.TypeScope, stocktakingForm_3.ToolsCanBeTaken, stocktakingForm_3.HelperIds, stocktakingForm_3.ResponsiblePersonIds)]), state.Users, state.Storages, state.Receiver, state.FormState), Cmd_none()];
            }
        }
        case 7: {
            const matchValue_6 = state.StocktakingForm;
            if (matchValue_6.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const stocktakingForm_4 = matchValue_6.fields[0];
                return [new State(state.StocktakingId, new RequestedValue$1(1, [new CreateOrUpdateStocktakingDto(stocktakingForm_4.Name, stocktakingForm_4.Description, stocktakingForm_4.StartDate, msg.fields[0], stocktakingForm_4.LocationScope, stocktakingForm_4.TypeScope, stocktakingForm_4.ToolsCanBeTaken, stocktakingForm_4.HelperIds, stocktakingForm_4.ResponsiblePersonIds)]), state.Users, state.Storages, state.Receiver, state.FormState), Cmd_none()];
            }
        }
        case 8: {
            const matchValue_7 = state.StocktakingForm;
            if (matchValue_7.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const stocktakingForm_5 = matchValue_7.fields[0];
                return [new State(state.StocktakingId, new RequestedValue$1(1, [new CreateOrUpdateStocktakingDto(stocktakingForm_5.Name, msg.fields[0], stocktakingForm_5.StartDate, stocktakingForm_5.EndDate, stocktakingForm_5.LocationScope, stocktakingForm_5.TypeScope, stocktakingForm_5.ToolsCanBeTaken, stocktakingForm_5.HelperIds, stocktakingForm_5.ResponsiblePersonIds)]), state.Users, state.Storages, state.Receiver, state.FormState), Cmd_none()];
            }
        }
        case 11: {
            const matchValue_8 = state.StocktakingForm;
            if (matchValue_8.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const stocktakingForm_6 = matchValue_8.fields[0];
                return [new State(state.StocktakingId, new RequestedValue$1(1, [new CreateOrUpdateStocktakingDto(stocktakingForm_6.Name, stocktakingForm_6.Description, stocktakingForm_6.StartDate, stocktakingForm_6.EndDate, stocktakingForm_6.LocationScope, stocktakingForm_6.TypeScope, msg.fields[0], stocktakingForm_6.HelperIds, stocktakingForm_6.ResponsiblePersonIds)]), state.Users, state.Storages, state.Receiver, state.FormState), Cmd_none()];
            }
        }
        case 12: {
            const matchValue_9 = state.StocktakingForm;
            if (matchValue_9.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const stocktakingForm_7 = matchValue_9.fields[0];
                return [new State(state.StocktakingId, new RequestedValue$1(1, [new CreateOrUpdateStocktakingDto(stocktakingForm_7.Name, stocktakingForm_7.Description, stocktakingForm_7.StartDate, stocktakingForm_7.EndDate, stocktakingForm_7.LocationScope, stocktakingForm_7.TypeScope, stocktakingForm_7.ToolsCanBeTaken, append(map((r) => r.Id, msg.fields[0]), stocktakingForm_7.HelperIds), stocktakingForm_7.ResponsiblePersonIds)]), state.Users, state.Storages, state.Receiver, state.FormState), Cmd_none()];
            }
        }
        case 13: {
            const matchValue_10 = state.StocktakingForm;
            if (matchValue_10.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const stocktakingForm_8 = matchValue_10.fields[0];
                const removedReceiverIds = map((receiver) => receiver.Id, msg.fields[0]);
                return [new State(state.StocktakingId, new RequestedValue$1(1, [new CreateOrUpdateStocktakingDto(stocktakingForm_8.Name, stocktakingForm_8.Description, stocktakingForm_8.StartDate, stocktakingForm_8.EndDate, stocktakingForm_8.LocationScope, stocktakingForm_8.TypeScope, stocktakingForm_8.ToolsCanBeTaken, filter((helperId) => !contains(helperId, removedReceiverIds, {
                    Equals: (x_2, y) => (x_2 === y),
                    GetHashCode: stringHash,
                }), stocktakingForm_8.HelperIds), stocktakingForm_8.ResponsiblePersonIds)]), state.Users, state.Storages, state.Receiver, state.FormState), Cmd_none()];
            }
        }
        case 14: {
            const matchValue_11 = state.StocktakingForm;
            if (matchValue_11.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const stocktakingForm_9 = matchValue_11.fields[0];
                const removedReceiverIds_1 = map((receiver_1) => receiver_1.Id, msg.fields[0]);
                return [new State(state.StocktakingId, new RequestedValue$1(1, [new CreateOrUpdateStocktakingDto(stocktakingForm_9.Name, stocktakingForm_9.Description, stocktakingForm_9.StartDate, stocktakingForm_9.EndDate, stocktakingForm_9.LocationScope, stocktakingForm_9.TypeScope, stocktakingForm_9.ToolsCanBeTaken, stocktakingForm_9.HelperIds, filter((responsiblePersonId) => !contains(responsiblePersonId, removedReceiverIds_1, {
                    Equals: (x_3, y_1) => (x_3 === y_1),
                    GetHashCode: stringHash,
                }), stocktakingForm_9.ResponsiblePersonIds))]), state.Users, state.Storages, state.Receiver, state.FormState), Cmd_none()];
            }
        }
        case 15: {
            const matchValue_12 = state.StocktakingForm;
            if (matchValue_12.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const stocktakingForm_10 = matchValue_12.fields[0];
                return [new State(state.StocktakingId, new RequestedValue$1(1, [new CreateOrUpdateStocktakingDto(stocktakingForm_10.Name, stocktakingForm_10.Description, stocktakingForm_10.StartDate, stocktakingForm_10.EndDate, stocktakingForm_10.LocationScope, stocktakingForm_10.TypeScope, stocktakingForm_10.ToolsCanBeTaken, stocktakingForm_10.HelperIds, append(map((r_1) => r_1.Id, msg.fields[0]), stocktakingForm_10.ResponsiblePersonIds))]), state.Users, state.Storages, state.Receiver, state.FormState), Cmd_none()];
            }
        }
        case 9: {
            const matchValue_13 = state.StocktakingForm;
            if (matchValue_13.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const stocktakingForm_11 = matchValue_13.fields[0];
                return [new State(state.StocktakingId, new RequestedValue$1(1, [new CreateOrUpdateStocktakingDto(stocktakingForm_11.Name, stocktakingForm_11.Description, stocktakingForm_11.StartDate, stocktakingForm_11.EndDate, msg.fields[0], stocktakingForm_11.TypeScope, stocktakingForm_11.ToolsCanBeTaken, stocktakingForm_11.HelperIds, stocktakingForm_11.ResponsiblePersonIds)]), state.Users, state.Storages, state.Receiver, state.FormState), Cmd_none()];
            }
        }
        case 10: {
            const matchValue_14 = state.StocktakingForm;
            if (matchValue_14.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const stocktakingForm_12 = matchValue_14.fields[0];
                return [new State(state.StocktakingId, new RequestedValue$1(1, [new CreateOrUpdateStocktakingDto(stocktakingForm_12.Name, stocktakingForm_12.Description, stocktakingForm_12.StartDate, stocktakingForm_12.EndDate, stocktakingForm_12.LocationScope, msg.fields[0], stocktakingForm_12.ToolsCanBeTaken, stocktakingForm_12.HelperIds, stocktakingForm_12.ResponsiblePersonIds)]), state.Users, state.Storages, state.Receiver, state.FormState), Cmd_none()];
            }
        }
        case 17:
            return [new State(state.StocktakingId, state.StocktakingForm, state.Users, new RequestedValue$1(1, [msg.fields[0]]), state.Receiver, state.FormState), Cmd_none()];
        case 18:
            return [new State(state.StocktakingId, state.StocktakingForm, state.Users, state.Storages, new RequestedValue$1(1, [msg.fields[0]]), state.FormState), Cmd_none()];
        case 19:
            return [state, Cmd_none()];
        default: {
            const stocktaking = msg.fields[0];
            return [new State(state.StocktakingId, new RequestedValue$1(1, [new CreateOrUpdateStocktakingDto(stocktaking.Name, stocktaking.Description, stocktaking.StartDate, stocktaking.EndDate, stocktaking.LocationScope, stocktaking.TypeScope, stocktaking.ToolsCanBeTaken, stocktaking.HelperIds, stocktaking.ResponsiblePersonIds)]), state.Users, state.Storages, state.Receiver, state.FormState), Cmd_none()];
        }
    }
}

