import { isEmpty, singleton as singleton_1, tryFind, ofArray, contains, filter, map, toArray } from "../../../fable_modules/fable-library-js.4.19.2/List.js";
import { multiSelectWithLabel, toNoOptionsMessage, CommonPropsMultiSelect$1, OptionType$1 } from "../../../SharedComponents/ReactSelect.js";
import { LabelId, StorageId__get_unwrap, FormState__isValid, FormState__getValidation, LabelId__get_unwrap } from "../../../Shared/Shared.js";
import { createObj, stringHash } from "../../../fable_modules/fable-library-js.4.19.2/Util.js";
import { Msg } from "./Types.js";
import { createElement } from "react";
import React from "react";
import { PageHeader, SectionHeader } from "../../../Components/Text.js";
import { isNullOrWhiteSpace, join } from "../../../fable_modules/fable-library-js.4.19.2/String.js";
import { empty, singleton, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { Interop_reactApi } from "../../../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { useTranslation } from "react-i18next";
import { useReact_useMemo_10C6A43C, useFeliz_React__React_useState_Static_1505 } from "../../../fable_modules/Feliz.2.7.0/React.fs.js";
import { AreaAnchorMenu } from "../../../Widgets/AnchorMenu.js";
import { NewCategoryDialog } from "../../../Widgets/Dialogs/NewCategoryDialog.js";
import { InformationAlert, ErrorAlert } from "../../../Components/Alert.js";
import { Feature, isFeatureEnabled } from "../../../FeatureFlip.js";
import { FormImageSection } from "../../../Widgets/FormSection.js";
import { map as map_1, value as value_20, unwrap } from "../../../fable_modules/fable-library-js.4.19.2/Option.js";
import { CurrencyInput, NumberInput, Input, AutoSuggestInput } from "../../../Components/Input.js";
import { TextArea } from "../../../Components/TextArea.js";
import { Select } from "../../../Components/Select.js";
import { getQueryParams, numberInputIntMaxValue } from "../../../Common.js";
import { Button, TextButton } from "../../../Components/Button.js";
import { DatePicker } from "../../../Components/DatePicker.js";
import { NewInventoryCustomProperties } from "../../../Widgets/CustomProperties.js";
import { parse } from "../../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { initCopyNewToolWithQuantity, initEditView, update as update_1, initNewToolWithQuantityView } from "./State.js";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import Arrow_Left_1 from "../../../../public/assets/icons/Arrow_Left_1.svg";
import { RouterModule_encodeQueryString, RouterModule_nav } from "../../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { Skeleton } from "../../../Components/Skeleton.js";
import { unwrapToolId } from "../../../Shared/Helper.js";

function selectLabelProps(categoryIds, labels, dispatch, isDisabled) {
    return [new CommonPropsMultiSelect$1(0, [toArray(map((l_1) => (new OptionType$1(l_1.Name, l_1.Id)), filter((l) => !contains(LabelId__get_unwrap(l.Id), categoryIds, {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    }), labels)))]), new CommonPropsMultiSelect$1(2, [(arg) => {
        dispatch(new Msg(8, [arg]));
    }]), new CommonPropsMultiSelect$1(5, [true]), new CommonPropsMultiSelect$1(9, [false]), new CommonPropsMultiSelect$1(8, [true]), new CommonPropsMultiSelect$1(6, ["select.label_placeholder"]), new CommonPropsMultiSelect$1(11, [(_arg) => toNoOptionsMessage("select.label_no_options", _arg)]), new CommonPropsMultiSelect$1(12, ["flex-grow-1"]), new CommonPropsMultiSelect$1(7, [isDisabled]), new CommonPropsMultiSelect$1(3, [toArray(map((l_3) => (new OptionType$1(l_3.Name, l_3.Id)), filter((l_2) => contains(LabelId__get_unwrap(l_2.Id), categoryIds, {
        Equals: (x_1, y_1) => (x_1 === y_1),
        GetHashCode: stringHash,
    }), labels)))])];
}

export function FormSection(props) {
    let elems_1, elems;
    return createElement("div", createObj(ofArray([["id", props.Id], (elems_1 = [createElement(SectionHeader, {
        Text: props.Header,
    }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-5"])], (elems = toList(delay(() => props.Content)), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export const scrollOptions = new (class {
    get behavior() {
        return "smooth";
    }
    set behavior(_arg) {
    }
    get block() {
        return "center";
    }
    set block(_arg_1) {
    }
    get inline() {
        return "center";
    }
    set inline(_arg_2) {
    }
}
)();

export function ToolWithQuantityForm(props) {
    let elems_4, elems, elems_3;
    const t = useTranslation()[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setisNewCategoryFormOpen = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505("tool-with-quantity-masterdata-section");
    const formState = props.FormState.IsLoading ? "disabled" : "enabled";
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-[10rem,1fr]", "mt-12", "max-w-5xl"])], (elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", ["top-5"])], (elems = [createElement(AreaAnchorMenu, {
        Anchors: toList(delay(() => append(singleton({
            Id: "tool-with-quantity-masterdata-section",
            Label: t("general.master_data"),
        }), delay(() => append(!props.IsEdit ? singleton({
            Id: "tool-with-quantity-storage-section",
            Label: t("general.storage"),
        }) : empty(), delay(() => append(singleton({
            Id: "tool-with-quantity-label-section",
            Label: t("tool.label"),
        }), delay(() => append(singleton({
            Id: "tool-with-quantity-stock-section",
            Label: t("tool.commercial_data"),
        }), delay(() => (props.IsCustomPropertiesEnabled ? singleton({
            Id: "tool-with-quantity-nav-header",
            Label: t("tool.custom_properties"),
        }) : empty()))))))))))),
        OnSelectAnchor: patternInput_2[1],
        SelectedAnchor: patternInput_2[0],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["pl-3", "pb-3", "flex", "flex-col", "gap-9"])], (elems_3 = toList(delay(() => append(singleton(createElement(NewCategoryDialog, {
        IsOpen: patternInput_1[0],
        OnClose: () => {
            setisNewCategoryFormOpen(false);
            props.Dispatch(new Msg(27, []));
        },
    })), delay(() => {
        let matchValue;
        return append((matchValue = FormState__getValidation(props.FormState, t, "global"), (matchValue == null) ? (empty()) : singleton(createElement(ErrorAlert, {
            Label: matchValue,
        }))), delay(() => append(singleton(createElement(InformationAlert, {
            Label: t("tool.create_tool_validation_info"),
        })), delay(() => {
            let Header;
            return append(singleton(createElement(FormSection, (Header = t("general.master_data"), {
                Content: toList(delay(() => append(isFeatureEnabled(new Feature(5, [])) ? (!props.IsEdit ? singleton(createElement(FormImageSection, {
                    FormState: formState,
                    SelectedFile: unwrap(props.SelectedFile),
                    SetSelectedFile: (file) => {
                        props.Dispatch(new Msg(28, [file]));
                    },
                })) : empty()) : empty(), delay(() => {
                    let matchValue_2;
                    return append(singleton(useReact_useMemo_10C6A43C(() => {
                        let Label, Value, matchValue_1;
                        return createElement(AutoSuggestInput, (Label = t("tool.manufacturer"), (Value = ((matchValue_1 = props.ToolWithQuantity, (matchValue_1.tag === 1) ? matchValue_1.fields[0].Manufacturer : matchValue_1.fields[0].Manufacturer)), {
                            ComponentState: formState,
                            Label: unwrap(Label),
                            OnChange: (arg) => {
                                props.Dispatch(new Msg(0, [arg]));
                            },
                            Suggestions: props.Manufacturers,
                            TestId: "tool-with-quantity-form-manufactur-tool-id",
                            ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "manufacturer")),
                            Value: Value,
                        })));
                    }, [(matchValue_2 = props.ToolWithQuantity, (matchValue_2.tag === 1) ? matchValue_2.fields[0].Manufacturer : matchValue_2.fields[0].Manufacturer), props.Manufacturers, formState, () => FormState__isValid(props.FormState)])), delay(() => {
                        let Label_1, Value_1, matchValue_3;
                        return append(singleton(createElement(Input, (Label_1 = t("tool.model"), (Value_1 = ((matchValue_3 = props.ToolWithQuantity, (matchValue_3.tag === 1) ? matchValue_3.fields[0].Model : matchValue_3.fields[0].Model)), {
                            ComponentState: formState,
                            Label: unwrap(Label_1),
                            OnChange: (arg_1) => {
                                props.Dispatch(new Msg(2, [arg_1]));
                            },
                            TestId: "tool-with-quantity-form-model-tool-id",
                            ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "model")),
                            Value: Value_1,
                        })))), delay(() => {
                            let Label_2, Value_2, matchValue_4;
                            return append(singleton(createElement(Input, (Label_2 = t("tool.displayname"), (Value_2 = ((matchValue_4 = props.ToolWithQuantity, (matchValue_4.tag === 1) ? matchValue_4.fields[0].DisplayName : matchValue_4.fields[0].DisplayName)), {
                                ComponentState: formState,
                                Label: unwrap(Label_2),
                                OnChange: (arg_2) => {
                                    props.Dispatch(new Msg(1, [arg_2]));
                                },
                                TestId: "tool-with-quantity-form-display-name-tool-id",
                                ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "displayName")),
                                Value: Value_2,
                            })))), delay(() => {
                                let matchValue_5;
                                return append(singleton(createElement(Input, {
                                    ComponentState: formState,
                                    Label: t("assign_multiple_tools_dialog.inventory_number"),
                                    OnChange: (arg_3) => {
                                        props.Dispatch(new Msg(3, [arg_3]));
                                    },
                                    TestId: "tool-with-quantity-form-inventory-number-tool-id",
                                    Value: (matchValue_5 = props.ToolWithQuantity, (matchValue_5.tag === 1) ? matchValue_5.fields[0].InventoryNumber : matchValue_5.fields[0].InventoryNumber),
                                })), delay(() => {
                                    let matchValue_6;
                                    return singleton(createElement(TextArea, {
                                        ComponentState: formState,
                                        Label: t("general.comment"),
                                        OnChange: (arg_4) => {
                                            props.Dispatch(new Msg(7, [arg_4]));
                                        },
                                        TestId: "tool-with-quantity-form-comment-tool-id",
                                        Value: (matchValue_6 = props.ToolWithQuantity, (matchValue_6.tag === 1) ? matchValue_6.fields[0].Comment : matchValue_6.fields[0].Comment),
                                    }));
                                }));
                            }));
                        }));
                    }));
                })))),
                Header: Header,
                Id: "tool-with-quantity-masterdata-section",
            }))), delay(() => {
                let Header_1;
                return append(!props.IsEdit ? singleton(createElement(FormSection, (Header_1 = t("general.storage"), {
                    Content: toList(delay(() => {
                        let Label_5, Options, NoOptionsMessage, Value_6, storage_2;
                        const storage = tryFind((s) => {
                            let matchValue_7;
                            return StorageId__get_unwrap(s.Id) === ((matchValue_7 = props.ToolWithQuantity, (matchValue_7.tag === 1) ? "" : matchValue_7.fields[0].StorageId));
                        }, props.Storages);
                        return append(singleton(createElement(Select, (Label_5 = t("general.storage"), (Options = map((storage_1) => ({
                            label: storage_1.Name,
                            value: storage_1.Id,
                        }), filter((s_1) => {
                            if (props.UserData.Role === "toolManager") {
                                return contains(props.UserData.UserId, s_1.AssignedUserIds, {
                                    Equals: (x, y) => (x === y),
                                    GetHashCode: stringHash,
                                });
                            }
                            else {
                                return true;
                            }
                        }, props.Storages)), (NoOptionsMessage = t("select.storage_no_options"), (Value_6 = ((storage == null) ? undefined : ((storage_2 = storage, {
                            label: storage_2.Name,
                            value: storage_2.Id,
                        }))), {
                            ComponentState: formState,
                            IsClearable: false,
                            Label: unwrap(Label_5),
                            NoOptionsMessage: NoOptionsMessage,
                            OnChange: (s_2) => {
                                props.Dispatch(new Msg(4, [value_20(s_2)]));
                            },
                            Options: Options,
                            PlaceholderKey: "",
                            TestId: "tool-with-quantity-form-storage-tool-id",
                            ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "storage")),
                            Value: unwrap(Value_6),
                        })))))), delay(() => {
                            let copyOfStruct_1, matchValue_8;
                            return append((!((copyOfStruct_1 = props.FormState, FormState__isValid(copyOfStruct_1))) && isNullOrWhiteSpace((matchValue_8 = props.ToolWithQuantity, (matchValue_8.tag === 1) ? "" : matchValue_8.fields[0].StorageId))) ? singleton(createElement("div", {
                                className: "invalid-feedback",
                                children: t("tool.invalid_storage"),
                            })) : empty(), delay(() => {
                                let matchValue_9;
                                return append(singleton(createElement(Input, {
                                    ComponentState: formState,
                                    Label: t("tool.storage_space"),
                                    OnChange: (arg_5) => {
                                        props.Dispatch(new Msg(5, [arg_5]));
                                    },
                                    TestId: "tool-with-quantity-form-storage-space-tool-id",
                                    Value: (matchValue_9 = props.ToolWithQuantity, (matchValue_9.tag === 1) ? "" : matchValue_9.fields[0].StorageSpace),
                                })), delay(() => {
                                    let Label_7, Value_8;
                                    return singleton(createElement(NumberInput, (Label_7 = t("tool.storage_stock"), (Value_8 = map_1((value_10) => value_10, props.StorageStock), {
                                        ComponentState: formState,
                                        Label: unwrap(Label_7),
                                        MaxValue: numberInputIntMaxValue,
                                        OnChange: (arg_7) => {
                                            props.Dispatch(new Msg(6, [map_1((value_11) => ~~value_11, arg_7)]));
                                        },
                                        TestId: "tool-with-quantity-form-storage-stock-tool-id",
                                        ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "storageStock")),
                                        Value: unwrap(Value_8),
                                    }))));
                                }));
                            }));
                        }));
                    })),
                    Header: Header_1,
                    Id: "tool-with-quantity-storage-section",
                }))) : empty(), delay(() => {
                    let Header_2, matchValue_10;
                    return append(singleton(createElement(FormSection, (Header_2 = t("tool.label"), {
                        Content: ofArray([multiSelectWithLabel(selectLabelProps((matchValue_10 = props.ToolWithQuantity, (matchValue_10.tag === 1) ? matchValue_10.fields[0].CategoryIds : matchValue_10.fields[0].CategoryIds), props.Labels, props.Dispatch, formState === "disabled"), t("tool.label"), ""), createElement(TextButton, {
                            ComponentState: formState,
                            Label: t("settings.create_new_label"),
                            OnClick: () => {
                                setisNewCategoryFormOpen(true);
                            },
                            TestId: "add-new-category-button",
                            Variant: "default",
                        })]),
                        Header: Header_2,
                        Id: "tool-with-quantity-label-section",
                    }))), delay(() => {
                        let Header_3, matchValue_11, matchValue_12, matchValue_13, matchValue_14;
                        return append(singleton(createElement(FormSection, (Header_3 = t("tool.commercial_data"), {
                            Content: ofArray([createElement(Input, {
                                ComponentState: formState,
                                Label: t("general.supplier"),
                                OnChange: (arg_8) => {
                                    props.Dispatch(new Msg(11, [arg_8]));
                                },
                                TestId: "tool-with-quantity-form-supplier-tool-id",
                                Value: (matchValue_11 = props.ToolWithQuantity, (matchValue_11.tag === 1) ? matchValue_11.fields[0].CommercialData.Supplier : matchValue_11.fields[0].CommercialData.Supplier),
                            }), createElement(Input, {
                                ComponentState: formState,
                                Label: t("general.supplier_article_number"),
                                OnChange: (arg_9) => {
                                    props.Dispatch(new Msg(12, [arg_9]));
                                },
                                TestId: "tool-with-quantity-form-article-number-tool-id",
                                Value: (matchValue_12 = props.ToolWithQuantity, (matchValue_12.tag === 1) ? matchValue_12.fields[0].CommercialData.SupplierArticleNumber : matchValue_12.fields[0].CommercialData.SupplierArticleNumber),
                            }), createElement(CurrencyInput, {
                                ComponentState: formState,
                                Label: t("general.purchase_price"),
                                OnChange: (price_1) => {
                                    props.Dispatch(new Msg(10, [price_1]));
                                },
                                TestId: "tool-with-quantity-form-purchase-price-tool-id",
                                Value: unwrap(map_1((price) => (price / 100), (matchValue_13 = props.ToolWithQuantity, (matchValue_13.tag === 1) ? matchValue_13.fields[0].CommercialData.PurchasePrice : matchValue_13.fields[0].CommercialData.PurchasePrice))),
                            }), createElement(DatePicker, {
                                ComponentState: formState,
                                IsClearable: true,
                                Label: t("general.purchase_date"),
                                OnChange: (date) => {
                                    props.Dispatch(new Msg(13, [date]));
                                },
                                TestId: "tool-with-quantity-form-purchase-date-tool-id",
                                Value: unwrap(props.PurchaseDate),
                            }), createElement(DatePicker, {
                                ComponentState: formState,
                                IsClearable: true,
                                Label: t("general.gurantee_date"),
                                OnChange: (date_1) => {
                                    props.Dispatch(new Msg(14, [date_1]));
                                },
                                TestId: "tool-with-quantity-form-gurantee-date-tool-id",
                                Value: unwrap(props.WarrantyDate),
                            }), createElement(TextArea, {
                                ComponentState: formState,
                                Label: t("general.comment"),
                                OnChange: (arg_10) => {
                                    props.Dispatch(new Msg(15, [arg_10]));
                                },
                                TestId: "tool-with-quantity-form-commercial-data-comment-tool-id",
                                Value: (matchValue_14 = props.ToolWithQuantity, (matchValue_14.tag === 1) ? matchValue_14.fields[0].CommercialData.Comment : matchValue_14.fields[0].CommercialData.Comment),
                            })]),
                            Header: Header_3,
                            Id: "tool-with-quantity-stock-section",
                        }))), delay(() => {
                            let Header_4, elems_1, matchValue_15, matchValue_16;
                            return append(props.IsCustomPropertiesEnabled ? singleton(createElement(FormSection, (Header_4 = t("tool.custom_properties"), {
                                Content: singleton_1(createElement("div", createObj(ofArray([["className", "form-group"], (elems_1 = [createElement(NewInventoryCustomProperties, {
                                    CustomProperties: (matchValue_15 = props.ToolWithQuantity, (matchValue_15.tag === 1) ? matchValue_15.fields[0].CustomProperties : matchValue_15.fields[0].CustomProperties),
                                    FormState: props.FormState,
                                    LabelIds: (matchValue_16 = props.ToolWithQuantity, (matchValue_16.tag === 1) ? map((arg_13) => (new LabelId(parse(arg_13))), matchValue_16.fields[0].CategoryIds) : map((arg_12) => (new LabelId(parse(arg_12))), matchValue_16.fields[0].CategoryIds)),
                                    OnSetCustomProperty: (arg_11) => {
                                        props.Dispatch(new Msg(9, [arg_11]));
                                    },
                                    PropertyConfigurations: props.PropertyConfigurations,
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))),
                                Header: Header_4,
                                Id: "tool-with-quantity-nav-header",
                            }))) : empty(), delay(() => {
                                let matchValue_17;
                                return append((matchValue_17 = FormState__getValidation(props.FormState, t, "global"), (matchValue_17 == null) ? (empty()) : singleton(createElement(ErrorAlert, {
                                    Label: matchValue_17,
                                }))), delay(() => {
                                    let elems_2;
                                    return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mt-3", "flex", "justify-end"])], (elems_2 = [createElement(Button, {
                                        ComponentState: formState,
                                        Label: t("general.save"),
                                        OnClick: props.OnSave,
                                        TestId: "tool-form-save-button",
                                        Variant: "primary",
                                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))));
                                }));
                            }));
                        }));
                    }));
                }));
            }));
        }))));
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])));
}

export function NewToolWithQantityView(props) {
    let elems_1, elems, Label;
    const patternInput = useTranslation();
    let patternInput_1;
    const init = initNewToolWithQuantityView(props.UserData);
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    const matchValue = state_1.ToolForm;
    const matchValue_1 = state_1.Storages;
    const matchValue_2 = state_1.Labels;
    const matchValue_3 = state_1.PropertyConfigurations;
    const matchValue_4 = state_1.IsCustomPropertiesEnabled;
    let matchResult, isCustomPropertiesEnabled, labels, propertyConfigurations, storages, tool;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_2.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_3.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_4.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        isCustomPropertiesEnabled = matchValue_4.fields[0];
        labels = matchValue_2.fields[0];
        propertyConfigurations = matchValue_3.fields[0];
        storages = matchValue_1.fields[0];
        tool = matchValue.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement("div", createObj(ofArray([["id", "tool-container"], ["className", "flex-grow-1 d-flex flex-column"], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["mb-3", "flex"])], (elems = [createElement(TextButton, (Label = patternInput[0]("general.back_to_overview"), {
                ComponentState: "enabled",
                Icon: Arrow_Left_1(),
                Label: Label,
                OnClick: () => {
                    RouterModule_nav(singleton_1("tools" + RouterModule_encodeQueryString(getQueryParams(undefined))), 1, 1);
                },
                TestId: "tools-new-back-to-overview-button",
                Variant: "default",
            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement(PageHeader, {
                Text: "tool.create_new_simple_tool",
            }), createElement(ToolWithQuantityForm, {
                Dispatch: dispatch,
                FormState: state_1.FormState,
                IsCustomPropertiesEnabled: isCustomPropertiesEnabled && !isEmpty(propertyConfigurations),
                IsEdit: false,
                Labels: labels,
                Manufacturers: state_1.Manufacturers,
                OnSave: () => {
                    dispatch(new Msg(16, []));
                },
                PropertyConfigurations: propertyConfigurations,
                PurchaseDate: unwrap(state_1.PurchaseDate),
                SelectedFile: unwrap(state_1.SelectedFile),
                StorageStock: unwrap(state_1.StorageStock),
                Storages: storages,
                ToolWithQuantity: tool,
                UserData: props.UserData,
                WarrantyDate: unwrap(state_1.WarrantyDate),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
        default:
            return createElement(Skeleton, {
                Variant: "normal",
            });
    }
}

export function EditToolView(props) {
    let elems_1, elems, Label;
    const patternInput = useTranslation();
    let patternInput_1;
    const init = initEditView(props.UserData, props.ToolId);
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, [props.ToolId]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    const matchValue = state_1.ToolForm;
    const matchValue_1 = state_1.Storages;
    const matchValue_2 = state_1.Labels;
    const matchValue_3 = state_1.PropertyConfigurations;
    const matchValue_4 = state_1.IsCustomPropertiesEnabled;
    let matchResult, isCustomPropertiesEnabled, labels, propertyConfigurations, storages, tool;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_2.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_3.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_4.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        isCustomPropertiesEnabled = matchValue_4.fields[0];
        labels = matchValue_2.fields[0];
        propertyConfigurations = matchValue_3.fields[0];
        storages = matchValue_1.fields[0];
        tool = matchValue.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["mb-3", "flex"])], (elems = [createElement(TextButton, (Label = patternInput[0]("general.back"), {
                ComponentState: "enabled",
                Icon: Arrow_Left_1(),
                Label: Label,
                OnClick: () => {
                    RouterModule_nav(ofArray(["toolswithquantity", unwrapToolId(props.ToolId) + RouterModule_encodeQueryString(getQueryParams(undefined))]), 1, 1);
                },
                TestId: "tools-new-back-to-overview-button",
                Variant: "default",
            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement(PageHeader, {
                Text: "tool.edit_tool",
            }), createElement(ToolWithQuantityForm, {
                Dispatch: dispatch,
                FormState: state_1.FormState,
                IsCustomPropertiesEnabled: isCustomPropertiesEnabled && !isEmpty(propertyConfigurations),
                IsEdit: true,
                Labels: labels,
                Manufacturers: state_1.Manufacturers,
                OnSave: () => {
                    dispatch(new Msg(17, []));
                },
                PropertyConfigurations: propertyConfigurations,
                PurchaseDate: unwrap(state_1.PurchaseDate),
                SelectedFile: unwrap(state_1.SelectedFile),
                StorageStock: unwrap(state_1.StorageStock),
                Storages: storages,
                ToolWithQuantity: tool,
                UserData: props.UserData,
                WarrantyDate: unwrap(state_1.WarrantyDate),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
        default:
            return createElement(Skeleton, {
                Variant: "normal",
            });
    }
}

export function CopyNewToolWithQantityView(props) {
    let elems_1, elems, Label;
    const patternInput = useTranslation();
    let patternInput_1;
    const init = initCopyNewToolWithQuantity(props.ToolId, props.UserData);
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, [props.ToolId]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    const matchValue = state_1.ToolForm;
    const matchValue_1 = state_1.Storages;
    const matchValue_2 = state_1.Labels;
    const matchValue_3 = state_1.PropertyConfigurations;
    const matchValue_4 = state_1.IsCustomPropertiesEnabled;
    let matchResult, isCustomPropertiesEnabled, labels, propertyConfigurations, storages, tool;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_2.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_3.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_4.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        isCustomPropertiesEnabled = matchValue_4.fields[0];
        labels = matchValue_2.fields[0];
        propertyConfigurations = matchValue_3.fields[0];
        storages = matchValue_1.fields[0];
        tool = matchValue.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement("div", createObj(ofArray([["id", "tool-container"], ["className", "flex-grow-1 d-flex flex-column"], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["mb-3", "flex"])], (elems = [createElement(TextButton, (Label = patternInput[0]("general.back"), {
                ComponentState: "enabled",
                Icon: Arrow_Left_1(),
                Label: Label,
                OnClick: () => {
                    RouterModule_nav(ofArray(["toolswithquantity", unwrapToolId(props.ToolId) + RouterModule_encodeQueryString(getQueryParams(undefined))]), 1, 1);
                },
                TestId: "tools-new-back-to-overview-button",
                Variant: "default",
            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement(PageHeader, {
                Text: "tool.copy_tool_with_quantity",
            }), createElement(ToolWithQuantityForm, {
                Dispatch: dispatch,
                FormState: state_1.FormState,
                IsCustomPropertiesEnabled: isCustomPropertiesEnabled && !isEmpty(propertyConfigurations),
                IsEdit: false,
                Labels: labels,
                Manufacturers: state_1.Manufacturers,
                OnSave: () => {
                    dispatch(new Msg(16, []));
                },
                PropertyConfigurations: propertyConfigurations,
                PurchaseDate: unwrap(state_1.PurchaseDate),
                SelectedFile: unwrap(state_1.SelectedFile),
                StorageStock: unwrap(state_1.StorageStock),
                Storages: storages,
                ToolWithQuantity: tool,
                UserData: props.UserData,
                WarrantyDate: unwrap(state_1.WarrantyDate),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
        default:
            return createElement(Skeleton, {
                Variant: "normal",
            });
    }
}

