import { Union, Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { class_type, union_type, lambda_type, unit_type, string_type, float64_type, record_type, int32_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { createElement } from "react";
import * as react from "react";
import { SketchPicker } from "react-color";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";

export class Rgb extends Record {
    constructor(r, g, b, a) {
        super();
        this.r = (r | 0);
        this.g = (g | 0);
        this.b = (b | 0);
        this.a = (a | 0);
    }
}

export function Rgb_$reflection() {
    return record_type("SharedComponents.ReactColorPicker.Rgb", [], Rgb, () => [["r", int32_type], ["g", int32_type], ["b", int32_type], ["a", int32_type]]);
}

export class Hsl extends Record {
    constructor(h, s, l, a) {
        super();
        this.h = (h | 0);
        this.s = (s | 0);
        this.l = l;
        this.a = (a | 0);
    }
}

export function Hsl_$reflection() {
    return record_type("SharedComponents.ReactColorPicker.Hsl", [], Hsl, () => [["h", int32_type], ["s", int32_type], ["l", float64_type], ["a", int32_type]]);
}

export class color extends Record {
    constructor(hex, rgb, hsl) {
        super();
        this.hex = hex;
        this.rgb = rgb;
        this.hsl = hsl;
    }
}

export function color_$reflection() {
    return record_type("SharedComponents.ReactColorPicker.color", [], color, () => [["hex", string_type], ["rgb", Rgb_$reflection()], ["hsl", Hsl_$reflection()]]);
}

export class CommonProps extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["OnChange", "Color"];
    }
}

export function CommonProps_$reflection() {
    return union_type("SharedComponents.ReactColorPicker.CommonProps", [], CommonProps, () => [[["Item", lambda_type(color_$reflection(), unit_type)]], [["Item", string_type]]]);
}

export class ColorPicker {
    constructor() {
    }
}

export function ColorPicker_$reflection() {
    return class_type("SharedComponents.ReactColorPicker.ColorPicker", undefined, ColorPicker);
}

export function ColorPicker_$ctor() {
    return new ColorPicker();
}

export function ColorPicker_colorPicker_BD3CF72(onChange, color_1, labelName) {
    let elems_1;
    let colorPicker;
    const props_1 = {
        color: color_1,
        onChange: onChange,
    };
    colorPicker = react.createElement(SketchPicker, props_1);
    if (labelName == null) {
        return createElement("div", {
            className: "",
            children: Interop_reactApi.Children.toArray([colorPicker]),
        });
    }
    else {
        const labelName_1 = labelName;
        return createElement("div", createObj(ofArray([["className", "datepicker-with-label form-group"], (elems_1 = [createElement("label", {
            children: Interop_reactApi.Children.toArray([labelName_1]),
        }), colorPicker], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
    }
}

