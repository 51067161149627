import { createElement } from "react";
import React from "react";
import { useFeliz_React__React_useState_Static_1505 } from "../../fable_modules/Feliz.2.7.0/React.fs.js";
import { stringHash, createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { empty, singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { singleton as singleton_1, ofArray, map } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Checkbox } from "../../Components/Checkbox.js";
import { Interop_reactApi } from "../../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { List_groupBy } from "../../fable_modules/fable-library-js.4.19.2/Seq2.js";
import { ReactPopover } from "../../Components/ReactPopover.js";

export function ElementsGroup(props) {
    let elems_1;
    const patternInput = useFeliz_React__React_useState_Static_1505(true);
    return createElement("div", createObj(ofArray([["className", join(" ", ["min-w-max"])], (elems_1 = toList(delay(() => append(singleton(createElement("div", {
        className: join(" ", ["font-semibold"]),
        children: props.Group,
    })), delay(() => (patternInput[0] ? map((item) => {
        let elems;
        return createElement("div", createObj(ofArray([["className", join(" ", ["font-medium"])], (elems = [Checkbox({
            ComponentState: "enabled",
            IsChecked: item.Checked,
            Label: item.Name,
            OnCheck: item.OnCheck,
            TestId: "",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
    }, props.Items) : empty()))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function ColumnSelect(props) {
    const elements = List_groupBy((item) => item.Group, props.Items, {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    });
    return createElement(ReactPopover, {
        Body: (_arg) => {
            let elems;
            return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["p-6"])], (elems = toList(delay(() => map((tupledArg) => createElement(ElementsGroup, {
                Group: tupledArg[0],
                Items: tupledArg[1],
            }), elements))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))));
        },
        ButtonLabel: props.ButtonLabel,
        ComponentState: props.ComponentState,
        TestId: `column-select-popover-${props.TestId}`,
    });
}

