import { filter, ofArray, singleton, empty } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Requests_UserDeleteResponse_$reflection, Requests_EmployeeFormDto_$reflection, Employee_$reflection, Requests_EmployeeFormDto } from "../../Shared/User.js";
import { FormState__setValidationResponse_5219762A, FormState__startLoading, FormState_get_empty, RequestedValue$1 } from "../../Shared/Shared.js";
import { Msg, State } from "./Types.js";
import { Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { list_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { fetchWithDecoder as fetchWithDecoder_1, addPrefix as addPrefix_1 } from "../../CommunicationV2.js";
import { PropertyConfigurationEnabledResponse, PropertyConfiguration_$reflection, PropertyConfigurationEnabledResponse_$reflection } from "../../Shared/PropertyConfiguration.js";
import { ValidationOnGetRequest } from "../../ErrorHandling.js";
import { unwrapUserId } from "../../Shared/Helper.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";
import { Cmd_ofEffect } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { equals } from "../../fable_modules/fable-library-js.4.19.2/Util.js";

export const emptyEmployeeForm = new Requests_EmployeeFormDto("", "", "", undefined, empty(), "", empty());

export const initialState = new State(undefined, new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), FormState_get_empty());

export function initNew() {
    return [new State(initialState.EmployeeId, new RequestedValue$1(1, [emptyEmployeeForm]), initialState.Departments, initialState.IsCustomPropertiesEnabled, initialState.PropertyConfigurations, initialState.FormState), Cmd_batch(ofArray([Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(string_type)));
    })))), "/api/receivers/suggestions/department", (Item) => (new Msg(11, [Item])), (Item_1) => (new Msg(14, [Item_1]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_5;
        return ((url_5 = addPrefix_1(url_3), (refreshOn) => fetchWithDecoder_1(url_5, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
            const pr_1 = response_1.arrayBuffer();
            return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), PropertyConfigurationEnabledResponse_$reflection()));
        }, refreshOn)))(true).then((_arg) => {
            const result_2 = _arg;
            return (result_2.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_2.fields[0]);
            })())) : (Promise.resolve(result_2.fields[0]));
        });
    })), "/api/property-configurations/enabled", (Item_2) => (new Msg(12, [Item_2])), (Item_3) => (new Msg(14, [Item_3]))), Cmd_OfPromise_either((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_8;
        return ((url_8 = addPrefix_1(url_6), (refreshOn_1) => fetchWithDecoder_1(url_8, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
            const pr_2 = response_2.arrayBuffer();
            return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), list_type(PropertyConfiguration_$reflection())));
        }, refreshOn_1)))(true).then((_arg_1) => {
            const result_4 = _arg_1;
            return (result_4.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_4.fields[0]);
            })())) : (Promise.resolve(result_4.fields[0]));
        });
    })), "/api/property-configurations", (Item_4) => (new Msg(13, [Item_4])), (Item_5) => (new Msg(14, [Item_5])))]))];
}

export function initEdit(employeeId) {
    return [initialState, Cmd_batch(ofArray([Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(string_type)));
    })))), "/api/receivers/suggestions/department", (Item) => (new Msg(11, [Item])), (Item_1) => (new Msg(14, [Item_1]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), Employee_$reflection()));
    })))), `/api/receivers/employees/${unwrapUserId(employeeId)}`, (Item_2) => (new Msg(8, [Item_2])), (Item_3) => (new Msg(14, [Item_3]))), Cmd_OfPromise_either((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_8;
        return ((url_8 = addPrefix_1(url_6), (refreshOn) => fetchWithDecoder_1(url_8, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
            const pr_2 = response_2.arrayBuffer();
            return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), PropertyConfigurationEnabledResponse_$reflection()));
        }, refreshOn)))(true).then((_arg) => {
            const result_3 = _arg;
            return (result_3.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_3.fields[0]);
            })())) : (Promise.resolve(result_3.fields[0]));
        });
    })), "/api/property-configurations/enabled", (Item_4) => (new Msg(12, [Item_4])), (Item_5) => (new Msg(14, [Item_5]))), Cmd_OfPromise_either((url_9) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_11;
        return ((url_11 = addPrefix_1(url_9), (refreshOn_1) => fetchWithDecoder_1(url_11, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_3) => {
            const pr_3 = response_3.arrayBuffer();
            return pr_3.then((blob_3) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_3)), list_type(PropertyConfiguration_$reflection())));
        }, refreshOn_1)))(true).then((_arg_1) => {
            const result_5 = _arg_1;
            return (result_5.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_5.fields[0]);
            })())) : (Promise.resolve(result_5.fields[0]));
        });
    })), "/api/property-configurations", (Item_6) => (new Msg(13, [Item_6])), (Item_7) => (new Msg(14, [Item_7])))]))];
}

export function update(msg, state) {
    let xs, xs_2;
    switch (msg.tag) {
        case 1: {
            const matchValue_1 = state.Form;
            if (matchValue_1.tag === 1) {
                const employee_1 = matchValue_1.fields[0];
                return [new State(state.EmployeeId, new RequestedValue$1(1, [new Requests_EmployeeFormDto(employee_1.ImageUrl, employee_1.Name, msg.fields[0], employee_1.LocationId, employee_1.QRCodeIds, employee_1.Department, employee_1.CustomProperties)]), state.Departments, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.FormState), Cmd_none()];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        case 2: {
            const matchValue_2 = state.Form;
            if (matchValue_2.tag === 1) {
                const employee_2 = matchValue_2.fields[0];
                return [new State(state.EmployeeId, new RequestedValue$1(1, [new Requests_EmployeeFormDto(employee_2.ImageUrl, employee_2.Name, employee_2.PersonnelNumber, employee_2.LocationId, employee_2.QRCodeIds, msg.fields[0], employee_2.CustomProperties)]), state.Departments, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.FormState), Cmd_none()];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        case 3: {
            const matchValue_3 = state.Form;
            if (matchValue_3.tag === 1) {
                const employee_3 = matchValue_3.fields[0];
                return [new State(state.EmployeeId, new RequestedValue$1(1, [new Requests_EmployeeFormDto(employee_3.ImageUrl, employee_3.Name, employee_3.PersonnelNumber, employee_3.LocationId, employee_3.QRCodeIds, employee_3.Department, msg.fields[0])]), state.Departments, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.FormState), Cmd_none()];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        case 4: {
            const matchValue_4 = state.Form;
            if (matchValue_4.tag === 1) {
                return [new State(state.EmployeeId, state.Form, state.Departments, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, FormState__startLoading(state.FormState)), Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    let url_2;
                    return ((url_2 = addPrefix_1(tupledArg[0]), (refreshOn) => fetchWithDecoder_1(url_2, ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
                        const pr = response.arrayBuffer();
                        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
                    }, refreshOn)))(true);
                })), ["/api/receivers/employees", toString(0, Auto_generateBoxedEncoder_437914C6(Requests_EmployeeFormDto_$reflection(), undefined, undefined, undefined)(matchValue_4.fields[0]))], (Item) => (new Msg(9, [Item])), (Item_1) => (new Msg(14, [Item_1])))];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        case 5: {
            const matchValue_5 = state.EmployeeId;
            const matchValue_6 = state.Form;
            let matchResult, employeeId, updateEmployee;
            if (matchValue_5 != null) {
                if (matchValue_6.tag === 1) {
                    matchResult = 1;
                    employeeId = matchValue_5;
                    updateEmployee = matchValue_6.fields[0];
                }
                else {
                    matchResult = 0;
                }
            }
            else {
                matchResult = 0;
            }
            switch (matchResult) {
                case 0:
                    return [state, Cmd_none()];
                default:
                    return [new State(state.EmployeeId, state.Form, state.Departments, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, FormState__startLoading(state.FormState)), Cmd_OfPromise_either((tupledArg_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        let url_5;
                        return ((url_5 = addPrefix_1(tupledArg_1[0]), (refreshOn_1) => fetchWithDecoder_1(url_5, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg_1[1]])]), "application/json", (response_1) => {
                            const pr_1 = response_1.arrayBuffer();
                            return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), string_type));
                        }, refreshOn_1)))(true);
                    })), [`/api/receivers/employees/${employeeId}`, toString(0, Auto_generateBoxedEncoder_437914C6(Requests_EmployeeFormDto_$reflection(), undefined, undefined, undefined)(updateEmployee))], (Item_2) => (new Msg(9, [Item_2])), (Item_3) => (new Msg(14, [Item_3])))];
            }
        }
        case 8: {
            const employee_6 = msg.fields[0];
            return [new State(employee_6.Id, new RequestedValue$1(1, [new Requests_EmployeeFormDto(employee_6.ImageUrl, employee_6.Name, employee_6.PersonnelNumber, employee_6.LocationId, employee_6.QRCodeIds, employee_6.Department, employee_6.CustomProperties)]), state.Departments, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.FormState), Cmd_none()];
        }
        case 9: {
            const response_2 = msg.fields[0];
            if (response_2.tag === 1) {
                return [new State(state.EmployeeId, state.Form, state.Departments, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, FormState__setValidationResponse_5219762A(state.FormState, response_2.fields[0])), Cmd_errorToast("validation.toast")];
            }
            else {
                return [new State(state.EmployeeId, state.Form, state.Departments, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, FormState_get_empty()), (xs = ["receivers", response_2.fields[0]], Cmd_ofEffect((_arg) => {
                    RouterModule_nav(ofArray(xs), 1, 1);
                }))];
            }
        }
        case 10:
            return [state, (xs_2 = ["receivers", msg.fields[0]], Cmd_ofEffect((_arg_1) => {
                RouterModule_nav(ofArray(xs_2), 1, 1);
            }))];
        case 6:
            return [state, Cmd_OfPromise_either((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_6), singleton(new Types_RequestProperties(0, ["DELETE"])), "application/json", (response_3) => {
                const pr_2 = response_3.arrayBuffer();
                return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), Requests_UserDeleteResponse_$reflection()));
            })))), `/api/receivers/employees/${unwrapUserId(msg.fields[0])}`, (Item_4) => (new Msg(7, [Item_4])), (Item_5) => (new Msg(14, [Item_5])))];
        case 11:
            return [new State(state.EmployeeId, state.Form, new RequestedValue$1(1, [msg.fields[0]]), state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.FormState), Cmd_none()];
        case 12:
            return [new State(state.EmployeeId, state.Form, state.Departments, new RequestedValue$1(1, [equals(msg.fields[0], new PropertyConfigurationEnabledResponse(0, []))]), state.PropertyConfigurations, state.FormState), Cmd_none()];
        case 13:
            return [new State(state.EmployeeId, state.Form, state.Departments, state.IsCustomPropertiesEnabled, new RequestedValue$1(1, [filter((propertyConfiguration) => {
                const matchValue_8 = propertyConfiguration.Visibility;
                switch (matchValue_8.tag) {
                    case 0:
                    case 2:
                    case 1:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                        return false;
                    default:
                        return true;
                }
            }, msg.fields[0])]), state.FormState), Cmd_none()];
        case 7: {
            const response_8 = msg.fields[0];
            switch (response_8.tag) {
                case 2:
                    return [state, Cmd_errorToast("receivers.user_failed_delete_tools_assigned")];
                case 1:
                    return [state, Cmd_batch(singleton(Cmd_errorToast("receivers.delete_self_error")))];
                default:
                    return [state, Cmd_batch(ofArray([Cmd_ofEffect((_arg_2) => {
                        RouterModule_nav(singleton("receivers"), 1, 1);
                    }), Cmd_successToast("general.deleted_successfully")]))];
            }
        }
        case 14:
            return [state, Cmd_none()];
        default: {
            const matchValue = state.Form;
            if (matchValue.tag === 1) {
                const employee = matchValue.fields[0];
                return [new State(state.EmployeeId, new RequestedValue$1(1, [new Requests_EmployeeFormDto(employee.ImageUrl, msg.fields[0], employee.PersonnelNumber, employee.LocationId, employee.QRCodeIds, employee.Department, employee.CustomProperties)]), state.Departments, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.FormState), Cmd_none()];
            }
            else {
                return [state, Cmd_none()];
            }
        }
    }
}

