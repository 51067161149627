import { Helper_priceToString } from "../Shared/Tool.js";
import { toLocalTime, toString } from "../fable_modules/fable-library-js.4.19.2/Date.js";

export function price(p) {
    if (p == null) {
        return "";
    }
    else {
        return Helper_priceToString(p);
    }
}

export function purchaseDate(d) {
    if (d == null) {
        return "";
    }
    else {
        return toString(toLocalTime(d), "dd.MM.yyyy");
    }
}

