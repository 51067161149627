import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { int32ToString, createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { TextValue } from "../../Components/Text.js";
import { StocktakingListState__ToI18nString, StocktakingState__ToI18nString } from "../../Shared/Stocktaking.js";
import { empty as empty_1, toArray as toArray_1, singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { ColoredDot } from "../../Components/ColoredDot.js";
import { toString, now, compare } from "../../fable_modules/fable-library-js.4.19.2/Date.js";
import { Interop_reactApi } from "../../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { singleton as singleton_1, head, tail, map, reduce, isEmpty, length, empty, toArray, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { TableControl$1, OnTableRowClick$1, ColumnDefinition$1, CellContent, Table } from "../../Widgets/Table/Table.js";
import { useFeliz_React__React_useState_Static_1505 } from "../../fable_modules/Feliz.2.7.0/React.fs.js";
import { StocktakingListHelperDialog } from "../Dialog/StocktakingListHelperDialog.js";
import { map as map_1, defaultArg, unwrap } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { Msg } from "./Types.js";
import { StocktakingListDetailModalSheet } from "../Dialog/StocktakingListDetailModalSheet.js";
import { RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { StocktakingListId__get_unwrap, StocktakingId__get_unwrap } from "../../Shared/Shared.js";
import Delete_1 from "../../../public/assets/icons/Delete_1.svg";
import Check from "../../../public/assets/icons/Check.svg";
import Qr_Code from "../../../public/assets/icons/Qr_Code.svg";
import { ConfirmationDialog } from "../../Components/Dialog.js";
import { TextButton } from "../../Components/Button.js";
import Shipment_Time from "../../../public/assets/icons/Shipment_Time.svg";
import { DetailViewHeader } from "../../Widgets/DetailViewHeader.js";
import { AreaAnchorMenu } from "../../Widgets/AnchorMenu.js";
import { TableContainer } from "../../Widgets/Container.js";
import { update as update_1, initViewStocktaking } from "./State.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Skeleton } from "../../Components/Skeleton.js";

function OverviewInformationSection(props) {
    let elems_3, elems_2, elems_1;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-2"])], (elems_2 = [createElement(TextValue, {
        FontWeight: "bold",
        TestId: "overview-name-label",
        Text: t("general.name"),
    }), createElement(TextValue, {
        FontWeight: "normal",
        TestId: "overview-name-value",
        Text: props.Stocktaking.Name,
    }), createElement(TextValue, {
        FontWeight: "bold",
        TestId: "overview-description-label",
        Text: t("general.description"),
    }), createElement(TextValue, {
        FontWeight: "normal",
        TestId: "overview-description-value",
        Text: props.Stocktaking.Description,
    }), createElement(TextValue, {
        FontWeight: "bold",
        TestId: "overview-state-label",
        Text: t("general.state"),
    }), createElement(TextValue, {
        FontWeight: "normal",
        TestId: "overview-state-value",
        Text: t(StocktakingState__ToI18nString(props.Stocktaking.State)),
    }), createElement(TextValue, {
        FontWeight: "bold",
        TestId: "overview-stocktaking-time",
        Text: t("stocktaking.stocktaking_time"),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "gap-2", "mt-2"])], (elems_1 = toList(delay(() => {
        let elems;
        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mt-1"])], (elems = [createElement(ColoredDot, {
            Color: ((compare(props.Stocktaking.StartDate, now()) < 0) && (compare(props.Stocktaking.EndDate, now()) > 0)) ? "green" : "red",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => singleton(`${toString(props.Stocktaking.StartDate, "dd.MM.yyyy")} - ${toString(props.Stocktaking.EndDate, "dd.MM.yyyy")}`)));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
}

function ResponsiblePersonTable(props) {
    let elems, TableRows;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "grow"])], (elems = [createElement(Table, (TableRows = toArray(props.Stocktaking.ResponsiblePersons), {
        ColumnDefinitions: [new ColumnDefinition$1("name", "", t("general.name"), (dto) => (new CellContent(0, [dto.Name])), "text", "text"), new ColumnDefinition$1("department", "", t("user.department"), (dto_1) => (new CellContent(0, [dto_1.Department])), "text", "text")],
        Controls: empty(),
        Options: {
            BorderStyle: "row",
            CSVDownload: false,
            ConfigurableColumns: false,
            DefaultSortColumn: ["name", "asc"],
            GlobalSearch: false,
            RowSelection: "disabled",
        },
        TableRows: TableRows,
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

function HelperTable(props) {
    let elems, TableRows;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col"])], (elems = [createElement(Table, (TableRows = toArray(props.Stocktaking.Helpers), {
        ColumnDefinitions: [new ColumnDefinition$1("name", "", t("general.name"), (dto) => (new CellContent(0, [dto.Name])), "text", "text"), new ColumnDefinition$1("department", "", t("user.department"), (dto_1) => (new CellContent(0, [dto_1.Department])), "text", "text")],
        Controls: empty(),
        Options: {
            BorderStyle: "row",
            CSVDownload: false,
            ConfigurableColumns: false,
            DefaultSortColumn: ["name", "asc"],
            GlobalSearch: false,
            RowSelection: "disabled",
        },
        TableRows: TableRows,
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

function StocktakingListsTable(props) {
    let elems_2, TableRows, Options;
    const t = useTranslation()[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(undefined);
    const setStocktakingListHelperDialogIsOpen = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(undefined);
    const setStocktakingListDetailsModalSheetIsOpen = patternInput_2[1];
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "grow"])], (elems_2 = [createElement(StocktakingListHelperDialog, {
        IsOpen: unwrap(patternInput_1[0]),
        OnClose: () => {
            setStocktakingListHelperDialogIsOpen(undefined);
            props.Dispatch(new Msg(0, []));
        },
        Stocktaking: props.Stocktaking,
        Users: props.Users,
    }), createElement(StocktakingListDetailModalSheet, {
        OnClose: () => {
            setStocktakingListDetailsModalSheetIsOpen(undefined);
            props.Dispatch(new Msg(0, []));
        },
        OnSave: (value_1) => {
        },
        OnUpdate: () => {
            props.Dispatch(new Msg(0, []));
        },
        Stocktaking: props.Stocktaking,
        StocktakingList: unwrap(patternInput_2[0]),
    }), createElement(Table, (TableRows = toArray(props.Stocktaking.StocktakingLists), (Options = {
        BorderStyle: "row",
        CSVDownload: false,
        ConfigurableColumns: false,
        DefaultSortColumn: ["scope", "asc"],
        GlobalSearch: false,
        OnRowClick: new OnTableRowClick$1(0, [(dto_6) => {
            RouterModule_nav(ofArray(["stocktaking", StocktakingId__get_unwrap(props.Stocktaking.Id), "lists", StocktakingListId__get_unwrap(dto_6.Id)]), 1, 1);
        }]),
        RowSelection: "singleRow",
    }, {
        ColumnDefinitions: toArray_1(delay(() => {
            let matchValue;
            return append((matchValue = props.Stocktaking.State, (matchValue.tag === 0) ? (empty_1()) : ((matchValue.tag === 1) ? (empty_1()) : ((matchValue.tag === 2) ? (empty_1()) : ((matchValue.tag === 4) ? (empty_1()) : singleton(new ColumnDefinition$1("status_icon", "", t("stocktaking.status"), (dto) => {
                let elems, elems_1;
                return new CellContent(1, [(dto.RequiredActions != null) ? createElement("div", createObj(ofArray([["className", join(" ", ["text-red", "h-5", "w-5"])], (elems = [Delete_1()], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))) : createElement("div", createObj(ofArray([["className", join(" ", ["text-primary", "h-5", "w-5"])], (elems_1 = [Check()], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))]);
            }, "none", "text")))))), delay(() => append(singleton(new ColumnDefinition$1("scope", "", t("stocktaking.scope"), (dto_1) => (new CellContent(0, [dto_1.DisplayName])), "select", "text")), delay(() => append(singleton(new ColumnDefinition$1("affectedElements", "", t("stocktaking.affected_elements"), (dto_2) => (new CellContent(0, [int32ToString((length(dto_2.Tools) + length(dto_2.ToolsWithQuantity)) + length(dto_2.Consumables))])), "text", "text")), delay(() => append(singleton(new ColumnDefinition$1("helper", "", t("stocktaking.stocktaking_helper"), (dto_3) => (new CellContent(0, [isEmpty(dto_3.Helper) ? "" : reduce((e1, e2) => (`${e1},${e2}`), map((helper) => helper.Name, dto_3.Helper))])), "select", "text")), delay(() => {
                const matchValue_1 = props.Stocktaking.State;
                switch (matchValue_1.tag) {
                    case 2:
                        return append(singleton(new ColumnDefinition$1("state", "", t("stocktaking.stocktaking_list_state"), (dto_4) => (new CellContent(0, [t(StocktakingListState__ToI18nString(dto_4.State))])), "text", "text")), delay(() => singleton(new ColumnDefinition$1("progress", "", t("stocktaking.stocktaking_list_progress"), (dto_5) => (new CellContent(0, [defaultArg(map_1((arg) => (`${int32ToString(arg)}%`), dto_5.Progress), "")])), "text", "text"))));
                    default: {
                        return empty_1();
                    }
                }
            }))))))));
        })),
        Controls: toList(delay(() => {
            let matchValue_2;
            return append((matchValue_2 = props.Stocktaking.State, (matchValue_2.tag === 3) ? (empty_1()) : ((matchValue_2.tag === 4) ? (empty_1()) : ((matchValue_2.tag === 1) ? singleton(new TableControl$1(t("stocktaking.assign_stocktaking_helper_to_list"), Qr_Code(), (dto_7) => {
                let matchResult, selectedList;
                if (!isEmpty(dto_7)) {
                    if (isEmpty(tail(dto_7))) {
                        matchResult = 0;
                        selectedList = head(dto_7);
                    }
                    else {
                        matchResult = 1;
                    }
                }
                else {
                    matchResult = 1;
                }
                switch (matchResult) {
                    case 0: {
                        setStocktakingListHelperDialogIsOpen(selectedList.Id);
                        break;
                    }
                    case 1: {
                        setStocktakingListHelperDialogIsOpen(undefined);
                        break;
                    }
                }
            }, true, false, "stocktaking-assign-helper-to-list")) : ((matchValue_2.tag === 0) ? singleton(new TableControl$1(t("stocktaking.assign_stocktaking_helper_to_list"), Qr_Code(), (dto_7) => {
                let matchResult_1, selectedList;
                if (!isEmpty(dto_7)) {
                    if (isEmpty(tail(dto_7))) {
                        matchResult_1 = 0;
                        selectedList = head(dto_7);
                    }
                    else {
                        matchResult_1 = 1;
                    }
                }
                else {
                    matchResult_1 = 1;
                }
                switch (matchResult_1) {
                    case 0: {
                        setStocktakingListHelperDialogIsOpen(selectedList.Id);
                        break;
                    }
                    case 1: {
                        setStocktakingListHelperDialogIsOpen(undefined);
                        break;
                    }
                }
            }, true, false, "stocktaking-assign-helper-to-list")) : (empty_1()))))), delay(() => singleton(new TableControl$1(t("stocktaking.show_stocktaking_list"), Qr_Code(), (dto_8) => {
                let matchResult_2, selectedList_1;
                if (!isEmpty(dto_8)) {
                    if (isEmpty(tail(dto_8))) {
                        matchResult_2 = 0;
                        selectedList_1 = head(dto_8);
                    }
                    else {
                        matchResult_2 = 1;
                    }
                }
                else {
                    matchResult_2 = 1;
                }
                switch (matchResult_2) {
                    case 0: {
                        setStocktakingListDetailsModalSheetIsOpen(selectedList_1);
                        break;
                    }
                    case 1: {
                        setStocktakingListDetailsModalSheetIsOpen(undefined);
                        break;
                    }
                }
            }, true, false, "stocktaking-assign-helper-to-list"))));
        })),
        Options: Options,
        TableRows: TableRows,
    })))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

function DetailsActions(props) {
    let elems_1, Title, Title_1, Title_2, elems;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-between"])], (elems_1 = [createElement(ConfirmationDialog, (Title = t("stocktaking.dialog.state_to_fixed_title"), {
        Description: t("stocktaking.dialog.state_to_fixed_description"),
        FormState: props.FormState,
        IsOpen: props.DialogOpenState.IsConfirmationStateFixedDialogOpen,
        OnClose: () => {
            props.Dispatch(new Msg(7, [false]));
        },
        OnConfirm: () => {
            props.Dispatch(new Msg(4, []));
        },
        Title: Title,
    })), createElement(ConfirmationDialog, (Title_1 = t("stocktaking.dialog.state_to_start_title"), {
        Description: t("stocktaking.dialog.state_to_start_description"),
        FormState: props.FormState,
        IsOpen: props.DialogOpenState.IsConfirmationStateStartDialogOpen,
        OnClose: () => {
            props.Dispatch(new Msg(8, [false]));
        },
        OnConfirm: () => {
            props.Dispatch(new Msg(5, []));
        },
        Title: Title_1,
    })), createElement(ConfirmationDialog, (Title_2 = t("stocktaking.dialog.state_to_done_title"), {
        Description: t("stocktaking.dialog.state_to_done_description"),
        FormState: props.FormState,
        IsOpen: props.DialogOpenState.IsConfirmationStateDoneDialogOpen,
        OnClose: () => {
            props.Dispatch(new Msg(9, [false]));
        },
        OnConfirm: () => {
            props.Dispatch(new Msg(6, []));
        },
        Title: Title_2,
    })), createElement("div", {}), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "gap-7"])], (elems = toList(delay(() => {
        let Label_1, Label_2, Label;
        const matchValue = props.Stocktaking.State;
        switch (matchValue.tag) {
            case 1:
                return singleton(createElement(TextButton, (Label_1 = t("stocktaking.state.to_start"), {
                    ComponentState: "enabled",
                    Icon: Shipment_Time(),
                    Label: Label_1,
                    OnClick: () => {
                        props.Dispatch(new Msg(8, [true]));
                    },
                    TestId: "tool-tooldetail-in-maintenance-state-button",
                    Variant: "default",
                })));
            case 2:
                return singleton(createElement(TextButton, (Label_2 = t("stocktaking.state.to_done"), {
                    ComponentState: "enabled",
                    Icon: Shipment_Time(),
                    Label: Label_2,
                    OnClick: () => {
                        props.Dispatch(new Msg(9, [true]));
                    },
                    TestId: "tool-tooldetail-in-maintenance-state-button",
                    Variant: "default",
                })));
            case 3:
            case 4: {
                return empty_1();
            }
            default:
                return singleton(createElement(TextButton, (Label = t("stocktaking.state.to_fixed"), {
                    ComponentState: "enabled",
                    Icon: Shipment_Time(),
                    Label: Label,
                    OnClick: () => {
                        props.Dispatch(new Msg(7, [true]));
                    },
                    TestId: "tool-tooldetail-in-maintenance-state-button",
                    Variant: "default",
                })));
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

function OverviewSection(props) {
    let elems_2, elems_1, elems;
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "grow"])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["bg-white", "rounded", "px-12", "py-9"])], (elems_1 = [DetailsActions({
        DialogOpenState: props.DialogOpenState,
        Dispatch: props.Dispatch,
        FormState: props.FormState,
        Stocktaking: props.Stocktaking,
    }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "mt-6"])], (elems = [createElement(OverviewInformationSection, {
        Stocktaking: props.Stocktaking,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

function DetailViewComponent(props) {
    let elems_8, Title, elems_7, elems, elems_6, elems_5, elems_1, elems_2, Title_2, elems_3, Title_3, elems_4, Title_4;
    const t = useTranslation()[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("overview-section");
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setIsDeleteModalOpen = patternInput_2[1];
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "grow", "flex-col"])], (elems_8 = [createElement(ConfirmationDialog, (Title = t("stocktaking.delete_stocktaking"), {
        Description: t("stocktaking.delete_stocktaking_confirmation"),
        IsOpen: patternInput_2[0],
        OnClose: () => {
            setIsDeleteModalOpen(false);
        },
        OnConfirm: () => {
            props.Dispatch(new Msg(2, []));
        },
        Title: Title,
    })), createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-[10rem,min(calc(100vw-16rem-2px-28px-11rem),80rem)]"])], (elems_7 = [createElement("div", createObj(ofArray([["className", join(" ", ["col-span-2"])], (elems = [createElement(DetailViewHeader, {
        Badges: empty(),
        OnBack: () => {
            RouterModule_nav(ofArray(["stocktaking"]), 1, 1);
        },
        OnDelete: () => {
            setIsDeleteModalOpen(true);
        },
        OnDuplicate: () => {
            RouterModule_nav(ofArray(["stocktaking", StocktakingId__get_unwrap(props.Stocktaking.Id), "copy"]), 1, 1);
        },
        OnEdit: () => {
            RouterModule_nav(ofArray(["stocktaking", StocktakingId__get_unwrap(props.Stocktaking.Id), "edit"]), 1, 1);
        },
        Title: props.Stocktaking.Name,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement(AreaAnchorMenu, {
        Anchors: toList(delay(() => append(singleton({
            Id: "overview-section",
            Label: t("general.overview"),
        }), delay(() => append((props.Stocktaking.State.tag === 3) ? singleton({
            Id: "required-actions-section",
            Label: t("stocktaking.required_actions"),
        }) : (empty_1()), delay(() => append(singleton({
            Id: "stocktaking-lists-section",
            Label: t("stocktaking.stocktaking_lists"),
        }), delay(() => append(singleton({
            Id: "responsible-section",
            Label: t("stocktaking.responsible_person"),
        }), delay(() => singleton({
            Id: "helper-section",
            Label: t("stocktaking.stocktaking_helper"),
        }))))))))))),
        OnSelectAnchor: patternInput_1[1],
        SelectedAnchor: patternInput_1[0],
    }), createElement("div", createObj(ofArray([["className", join(" ", ["pr-3"])], (elems_6 = [createElement("div", createObj(singleton_1((elems_5 = [createElement("div", createObj(ofArray([["id", "overview-section"], (elems_1 = [createElement(OverviewSection, {
        DialogOpenState: props.DialogOpenState,
        Dispatch: props.Dispatch,
        FormState: props.FormState,
        Stocktaking: props.Stocktaking,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("section", createObj(ofArray([["id", "stocktaking-lists-section"], (elems_2 = [createElement(TableContainer, (Title_2 = t("stocktaking.stocktaking_lists"), {
        Content: createElement(StocktakingListsTable, {
            Dispatch: props.Dispatch,
            Stocktaking: props.Stocktaking,
            Users: props.Users,
        }),
        Title: unwrap(Title_2),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("section", createObj(ofArray([["id", "responsible-section"], (elems_3 = [createElement(TableContainer, (Title_3 = t("stocktaking.responsible_person"), {
        Content: createElement(ResponsiblePersonTable, {
            Stocktaking: props.Stocktaking,
        }),
        Title: unwrap(Title_3),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("section", createObj(ofArray([["id", "helper-section"], (elems_4 = [createElement(TableContainer, (Title_4 = t("stocktaking.stocktaking_helper"), {
        Content: createElement(HelperTable, {
            Stocktaking: props.Stocktaking,
        }),
        Title: unwrap(Title_4),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])));
}

export function DetailView(props) {
    let patternInput;
    const init = initViewStocktaking(props.StocktakingId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    const matchValue = state_1.Stocktaking;
    const matchValue_1 = state_1.Users;
    let matchResult, stocktaking, users;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        stocktaking = matchValue.fields[0];
        users = matchValue_1.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement(DetailViewComponent, {
                DialogOpenState: state_1.DialogOpenState,
                Dispatch: patternInput[1],
                FormState: state_1.FormState,
                Stocktaking: stocktaking,
                Users: users,
            });
        default:
            return createElement(Skeleton, {
                Variant: "detail",
            });
    }
}

