import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, union_type, anonRecord_type, option_type, obj_type, class_type, bool_type, int32_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { CreateStripeInvoicePaymentDto, CreateStripeCustomerAndSubscriptionDto, CreateStripeCustomerDto as CreateStripeCustomerDto_7, CreateStripeCustomerDto_$reflection, StripeCustomer_$reflection, CreateCustomerAndSubscriptionResponse_$reflection } from "../../Shared/Customer.js";
import { RequestedValue$1, RequestedValue$1_$reflection } from "../../Shared/Shared.js";
import { Cmd_OfPromise_perform, Cmd_batch, Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createStripeInvoicePayment, getStripeCustomer, createStripeCustomer } from "../../Requests/Customer.js";
import { exists, tryFind, map, ofArray, singleton } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Cmd_ofEffect } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { Variant as Variant_1 } from "../../Variant.js";
import { unwrap, map as map_1, defaultArg, value as value_17 } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { loadStripe } from "@stripe/stripe-js";
import { createElement } from "react";
import React from "react";
import { isNullOrWhiteSpace, join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { TextButton, Button } from "../../Components/Button.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { Interop_reactApi } from "../../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { Toggle } from "../../Components/Toggle.js";
import { Input, NumberInput } from "../../Components/Input.js";
import { numberInputIntMaxValue } from "../../Common.js";
import { useTranslation } from "react-i18next";
import { Select } from "../../Components/Select.js";
import { spinner } from "../../SharedComponents/Spinners.js";
import { Elements, PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Interop_reactApi as Interop_reactApi_1 } from "../../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { defaultOf } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { BlockHeader } from "../../Components/Text.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetPaymentInterval", "SetActiveChapter", "SelectPackage", "SetAdditionalUsers", "SetEmail", "SetCompanyName", "SetCountryCode", "SetAddress", "SetPostalCode", "SetCity", "SetTaxId", "SetPaymentMethod", "SetPaymentDataValid", "CreateCustomer", "CustomerCreated", "FailedCreateCustomer", "FetchStripeCustomer", "StripeCustomerFetched", "FetchStripeCustomerFailed", "CreateInvoicePayment", "InvoicePaymentCreated", "FailedCreateInvoicePayment", "CreateStripePayment", "StripePaymentCreated"];
    }
}

export function Msg_$reflection() {
    return union_type("Customer.Wizard.Msg", [], Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item1", string_type], ["Item2", string_type], ["Item3", string_type]], [["Item", int32_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [], [["Item", CreateCustomerAndSubscriptionResponse_$reflection()]], [["Item", class_type("System.Exception")]], [["Item", string_type]], [["Item", StripeCustomer_$reflection()]], [["Item", class_type("System.Exception")]], [], [["Item", string_type]], [["Item", class_type("System.Exception")]], [["Item1", class_type("Fable.Stripe.IStripe")], ["Item2", class_type("Fable.Stripe.IStripeElement")]], [["Item", anonRecord_type(["error", option_type(obj_type)], ["paymentIntent", option_type(anonRecord_type(["status", string_type]))])]]]);
}

export class StripePackage extends Record {
    constructor(PackageConfiguration, PackageName, Price) {
        super();
        this.PackageConfiguration = PackageConfiguration;
        this.PackageName = PackageName;
        this.Price = Price;
    }
}

export function StripePackage_$reflection() {
    return record_type("Customer.Wizard.StripePackage", [], StripePackage, () => [["PackageConfiguration", string_type], ["PackageName", string_type], ["Price", string_type]]);
}

export class State extends Record {
    constructor(ActiveChapter, PaymentInterval, StripePackage, AdditionalUsers, CreateStripeCustomerDto, PaymentMethod, ClientSecret, StripeCustomer, PaymentDataValid, IsLoading) {
        super();
        this.ActiveChapter = ActiveChapter;
        this.PaymentInterval = PaymentInterval;
        this.StripePackage = StripePackage;
        this.AdditionalUsers = (AdditionalUsers | 0);
        this.CreateStripeCustomerDto = CreateStripeCustomerDto;
        this.PaymentMethod = PaymentMethod;
        this.ClientSecret = ClientSecret;
        this.StripeCustomer = StripeCustomer;
        this.PaymentDataValid = PaymentDataValid;
        this.IsLoading = IsLoading;
    }
}

export function State_$reflection() {
    return record_type("Customer.Wizard.State", [], State, () => [["ActiveChapter", string_type], ["PaymentInterval", string_type], ["StripePackage", StripePackage_$reflection()], ["AdditionalUsers", int32_type], ["CreateStripeCustomerDto", CreateStripeCustomerDto_$reflection()], ["PaymentMethod", string_type], ["ClientSecret", option_type(string_type)], ["StripeCustomer", RequestedValue$1_$reflection(StripeCustomer_$reflection())], ["PaymentDataValid", bool_type], ["IsLoading", bool_type]]);
}

export function init() {
    return [new State("package", "yearly", new StripePackage("monthlySmall", "", ""), 0, new CreateStripeCustomerDto_7("", "", "DE", "", "", "", undefined), "sEPA", undefined, new RequestedValue$1(0, []), false, false), Cmd_none()];
}

export function update(msg, state) {
    let bind$0040_1, bind$0040_2, bind$0040_3, bind$0040_4, bind$0040_5, bind$0040_6, bind$0040_7, xs, xs_2;
    switch (msg.tag) {
        case 1:
            return [new State(msg.fields[0], state.PaymentInterval, state.StripePackage, state.AdditionalUsers, state.CreateStripeCustomerDto, state.PaymentMethod, state.ClientSecret, state.StripeCustomer, state.PaymentDataValid, state.IsLoading), Cmd_none()];
        case 2:
            return [new State(state.ActiveChapter, state.PaymentInterval, new StripePackage(msg.fields[0], msg.fields[1], msg.fields[2]), state.AdditionalUsers, state.CreateStripeCustomerDto, state.PaymentMethod, state.ClientSecret, state.StripeCustomer, state.PaymentDataValid, state.IsLoading), Cmd_none()];
        case 3: {
            const additionalUsers = msg.fields[0] | 0;
            return [new State(state.ActiveChapter, state.PaymentInterval, state.StripePackage, (additionalUsers < 0) ? 0 : additionalUsers, state.CreateStripeCustomerDto, state.PaymentMethod, state.ClientSecret, state.StripeCustomer, state.PaymentDataValid, state.IsLoading), Cmd_none()];
        }
        case 4:
            return [new State(state.ActiveChapter, state.PaymentInterval, state.StripePackage, state.AdditionalUsers, (bind$0040_1 = state.CreateStripeCustomerDto, new CreateStripeCustomerDto_7(msg.fields[0], bind$0040_1.CompanyName, bind$0040_1.CountryCode, bind$0040_1.Address, bind$0040_1.PostalCode, bind$0040_1.City, bind$0040_1.TaxId)), state.PaymentMethod, state.ClientSecret, state.StripeCustomer, state.PaymentDataValid, state.IsLoading), Cmd_none()];
        case 5:
            return [new State(state.ActiveChapter, state.PaymentInterval, state.StripePackage, state.AdditionalUsers, (bind$0040_2 = state.CreateStripeCustomerDto, new CreateStripeCustomerDto_7(bind$0040_2.Email, msg.fields[0], bind$0040_2.CountryCode, bind$0040_2.Address, bind$0040_2.PostalCode, bind$0040_2.City, bind$0040_2.TaxId)), state.PaymentMethod, state.ClientSecret, state.StripeCustomer, state.PaymentDataValid, state.IsLoading), Cmd_none()];
        case 6:
            return [new State(state.ActiveChapter, state.PaymentInterval, state.StripePackage, state.AdditionalUsers, (bind$0040_3 = state.CreateStripeCustomerDto, new CreateStripeCustomerDto_7(bind$0040_3.Email, bind$0040_3.CompanyName, msg.fields[0], bind$0040_3.Address, bind$0040_3.PostalCode, bind$0040_3.City, bind$0040_3.TaxId)), state.PaymentMethod, state.ClientSecret, state.StripeCustomer, state.PaymentDataValid, state.IsLoading), Cmd_none()];
        case 7:
            return [new State(state.ActiveChapter, state.PaymentInterval, state.StripePackage, state.AdditionalUsers, (bind$0040_4 = state.CreateStripeCustomerDto, new CreateStripeCustomerDto_7(bind$0040_4.Email, bind$0040_4.CompanyName, bind$0040_4.CountryCode, msg.fields[0], bind$0040_4.PostalCode, bind$0040_4.City, bind$0040_4.TaxId)), state.PaymentMethod, state.ClientSecret, state.StripeCustomer, state.PaymentDataValid, state.IsLoading), Cmd_none()];
        case 8:
            return [new State(state.ActiveChapter, state.PaymentInterval, state.StripePackage, state.AdditionalUsers, (bind$0040_5 = state.CreateStripeCustomerDto, new CreateStripeCustomerDto_7(bind$0040_5.Email, bind$0040_5.CompanyName, bind$0040_5.CountryCode, bind$0040_5.Address, msg.fields[0], bind$0040_5.City, bind$0040_5.TaxId)), state.PaymentMethod, state.ClientSecret, state.StripeCustomer, state.PaymentDataValid, state.IsLoading), Cmd_none()];
        case 9:
            return [new State(state.ActiveChapter, state.PaymentInterval, state.StripePackage, state.AdditionalUsers, (bind$0040_6 = state.CreateStripeCustomerDto, new CreateStripeCustomerDto_7(bind$0040_6.Email, bind$0040_6.CompanyName, bind$0040_6.CountryCode, bind$0040_6.Address, bind$0040_6.PostalCode, msg.fields[0], bind$0040_6.TaxId)), state.PaymentMethod, state.ClientSecret, state.StripeCustomer, state.PaymentDataValid, state.IsLoading), Cmd_none()];
        case 10: {
            const taxId = msg.fields[0];
            return [new State(state.ActiveChapter, state.PaymentInterval, state.StripePackage, state.AdditionalUsers, (bind$0040_7 = state.CreateStripeCustomerDto, new CreateStripeCustomerDto_7(bind$0040_7.Email, bind$0040_7.CompanyName, bind$0040_7.CountryCode, bind$0040_7.Address, bind$0040_7.PostalCode, bind$0040_7.City, (taxId === "") ? undefined : taxId)), state.PaymentMethod, state.ClientSecret, state.StripeCustomer, state.PaymentDataValid, state.IsLoading), Cmd_none()];
        }
        case 11:
            return [new State(state.ActiveChapter, state.PaymentInterval, state.StripePackage, state.AdditionalUsers, state.CreateStripeCustomerDto, msg.fields[0], state.ClientSecret, state.StripeCustomer, state.PaymentDataValid, state.IsLoading), Cmd_none()];
        case 13:
            return [new State(state.ActiveChapter, state.PaymentInterval, state.StripePackage, state.AdditionalUsers, state.CreateStripeCustomerDto, state.PaymentMethod, state.ClientSecret, state.StripeCustomer, state.PaymentDataValid, true), Cmd_batch(singleton(createStripeCustomer(new CreateStripeCustomerAndSubscriptionDto(state.CreateStripeCustomerDto, state.StripePackage.PackageConfiguration, state.PaymentMethod, state.AdditionalUsers), (Item) => (new Msg(14, [Item])), (Item_1) => (new Msg(15, [Item_1])))))];
        case 14: {
            const response = msg.fields[0];
            return [new State(state.ActiveChapter, state.PaymentInterval, state.StripePackage, state.AdditionalUsers, state.CreateStripeCustomerDto, state.PaymentMethod, response.ClientSecret, state.StripeCustomer, state.PaymentDataValid, false), Cmd_batch(ofArray([singleton((dispatch) => {
                dispatch(new Msg(16, [response.CustomerId]));
            }), singleton((dispatch_1) => {
                dispatch_1(new Msg(1, ["paymentData"]));
            })]))];
        }
        case 15:
            return [new State(state.ActiveChapter, state.PaymentInterval, state.StripePackage, state.AdditionalUsers, state.CreateStripeCustomerDto, state.PaymentMethod, state.ClientSecret, state.StripeCustomer, state.PaymentDataValid, false), Cmd_none()];
        case 16:
            return [state, getStripeCustomer(msg.fields[0], (Item_2) => (new Msg(17, [Item_2])), (Item_3) => (new Msg(18, [Item_3])))];
        case 17:
            return [new State(state.ActiveChapter, state.PaymentInterval, state.StripePackage, state.AdditionalUsers, state.CreateStripeCustomerDto, state.PaymentMethod, state.ClientSecret, new RequestedValue$1(1, [msg.fields[0]]), state.PaymentDataValid, state.IsLoading), Cmd_none()];
        case 18:
            return [state, Cmd_none()];
        case 19: {
            const matchValue = state.StripeCustomer;
            if (matchValue.tag === 1) {
                return [new State(state.ActiveChapter, state.PaymentInterval, state.StripePackage, state.AdditionalUsers, state.CreateStripeCustomerDto, state.PaymentMethod, state.ClientSecret, state.StripeCustomer, state.PaymentDataValid, true), createStripeInvoicePayment(new CreateStripeInvoicePaymentDto(matchValue.fields[0].Id, state.StripePackage.PackageConfiguration, state.AdditionalUsers), (Item_4) => (new Msg(20, [Item_4])), (Item_5) => (new Msg(21, [Item_5])))];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        case 20:
            return [new State(state.ActiveChapter, state.PaymentInterval, state.StripePackage, state.AdditionalUsers, state.CreateStripeCustomerDto, state.PaymentMethod, state.ClientSecret, state.StripeCustomer, state.PaymentDataValid, false), (xs = ["customer", "wizard", "complete"], Cmd_ofEffect((_arg) => {
                RouterModule_nav(ofArray(xs), 1, 1);
            }))];
        case 21:
            return [new State(state.ActiveChapter, state.PaymentInterval, state.StripePackage, state.AdditionalUsers, state.CreateStripeCustomerDto, state.PaymentMethod, state.ClientSecret, state.StripeCustomer, state.PaymentDataValid, false), Cmd_none()];
        case 12:
            return [new State(state.ActiveChapter, state.PaymentInterval, state.StripePackage, state.AdditionalUsers, state.CreateStripeCustomerDto, state.PaymentMethod, state.ClientSecret, state.StripeCustomer, msg.fields[0], state.IsLoading), Cmd_none()];
        case 22: {
            const redirectUrl = (Variant_1.tag === 0) ? "https://app.meinwerkzeugkoffer.de" : "https://app.inventory-one.com";
            const matchValue_1 = state.StripeCustomer;
            if (matchValue_1.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const stripeCustomer_2 = matchValue_1.fields[0];
                return [new State(state.ActiveChapter, state.PaymentInterval, state.StripePackage, state.AdditionalUsers, state.CreateStripeCustomerDto, state.PaymentMethod, state.ClientSecret, state.StripeCustomer, state.PaymentDataValid, true), Cmd_OfPromise_perform((arg) => msg.fields[0].confirmPayment(arg), {
                    confirmParams: {
                        payment_method_data: {
                            billing_details: {
                                address: {
                                    city: stripeCustomer_2.Address.City,
                                    country: stripeCustomer_2.Address.CountryCode,
                                    line1: stripeCustomer_2.Address.Address,
                                    line2: "",
                                    postal_code: stripeCustomer_2.Address.PostalCode,
                                },
                                email: stripeCustomer_2.Email,
                                name: stripeCustomer_2.Name,
                            },
                        },
                        return_url: redirectUrl,
                    },
                    elements: msg.fields[1],
                    redirect: "if_required",
                }, (Item_6) => (new Msg(23, [Item_6])))];
            }
        }
        case 23: {
            const payment = msg.fields[0];
            const matchValue_2 = payment.paymentIntent;
            const matchValue_3 = payment.error;
            if (matchValue_2 != null) {
                const paymentIntent = matchValue_2;
                return [new State(state.ActiveChapter, state.PaymentInterval, state.StripePackage, state.AdditionalUsers, state.CreateStripeCustomerDto, state.PaymentMethod, state.ClientSecret, state.StripeCustomer, state.PaymentDataValid, false), (xs_2 = ["customer", "wizard", "complete"], Cmd_ofEffect((_arg_1) => {
                    RouterModule_nav(ofArray(xs_2), 1, 1);
                }))];
            }
            else if (matchValue_3 != null) {
                const error = value_17(matchValue_3);
                return [new State(state.ActiveChapter, state.PaymentInterval, state.StripePackage, state.AdditionalUsers, state.CreateStripeCustomerDto, state.PaymentMethod, state.ClientSecret, state.StripeCustomer, state.PaymentDataValid, false), Cmd_none()];
            }
            else {
                return [new State(state.ActiveChapter, state.PaymentInterval, state.StripePackage, state.AdditionalUsers, state.CreateStripeCustomerDto, state.PaymentMethod, state.ClientSecret, state.StripeCustomer, state.PaymentDataValid, false), Cmd_none()];
            }
        }
        default:
            return [new State(state.ActiveChapter, msg.fields[0], state.StripePackage, state.AdditionalUsers, state.CreateStripeCustomerDto, state.PaymentMethod, state.ClientSecret, state.StripeCustomer, state.PaymentDataValid, state.IsLoading), Cmd_none()];
    }
}

export const stripe = loadStripe("pk_live_51MjmHoIjqyBvQM6547jnMlAOXKDLxIyVZv7Ge7ZIwdoyaEcp28ySGe8MPtPWNtj7cYOnlesapr2YtpjB4VxspxAD00REn01sxn");

export function PackageFeature(props) {
    return createElement("div", {
        className: join(" ", []),
        children: props.FeatureDescription,
    });
}

export function Package(props) {
    let elems_1, elems;
    return createElement("div", createObj(ofArray([["className", join(" ", ["border", "border-solid", "rounded-lg", "p-4", "grid", "grid-cols-1", "gap-3"])], (elems_1 = [createElement("div", {
        className: join(" ", ["text-lg", "font-bold"]),
        children: props.PackageName,
    }), createElement("div", {
        className: join(" ", ["text-2xl", "font-extrabold"]),
        children: props.Price,
    }), createElement(Button, {
        ComponentState: "enabled",
        Label: "Jetzt buchen",
        OnClick: () => {
            props.Dispatch(new Msg(2, [props.PackageConfiguration, props.PackageName, props.Price]));
            props.Dispatch(new Msg(1, ["additionalUser"]));
        },
        TestId: "",
        Variant: "primary",
    }), createElement("div", createObj(singleton((elems = toList(delay(() => map((feature) => createElement(PackageFeature, {
        FeatureDescription: feature,
    }), props.Features))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function PackageForm(props) {
    let elems_2, elems, elems_1;
    return createElement("div", createObj(ofArray([["className", join(" ", ["mb-6", "bg-white", "rounded", "px-12", "py-9"])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["mb-3", "flex", "justify-center"])], (elems = [createElement(Toggle, {
        Checked: props.PaymentInterval === "yearly",
        LeftLabel: "Monatlich",
        OnChange: (selected) => {
            props.Dispatch(selected ? (new Msg(0, ["yearly"])) : (new Msg(0, ["monthly"])));
        },
        RightLabel: "Jährlich",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-3", "gap-3"])], (elems_1 = toList(delay(() => ((props.PaymentInterval === "monthly") ? append(singleton_1(createElement(Package, {
        Dispatch: props.Dispatch,
        Features: ofArray(["500 Betriebsmittel", "20 Benutzerzugänge", "Monatliche Bezahlung"]),
        PackageConfiguration: "monthlySmall",
        PackageName: "BASIS 500",
        Price: "69€ / Monat",
    })), delay(() => append(singleton_1(createElement(Package, {
        Dispatch: props.Dispatch,
        Features: ofArray(["4.000 Betriebsmittel", "50 Benutzerzugänge", "Monatliche Bezahlung"]),
        PackageConfiguration: "monthlyMedium",
        PackageName: "PRO 4.000",
        Price: "99€ / Monat",
    })), delay(() => singleton_1(createElement(Package, {
        Dispatch: props.Dispatch,
        Features: ofArray(["10.000 Betriebsmittel", "100 Benutzerzugänge", "Monatliche Bezahlung"]),
        PackageConfiguration: "monthlyLarge",
        PackageName: "BIG 10.000",
        Price: "199€ / Monat",
    })))))) : append(singleton_1(createElement(Package, {
        Dispatch: props.Dispatch,
        Features: ofArray(["500 Betriebsmittel", "20 Benutzerzugänge", "Jährliche Bezahlung"]),
        PackageConfiguration: "yearlySmall",
        PackageName: "BASIS 500",
        Price: "59€ / Monat",
    })), delay(() => append(singleton_1(createElement(Package, {
        Dispatch: props.Dispatch,
        Features: ofArray(["4.000 Betriebsmittel", "50 Benutzerzugänge", "Jährliche Bezahlung"]),
        PackageConfiguration: "yearlyMedium",
        PackageName: "PRO 4.000",
        Price: "89€ / Monat",
    })), delay(() => singleton_1(createElement(Package, {
        Dispatch: props.Dispatch,
        Features: ofArray(["10.000 Betriebsmittel", "100 Benutzerzugänge", "Jährliche Bezahlung"]),
        PackageConfiguration: "yearlyLarge",
        PackageName: "BIG 10.000",
        Price: "179€ / Monat",
    }))))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

export function AdditionalUsersForm(props) {
    let elems_1, elems;
    return createElement("div", createObj(ofArray([["className", join(" ", ["mb-6", "bg-white", "rounded", "px-12", "py-9", "flex", "flex-col", "gap-2"])], (elems_1 = [createElement(NumberInput, {
        ComponentState: "enabled",
        Label: "Zusätzliche Benutzer",
        MaxValue: numberInputIntMaxValue,
        OnChange: (arg_2) => {
            props.Dispatch(new Msg(3, [defaultArg(map_1((value_1) => ~~value_1, arg_2), 0)]));
        },
        TestId: "",
        Value: props.AdditionalUsers,
    }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-between"])], (elems = [createElement("div", {
        className: join(" ", ["grow"]),
    }), createElement(Button, {
        ComponentState: "enabled",
        Label: "Weiter",
        OnClick: () => {
            props.Dispatch(new Msg(1, ["billingAddress"]));
        },
        TestId: "",
        Variant: "primary",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function BillingAddressForm(props) {
    let elems_2, elems, elems_1;
    const patternInput = useTranslation();
    const countryOptions = ofArray([{
        label: "Deutschland",
        value: "DE",
    }, {
        label: "Luxemburg",
        value: "LUX",
    }, {
        label: "Österreich",
        value: "AT",
    }, {
        label: "Schweiz",
        value: "CH",
    }]);
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "justify-center", "mb-6", "bg-white", "rounded", "px-12", "py-9"])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["mb-3", "grid", "grid-cols-1", "gap-2"])], (elems = toList(delay(() => append(singleton_1(createElement(Input, {
        ComponentState: "enabled",
        Label: "E-Mail",
        OnChange: (arg) => {
            props.Dispatch(new Msg(4, [arg]));
        },
        TestId: "",
        Value: props.StripeCustomer.Email,
    })), delay(() => append(singleton_1(createElement(Input, {
        ComponentState: "enabled",
        Label: "Unternehmen",
        OnChange: (arg_1) => {
            props.Dispatch(new Msg(5, [arg_1]));
        },
        TestId: "",
        Value: props.StripeCustomer.CompanyName,
    })), delay(() => append(singleton_1(createElement(Select, {
        ComponentState: "enabled",
        IsClearable: false,
        Label: "Land",
        NoOptionsMessage: "",
        OnChange: (option) => {
            if (option == null) {
            }
            else {
                props.Dispatch(new Msg(6, [option.value]));
            }
        },
        Options: countryOptions,
        PlaceholderKey: "",
        TestId: "",
        Value: unwrap(tryFind((option_2) => (option_2.value === props.StripeCustomer.CountryCode), countryOptions)),
    })), delay(() => append(singleton_1(createElement(Input, {
        ComponentState: "enabled",
        Label: "Straße",
        OnChange: (arg_2) => {
            props.Dispatch(new Msg(7, [arg_2]));
        },
        TestId: "",
        Value: props.StripeCustomer.Address,
    })), delay(() => append(singleton_1(createElement(Input, {
        ComponentState: "enabled",
        Label: "Postleitzahl",
        OnChange: (arg_3) => {
            props.Dispatch(new Msg(8, [arg_3]));
        },
        TestId: "",
        Value: props.StripeCustomer.PostalCode,
    })), delay(() => append(singleton_1(createElement(Input, {
        ComponentState: "enabled",
        Label: "City",
        OnChange: (arg_4) => {
            props.Dispatch(new Msg(9, [arg_4]));
        },
        TestId: "",
        Value: props.StripeCustomer.City,
    })), delay(() => {
        let matchValue;
        return (props.StripeCustomer.CountryCode !== "DE") ? singleton_1(createElement(Input, {
            ComponentState: "enabled",
            Label: "Umsatzsteuer Id",
            OnChange: (arg_5) => {
                props.Dispatch(new Msg(10, [arg_5]));
            },
            TestId: "",
            Value: (matchValue = props.StripeCustomer.TaxId, (matchValue == null) ? "" : matchValue),
        })) : empty();
    })))))))))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-between", ""])], (elems_1 = [createElement("div", {
        className: join(" ", ["grow"]),
    }), createElement(Button, {
        ComponentState: exists((x) => x, map(isNullOrWhiteSpace, toList(delay(() => append(singleton_1(props.StripeCustomer.Email), delay(() => append(singleton_1(props.StripeCustomer.CompanyName), delay(() => append(singleton_1(props.StripeCustomer.Address), delay(() => append(singleton_1(props.StripeCustomer.PostalCode), delay(() => append(singleton_1(props.StripeCustomer.City), delay(() => {
            const matchValue_1 = props.StripeCustomer.CountryCode !== "DE";
            const matchValue_2 = props.StripeCustomer.TaxId;
            if (matchValue_1) {
                return (matchValue_2 == null) ? singleton_1("") : singleton_1(matchValue_2);
            }
            else {
                return empty();
            }
        })))))))))))))) ? "disabled" : "enabled",
        Label: "Weiter",
        OnClick: () => {
            props.Dispatch(new Msg(1, ["paymentMethod"]));
        },
        TestId: "",
        Variant: "primary",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

export function PaymentMethodBox(props) {
    let elems;
    const t = useTranslation()[0];
    let paymentMethodName;
    const matchValue = props.PaymentMethod;
    paymentMethodName = ((matchValue === "sEPA") ? t("customerdata.wizard.payment_method.sepa") : ((matchValue === "invoice") ? t("customerdata.wizard.payment_method.invoice") : t("customerdata.wizard.payment_method.credit_card")));
    return createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton_1("border"), delay(() => append(singleton_1("border-solid"), delay(() => append(singleton_1("rounded-lg"), delay(() => append(singleton_1("p-4"), delay(() => append(singleton_1("grid"), delay(() => append(singleton_1("grid-cols-1"), delay(() => append(singleton_1("gap-3"), delay(() => append(singleton_1("cursor-pointer"), delay(() => (props.IsSelected ? append(singleton_1("bg-[#EAF8F7]"), delay(() => singleton_1("border-primary"))) : empty()))))))))))))))))))))], ["onClick", (_arg) => {
        props.Dispatch(new Msg(11, [props.PaymentMethod]));
    }], (elems = [createElement("div", {
        className: join(" ", ["text-lg", "font-bold"]),
        children: paymentMethodName,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function PaymentMethodForm(props) {
    let elems_2, elems, elems_1;
    return createElement("div", createObj(ofArray([["className", join(" ", ["mb-6", "bg-white", "rounded", "px-12", "py-9"])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-3", "gap-2", "mb-2"])], (elems = toList(delay(() => append(singleton_1(createElement(PaymentMethodBox, {
        Dispatch: props.Dispatch,
        IsSelected: props.ActivePaymentMethod === "sEPA",
        PaymentMethod: "sEPA",
    })), delay(() => append(singleton_1(createElement(PaymentMethodBox, {
        Dispatch: props.Dispatch,
        IsSelected: props.ActivePaymentMethod === "card",
        PaymentMethod: "card",
    })), delay(() => (((props.CustomerCountry === "DE") && (props.PaymentInterval === "yearly")) ? singleton_1(createElement(PaymentMethodBox, {
        Dispatch: props.Dispatch,
        IsSelected: props.ActivePaymentMethod === "invoice",
        PaymentMethod: "invoice",
    })) : empty()))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-between"])], (elems_1 = toList(delay(() => append(singleton_1(createElement("div", {
        className: join(" ", ["grow"]),
    })), delay(() => (!props.IsLoading ? singleton_1(createElement(Button, {
        ComponentState: "enabled",
        Label: "Weiter",
        OnClick: () => {
            props.Dispatch(new Msg(13, []));
        },
        TestId: "",
        Variant: "primary",
    })) : singleton_1(spinner)))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

export function StripePaymentForm(props) {
    let elems_1, props_1, elems;
    const elements = useElements(undefined);
    const stripe_1 = useStripe(undefined);
    return createElement("div", createObj(singleton((elems_1 = [(props_1 = ofArray([["options", {
        defaultValues: {
            billingDetails: {
                address: {
                    city: props.StripeCustomer.Address.City,
                    country: props.StripeCustomer.Address.CountryCode,
                    line1: props.StripeCustomer.Address.Address,
                    line2: "",
                    postal_code: props.StripeCustomer.Address.PostalCode,
                },
                email: props.StripeCustomer.Email,
                name: props.StripeCustomer.Name,
            },
        },
        fields: {
            billingDetails: {
                address: {
                    city: "never",
                    country: "never",
                    line1: "never",
                    line2: "never",
                    postalCode: "never",
                },
                email: "never",
                name: "never",
            },
        },
    }], ["onChange", (event) => {
        if (event.complete) {
            props.Dispatch(new Msg(12, [true]));
        }
        else {
            props.Dispatch(new Msg(12, [false]));
        }
    }]]), Interop_reactApi_1.createElement(PaymentElement, createObj(props_1))), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-between"])], (elems = toList(delay(() => append(singleton_1(createElement("div", {
        className: join(" ", ["grow"]),
    })), delay(() => (props.IsLoading ? singleton_1(spinner) : singleton_1(createElement(Button, {
        ComponentState: !props.FormValid ? "disabled" : "enabled",
        Label: "Kostenpflichtig buchen",
        OnClick: () => {
            props.Dispatch(new Msg(22, [stripe_1, elements]));
        },
        TestId: "",
        Variant: "primary",
    }))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))]))));
}

export function PaymentDataForm(props) {
    let elems;
    const patternInput = useTranslation();
    return createElement("div", createObj(ofArray([["className", join(" ", ["mb-6", "bg-white", "rounded", "px-12", "py-9"])], (elems = toList(delay(() => {
        let props_1;
        const matchValue = props.PaymentMethod;
        const matchValue_1 = props.ClientSecret;
        const matchValue_2 = props.StripeCustomer;
        let matchResult, clientSecret, stripeCustomer;
        switch (matchValue) {
            case "sEPA": {
                if (matchValue_1 == null) {
                    matchResult = 1;
                }
                else if (matchValue_2.tag === 0) {
                    matchResult = 3;
                }
                else {
                    matchResult = 0;
                    clientSecret = matchValue_1;
                    stripeCustomer = matchValue_2.fields[0];
                }
                break;
            }
            case "invoice": {
                if (matchValue_2.tag === 0) {
                    matchResult = 3;
                }
                else {
                    matchResult = 2;
                }
                break;
            }
            default:
                if (matchValue_1 == null) {
                    matchResult = 1;
                }
                else if (matchValue_2.tag === 0) {
                    matchResult = 3;
                }
                else {
                    matchResult = 0;
                    clientSecret = matchValue_1;
                    stripeCustomer = matchValue_2.fields[0];
                }
        }
        switch (matchResult) {
            case 0:
                return singleton_1((props_1 = ofArray([["stripe", stripe], ["options", {
                    clientSecret: clientSecret,
                }], ["children", singleton(createElement(StripePaymentForm, {
                    Dispatch: props.Dispatch,
                    FormValid: props.FormValid,
                    IsLoading: props.IsLoading,
                    StripeCustomer: stripeCustomer,
                }))]]), Interop_reactApi_1.createElement(Elements, createObj(props_1))));
            case 1:
                return singleton_1(createElement("div", {
                    children: ["No Client Secret"],
                }));
            case 2:
                return singleton_1(defaultOf());
            default:
                return singleton_1(spinner);
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function ChapterHeadline(props) {
    let elems_5;
    const patternInput = useTranslation();
    return createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton_1("flex"), delay(() => append(singleton_1("flex-col"), delay(() => (!props.IsActive ? singleton_1("mb-6") : empty()))))))))], (elems_5 = toList(delay(() => {
        let elems_2, elems_1;
        return append(singleton_1(createElement("div", createObj(singleton((elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-between", "items-center"])], (elems_1 = toList(delay(() => append(singleton_1(createElement(BlockHeader, {
            Text: props.Title,
        })), delay(() => {
            let elems;
            const matchValue = props.OnEdit;
            if (matchValue == null) {
                return empty();
            }
            else {
                const onEdit = matchValue;
                return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["mb-4"])], (elems = [createElement(TextButton, {
                    ComponentState: "enabled",
                    Label: patternInput[0]("general.edit"),
                    OnClick: onEdit,
                    TestId: "edit-package",
                    Variant: "default",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))));
            }
        })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))]))))), delay(() => {
            let elems_4, elems_3;
            const matchValue_1 = props.SubTitle;
            if (matchValue_1 == null) {
                return empty();
            }
            else {
                const subTitle = matchValue_1;
                return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "bg-white", "rounded", "px-12", "py-9"])], (elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", ["p-2", "grow", "bg-gray-200"])], (elems_3 = [createElement("div", {
                    className: join(" ", ["text-sm"]),
                    children: subTitle.Line1,
                }), createElement("div", {
                    className: join(" ", []),
                    children: subTitle.Line2,
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))));
            }
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])));
}

export function Wizard(props) {
    let elems_1;
    const t = useTranslation()[0];
    const state = props.State;
    const dispatch = props.Dispatch;
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "max-w-5xl", "mt-4", "py-4"])], (elems_1 = toList(delay(() => append(singleton_1(createElement("div", {
        className: join(" ", ["pb-3", "text-2xl", "font-semibold", "flex", "justify-center"]),
        children: t("customerdata.wizard.title"),
    })), delay(() => {
        let SubTitle, matchValue, matchValue_1;
        return append(singleton_1(createElement(ChapterHeadline, (SubTitle = ((matchValue = state.ActiveChapter, (matchValue === "additionalUser") ? {
            Line1: state.StripePackage.PackageName,
            Line2: `${state.StripePackage.Price}`,
        } : ((matchValue === "billingAddress") ? {
            Line1: state.StripePackage.PackageName,
            Line2: `${state.StripePackage.Price}`,
        } : ((matchValue === "paymentData") ? {
            Line1: state.StripePackage.PackageName,
            Line2: `${state.StripePackage.Price}`,
        } : ((matchValue === "paymentMethod") ? {
            Line1: state.StripePackage.PackageName,
            Line2: `${state.StripePackage.Price}`,
        } : undefined))))), {
            IsActive: state.ActiveChapter === "package",
            Number: "1",
            OnEdit: unwrap((matchValue_1 = state.ActiveChapter, (matchValue_1 === "additionalUser") ? (() => {
                dispatch(new Msg(1, ["package"]));
            }) : ((matchValue_1 === "billingAddress") ? (() => {
                dispatch(new Msg(1, ["package"]));
            }) : ((matchValue_1 === "paymentData") ? (() => {
                dispatch(new Msg(1, ["package"]));
            }) : ((matchValue_1 === "paymentMethod") ? (() => {
                dispatch(new Msg(1, ["package"]));
            }) : undefined))))),
            SubTitle: unwrap(SubTitle),
            Title: "Paketauswahl",
        }))), delay(() => append((state.ActiveChapter === "package") ? singleton_1(createElement(PackageForm, {
            Dispatch: dispatch,
            PaymentInterval: state.PaymentInterval,
        })) : empty(), delay(() => {
            let SubTitle_1, matchValue_2, matchValue_3;
            return append(singleton_1(createElement(ChapterHeadline, (SubTitle_1 = ((matchValue_2 = state.ActiveChapter, (matchValue_2 === "billingAddress") ? {
                Line1: `${state.AdditionalUsers} zusätzliche Benutzer`,
                Line2: `${state.AdditionalUsers} € / Monat`,
            } : ((matchValue_2 === "paymentData") ? {
                Line1: `${state.AdditionalUsers} zusätzliche Benutzer`,
                Line2: `${state.AdditionalUsers} € / Monat`,
            } : ((matchValue_2 === "paymentMethod") ? {
                Line1: `${state.AdditionalUsers} zusätzliche Benutzer`,
                Line2: `${state.AdditionalUsers} € / Monat`,
            } : undefined)))), {
                IsActive: state.ActiveChapter === "additionalUser",
                Number: "2",
                OnEdit: unwrap((matchValue_3 = state.ActiveChapter, (matchValue_3 === "billingAddress") ? (() => {
                    dispatch(new Msg(1, ["additionalUser"]));
                }) : ((matchValue_3 === "paymentData") ? (() => {
                    dispatch(new Msg(1, ["additionalUser"]));
                }) : ((matchValue_3 === "paymentMethod") ? (() => {
                    dispatch(new Msg(1, ["additionalUser"]));
                }) : undefined)))),
                SubTitle: unwrap(SubTitle_1),
                Title: "Zusätzliche Benutzer",
            }))), delay(() => append((state.ActiveChapter === "additionalUser") ? singleton_1(createElement(AdditionalUsersForm, {
                AdditionalUsers: state.AdditionalUsers,
                Dispatch: dispatch,
            })) : empty(), delay(() => {
                let matchValue_4;
                return append(singleton_1(createElement(ChapterHeadline, {
                    IsActive: state.ActiveChapter === "billingAddress",
                    Number: "3",
                    OnEdit: unwrap((matchValue_4 = state.ActiveChapter, (matchValue_4 === "paymentData") ? (() => {
                        dispatch(new Msg(1, ["billingAddress"]));
                    }) : ((matchValue_4 === "paymentMethod") ? (() => {
                        dispatch(new Msg(1, ["billingAddress"]));
                    }) : undefined))),
                    Title: "Rechnungsadresse",
                })), delay(() => append((state.ActiveChapter === "billingAddress") ? singleton_1(createElement(BillingAddressForm, {
                    Dispatch: dispatch,
                    StripeCustomer: state.CreateStripeCustomerDto,
                })) : empty(), delay(() => {
                    let matchValue_6;
                    return append(singleton_1(createElement(ChapterHeadline, {
                        IsActive: state.ActiveChapter === "paymentMethod",
                        Number: "4",
                        OnEdit: unwrap((state.ActiveChapter === "paymentData") ? (() => {
                            dispatch(new Msg(1, ["paymentMethod"]));
                        }) : undefined),
                        SubTitle: unwrap((state.ActiveChapter === "paymentData") ? {
                            Line1: (matchValue_6 = state.PaymentMethod, (matchValue_6 === "sEPA") ? t("customerdata.wizard.payment_method.sepa") : ((matchValue_6 === "invoice") ? t("customerdata.wizard.payment_method.invoice") : t("customerdata.wizard.payment_method.credit_card"))),
                            Line2: "",
                        } : undefined),
                        Title: "Bezahlmethode",
                    })), delay(() => append((state.ActiveChapter === "paymentMethod") ? singleton_1(createElement(PaymentMethodForm, {
                        ActivePaymentMethod: state.PaymentMethod,
                        CustomerCountry: state.CreateStripeCustomerDto.CountryCode,
                        Dispatch: dispatch,
                        IsLoading: state.IsLoading,
                        PaymentInterval: state.PaymentInterval,
                    })) : empty(), delay(() => {
                        let elems;
                        if (state.ActiveChapter === "paymentData") {
                            const matchValue_8 = state.PaymentMethod;
                            switch (matchValue_8) {
                                case "invoice":
                                    return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-between"])], (elems = toList(delay(() => append(singleton_1(createElement("div", {
                                        className: join(" ", ["grow"]),
                                    })), delay(() => {
                                        const matchValue_9 = state.StripeCustomer;
                                        let matchResult, stripeCustomer;
                                        if (matchValue_9.tag === 0) {
                                            matchResult = 1;
                                        }
                                        else if (state.IsLoading) {
                                            matchResult = 1;
                                        }
                                        else {
                                            matchResult = 0;
                                            stripeCustomer = matchValue_9.fields[0];
                                        }
                                        switch (matchResult) {
                                            case 0:
                                                return singleton_1(createElement(Button, {
                                                    ComponentState: "enabled",
                                                    Label: "Kostenpflichtig buchen",
                                                    OnClick: () => {
                                                        props.Dispatch(new Msg(19, []));
                                                    },
                                                    TestId: "",
                                                    Variant: "primary",
                                                }));
                                            default:
                                                return singleton_1(spinner);
                                        }
                                    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))));
                                default:
                                    return append(singleton_1(createElement(ChapterHeadline, {
                                        IsActive: state.ActiveChapter === "paymentData",
                                        Number: "5",
                                        Title: "Bezahldaten",
                                    })), delay(() => singleton_1(createElement(PaymentDataForm, {
                                        ClientSecret: unwrap(state.ClientSecret),
                                        Dispatch: dispatch,
                                        FormValid: state.PaymentDataValid,
                                        IsLoading: state.IsLoading,
                                        PaymentMethod: state.PaymentMethod,
                                        StripeCustomer: state.StripeCustomer,
                                    }))));
                            }
                        }
                        else {
                            return empty();
                        }
                    }))));
                }))));
            }))));
        }))));
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function WizardView() {
    let elems_1, elems;
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init, update, (_arg, _arg_1) => {
    }), undefined, []);
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "min-h-full", "items-center", "content-center", "bg-background", "overflow-auto"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "min-w-[70rem]", "my-10", "p-6", "border", "border-border", "rounded-md", "bg-[#FFFFFF]"])], (elems = [createElement(Wizard, {
        Dispatch: patternInput[1],
        State: patternInput[0],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

