import { createElement } from "react";
import React from "react";
import * as react from "react";
import { useTranslation } from "react-i18next";
import { TableControl$1, ColumnDefinition$1, CellContent, Table } from "../../Widgets/Table/Table.js";
import { empty, head, tail, isEmpty, ofArray, map, append, singleton, toArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { StocktakingResultType, ResultType__ToI18nString } from "../../Shared/Stocktaking.js";
import Qr_Code from "../../../public/assets/icons/Qr_Code.svg";
import { useFeliz_React__React_useState_Static_1505 } from "../../fable_modules/Feliz.2.7.0/React.fs.js";
import { defaultArg, unwrap, map as map_1 } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { equals, createObj, int32ToString } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { ConfirmStocktakingResultDialog } from "./ConfirmStocktakingResultDialog.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import Check from "../../../public/assets/icons/Check.svg";
import { Interop_reactApi } from "../../fable_modules/Feliz.2.7.0/Interop.fs.js";
import Delete_1 from "../../../public/assets/icons/Delete_1.svg";
import { defaultOf } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { Union, toString } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { union_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { ModalSheet } from "../../Components/ModalSheet.js";
import { singleton as singleton_1, append as append_1, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { TabsMenu } from "../../Widgets/TabsMenu.js";

export function RequiredActionsTable(props) {
    let TableRows, Options;
    const t = useTranslation()[0];
    return createElement(Table, (TableRows = toArray(props.StocktakingListRequiredActions), (Options = {
        BorderStyle: "row",
        CSVDownload: false,
        ConfigurableColumns: false,
        DefaultSortColumn: ["name", "asc"],
        GlobalSearch: false,
        RowSelection: "singleRow",
    }, {
        ColumnDefinitions: [new ColumnDefinition$1("name", "", t("general.name"), (dto) => (new CellContent(0, [dto.Name])), "text", "text"), new ColumnDefinition$1("result_type", "", t("stocktaking.result"), (dto_1) => (new CellContent(0, [t(ResultType__ToI18nString(dto_1.ResultType))])), "text", "text")],
        Controls: singleton(new TableControl$1(t("stocktaking.todo"), Qr_Code(), undefined, true, false, "stocktaking-assign-helper-to-list")),
        Options: Options,
        TableRows: TableRows,
    })));
}

export function StocktakingListOverviewTable(props) {
    let TableRows, Options;
    const t = useTranslation()[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(undefined);
    const setIsConfirmStocktakingResultDialogOpen = patternInput_1[1];
    const rows = append(map((stocktakingListTool) => {
        const RecordedBy = map_1((_arg) => _arg.RecordedBy, stocktakingListTool.Result);
        return {
            Name: stocktakingListTool.ToolName,
            Quantity: "",
            RecordTimestamp: unwrap(map_1((_arg_1) => _arg_1.Timestamp, stocktakingListTool.Result)),
            RecordedBy: unwrap(RecordedBy),
            StocktakingResultType: new StocktakingResultType(0, [stocktakingListTool.ToolId]),
        };
    }, props.StocktakingList.Tools), append(map((stocktakingListToolWithQuantity) => {
        const Quantity_1 = int32ToString(stocktakingListToolWithQuantity.Quantity);
        const RecordedBy_1 = map_1((_arg_2) => _arg_2.RecordedBy, stocktakingListToolWithQuantity.Result);
        return {
            Name: stocktakingListToolWithQuantity.ToolName,
            Quantity: Quantity_1,
            RecordTimestamp: unwrap(map_1((_arg_3) => _arg_3.Timestamp, stocktakingListToolWithQuantity.Result)),
            RecordedBy: unwrap(RecordedBy_1),
            RecordedQuantity: unwrap(map_1((arg) => int32ToString(arg.Quantity), stocktakingListToolWithQuantity.Result)),
            StocktakingResultType: new StocktakingResultType(1, [stocktakingListToolWithQuantity.ToolId]),
        };
    }, props.StocktakingList.ToolsWithQuantity), map((stocktakingListConsumable) => {
        const Quantity_2 = stocktakingListConsumable.Quantity.toString();
        const RecordedBy_2 = map_1((_arg_5) => _arg_5.RecordedBy, stocktakingListConsumable.Result);
        return {
            Name: stocktakingListConsumable.ConsumableName,
            Quantity: Quantity_2,
            RecordTimestamp: unwrap(map_1((_arg_6) => _arg_6.Timestamp, stocktakingListConsumable.Result)),
            RecordedBy: unwrap(RecordedBy_2),
            RecordedQuantity: unwrap(map_1((arg_1) => arg_1.Quantity.toString(), stocktakingListConsumable.Result)),
            StocktakingResultType: new StocktakingResultType(2, [stocktakingListConsumable.ConsumableId]),
        };
    }, props.StocktakingList.Consumables)));
    const xs_3 = [createElement(ConfirmStocktakingResultDialog, {
        IsOpen: unwrap(patternInput_1[0]),
        OnClose: () => {
            setIsConfirmStocktakingResultDialogOpen(undefined);
            props.OnUpdate();
        },
        Stocktaking: props.Stocktaking,
        StocktakingListId: props.StocktakingList.Id,
    }), createElement(Table, (TableRows = toArray(rows), (Options = {
        BorderStyle: "row",
        CSVDownload: true,
        ConfigurableColumns: false,
        DefaultSortColumn: ["name", "asc"],
        GlobalSearch: true,
        RowSelection: "singleRow",
    }, {
        ColumnDefinitions: [new ColumnDefinition$1("status_icon", "", t("stocktaking.status"), (dto) => {
            let matchValue, elems_2, elems, elems_1;
            return new CellContent(1, [(dto.RecordedBy != null) ? ((matchValue = dto.RecordedQuantity, (matchValue == null) ? createElement("div", createObj(ofArray([["className", join(" ", ["text-primary", "h-5", "w-5"])], (elems_2 = [Check()], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))) : ((matchValue === dto.Quantity) ? createElement("div", createObj(ofArray([["className", join(" ", ["text-primary", "h-5", "w-5"])], (elems = [Check()], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))) : createElement("div", createObj(ofArray([["className", join(" ", ["text-red", "h-5", "w-5"])], (elems_1 = [Delete_1()], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))))) : defaultOf()]);
        }, "none", "text"), new ColumnDefinition$1("name", "", t("general.name"), (dto_1) => (new CellContent(0, [dto_1.Name])), "text", "text"), new ColumnDefinition$1("quantity", "", t("general.quantity"), (dto_2) => (new CellContent(0, [dto_2.Quantity])), "text", "text"), new ColumnDefinition$1("recordedBy", "", t("stocktaking.recorded_by"), (dto_3) => (new CellContent(0, [defaultArg(dto_3.RecordedBy, "")])), "text", "text"), new ColumnDefinition$1("recordTimestamp", "", t("stocktaking.record_timestamp"), (dto_4) => (new CellContent(0, [defaultArg(map_1(toString, dto_4.RecordTimestamp), "")])), "none", "text"), new ColumnDefinition$1("recordedQuantity", "", t("stocktaking.recorded_quantity"), (dto_5) => (new CellContent(0, [defaultArg(dto_5.RecordedQuantity, "")])), "text", "text")],
        Controls: singleton(new TableControl$1(t("stocktaking.todo"), Qr_Code(), (value_11) => {
            let matchResult, selectedElement;
            if (!isEmpty(value_11)) {
                if (isEmpty(tail(value_11))) {
                    matchResult = 0;
                    selectedElement = head(value_11);
                }
                else {
                    matchResult = 1;
                }
            }
            else {
                matchResult = 1;
            }
            switch (matchResult) {
                case 0: {
                    setIsConfirmStocktakingResultDialogOpen(selectedElement.StocktakingResultType);
                    break;
                }
                case 1: {
                    break;
                }
            }
        }, true, false, "stocktaking-assign-helper-to-list")),
        Options: Options,
        TableRows: TableRows,
    })))];
    return react.createElement(react.Fragment, {}, ...xs_3);
}

class SelectedTab extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Overview", "RequiredActions"];
    }
}

function SelectedTab_$reflection() {
    return union_type("Stocktaking.Dialog.StocktakingListDetailModalSheet.SelectedTab", [], SelectedTab, () => [[], []]);
}

export function StocktakingListDetailModalSheet(props) {
    let IsModalOpen, matchValue, stocktakingList, elems;
    const t = useTranslation()[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(new SelectedTab(0, []));
    const setSelectedTab = patternInput_1[1];
    const selectedTab = patternInput_1[0];
    return createElement(ModalSheet, (IsModalOpen = (props.StocktakingList != null), {
        CloseButtonTestId: "close-stocktaking-modal-button",
        Content: (matchValue = props.StocktakingList, (matchValue == null) ? defaultOf() : ((stocktakingList = matchValue, createElement("div", createObj(ofArray([["className", join(" ", ["p-4"])], (elems = toList(delay(() => {
            let Label, Label_1;
            return append_1(singleton_1(createElement(TabsMenu, {
                Tabs: ofArray([(Label = t("general.overview"), {
                    IsSelected: equals(selectedTab, new SelectedTab(0, [])),
                    Label: Label,
                    OnClick: () => {
                        setSelectedTab(new SelectedTab(0, []));
                    },
                    TestId: "",
                }), (Label_1 = t("stocktaking.required_actions"), {
                    IsSelected: equals(selectedTab, new SelectedTab(1, [])),
                    Label: Label_1,
                    OnClick: () => {
                        setSelectedTab(new SelectedTab(1, []));
                    },
                    TestId: "",
                })]),
            })), delay(() => {
                if (selectedTab.tag === 1) {
                    const matchValue_2 = stocktakingList.RequiredActions;
                    return (matchValue_2 == null) ? singleton_1(defaultOf()) : singleton_1(createElement(RequiredActionsTable, {
                        StocktakingListRequiredActions: matchValue_2,
                    }));
                }
                else {
                    return singleton_1(createElement(StocktakingListOverviewTable, {
                        OnUpdate: props.OnUpdate,
                        Stocktaking: props.Stocktaking,
                        StocktakingList: stocktakingList,
                    }));
                }
            }));
        })), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))))),
        Controls: empty(),
        IsModalOpen: IsModalOpen,
        OnClose: props.OnClose,
    }));
}

