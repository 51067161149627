import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Skeleton } from "../Components/Skeleton.js";
import { OnTableRowClick$1, Table, ColumnDefinition$1, CellContent } from "../Widgets/Table/Table.js";
import { unwrap, map, defaultArg } from "../fable_modules/fable-library-js.4.19.2/Option.js";
import { singleton, contains, filter, map as map_1, toArray, ofArray, empty, tryFind } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { stringHash, createObj, equals } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { isNullOrWhiteSpace, printf, toText, join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { RouterModule_nav, RouterModule_encodeParts } from "../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { unwrapStorageId } from "../Shared/Helper.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { TabsMenu } from "../Widgets/TabsMenu.js";
import { multiSelectWithLabel, selectWithLabel, CommonPropsMultiSelect$1, toNoOptionsMessage, CommonProps$1, OptionType$1 } from "../SharedComponents/ReactSelect.js";
import { Msg } from "./Types.js";
import { tryFind as tryFind_1 } from "../fable_modules/fable-library-js.4.19.2/Array.js";
import { React_useElmish_Z6C327F2E } from "../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { initEditStorageView, initNewStorageView, initViewStorageView, update as update_1, init as init_1 } from "./State.js";
import { Button } from "../Components/Button.js";
import { FormContainer } from "../Components/FormContainer.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { Input } from "../Components/Input.js";
import { InformationAlert, WarningAlert } from "../Components/Alert.js";
import { Checkbox } from "../Components/Checkbox.js";
import { TableContainer } from "../Widgets/Container.js";
import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.2.7.0/React.fs.js";
import { ConfirmationDialog } from "../Components/Dialog.js";
import { DetailViewHeader } from "../Widgets/DetailViewHeader.js";
import { LabeledValue } from "../Components/Text.js";

export function StorageOverviewTable(props) {
    let elems;
    const t = useTranslation()[0];
    const matchValue = props.State.Locations;
    const matchValue_1 = props.State.Storages;
    let matchResult, locations, storages;
    if (matchValue.tag === 1) {
        if (matchValue_1.tag === 1) {
            matchResult = 1;
            locations = matchValue.fields[0];
            storages = matchValue_1.fields[0];
        }
        else {
            matchResult = 0;
        }
    }
    else {
        matchResult = 0;
    }
    switch (matchResult) {
        case 0:
            return createElement(Skeleton, {
                Variant: "table",
            });
        default: {
            const columnDefinitions = [new ColumnDefinition$1("name", "", t("general.name"), (dto) => (new CellContent(0, [dto.Name])), "text", "text"), new ColumnDefinition$1("Description", "", t("general.description"), (dto_1) => (new CellContent(0, [dto_1.Description])), "text", "text"), new ColumnDefinition$1("Location", "", t("general.location"), (dto_2) => {
                const matchValue_3 = dto_2.LocationId;
                if (matchValue_3 != null) {
                    const locationID = matchValue_3;
                    return defaultArg(map((location_1) => (new CellContent(0, [location_1.Name])), tryFind((location) => equals(location.Id, locationID), locations)), new CellContent(0, [""]));
                }
                else {
                    return new CellContent(0, [""]);
                }
            }, "select", "text")];
            return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col"])], (elems = [createElement(Table, {
                ColumnDefinitions: columnDefinitions,
                Controls: empty(),
                Options: {
                    BorderStyle: "row",
                    CSVDownload: false,
                    ConfigurableColumns: false,
                    DefaultSortColumn: ["name", "asc"],
                    GlobalSearch: false,
                    OnRowClick: new OnTableRowClick$1(1, [(dto_3) => RouterModule_encodeParts(ofArray(["storages", unwrapStorageId(dto_3.Id)]), 1)]),
                    RowSelection: "disabled",
                },
                TableRows: toArray(storages),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
        }
    }
}

export function Container() {
    let elems_2, elems_1, elems;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "align-text-middle"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["pr-4"])], (elems = [createElement(TabsMenu, {
        Tabs: ofArray([{
            IsSelected: true,
            Label: t("navbar.storages"),
            OnClick: () => {
                RouterModule_nav(ofArray(["storages"]), 1, 1);
            },
            TestId: "",
        }, {
            IsSelected: false,
            Label: t("navbar.locations"),
            OnClick: () => {
                RouterModule_nav(ofArray(["locations"]), 1, 1);
            },
            TestId: "",
        }]),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

function LocationProps(props) {
    let matchValue, locationId;
    const options = toArray(map_1((location) => (new OptionType$1(location.Name, location)), props.Locations));
    return [new CommonProps$1(0, [options]), new CommonProps$1(2, [(arg) => {
        props.Dispatch(new Msg(3, [arg]));
    }]), new CommonProps$1(5, [true]), new CommonProps$1(9, [false]), new CommonProps$1(6, ["select.location_placeholder"]), new CommonProps$1(10, [(_arg) => toNoOptionsMessage("select.location_no_options", _arg)]), new CommonProps$1(11, ["flex-grow-1"]), new CommonProps$1(3, [(matchValue = props.StorageForm.LocationId, (matchValue == null) ? undefined : ((locationId = matchValue, tryFind_1((location_1) => equals(location_1.value.Id, locationId), options))))])];
}

function AssignedUserProps(props) {
    const userToSelectElement = (user) => (new OptionType$1(user.Name, user));
    const valueElements = new CommonPropsMultiSelect$1(3, [toArray(map_1(userToSelectElement, filter((u_1) => contains(u_1.Id, props.StorageForm.AssignedUserIds, {
        Equals: (x_1, y_1) => (x_1 === y_1),
        GetHashCode: stringHash,
    }), props.Users)))]);
    return [new CommonPropsMultiSelect$1(0, [toArray(map_1(userToSelectElement, filter((u) => {
        if (!contains(u.Id, props.StorageForm.AssignedUserIds, {
            Equals: (x, y) => (x === y),
            GetHashCode: stringHash,
        }) && (u.Role === "administrator")) {
            return true;
        }
        else {
            return u.Role === "toolManager";
        }
    }, props.Users)))]), new CommonPropsMultiSelect$1(2, [(arg) => {
        props.Dispatch(new Msg(4, [arg]));
    }]), new CommonPropsMultiSelect$1(5, [true]), new CommonPropsMultiSelect$1(9, [false]), new CommonPropsMultiSelect$1(8, [true]), new CommonPropsMultiSelect$1(6, ["select.tool_manager_placeholder"]), new CommonPropsMultiSelect$1(11, [(_arg) => toNoOptionsMessage("select.tool_manager_no_options", _arg)]), new CommonPropsMultiSelect$1(12, ["flex-grow-1"]), valueElements];
}

function AssignedSelfServiceUsers(props) {
    const userToSelectElement = (user) => (new OptionType$1(user.Name, user));
    const valueElements = new CommonPropsMultiSelect$1(3, [toArray(map_1(userToSelectElement, filter((u) => contains(u.Id, props.StorageForm.AccessByUser, {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    }), props.Users)))]);
    const matchValue = props.State.Users;
    if (matchValue.tag === 1) {
        return [new CommonPropsMultiSelect$1(0, [toArray(map_1(userToSelectElement, matchValue.fields[0]))]), new CommonPropsMultiSelect$1(2, [(arg) => {
            props.Dispatch(new Msg(5, [arg]));
        }]), new CommonPropsMultiSelect$1(5, [true]), new CommonPropsMultiSelect$1(9, [false]), new CommonPropsMultiSelect$1(8, [true]), new CommonPropsMultiSelect$1(6, ["select.self_service_user_placeholder"]), new CommonPropsMultiSelect$1(11, [(_arg) => toNoOptionsMessage("select.employee_no_options", _arg)]), new CommonPropsMultiSelect$1(12, ["flex-grow-1"]), valueElements];
    }
    else {
        return [];
    }
}

export function DetailBtn(props) {
    let elems_1, elems;
    const patternInput = useTranslation();
    return createElement("a", createObj(ofArray([["href", RouterModule_encodeParts(singleton(RouterModule_encodeParts(singleton(toText(printf("/storages/view/storage/%O"))(props.Storage.Id.fields[0])), 1)), 1)], (elems_1 = [createElement("i", {
        className: "fas fa-eye",
    }), createElement("span", createObj(ofArray([["className", "url-text"], (elems = [patternInput[0]("general.display")], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function StoragesOverview() {
    let elems_2, elems_1, elems;
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init_1, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const patternInput_1 = useTranslation();
    return createElement("div", createObj(ofArray([["id", "storage-container"], ["className", join(" ", [])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-between", "mb-5", "items-center"])], (elems_1 = [createElement(Container, null), createElement("div", createObj(ofArray([["className", join(" ", [])], (elems = [createElement(Button, {
        ComponentState: "enabled",
        Label: patternInput_1[0]("storage.create_new_storage"),
        OnClick: () => {
            RouterModule_nav(ofArray(["storages", "new"]), 1, 1);
        },
        TestId: "new-storage-button",
        Variant: "primary",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement(StorageOverviewTable, {
        State: patternInput[0],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

export function StorageForm(props) {
    const t = useTranslation()[0];
    const matchValue = props.State.StorageForm;
    const matchValue_1 = props.State.Locations;
    const matchValue_2 = props.State.Users;
    const matchValue_3 = props.State.Configuration;
    let matchResult, configuration, location, storage, users;
    if (matchValue_3.tag === 1) {
        if (matchValue_2.tag === 1) {
            if (matchValue_1.tag === 1) {
                if (matchValue.tag === 1) {
                    matchResult = 1;
                    configuration = matchValue_3.fields[0];
                    location = matchValue_1.fields[0];
                    storage = matchValue.fields[0];
                    users = matchValue_2.fields[0];
                }
                else {
                    matchResult = 0;
                }
            }
            else {
                matchResult = 0;
            }
        }
        else {
            matchResult = 0;
        }
    }
    else {
        matchResult = 0;
    }
    switch (matchResult) {
        case 0:
            return createElement(Skeleton, {
                Variant: "detail",
            });
        default:
            return createElement(FormContainer, {
                Children: toList(delay(() => append(singleton_1(createElement(Input, {
                    ComponentState: "enabled",
                    Label: t("general.name"),
                    OnChange: (arg) => {
                        props.Dispatch(new Msg(0, [arg]));
                    },
                    TestId: "",
                    ValidationMessage: unwrap((props.State.IsValidated && isNullOrWhiteSpace(storage.Name)) ? t("storage.invalid_name") : undefined),
                    Value: storage.Name,
                })), delay(() => append(singleton_1(createElement(Input, {
                    ComponentState: "enabled",
                    Label: t("general.description"),
                    OnChange: (arg_1) => {
                        props.Dispatch(new Msg(1, [arg_1]));
                    },
                    TestId: "",
                    Value: storage.Description,
                })), delay(() => append(singleton_1(selectWithLabel(LocationProps({
                    Dispatch: props.Dispatch,
                    Locations: location,
                    StorageForm: storage,
                }), t("general.location"), "")), delay(() => append(singleton_1(multiSelectWithLabel(AssignedUserProps({
                    Dispatch: props.Dispatch,
                    StorageForm: storage,
                    Users: users,
                }), t("general.stockist"), "")), delay(() => (configuration.GlobalSelfServiceStorageActive ? singleton_1(createElement(WarningAlert, {
                    Label: "storage.self_service_is_configured_global",
                })) : append(singleton_1(Checkbox({
                    ComponentState: "enabled",
                    IsChecked: storage.IsSelfService,
                    Label: t("storage.is_self_service_active"),
                    OnCheck: (arg_2) => {
                        props.Dispatch(new Msg(2, [arg_2]));
                    },
                    TestId: "",
                })), delay(() => append(singleton_1(createElement(InformationAlert, {
                    Label: t("storage.is_self_service_active_info"),
                })), delay(() => (!storage.IsSelfService ? singleton_1(multiSelectWithLabel(AssignedSelfServiceUsers({
                    Dispatch: props.Dispatch,
                    State: props.State,
                    StorageForm: storage,
                    Users: users,
                }), t("storage.self_service_user"), "")) : empty_1())))))))))))))))),
                Header: props.Header,
                OnBack: props.OnBack,
                OnSave: props.OnSave,
            });
    }
}

function UserListSection(props) {
    let Title, elems, TableRows;
    const t = useTranslation()[0];
    const displayUsers = filter((user) => contains(user.Id, props.DisplayUserIds, {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    }), props.Users);
    return createElement(TableContainer, (Title = t(props.Header), {
        Content: createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col"])], (elems = [createElement(Table, (TableRows = toArray(displayUsers), {
            ColumnDefinitions: [new ColumnDefinition$1("name", "", t("general.name"), (dto) => (new CellContent(0, [dto.Name])), "none", "text"), new ColumnDefinition$1("id", "", t("general.role"), (dto_1) => {
                let matchValue;
                return new CellContent(0, [(matchValue = dto_1.Role, (matchValue === "administrator") ? t("role.admin") : ((matchValue === "toolManager") ? t("role.tool_manager") : ((matchValue === "user") ? t("role.employee") : t("role.system"))))]);
            }, "none", "text"), new ColumnDefinition$1("department", "", t("user.department"), (dto_2) => (new CellContent(0, [dto_2.Department])), "none", "text")],
            Controls: empty(),
            Options: {
                BorderStyle: "row",
                CSVDownload: false,
                ConfigurableColumns: false,
                DefaultSortColumn: ["name", "asc"],
                GlobalSearch: false,
                RowSelection: "disabled",
            },
            TableRows: TableRows,
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))),
        Title: unwrap(Title),
    }));
}

export function StorageDetails(props) {
    let elems_4;
    const t = useTranslation()[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setIsDeleteDialogOpen = patternInput_1[1];
    const isDeleteDialogOpen = patternInput_1[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["max-w-[75rem]"])], (elems_4 = toList(delay(() => {
        let Title;
        return append(singleton_1(createElement(ConfirmationDialog, (Title = t("general.delete"), {
            Description: t("storage.storage_delete_msg"),
            IsOpen: isDeleteDialogOpen,
            OnClose: () => {
                setIsDeleteDialogOpen(false);
            },
            OnConfirm: () => {
                props.Dispatch(new Msg(9, []));
            },
            Title: Title,
        }))), delay(() => append(singleton_1(createElement(DetailViewHeader, {
            Badges: empty(),
            OnBack: () => {
                RouterModule_nav(ofArray(["storages"]), 1, 1);
            },
            OnDelete: () => {
                setIsDeleteDialogOpen(!isDeleteDialogOpen);
            },
            OnEdit: () => {
                RouterModule_nav(ofArray(["storages", unwrapStorageId(props.Storage.Id), "edit"]), 1, 1);
            },
            Title: t("storage.storage_details"),
        })), delay(() => {
            let elems_1, elems;
            return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-shrink-0", "flex-col", "mb-3", "bg-white", "rounded", "px-12", "py-9"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["grow", "mx-4", "mt-3"])], (elems = toList(delay(() => append(singleton_1(LabeledValue({
                Label: t("general.name"),
                Value: props.Storage.Name,
            })), delay(() => append(singleton_1(LabeledValue({
                Label: t("general.description"),
                Value: props.Storage.Description,
            })), delay(() => {
                let matchValue, locationId;
                return append(singleton_1(LabeledValue({
                    Label: t("general.location"),
                    Value: (matchValue = props.Storage.LocationId, (matchValue != null) ? ((locationId = matchValue, defaultArg(map((location_1) => location_1.Name, tryFind((location) => equals(location.Id, locationId), props.Locations)), ""))) : ""),
                })), delay(() => (props.Storage.IsSelfService ? singleton_1(LabeledValue({
                    Label: t("storage.self_service_user"),
                    Value: t("storage.is_self_service_active"),
                })) : empty_1())));
            })))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => {
                let elems_2;
                return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["pt-5"])], (elems_2 = [createElement(UserListSection, {
                    DisplayUserIds: props.Storage.AssignedUserIds,
                    Header: "storage.stockists",
                    Users: props.Users,
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
                    let elems_3;
                    return !props.Storage.IsSelfService ? singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["pt-5"])], (elems_3 = [createElement(UserListSection, {
                        DisplayUserIds: props.Storage.AccessByUser,
                        Header: "storage.self_service_user",
                        Users: props.Users,
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))) : empty_1();
                }));
            }));
        }))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])));
}

export function StorageDetailsView(props) {
    let patternInput;
    const init = initViewStorageView(props.StorageId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, [props.StorageId]);
    const state_1 = patternInput[0];
    const matchValue = state_1.StorageForm;
    const matchValue_1 = state_1.Locations;
    const matchValue_2 = state_1.Users;
    let matchResult, locations, storage, users;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_2.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        locations = matchValue_1.fields[0];
        storage = matchValue.fields[0];
        users = matchValue_2.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement(StorageDetails, {
                Dispatch: patternInput[1],
                Locations: locations,
                Storage: storage,
                Users: users,
            });
        default:
            return createElement(Skeleton, {
                Variant: "detail",
            });
    }
}

export function StorageNewView() {
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(initNewStorageView, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const dispatch = patternInput[1];
    return createElement(StorageForm, {
        Dispatch: dispatch,
        Header: useTranslation()[0]("storage.create_new_storage"),
        OnBack: () => {
            RouterModule_nav(ofArray(["storages"]), 1, 1);
        },
        OnSave: () => {
            dispatch(new Msg(7, []));
        },
        State: patternInput[0],
    });
}

export function StorageEditView(props) {
    let patternInput;
    const init = initEditStorageView(props.StorageId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, [props.StorageId]);
    const dispatch = patternInput[1];
    return createElement(StorageForm, {
        Dispatch: dispatch,
        Header: useTranslation()[0]("storage.storage_edit"),
        OnBack: () => {
            RouterModule_nav(ofArray(["storages"]), 1, 1);
        },
        OnSave: () => {
            dispatch(new Msg(8, []));
        },
        State: patternInput[0],
    });
}

