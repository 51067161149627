import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { Button, TextButton } from "./Button.js";
import Arrow_Left_1 from "../../public/assets/icons/Arrow_Left_1.svg";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { PageHeader } from "./Text.js";
import { singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";

export function FormContainer(props) {
    let elems_3, elems, Label, elems_2;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "max-w-5xl"])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["mb-5"])], (elems = [createElement(TextButton, (Label = t("general.back"), {
        ComponentState: "enabled",
        Icon: Arrow_Left_1(),
        Label: Label,
        OnClick: props.OnBack,
        TestId: "form-on-back-button",
        Variant: "default",
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement(PageHeader, {
        Text: props.Header,
    }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-5"])], (elems_2 = toList(delay(() => append(props.Children, delay(() => {
        let elems_1;
        return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-end"])], (elems_1 = [createElement(Button, {
            ComponentState: "enabled",
            Label: t("general.save"),
            OnClick: props.OnSave,
            TestId: "submit-form-button",
            Variant: "primary",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))));
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
}

