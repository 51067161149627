import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { unwrap, map as map_1, ofNullable } from "../fable_modules/fable-library-js.4.19.2/Option.js";
import { ofArray, singleton as singleton_1, contains, filter, map } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { createObj, stringHash } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { singleton, empty, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { TextValue } from "../Components/Text.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { Select } from "../Components/Select.js";
import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.2.7.0/React.fs.js";
import { Dialog } from "../Components/Dialog.js";
import { TextButton } from "../Components/Button.js";

function DialogBody(props) {
    let elems_2;
    const t = useTranslation()[0];
    let activeTenant;
    const matchValue = ofNullable(localStorage.getItem("tenant"));
    activeTenant = ((matchValue == null) ? props.Customer : matchValue);
    const userTenants = map((tenant_1) => tenant_1.Tenant, props.UserTenants);
    const options = filter((tenant_4) => (tenant_4.label !== activeTenant), map((tenant_3) => ({
        label: tenant_3.Label,
        value: tenant_3.Customer,
    }), filter((tenant_2) => {
        if (contains(tenant_2.Customer, userTenants, {
            Equals: (x, y) => (x === y),
            GetHashCode: stringHash,
        })) {
            return true;
        }
        else {
            return props.Customer === tenant_2.Customer;
        }
    }, props.Tenants)));
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-3"])], (elems_2 = toList(delay(() => {
        let matchValue_1, tenantName, elems;
        return append((matchValue_1 = props.TenantName, (matchValue_1 == null) ? (empty()) : ((tenantName = matchValue_1, singleton(createElement("div", createObj(singleton_1((elems = [createElement(TextValue, {
            FontWeight: "semiBold",
            TestId: "",
            Text: t("tenant.active_tenant"),
        }), createElement(TextValue, {
            FontWeight: "normal",
            TestId: "",
            Text: tenantName,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])))))))), delay(() => {
            let elems_1;
            return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mb-8"])], (elems_1 = [createElement(Select, {
                ComponentState: "enabled",
                IsClearable: false,
                Label: t("tenant.tenant"),
                NoOptionsMessage: "",
                OnChange: (option) => {
                    props.OnSelectTenant(map_1((option_1) => option_1.value, option));
                },
                Options: options,
                PlaceholderKey: "",
                TestId: "",
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

export function OpenChangeTenantDialog(props) {
    let Title, Label_1;
    const t = useTranslation()[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(undefined);
    const setSelectedTenant = patternInput_1[1];
    const selectedTenant = patternInput_1[0];
    return createElement(Dialog, (Title = t("tenant.change_tenant"), {
        Body: singleton_1(createElement(DialogBody, {
            Customer: props.Customer,
            OnSelectTenant: setSelectedTenant,
            TenantName: unwrap(props.TenantName),
            Tenants: props.Tenants,
            UserTenants: props.UserTenants,
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.cancel"),
            OnClick: () => {
                setSelectedTenant(undefined);
                props.OnClose();
            },
            TestId: "close-tanent-change-dialog-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, (Label_1 = t("tenant.change_now"), {
            ComponentState: (selectedTenant == null) ? "disabled" : "enabled",
            Label: Label_1,
            OnClick: () => {
                if (selectedTenant == null) {
                    localStorage.removeItem("tenant");
                }
                else {
                    const tenant = selectedTenant;
                    if (tenant === props.Customer) {
                        localStorage.removeItem("tenant");
                    }
                    else {
                        localStorage.setItem("tenant", tenant);
                    }
                }
                window.location.reload();
                props.OnClose();
            },
            TestId: "close-tanent-change-dialog-test-id",
            Variant: "blueButton",
        }))]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

