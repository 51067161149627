import { Record, Union } from "../../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, bool_type, lambda_type, unit_type, union_type, class_type, list_type, anonRecord_type, option_type, int32_type, string_type } from "../../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { Helper_receiverTypeNameTranslation, ReceiverTypeName, Receiver_$reflection } from "../../../Shared/User.js";
import { ToolWithQuantityLostDto, ToolWithQuantityDetailDto_$reflection, ToolWithQuantityLostDto_$reflection, ToolWithQuantityLostResponse_$reflection } from "../../../Shared/Tool.js";
import { RequestedValue$1, RequestedValue$1_$reflection, PostResponse$1_$reflection } from "../../../Shared/Shared.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none, Cmd_OfPromise_either } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../../Communication.js";
import { Types_RequestProperties } from "../../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { sortBy, toArray, contains, filter, map as map_1, singleton, ofArray } from "../../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { Cmd_OfPromise_either as Cmd_OfPromise_either_1 } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_successToast } from "../../../Components/Toast.js";
import { unwrap, map } from "../../../fable_modules/fable-library-js.4.19.2/Option.js";
import { safeHash, comparePrimitives, equals, createObj, stringHash } from "../../../fable_modules/fable-library-js.4.19.2/Util.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { join } from "../../../fable_modules/fable-library-js.4.19.2/String.js";
import { singleton as singleton_1, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { defaultOf } from "../../../fable_modules/fable-library-js.4.19.2/Util.js";
import { ErrorAlert } from "../../../Components/Alert.js";
import { NumberInput } from "../../../Components/Input.js";
import { numberInputIntMaxValue } from "../../../Common.js";
import { GroupedSelect } from "../../../Components/Select.js";
import { List_groupBy } from "../../../fable_modules/fable-library-js.4.19.2/Seq2.js";
import { TextArea } from "../../../Components/TextArea.js";
import { Interop_reactApi } from "../../../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Dialog } from "../../../Components/Dialog.js";
import { Skeleton } from "../../../Components/Skeleton.js";
import { TextButton } from "../../../Components/Button.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetComment", "SetQuantity", "SetSelectedReceiver", "ToolWithQuantityLostResponse", "ReportLost", "ReceiversFetched", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Tools.LostToolWithQuantityDialog.Msg", [], Msg, () => [[["Item", string_type]], [["Item", option_type(int32_type)]], [["Item", option_type(anonRecord_type(["label", string_type], ["value", Receiver_$reflection()]))]], [["Item", PostResponse$1_$reflection(ToolWithQuantityLostResponse_$reflection())]], [], [["Item", list_type(Receiver_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

function assignToolCmd(dto) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(ToolWithQuantityLostResponse_$reflection())));
    })))), ["/api/tools/tool-with-quantity/lost", toString(0, Auto_generateBoxedEncoder_437914C6(ToolWithQuantityLostDto_$reflection(), undefined, undefined, undefined)(dto))], (Item) => (new Msg(3, [Item])), (Item_1) => (new Msg(6, [Item_1])));
}

class State extends Record {
    constructor(Tool, UserId, Receivers, SuccessCallback, OnClose, IsLoading, Dto, SelectedReceiver, Quantity, IsQuantityInvalid, IsUserStockInvalid, Error$) {
        super();
        this.Tool = Tool;
        this.UserId = UserId;
        this.Receivers = Receivers;
        this.SuccessCallback = SuccessCallback;
        this.OnClose = OnClose;
        this.IsLoading = IsLoading;
        this.Dto = Dto;
        this.SelectedReceiver = SelectedReceiver;
        this.Quantity = Quantity;
        this.IsQuantityInvalid = IsQuantityInvalid;
        this.IsUserStockInvalid = IsUserStockInvalid;
        this.Error = Error$;
    }
}

function State_$reflection() {
    return record_type("Tools.LostToolWithQuantityDialog.State", [], State, () => [["Tool", ToolWithQuantityDetailDto_$reflection()], ["UserId", string_type], ["Receivers", RequestedValue$1_$reflection(list_type(Receiver_$reflection()))], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["OnClose", lambda_type(unit_type, unit_type)], ["IsLoading", bool_type], ["Dto", ToolWithQuantityLostDto_$reflection()], ["SelectedReceiver", option_type(anonRecord_type(["label", string_type], ["value", Receiver_$reflection()]))], ["Quantity", option_type(int32_type)], ["IsQuantityInvalid", bool_type], ["IsUserStockInvalid", option_type(int32_type)], ["Error", option_type(string_type)]]);
}

function init(props) {
    return [new State(props.Tool, props.UserId, new RequestedValue$1(0, []), props.SuccessCallback, props.OnClose, false, new ToolWithQuantityLostDto(props.Tool.Id, undefined, 1, ""), undefined, undefined, false, undefined, undefined), Cmd_OfPromise_either_1((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(Receiver_$reflection())));
    })))), "/api/receivers/active", (Item) => (new Msg(5, [Item])), (Item_1) => (new Msg(6, [Item_1])))];
}

function update(msg, state) {
    let bind$0040, bind$0040_1;
    switch (msg.tag) {
        case 0:
            return [new State(state.Tool, state.UserId, state.Receivers, state.SuccessCallback, state.OnClose, state.IsLoading, (bind$0040 = state.Dto, new ToolWithQuantityLostDto(bind$0040.ToolId, bind$0040.ReceiverId, bind$0040.Quantity, msg.fields[0])), state.SelectedReceiver, state.Quantity, state.IsQuantityInvalid, state.IsUserStockInvalid, state.Error), Cmd_none()];
        case 1:
            return [new State(state.Tool, state.UserId, state.Receivers, state.SuccessCallback, state.OnClose, state.IsLoading, state.Dto, state.SelectedReceiver, msg.fields[0], state.IsQuantityInvalid, state.IsUserStockInvalid, state.Error), Cmd_none()];
        case 2:
            return [new State(state.Tool, state.UserId, state.Receivers, state.SuccessCallback, state.OnClose, state.IsLoading, state.Dto, msg.fields[0], state.Quantity, state.IsQuantityInvalid, state.IsUserStockInvalid, state.Error), Cmd_none()];
        case 3: {
            let patternInput;
            const matchValue = msg.fields[0].Result;
            patternInput = ((matchValue.tag === 1) ? ["tool_with_quantity_lost_dialog.no_receiver_id_set", Cmd_none()] : ((matchValue.tag === 2) ? ["tool_with_quantity_lost_dialog.wrong_tool_type", Cmd_none()] : ((matchValue.tag === 3) ? ["tool_with_quantity_lost_dialog.no_user_assignment", Cmd_none()] : ((matchValue.tag === 4) ? ["tool_with_quantity_lost_dialog.user_stock_small", Cmd_none()] : ((matchValue.tag === 5) ? ["tool_with_quantity_lost_dialog.error", Cmd_none()] : ((matchValue.tag === 6) ? [undefined, Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                state.SuccessCallback();
            }), Cmd_successToast("tool_with_quantity_lost_dialog.success"), Cmd_ofEffect((_arg_1) => {
                state.OnClose();
            })]))] : ["tool_with_quantity_lost_dialog.no_valid_quantity_set", Cmd_none()]))))));
            return [new State(state.Tool, state.UserId, state.Receivers, state.SuccessCallback, state.OnClose, false, state.Dto, state.SelectedReceiver, state.Quantity, state.IsQuantityInvalid, state.IsUserStockInvalid, patternInput[0]), patternInput[1]];
        }
        case 4: {
            const matchValue_1 = state.Quantity;
            if (matchValue_1 != null) {
                const quantity = matchValue_1 | 0;
                return [new State(state.Tool, state.UserId, state.Receivers, state.SuccessCallback, state.OnClose, true, state.Dto, state.SelectedReceiver, state.Quantity, false, state.IsUserStockInvalid, state.Error), assignToolCmd((bind$0040_1 = state.Dto, new ToolWithQuantityLostDto(bind$0040_1.ToolId, map((r) => r.value.Id, state.SelectedReceiver), quantity, bind$0040_1.Comment)))];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        case 6:
            return [new State(state.Tool, state.UserId, state.Receivers, state.SuccessCallback, state.OnClose, false, state.Dto, state.SelectedReceiver, state.Quantity, state.IsQuantityInvalid, state.IsUserStockInvalid, state.Error), Cmd_none()];
        default: {
            const userIdsWithAssignment = map_1((u) => u.ReceiverId, state.Tool.UserAssignments);
            return [new State(state.Tool, state.UserId, new RequestedValue$1(1, [filter((u_1) => contains(u_1.Id, userIdsWithAssignment, {
                Equals: (x, y) => (x === y),
                GetHashCode: stringHash,
            }), msg.fields[0])]), state.SuccessCallback, state.OnClose, state.IsLoading, state.Dto, state.SelectedReceiver, state.Quantity, state.IsQuantityInvalid, state.IsUserStockInvalid, state.Error), Cmd_none()];
        }
    }
}

function DialogBody(props) {
    let elems;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-4"])], (elems = toList(delay(() => {
        let matchValue;
        return append((matchValue = props.Error, (matchValue == null) ? singleton_1(defaultOf()) : singleton_1(createElement(ErrorAlert, {
            Label: matchValue,
        }))), delay(() => append(singleton_1(createElement(NumberInput, {
            ComponentState: props.FormState,
            Label: t("general.quantity_3"),
            MaxValue: numberInputIntMaxValue,
            OnChange: (arg) => {
                props.SetQuantity(map((value_2) => ~~value_2, arg));
            },
            TestId: "quantity-report-lost-dialog-test-id",
            Value: unwrap(map((value_1) => value_1, props.Quantity)),
        })), delay(() => append(singleton_1(createElement(GroupedSelect, (($value) => { $value.key = $value.Key; return $value; })({
            ComponentState: "enabled",
            IsClearable: true,
            Key: "select-receiver",
            Label: t("general.receiver"),
            NoOptionsMessage: "select.employee_no_options",
            OnChange: props.SetSelectedReceiver,
            Options: map_1((tupledArg) => {
                const receiverType = tupledArg[0];
                const receivers_1 = toArray(sortBy((receiver_2) => receiver_2.label.toLowerCase(), map_1((receiver_1) => {
                    const matchValue_1 = receiver_1.LocationName;
                    if (matchValue_1 != null) {
                        const locationName = matchValue_1;
                        if (equals(receiverType, new ReceiverTypeName(4, []))) {
                            return {
                                label: `${locationName} - ${receiver_1.Name}`,
                                value: receiver_1,
                            };
                        }
                        else {
                            return {
                                label: `${receiver_1.Name} - ${locationName}`,
                                value: receiver_1,
                            };
                        }
                    }
                    else {
                        return {
                            label: `${receiver_1.Name}`,
                            value: receiver_1,
                        };
                    }
                }, tupledArg[1]), {
                    Compare: comparePrimitives,
                }));
                return {
                    label: Helper_receiverTypeNameTranslation(t, receiverType),
                    options: receivers_1,
                };
            }, List_groupBy((receiver) => receiver.ReceiverType, props.Users, {
                Equals: equals,
                GetHashCode: safeHash,
            })),
            PlaceholderKey: "select.employee_placeholder",
            Value: unwrap(props.SelectedReceiver),
        }))), delay(() => singleton_1(createElement(TextArea, {
            ComponentState: props.FormState,
            Label: t("general.comment"),
            OnChange: props.SetComment,
            TestId: "",
            Value: props.Comment,
        }))))))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function LostToolWithQuantityDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init({
        OnClose: props.OnClose,
        SuccessCallback: props.SuccessCallback,
        Tool: props.Tool,
        UserId: props.UserId,
    });
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    const formState = state_1.IsLoading ? "disabled" : "enabled";
    return createElement(Dialog, (Title = t("tool_with_quantity_lost_dialog.lost_button"), {
        Body: toList(delay(() => {
            const matchValue = state_1.Receivers;
            return (matchValue.tag === 1) ? singleton_1(createElement(DialogBody, {
                Comment: state_1.Dto.Comment,
                Error: unwrap(state_1.Error),
                FormState: formState,
                Quantity: unwrap(state_1.Quantity),
                SelectedReceiver: unwrap(state_1.SelectedReceiver),
                SetComment: (value_1) => {
                    dispatch(new Msg(0, [value_1]));
                },
                SetQuantity: (value) => {
                    dispatch(new Msg(1, [value]));
                },
                SetSelectedReceiver: (value_2) => {
                    dispatch(new Msg(2, [value_2]));
                },
                Tool: props.Tool,
                UserId: props.UserId,
                Users: matchValue.fields[0],
            })) : singleton_1(createElement(Skeleton, {
                Variant: "normal",
            }));
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: formState,
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-unassign-tool-with-quantity-dialog-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: formState,
            Label: t("general.save"),
            OnClick: () => {
                dispatch(new Msg(4, []));
            },
            TestId: "save-unassign-tool-with-quantity-dialog-test-id",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

