import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, lambda_type, unit_type, list_type, bool_type, union_type, class_type, anonRecord_type, string_type, option_type, float64_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { Storage_$reflection } from "../../Shared/Storage.js";
import { ConsumableId, UpdateConsumableStorageDto, UpdateConsumableStorageDto_$reflection, PostResponse$1_$reflection } from "../../Shared/Shared.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none, Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { singleton, exists, filter, map, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { unwrapStorageId, unwrapConsumableId } from "../../Shared/Helper.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { ConsumableDetailDto_$reflection } from "../../Shared/Consumable.js";
import { parse } from "../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { Cmd_successToast } from "../../Components/Toast.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { Select } from "../../Components/Select.js";
import { unwrap } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { NumberInput } from "../../Components/Input.js";
import { Interop_reactApi } from "../../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Dialog } from "../../Components/Dialog.js";
import { TextButton } from "../../Components/Button.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetQuantity", "SetTargetStorage", "SetSourceStorage", "UpdateStorage", "UpdateStorageResponse", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Consumables.SwitchStorageDialog.Msg", [], Msg, () => [[["Item", option_type(float64_type)]], [["Item", option_type(anonRecord_type(["label", string_type], ["value", Storage_$reflection()]))]], [["Item", option_type(anonRecord_type(["label", string_type], ["value", Storage_$reflection()]))]], [], [["Item", PostResponse$1_$reflection(string_type)]], [["Item", class_type("System.Exception")]]]);
}

function updateStorageCmd(consumableId, dto) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(string_type)));
    })))), [`/api/consumables/${unwrapConsumableId(consumableId)}/storage`, toString(0, Auto_generateBoxedEncoder_437914C6(UpdateConsumableStorageDto_$reflection(), undefined, undefined, undefined)(dto))], (Item) => (new Msg(4, [Item])), (Item_1) => (new Msg(5, [Item_1])));
}

class State extends Record {
    constructor(IsLoading, Consumable, UserId, Storages, SuccessCallback, OnClose, NegativeStockNotAllowed, TargetStorage, SourceStorage, Quantity, IsTargetStorageNotSelected, IsSourceStorageNotSelected) {
        super();
        this.IsLoading = IsLoading;
        this.Consumable = Consumable;
        this.UserId = UserId;
        this.Storages = Storages;
        this.SuccessCallback = SuccessCallback;
        this.OnClose = OnClose;
        this.NegativeStockNotAllowed = NegativeStockNotAllowed;
        this.TargetStorage = TargetStorage;
        this.SourceStorage = SourceStorage;
        this.Quantity = Quantity;
        this.IsTargetStorageNotSelected = IsTargetStorageNotSelected;
        this.IsSourceStorageNotSelected = IsSourceStorageNotSelected;
    }
}

function State_$reflection() {
    return record_type("Consumables.SwitchStorageDialog.State", [], State, () => [["IsLoading", bool_type], ["Consumable", ConsumableDetailDto_$reflection()], ["UserId", string_type], ["Storages", list_type(Storage_$reflection())], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["OnClose", lambda_type(unit_type, unit_type)], ["NegativeStockNotAllowed", bool_type], ["TargetStorage", option_type(anonRecord_type(["label", string_type], ["value", Storage_$reflection()]))], ["SourceStorage", option_type(anonRecord_type(["label", string_type], ["value", Storage_$reflection()]))], ["Quantity", option_type(float64_type)], ["IsTargetStorageNotSelected", bool_type], ["IsSourceStorageNotSelected", bool_type]]);
}

function init(props) {
    return [new State(false, props.Consumable, props.UserId, props.Storages, props.SuccessCallback, props.OnClose, false, undefined, undefined, undefined, false, false), Cmd_none()];
}

function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [new State(state.IsLoading, state.Consumable, state.UserId, state.Storages, state.SuccessCallback, state.OnClose, state.NegativeStockNotAllowed, msg.fields[0], state.SourceStorage, state.Quantity, state.IsTargetStorageNotSelected, state.IsSourceStorageNotSelected), Cmd_none()];
        case 2:
            return [new State(state.IsLoading, state.Consumable, state.UserId, state.Storages, state.SuccessCallback, state.OnClose, state.NegativeStockNotAllowed, state.TargetStorage, msg.fields[0], state.Quantity, state.IsTargetStorageNotSelected, state.IsSourceStorageNotSelected), Cmd_none()];
        case 3: {
            const matchValue = state.TargetStorage;
            const matchValue_1 = state.SourceStorage;
            const matchValue_2 = state.Quantity;
            if (matchValue == null) {
                if (matchValue_1 == null) {
                    return [new State(state.IsLoading, state.Consumable, state.UserId, state.Storages, state.SuccessCallback, state.OnClose, false, state.TargetStorage, state.SourceStorage, state.Quantity, true, true), Cmd_none()];
                }
                else {
                    return [new State(state.IsLoading, state.Consumable, state.UserId, state.Storages, state.SuccessCallback, state.OnClose, false, state.TargetStorage, state.SourceStorage, state.Quantity, true, false), Cmd_none()];
                }
            }
            else if (matchValue_1 == null) {
                return [new State(state.IsLoading, state.Consumable, state.UserId, state.Storages, state.SuccessCallback, state.OnClose, false, state.TargetStorage, state.SourceStorage, state.Quantity, false, true), Cmd_none()];
            }
            else if (matchValue_2 == null) {
                return [new State(state.IsLoading, state.Consumable, state.UserId, state.Storages, state.SuccessCallback, state.OnClose, true, state.TargetStorage, state.SourceStorage, state.Quantity, false, false), Cmd_none()];
            }
            else {
                const quantity = matchValue_2;
                const sourceStorage = matchValue_1;
                const targetStorage = matchValue;
                if (quantity > 0) {
                    const dto = new UpdateConsumableStorageDto(sourceStorage.value.Id, targetStorage.value.Id, quantity);
                    return [new State(true, state.Consumable, state.UserId, state.Storages, state.SuccessCallback, state.OnClose, false, state.TargetStorage, state.SourceStorage, state.Quantity, state.IsTargetStorageNotSelected, state.IsSourceStorageNotSelected), updateStorageCmd(new ConsumableId(parse(state.Consumable.Id)), dto)];
                }
                else {
                    return [new State(state.IsLoading, state.Consumable, state.UserId, state.Storages, state.SuccessCallback, state.OnClose, true, state.TargetStorage, state.SourceStorage, state.Quantity, state.IsTargetStorageNotSelected, state.IsSourceStorageNotSelected), Cmd_none()];
                }
            }
        }
        case 4: {
            const matchValue_4 = msg.fields[0].Result;
            switch (matchValue_4) {
                case "negativeStockNotAllowed":
                    return [new State(false, state.Consumable, state.UserId, state.Storages, state.SuccessCallback, state.OnClose, true, state.TargetStorage, state.SourceStorage, state.Quantity, state.IsTargetStorageNotSelected, state.IsSourceStorageNotSelected), Cmd_none()];
                case "notAuthorized":
                    return [new State(false, state.Consumable, state.UserId, state.Storages, state.SuccessCallback, state.OnClose, state.NegativeStockNotAllowed, state.TargetStorage, state.SourceStorage, state.Quantity, state.IsTargetStorageNotSelected, state.IsSourceStorageNotSelected), Cmd_none()];
                default:
                    return [new State(false, state.Consumable, state.UserId, state.Storages, state.SuccessCallback, state.OnClose, false, state.TargetStorage, state.SourceStorage, state.Quantity, state.IsTargetStorageNotSelected, state.IsSourceStorageNotSelected), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                        state.SuccessCallback();
                    }), Cmd_ofEffect((_arg_1) => {
                        state.OnClose();
                    }), Cmd_successToast("tool.switch_storage_consumable_success")]))];
            }
        }
        case 5:
            return [new State(false, state.Consumable, state.UserId, state.Storages, state.SuccessCallback, state.OnClose, state.NegativeStockNotAllowed, state.TargetStorage, state.SourceStorage, state.Quantity, state.IsTargetStorageNotSelected, state.IsSourceStorageNotSelected), Cmd_none()];
        default:
            return [new State(state.IsLoading, state.Consumable, state.UserId, state.Storages, state.SuccessCallback, state.OnClose, state.NegativeStockNotAllowed, state.TargetStorage, state.SourceStorage, msg.fields[0], state.IsTargetStorageNotSelected, state.IsSourceStorageNotSelected), Cmd_none()];
    }
}

export function DialogBody(props) {
    let elems;
    const t = useTranslation()[0];
    const storageSourceOptions = map((storage_1) => ({
        label: storage_1.Name,
        value: storage_1,
    }), filter((storage) => exists((sps) => (sps.StorageId === unwrapStorageId(storage.Id)), props.Consumable.StockPerStorage), props.Storages));
    const storageTargetOptions = map((storage_2) => ({
        label: storage_2.Name,
        value: storage_2,
    }), props.Storages);
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-4"])], (elems = [createElement(Select, {
        ComponentState: props.FormState,
        IsClearable: false,
        Label: t("tool.switch_storage_source"),
        NoOptionsMessage: "select.storage_no_options",
        OnChange: props.SetSelectedSourceStorage,
        Options: storageSourceOptions,
        PlaceholderKey: "select.storage_placeholder",
        TestId: "",
        ValidationMessage: unwrap(props.IsSourceStorageInvalid ? "tool.switch_storage_missing_storage_validation" : undefined),
        Value: unwrap(props.SelectedSourceStorage),
    }), createElement(Select, {
        ComponentState: props.FormState,
        IsClearable: false,
        Label: t("tool.switch_storage_target"),
        NoOptionsMessage: "select.storage_no_options",
        OnChange: props.SetSelectedTargetStorage,
        Options: storageTargetOptions,
        PlaceholderKey: "select.storage_placeholder",
        TestId: "",
        ValidationMessage: unwrap(props.IsTargetStorageInvalid ? "tool.switch_storage_missing_storage_validation" : undefined),
        Value: unwrap(props.SelectedTargetStorage),
    }), createElement(NumberInput, {
        ComponentState: props.FormState,
        Label: t("general.quantity_3"),
        OnChange: props.SetQuantity,
        TestId: "",
        ValidationMessage: unwrap(props.IsNewStockNegative ? t("tool.switch_storage_negative_stock_validation") : undefined),
        Value: unwrap(props.Quantity),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function SwitchStorageDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init({
        Consumable: props.Consumable,
        OnClose: props.OnClose,
        Storages: props.Storages,
        SuccessCallback: props.SuccessCallback,
        UserId: props.UserId,
    });
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    const formState = state_1.IsLoading ? "disabled" : "enabled";
    return createElement(Dialog, (Title = t("tool.switch_storage"), {
        Body: singleton(createElement(DialogBody, {
            Consumable: state_1.Consumable,
            FormState: formState,
            IsNewStockNegative: state_1.NegativeStockNotAllowed,
            IsSourceStorageInvalid: state_1.IsSourceStorageNotSelected,
            IsTargetStorageInvalid: state_1.IsTargetStorageNotSelected,
            Quantity: unwrap(state_1.Quantity),
            SelectedSourceStorage: unwrap(state_1.SourceStorage),
            SelectedTargetStorage: unwrap(state_1.TargetStorage),
            SetQuantity: (value_2) => {
                dispatch(new Msg(0, [value_2]));
            },
            SetSelectedSourceStorage: (value_1) => {
                dispatch(new Msg(2, [value_1]));
            },
            SetSelectedTargetStorage: (value) => {
                dispatch(new Msg(1, [value]));
            },
            Storages: state_1.Storages,
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: formState,
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-update-storage-dialog-dialog-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: formState,
            Label: t("general.save"),
            OnClick: () => {
                dispatch(new Msg(3, []));
            },
            TestId: "save-update-storage-dialog-dialog-test-id",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

