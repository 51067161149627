import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { lambda_type, unit_type, class_type, list_type, record_type, option_type, float64_type, string_type, union_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { FormState__getValidation, FormState__startLoading, FormState__setValidationResponse_5219762A, ToolId__get_unwrap, FormState_get_empty, RequestedValue$1, TokenDataDto_$reflection, FormState_$reflection, RequestedValue$1_$reflection, ClientApiResponse$1_$reflection } from "../../Shared/Shared.js";
import { DataTransfer_DetailSet_$reflection } from "../../Shared/Set.js";
import { Helper_toolId, ToolTypes_$reflection } from "../../Shared/Tool.js";
import { MultiStateLostToolWithQuantityDto, MultiUpdateStateToLostStateDto, MultiUpdateStateToLostStateDto_$reflection } from "../../Shared/MultiOperation.js";
import { tryHead, toArray as toArray_1, length, contains, append as append_1, collect, singleton as singleton_1, filter, cons, find, ofArray, map, empty, isEmpty } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Cmd_none, Cmd_ofEffect, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { iterate, toArray, empty as empty_1, singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { getSelectedSets } from "../../Requests/Set.js";
import { Cmd_getSelectedTools } from "../../Requests/Tool.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";
import { newGuid } from "../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { createObj, equals, stringHash } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { toArray as toArray_2, unwrap, map as map_1, defaultArg } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { fetchWithDecoder, addPrefix } from "../../CommunicationV2.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { FSharpMap__get_IsEmpty } from "../../fable_modules/fable-library-js.4.19.2/Map.js";
import { TableControl$1, Table as Table_1, ColumnDefinition$1, CellContent } from "../Table/Table.js";
import { SectionHeader, InvalidTextValue } from "../../Components/Text.js";
import { NumberInput } from "../../Components/Input.js";
import { numberInputIntMaxValue } from "../../Common.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { TabsMenu } from "../TabsMenu.js";
import { Interop_reactApi } from "../../fable_modules/Feliz.2.7.0/Interop.fs.js";
import Recycle_Bin_1 from "../../../public/assets/icons/Recycle_Bin_1.svg";
import { TextArea } from "../../Components/TextArea.js";
import { Button } from "../../Components/Button.js";
import { useFeliz_React__React_useState_Static_1505 } from "../../fable_modules/Feliz.2.7.0/React.fs.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { ModalSheet } from "../../Components/ModalSheet.js";
import { Skeleton } from "../../Components/Skeleton.js";

export class TableElementType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Tool", "ToolWithQuantity", "Set"];
    }
}

export function TableElementType_$reflection() {
    return union_type("Widgets.Modals.MultiSetLostStateModal.TableElementType", [], TableElementType, () => [[], [], []]);
}

export class TableElement extends Record {
    constructor(Id, ElementId, Manufacturer, Model, Name, AdditionalName, InventoryNumber, Quanity, Type) {
        super();
        this.Id = Id;
        this.ElementId = ElementId;
        this.Manufacturer = Manufacturer;
        this.Model = Model;
        this.Name = Name;
        this.AdditionalName = AdditionalName;
        this.InventoryNumber = InventoryNumber;
        this.Quanity = Quanity;
        this.Type = Type;
    }
}

export function TableElement_$reflection() {
    return record_type("Widgets.Modals.MultiSetLostStateModal.TableElement", [], TableElement, () => [["Id", string_type], ["ElementId", string_type], ["Manufacturer", string_type], ["Model", string_type], ["Name", string_type], ["AdditionalName", string_type], ["InventoryNumber", string_type], ["Quanity", option_type(float64_type)], ["Type", TableElementType_$reflection()]]);
}

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Assign", "SetComment", "SetQuantity", "AddElement", "RemoveElement", "InitTableElements", "AssignedResponse", "SetsFetched", "ToolsFetched", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Widgets.Modals.MultiSetLostStateModal.Msg", [], Msg, () => [[], [["Item", string_type]], [["id", string_type], ["quantity", option_type(float64_type)]], [["id", string_type]], [["id", string_type]], [], [["Item", ClientApiResponse$1_$reflection(string_type)]], [["Item", list_type(DataTransfer_DetailSet_$reflection())]], [["Item", list_type(ToolTypes_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

class State extends Record {
    constructor(SuccessCallback, OnClose, Sets, Tools, TableElements, RemovedElements, Dto, FormState, UserData) {
        super();
        this.SuccessCallback = SuccessCallback;
        this.OnClose = OnClose;
        this.Sets = Sets;
        this.Tools = Tools;
        this.TableElements = TableElements;
        this.RemovedElements = RemovedElements;
        this.Dto = Dto;
        this.FormState = FormState;
        this.UserData = UserData;
    }
}

function State_$reflection() {
    return record_type("Widgets.Modals.MultiSetLostStateModal.State", [], State, () => [["SuccessCallback", lambda_type(unit_type, unit_type)], ["OnClose", lambda_type(unit_type, unit_type)], ["Sets", RequestedValue$1_$reflection(list_type(DataTransfer_DetailSet_$reflection()))], ["Tools", RequestedValue$1_$reflection(list_type(ToolTypes_$reflection()))], ["TableElements", list_type(TableElement_$reflection())], ["RemovedElements", list_type(TableElement_$reflection())], ["Dto", MultiUpdateStateToLostStateDto_$reflection()], ["FormState", FormState_$reflection()], ["UserData", TokenDataDto_$reflection()]]);
}

function init(props) {
    return [new State(props.SuccessCallback, props.OnClose, isEmpty(props.AssignProps.SetIds) ? (new RequestedValue$1(1, [empty()])) : (new RequestedValue$1(0, [])), isEmpty(props.AssignProps.ToolIds) ? (new RequestedValue$1(1, [empty()])) : (new RequestedValue$1(0, [])), empty(), empty(), new MultiUpdateStateToLostStateDto(empty(), empty(), empty(), props.SenderId, ""), FormState_get_empty(), props.UserData), Cmd_batch(toList(delay(() => append(!isEmpty(props.AssignProps.SetIds) ? singleton(getSelectedSets(props.AssignProps.SetIds, (Item) => (new Msg(7, [Item])), (Item_1) => (new Msg(9, [Item_1])))) : empty_1(), delay(() => (!isEmpty(props.AssignProps.ToolIds) ? singleton(Cmd_getSelectedTools(map(ToolId__get_unwrap, props.AssignProps.ToolIds), (Item_2) => (new Msg(8, [Item_2])), (Item_3) => (new Msg(9, [Item_3])))) : empty_1()))))))];
}

function update(msg, state) {
    let bind$0040_1;
    switch (msg.tag) {
        case 6: {
            const response_1 = msg.fields[0];
            if (response_1.tag === 1) {
                return [new State(state.SuccessCallback, state.OnClose, state.Sets, state.Tools, state.TableElements, state.RemovedElements, state.Dto, FormState__setValidationResponse_5219762A(state.FormState, response_1.fields[0]), state.UserData), Cmd_errorToast("validation.toast")];
            }
            else {
                return [new State(state.SuccessCallback, state.OnClose, state.Sets, state.Tools, state.TableElements, state.RemovedElements, state.Dto, FormState_get_empty(), state.UserData), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                    state.SuccessCallback();
                }), Cmd_successToast("general.saved_successfully"), Cmd_ofEffect((_arg_1) => {
                    state.OnClose();
                })]))];
            }
        }
        case 2:
            return [new State(state.SuccessCallback, state.OnClose, state.Sets, state.Tools, map((tableElement) => {
                if (tableElement.Id === msg.fields[0]) {
                    return new TableElement(tableElement.Id, tableElement.ElementId, tableElement.Manufacturer, tableElement.Model, tableElement.Name, tableElement.AdditionalName, tableElement.InventoryNumber, msg.fields[1], tableElement.Type);
                }
                else {
                    return tableElement;
                }
            }, state.TableElements), state.RemovedElements, state.Dto, state.FormState, state.UserData), Cmd_none()];
        case 1:
            return [new State(state.SuccessCallback, state.OnClose, state.Sets, state.Tools, state.TableElements, state.RemovedElements, (bind$0040_1 = state.Dto, new MultiUpdateStateToLostStateDto(bind$0040_1.ToolIds, bind$0040_1.SetIds, bind$0040_1.ToolsWithQuantity, bind$0040_1.ReceiverId, msg.fields[0])), state.FormState, state.UserData), Cmd_none()];
        case 3: {
            const id_1 = msg.fields[0];
            return [new State(state.SuccessCallback, state.OnClose, state.Sets, state.Tools, cons(find((tableElement_1) => (tableElement_1.Id === id_1), state.RemovedElements), state.TableElements), filter((tableElement_2) => (tableElement_2.Id !== id_1), state.RemovedElements), state.Dto, state.FormState, state.UserData), Cmd_none()];
        }
        case 4: {
            const id_2 = msg.fields[0];
            const selectedElement_1 = find((tableElement_3) => (tableElement_3.Id === id_2), state.TableElements);
            return [new State(state.SuccessCallback, state.OnClose, state.Sets, state.Tools, filter((tableElement_4) => (tableElement_4.Id !== id_2), state.TableElements), cons(selectedElement_1, state.RemovedElements), state.Dto, state.FormState, state.UserData), Cmd_none()];
        }
        case 7:
            return [new State(state.SuccessCallback, state.OnClose, new RequestedValue$1(1, [msg.fields[0]]), state.Tools, state.TableElements, state.RemovedElements, state.Dto, state.FormState, state.UserData), singleton_1((dispatch) => {
                dispatch(new Msg(5, []));
            })];
        case 8:
            return [new State(state.SuccessCallback, state.OnClose, state.Sets, new RequestedValue$1(1, [msg.fields[0]]), state.TableElements, state.RemovedElements, state.Dto, state.FormState, state.UserData), singleton_1((dispatch_1) => {
                dispatch_1(new Msg(5, []));
            })];
        case 5: {
            const matchValue = state.Tools;
            const matchValue_1 = state.Sets;
            let matchResult, sets, tools;
            if (matchValue.tag === 0) {
                matchResult = 1;
            }
            else if (matchValue_1.tag === 0) {
                matchResult = 1;
            }
            else {
                matchResult = 0;
                sets = matchValue_1.fields[0];
                tools = matchValue.fields[0];
            }
            switch (matchResult) {
                case 0: {
                    const setToolIds = map((tool) => tool.ToolId, collect((set$) => set$.AssignedTools, sets));
                    return [new State(state.SuccessCallback, state.OnClose, state.Sets, state.Tools, append_1(map((set$_1) => (new TableElement(newGuid(), set$_1.Id, set$_1.MasterData.Manufacturer, set$_1.MasterData.Model, set$_1.MasterData.Name, "", set$_1.MasterData.InventoryNumber, undefined, new TableElementType(2, []))), sets), map((tool_2) => {
                        if (tool_2.tag === 1) {
                            const tool_4 = tool_2.fields[0];
                            let quantity;
                            const matchValue_3 = state.Dto.ReceiverId;
                            if (matchValue_3 != null) {
                                const senderId = matchValue_3;
                                quantity = find((ua) => (ua.UserId === senderId), tool_4.UserAssignments).Quantity;
                            }
                            else {
                                quantity = 0;
                            }
                            return new TableElement(newGuid(), ToolId__get_unwrap(tool_4.Id), tool_4.Manufacturer, tool_4.Model, tool_4.DisplayName, "", tool_4.InventoryNumber, quantity, new TableElementType(1, []));
                        }
                        else {
                            const tool_3 = tool_2.fields[0];
                            return new TableElement(newGuid(), ToolId__get_unwrap(tool_3.Id), tool_3.Manufacturer, tool_3.Model, tool_3.DisplayName, "", tool_3.InventoryNumber, undefined, new TableElementType(0, []));
                        }
                    }, filter((tool_1) => !contains(ToolId__get_unwrap(Helper_toolId(tool_1)), setToolIds, {
                        Equals: (x_1, y) => (x_1 === y),
                        GetHashCode: stringHash,
                    }), tools))), state.RemovedElements, state.Dto, state.FormState, state.UserData), Cmd_none()];
                }
                default:
                    return [state, Cmd_none()];
            }
        }
        case 9:
            return [new State(state.SuccessCallback, state.OnClose, state.Sets, state.Tools, state.TableElements, state.RemovedElements, state.Dto, FormState_get_empty(), state.UserData), Cmd_none()];
        default: {
            let updatedDto;
            const bind$0040 = state.Dto;
            updatedDto = (new MultiUpdateStateToLostStateDto(map((element_1) => element_1.ElementId, filter((element) => equals(element.Type, new TableElementType(0, [])), state.TableElements)), map((element_3) => element_3.ElementId, filter((element_2) => equals(element_2.Type, new TableElementType(2, [])), state.TableElements)), map((element_5) => (new MultiStateLostToolWithQuantityDto(element_5.ElementId, defaultArg(map_1((value) => ~~value, element_5.Quanity), 0))), filter((element_4) => equals(element_4.Type, new TableElementType(1, [])), state.TableElements)), bind$0040.ReceiverId, bind$0040.Comment));
            return [new State(state.SuccessCallback, state.OnClose, state.Sets, state.Tools, state.TableElements, state.RemovedElements, state.Dto, FormState__startLoading(state.FormState), state.UserData), Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                let url_2;
                return ((url_2 = addPrefix(tupledArg[0]), (refreshOn) => fetchWithDecoder(url_2, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
                    const pr = response.arrayBuffer();
                    return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
                }, refreshOn)))(true);
            })), ["/api/multi-operations/state/lost", toString(0, Auto_generateBoxedEncoder_437914C6(MultiUpdateStateToLostStateDto_$reflection(), undefined, undefined, undefined)(updatedDto))], (Item) => (new Msg(6, [Item])), (Item_1) => (new Msg(9, [Item_1])))];
        }
    }
}

function Table(props) {
    let elems_1;
    const patternInput = useTranslation();
    const t = patternInput[0];
    const componentState = props.FormState.IsLoading ? "disabled" : "enabled";
    const columnDefinition = toArray(delay(() => append(!FSharpMap__get_IsEmpty(props.FormState.Validations) ? singleton(new ColumnDefinition$1("hint", "", t("assign_multiple_tools_dialog.hint"), (row) => {
        const matchValue = FormState__getValidation(props.FormState, t, `${row.ElementId}-assigned-to-receiver`);
        return (matchValue == null) ? (new CellContent(0, [""])) : (new CellContent(1, [createElement(InvalidTextValue, {
            TestId: `${row.ElementId}-validation-message`,
            Text: matchValue,
        })]));
    }, "none", "text")) : empty_1(), delay(() => append(singleton(new ColumnDefinition$1("manufacturer", "", t("assign_multiple_tools_dialog.manufacturer"), (row_1) => (new CellContent(0, [row_1.Manufacturer])), "none", "text")), delay(() => append(singleton(new ColumnDefinition$1("model", "", t("assign_multiple_tools_dialog.model"), (row_2) => (new CellContent(0, [row_2.Model])), "none", "text")), delay(() => append(singleton(new ColumnDefinition$1("name", "", t("tool.displayname"), (row_3) => (new CellContent(0, [row_3.Name])), "none", "text")), delay(() => append(singleton(new ColumnDefinition$1("inventoryNumber", "", t("assign_multiple_tools_dialog.inventory_number"), (row_4) => (new CellContent(0, [row_4.InventoryNumber])), "none", "text")), delay(() => singleton(new ColumnDefinition$1("quantity", "", t("general.quantity_3"), (row_5) => {
        let Value;
        const matchValue_1 = row_5.Type;
        switch (matchValue_1.tag) {
            case 0:
            case 2:
                return new CellContent(0, ["1"]);
            default:
                return new CellContent(1, [createElement(NumberInput, (Value = map_1((value_1) => value_1, row_5.Quanity), {
                    ComponentState: componentState,
                    MaxValue: numberInputIntMaxValue,
                    OnChange: (value_2) => {
                        props.Dispatch(new Msg(2, [row_5.Id, value_2]));
                    },
                    TestId: "",
                    ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, `${row_5.ElementId}-stock`)),
                    Value: unwrap(Value),
                }))]);
        }
    }, "none", "text"))))))))))))));
    return createElement("div", createObj(ofArray([["className", join(" ", ["mt-4"])], (elems_1 = toList(delay(() => {
        let elems, arg_1;
        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "gap-4", "mb-4"])], (elems = [createElement(TabsMenu, {
            Tabs: ofArray([{
                IsSelected: props.IsAddedElementTabVisible,
                Label: t("assign_multiple_tools_dialog.tools"),
                OnClick: () => {
                    props.SetIsAddedElementTabVisible(true);
                },
                TestId: "",
            }, {
                IsSelected: !props.IsAddedElementTabVisible,
                Label: (arg_1 = {
                    numberOfRemovedTools: length(props.RemovedElements),
                }, patternInput[1].t("assign_multiple_tools_dialog.removed_elements", arg_1)),
                OnClick: () => {
                    props.SetIsAddedElementTabVisible(false);
                },
                TestId: "",
            }]),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let TableRows, Options, TableRows_1, Options_1;
            return props.IsAddedElementTabVisible ? singleton(createElement(Table_1, (TableRows = toArray_1(props.TableElements), (Options = {
                BorderStyle: "row",
                CSVDownload: false,
                ConfigurableColumns: false,
                DefaultSortColumn: ["manufacturer", "asc"],
                GlobalSearch: false,
                RowSelection: "singleRow",
            }, {
                ColumnDefinitions: columnDefinition,
                Controls: singleton_1(new TableControl$1(t("general.remove"), Recycle_Bin_1(), (rows) => {
                    iterate((row_7) => {
                        props.Dispatch(new Msg(4, [row_7]));
                    }, toArray_2(map_1((row_6) => row_6.Id, tryHead(rows))));
                }, true, false, "tools-dialog-toolreservation-dialog-remove-button")),
                Options: Options,
                TableRows: TableRows,
            })))) : singleton(createElement(Table_1, (TableRows_1 = toArray_1(props.RemovedElements), (Options_1 = {
                BorderStyle: "row",
                CSVDownload: false,
                ConfigurableColumns: false,
                DefaultSortColumn: ["manufacturer", "asc"],
                GlobalSearch: false,
                RowSelection: "singleRow",
            }, {
                ColumnDefinitions: columnDefinition,
                Controls: singleton_1(new TableControl$1(t("general.remove"), Recycle_Bin_1(), (rows_1) => {
                    iterate((row_9) => {
                        props.Dispatch(new Msg(3, [row_9]));
                    }, toArray_2(map_1((row_8) => row_8.Id, tryHead(rows_1))));
                }, true, false, "tools-dialog-toolreservation-dialog-remove-button")),
                Options: Options_1,
                TableRows: TableRows_1,
            }))));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

function Form(props) {
    let elems_1, elems, Label_1;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-4", "mt-4", "w-96"])], (elems_1 = [createElement(TextArea, {
        ComponentState: props.FormState,
        Label: t("general.comment"),
        OnChange: (value_1) => {
            props.Dispatch(new Msg(1, [value_1]));
        },
        TestId: "",
        Value: props.Dto.Comment,
    }), createElement("div", createObj(singleton_1((elems = [createElement(Button, (Label_1 = t("general.save"), {
        ComponentState: (props.FormState === "disabled") ? "disabled" : (props.IsTableElementsEmpty ? "disabled" : "enabled"),
        Label: Label_1,
        OnClick: () => {
            props.Dispatch(new Msg(0, []));
        },
        TestId: "tools-dialog-toolreservation-save-button",
        Variant: "primary",
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

function Content(props) {
    let elems_2, elems_1;
    const patternInput = useTranslation();
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(true);
    const isAddedElementsTabSelected = patternInput_1[0];
    const componentState = props.FormState.IsLoading ? "disabled" : "enabled";
    return createElement("div", createObj(ofArray([["className", join(" ", ["min-h-48"])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["p-4"])], (elems_1 = toList(delay(() => append(singleton(createElement(SectionHeader, {
        Text: patternInput[0]("general.report_lost"),
    })), delay(() => {
        let elems;
        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", [])], (elems = [createElement(Table, {
            Dispatch: props.Dispatch,
            FormState: props.FormState,
            IsAddedElementTabVisible: isAddedElementsTabSelected,
            RemovedElements: props.RemovedElements,
            SetIsAddedElementTabVisible: patternInput_1[1],
            TableElements: props.TableElements,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => (isAddedElementsTabSelected ? singleton(createElement(Form, {
            Dispatch: props.Dispatch,
            Dto: props.Dto,
            FormState: componentState,
            IsTableElementsEmpty: isEmpty(props.TableElements),
        })) : empty_1())));
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

export function MultiSetLostStateModalSheet(props) {
    let elems, matchValue, matchValue_1;
    let patternInput;
    const init_1 = init({
        AssignProps: props.Props,
        OnClose: props.OnClose,
        SenderId: props.SenderId,
        SuccessCallback: props.SuccessCallback,
        UserData: props.UserData,
    });
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput[0];
    return createElement("div", createObj(singleton_1((elems = [createElement(ModalSheet, {
        CloseButtonTestId: "",
        Content: (matchValue = state_1.Sets, (matchValue_1 = state_1.Tools, (matchValue.tag === 0) ? createElement(Skeleton, {
            Variant: "table",
        }) : ((matchValue_1.tag === 0) ? createElement(Skeleton, {
            Variant: "table",
        }) : createElement(Content, {
            Dispatch: patternInput[1],
            Dto: state_1.Dto,
            FormState: state_1.FormState,
            RemovedElements: state_1.RemovedElements,
            TableElements: state_1.TableElements,
        })))),
        Controls: empty(),
        IsModalOpen: props.IsOpen,
        OnClose: props.OnClose,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]))));
}

