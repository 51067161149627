import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "../../../Components/Dialog.js";
import { singleton, delay, toList } from "../../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { createObj } from "../../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.2/String.js";
import { Interop_reactApi } from "../../../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { ofArray } from "../../../fable_modules/fable-library-js.4.19.2/List.js";
import { TextButton } from "../../../Components/Button.js";

export function ArchiveDialog(props) {
    let Title;
    const t = useTranslation()[0];
    return createElement(Dialog, (Title = t("user.archive"), {
        Body: toList(delay(() => {
            let elems, elems_1;
            return !props.IsArchived ? singleton(createElement("div", createObj(ofArray([["className", join(" ", [])], (elems = [t("user.archive_receiver_information")], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))) : singleton(createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_1 = [t("user.reactivate_receiver_information")], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))));
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "archived-dialog-close",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: "enabled",
            Label: props.IsArchived ? t("receivers.archive_dialog.unarchive") : t("receivers.archive_dialog.archive"),
            OnClick: () => {
                props.OnSave();
                props.OnClose();
            },
            TestId: "archived-dialog-save",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

