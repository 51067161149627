import { createElement } from "react";
import React from "react";
import * as react from "react";
import { useTranslation } from "react-i18next";
import { ColumnDefinition$1, CellContent, Table } from "./Table.js";
import { singleton as singleton_1, ofArray, toArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { empty, singleton, append, delay, toArray as toArray_1 } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { TextButton } from "../../Components/Button.js";
import { RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { unwrap, defaultArg } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { Helper_stateToTranslationKey } from "../../Shared/Planning.js";
import { Label as Label_1, LabelId } from "../../Shared/Shared.js";

export function ReservationTable(props) {
    let TableRows;
    const t = useTranslation()[0];
    const xs_1 = [createElement(Table, (TableRows = toArray(props.Reservations), {
        ColumnDefinitions: toArray_1(delay(() => append(singleton(new ColumnDefinition$1("startDate", "", t("general.start_date"), (dto) => (new CellContent(5, [dto.StartDate])), "dateRange", "date")), delay(() => append(singleton(new ColumnDefinition$1("endDate", "", t("general.end_date"), (dto_1) => (new CellContent(5, [dto_1.EndDate])), "dateRange", "date")), delay(() => append(props.Options.DisplayReceiverName ? singleton(new ColumnDefinition$1("receiverName", "", t("general.receiver"), (dto_2) => (new CellContent(0, [dto_2.ReceiverInformation.ReceiverName])), "select", "text")) : empty(), delay(() => append(singleton(new ColumnDefinition$1("reservationName", "", t("general.name"), (dto_3) => (new CellContent(0, [dto_3.Name])), "text", "text")), delay(() => append(singleton(new ColumnDefinition$1("description", "", t("general.description"), (dto_4) => (new CellContent(0, [dto_4.Description])), "text", "text")), delay(() => append(singleton(new ColumnDefinition$1("tool", "", t("general.tool"), (dto_5) => (new CellContent(2, [createElement(TextButton, {
            ComponentState: "enabled",
            Label: dto_5.ToolInformation.Name,
            OnClick: () => {
                let matchValue;
                RouterModule_nav(ofArray(toArray((matchValue = dto_5.ToolInformation.Type, (matchValue.tag === 1) ? ofArray(["toolswithquantity", dto_5.ToolInformation.Id]) : ((matchValue.tag === 2) ? ofArray(["consumables", dto_5.ToolInformation.Id]) : ((matchValue.tag === 3) ? ofArray(["sets", dto_5.ToolInformation.Id]) : ((matchValue.tag === 4) ? ofArray(["reservations", defaultArg(dto_5.GroupId, "")]) : ofArray(["tools", dto_5.ToolInformation.Id]))))))), 1, 1);
            },
            TestId: "",
            Variant: "blueButton",
        })])), "text", "text")), delay(() => append(props.Options.IsReservationStateVisible ? singleton(new ColumnDefinition$1("state", t("general.master_data"), t("general.state"), (dto_6) => (new CellContent(0, [t(Helper_stateToTranslationKey(dto_6.State))])), "select", "text")) : empty(), delay(() => append(singleton(new ColumnDefinition$1("type", t("general.master_data"), t("general.tool_type"), (dto_7) => {
            let matchValue_1;
            return new CellContent(3, [(matchValue_1 = dto_7.ToolInformation.Type, (matchValue_1.tag === 1) ? singleton_1(new Label_1(new LabelId("00000000-0000-0000-0000-000000000000"), t("general.tool_with_quantity"), "#000000", true)) : ((matchValue_1.tag === 3) ? singleton_1(new Label_1(new LabelId("00000000-0000-0000-0000-000000000000"), t("general.set"), "#000000", true)) : ((matchValue_1.tag === 2) ? singleton_1(new Label_1(new LabelId("00000000-0000-0000-0000-000000000000"), t("general.consumable"), "#000000", true)) : ((matchValue_1.tag === 4) ? singleton_1(new Label_1(new LabelId("00000000-0000-0000-0000-000000000000"), t("general.group"), "#000000", true)) : singleton_1(new Label_1(new LabelId("00000000-0000-0000-0000-000000000000"), t("general.tool"), "#14BC9C", true))))))]);
        }, "select", "text")), delay(() => append(singleton(new ColumnDefinition$1("quantity", "", t("general.quantity"), (dto_8) => (new CellContent(0, [defaultArg(dto_8.ToolInformation.Quantity, "")])), "text", "text")), delay(() => singleton(new ColumnDefinition$1("unit", "", t("general.unit"), (dto_9) => (new CellContent(0, [t(dto_9.ToolInformation.Unit)])), "select", "text")))))))))))))))))))))),
        Controls: props.Options.Controls,
        Options: {
            BorderStyle: "row",
            CSVDownload: false,
            ConfigurableColumns: false,
            DefaultColumnFilter: singleton_1({
                id: "state",
                value: [t("general.planned")],
            }),
            DefaultSortColumn: ["startDate", "asc"],
            GlobalSearch: false,
            OnRowClick: unwrap(props.Options.OnRowClick),
            RowSelection: props.Options.RowSelection,
        },
        TableRows: TableRows,
    }))];
    return react.createElement(react.Fragment, {}, ...xs_1);
}

