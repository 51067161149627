import { Union, Record } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { LabelId, Label as Label_4, PostResponse$1_$reflection, LabelResponse_$reflection, Label_$reflection } from "../../Shared/Shared.js";
import { union_type, class_type, record_type, lambda_type, unit_type, bool_type, option_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { join, isNullOrWhiteSpace } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { singleton as singleton_1, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { empty, singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { InformationAlert, ErrorAlert } from "../../Components/Alert.js";
import { defaultOf } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { Input } from "../../Components/Input.js";
import { unwrap } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { Interop_reactApi } from "../../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { Badge } from "../../Components/Badge.js";
import { TextButton, Button } from "../../Components/Button.js";
import { ColorPicker_colorPicker_BD3CF72 } from "../../SharedComponents/ReactColorPicker.js";
import { Checkbox } from "../../Components/Checkbox.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Dialog } from "../../Components/Dialog.js";

class State extends Record {
    constructor(CategoryForm, Message, IsValidated, IsLabelColorSelectorOpen, OnClose) {
        super();
        this.CategoryForm = CategoryForm;
        this.Message = Message;
        this.IsValidated = IsValidated;
        this.IsLabelColorSelectorOpen = IsLabelColorSelectorOpen;
        this.OnClose = OnClose;
    }
}

function State_$reflection() {
    return record_type("Widgets.Dialogs.NewCategoryDialog.State", [], State, () => [["CategoryForm", Label_$reflection()], ["Message", option_type(string_type)], ["IsValidated", bool_type], ["IsLabelColorSelectorOpen", bool_type], ["OnClose", lambda_type(unit_type, unit_type)]]);
}

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetName", "SetColorHex", "SetVisibleForUser", "SaveCategory", "UpdateCategory", "ToggleColorSelector", "CategorySaved", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Widgets.Dialogs.NewCategoryDialog.Msg", [], Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [], [], [], [["Item", PostResponse$1_$reflection(LabelResponse_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

function update(msg, state) {
    let bind$0040_1, bind$0040_2, bind$0040;
    switch (msg.tag) {
        case 1:
            return [new State((bind$0040_1 = state.CategoryForm, new Label_4(bind$0040_1.Id, bind$0040_1.Name, msg.fields[0], bind$0040_1.VisibleForUserRole)), state.Message, state.IsValidated, state.IsLabelColorSelectorOpen, state.OnClose), Cmd_none()];
        case 2:
            return [new State((bind$0040_2 = state.CategoryForm, new Label_4(bind$0040_2.Id, bind$0040_2.Name, bind$0040_2.ColorHex, msg.fields[0])), state.Message, state.IsValidated, state.IsLabelColorSelectorOpen, state.OnClose), Cmd_none()];
        case 3:
            if (isNullOrWhiteSpace(state.CategoryForm.Name)) {
                return [new State(state.CategoryForm, state.Message, true, state.IsLabelColorSelectorOpen, state.OnClose), Cmd_none()];
            }
            else {
                return [new State(state.CategoryForm, undefined, state.IsValidated, state.IsLabelColorSelectorOpen, state.OnClose), Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
                    const pr = response.arrayBuffer();
                    return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(LabelResponse_$reflection())));
                })))), ["/api/labels", toString(0, Auto_generateBoxedEncoder_437914C6(Label_$reflection(), undefined, undefined, undefined)(state.CategoryForm))], (Item) => (new Msg(6, [Item])), (Item_1) => (new Msg(7, [Item_1])))];
            }
        case 4:
            if (isNullOrWhiteSpace(state.CategoryForm.Name)) {
                return [new State(state.CategoryForm, state.Message, true, state.IsLabelColorSelectorOpen, state.OnClose), Cmd_none()];
            }
            else {
                return [new State(state.CategoryForm, undefined, state.IsValidated, state.IsLabelColorSelectorOpen, state.OnClose), Cmd_OfPromise_either((tupledArg_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg_1[0]), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg_1[1]])]), "application/json", (response_1) => {
                    const pr_1 = response_1.arrayBuffer();
                    return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), PostResponse$1_$reflection(LabelResponse_$reflection())));
                })))), ["/api/labels", toString(0, Auto_generateBoxedEncoder_437914C6(Label_$reflection(), undefined, undefined, undefined)(state.CategoryForm))], (Item_2) => (new Msg(6, [Item_2])), (Item_3) => (new Msg(7, [Item_3])))];
            }
        case 5:
            return [new State(state.CategoryForm, state.Message, state.IsValidated, !state.IsLabelColorSelectorOpen, state.OnClose), Cmd_none()];
        case 6:
            if (msg.fields[0].Result.tag === 0) {
                return [new State(state.CategoryForm, "settings.label_exists_msg", state.IsValidated, state.IsLabelColorSelectorOpen, state.OnClose), Cmd_errorToast("settings.label_exists_msg")];
            }
            else {
                return [state, Cmd_batch(ofArray([Cmd_successToast("general.saved_successfully"), Cmd_ofEffect((_arg) => {
                    state.OnClose();
                })]))];
            }
        case 7:
            return [state, Cmd_none()];
        default:
            return [new State((bind$0040 = state.CategoryForm, new Label_4(bind$0040.Id, msg.fields[0], bind$0040.ColorHex, bind$0040.VisibleForUserRole)), state.Message, state.IsValidated, state.IsLabelColorSelectorOpen, state.OnClose), Cmd_none()];
    }
}

function init(onClose) {
    return [new State(new Label_4(new LabelId("00000000-0000-0000-0000-000000000000"), "", "#9A9FFF", true), undefined, false, false, onClose), Cmd_none()];
}

function initEdit(onClose, label) {
    return [new State(label, undefined, true, false, onClose), Cmd_none()];
}

function LabelForm(props) {
    let elems_4;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["max-w-xl", "flex", "flex-col", "gap-5"])], (elems_4 = toList(delay(() => {
        let matchValue;
        return append((matchValue = props.State.Message, (matchValue != null) ? singleton(createElement(ErrorAlert, {
            Label: matchValue,
        })) : singleton(defaultOf())), delay(() => {
            let elems, Value;
            return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", [])], (elems = [createElement(Input, (Value = (props.IsEdit ? props.State.CategoryForm.Name : props.Label.Name), {
                ComponentState: "enabled",
                Label: t("settings.label_name"),
                OnChange: (arg) => {
                    props.Dispatch(new Msg(0, [arg]));
                },
                TestId: "",
                ValidationMessage: unwrap((props.State.IsValidated && isNullOrWhiteSpace(props.Label.Name)) ? t("settings.label_name_invalid") : undefined),
                Value: Value,
            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
                let elems_1;
                return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_1 = [createElement(Badge, {
                    Color: props.IsEdit ? props.State.CategoryForm.ColorHex : props.Label.ColorHex,
                    Label: "",
                    TestId: "new-category-test-id",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => {
                    let elems_3;
                    return append(singleton(createElement("div", createObj(ofArray([["className", "form-group"], (elems_3 = toList(delay(() => {
                        let elems_2;
                        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mb-3"])], (elems_2 = [createElement(Button, {
                            ComponentState: "enabled",
                            Label: t("settings.label_choose_color"),
                            OnClick: () => {
                                props.Dispatch(new Msg(5, []));
                            },
                            TestId: "settings-choosecolor-button",
                            Variant: "secondary",
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => (props.State.IsLabelColorSelectorOpen ? singleton(ColorPicker_colorPicker_BD3CF72((color) => {
                            props.Dispatch(new Msg(1, [color.hex]));
                        }, props.IsEdit ? props.State.CategoryForm.ColorHex : props.Label.ColorHex)) : empty())));
                    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))), delay(() => append(singleton(Checkbox({
                        ComponentState: "enabled",
                        IsChecked: props.IsEdit ? props.State.CategoryForm.VisibleForUserRole : props.Label.VisibleForUserRole,
                        Label: t("settings.label_visible_for_user"),
                        OnCheck: (arg_1) => {
                            props.Dispatch(new Msg(2, [arg_1]));
                        },
                        TestId: "",
                    })), delay(() => singleton(createElement(InformationAlert, {
                        Label: t("settings.label_visible_for_user_tooltip"),
                    }))))));
                }));
            }));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])));
}

export function NewCategoryDialog(props) {
    let Title;
    let patternInput;
    const init_1 = init(props.OnClose);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const t = useTranslation()[0];
    return createElement(Dialog, (Title = t("settings.create_new_label"), {
        Body: singleton_1(createElement(LabelForm, {
            Dispatch: dispatch,
            IsEdit: false,
            Label: state_1.CategoryForm,
            State: state_1,
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-qr-code-dialog-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.save"),
            OnClick: () => {
                dispatch(new Msg(3, []));
            },
            TestId: "close-qr-code-dialog-test-id",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

export function EditCategoryDialog(props) {
    let Title;
    let patternInput;
    const init_1 = initEdit(props.OnClose, props.Label);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const dispatch = patternInput[1];
    const t = useTranslation()[0];
    return createElement(Dialog, (Title = t("settings.label_edit"), {
        Body: singleton_1(createElement(LabelForm, {
            Dispatch: dispatch,
            IsEdit: true,
            Label: props.Label,
            State: patternInput[0],
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-qr-code-dialog-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.save"),
            OnClick: () => {
                dispatch(new Msg(4, []));
            },
            TestId: "close-qr-code-dialog-test-id",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

