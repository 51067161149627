import { createElement } from "react";
import React from "react";
import { createObj } from "./fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "./fable_modules/fable-library-js.4.19.2/String.js";
import Loading_Circle from "../public/assets/icons/Loading_Circle.svg";
import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/Interop.fs.js";
import { ofArray } from "./fable_modules/fable-library-js.4.19.2/List.js";

export function LoadingPage() {
    let elems_1, elems;
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "h-full", "justify-center", "items-center"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["animate-[spin_1.5s_linear_infinite]", "text-primary", "h-10", "w-10"])], (elems = [Loading_Circle()], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

