import { Union, Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { ToolInfo_$reflection } from "../Shared/Tool.js";
import { RequestedValue$1, RequestedValue$1_$reflection } from "../Shared/Shared.js";
import { union_type, class_type, record_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_getToolInfo } from "../Requests/Tool.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { toLocalTime, toString } from "../fable_modules/fable-library-js.4.19.2/Date.js";
import { WarningAlert } from "../Components/Alert.js";
import { defaultOf } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { React_useElmish_Z6C327F2E } from "../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../fable_modules/Fable.Elmish.4.2.0/program.fs.js";

class State extends Record {
    constructor(ToolInfo) {
        super();
        this.ToolInfo = ToolInfo;
    }
}

function State_$reflection() {
    return record_type("Widgets.ToolInfoWidget.State", [], State, () => [["ToolInfo", RequestedValue$1_$reflection(ToolInfo_$reflection())]]);
}

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ToolInfoFetched", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Widgets.ToolInfoWidget.Msg", [], Msg, () => [[["Item", ToolInfo_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

function update(msg, state) {
    if (msg.tag === 1) {
        return [state, Cmd_none()];
    }
    else {
        return [new State(new RequestedValue$1(1, [msg.fields[0]])), Cmd_none()];
    }
}

function init(toolId) {
    return [new State(new RequestedValue$1(0, [])), Cmd_getToolInfo(toolId, (Item) => (new Msg(0, [Item])), (Item_1) => (new Msg(1, [Item_1])))];
}

function InfoContent(props) {
    let arg_1, elems;
    const patternInput = useTranslation();
    let reservationAlert;
    const matchValue = props.ToolInfo.CurrentReservation;
    if (matchValue != null) {
        const reservation = matchValue;
        const startDate = toString(toLocalTime(reservation.StartDate), "dd.MM.yyyy");
        const endDate = toString(toLocalTime(reservation.EndDate), "dd.MM.yyyy");
        reservationAlert = createElement(WarningAlert, {
            Label: `${patternInput[0]("tool.reserved_for")} ${reservation.ReceiverName} ${startDate} - ${endDate}`,
        });
    }
    else {
        reservationAlert = defaultOf();
    }
    let problemAlertAlert;
    const matchValue_1 = props.ToolInfo.ActiveProblemReport;
    problemAlertAlert = ((matchValue_1 != null) ? createElement(WarningAlert, {
        Label: (arg_1 = {
            timestamp: toString(toLocalTime(matchValue_1.Timestamp), "dd.MM.yyyy"),
        }, patternInput[1].t("tool_info.problem_report_exists", arg_1)),
    }) : defaultOf());
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex-col", "flex", "gap-4"])], (elems = toList(delay(() => append(props.DisplayReservationAlert ? singleton(reservationAlert) : empty(), delay(() => singleton(problemAlertAlert))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function ToolInfo(props) {
    let init_1;
    const matchValue = ((init_1 = init(props.ToolId), React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [])))[0].ToolInfo;
    if (matchValue.tag === 0) {
        return defaultOf();
    }
    else {
        return createElement(InfoContent, {
            DisplayReservationAlert: props.DisplayReservationAlert,
            ToolInfo: matchValue.fields[0],
        });
    }
}

