import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import Calendar_Warning from "../../public/assets/icons/Calendar_Warning.svg";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { singleton as singleton_1, ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { TextValue, PageHeader } from "../Components/Text.js";
import { Button } from "../Components/Button.js";
import { RouterModule_nav } from "../fable_modules/Feliz.Router.4.0.0/Router.fs.js";

export function View(props) {
    let elems_8, elems_7;
    const t = useTranslation()[0];
    const phoneNumber = t("global.phone_number");
    return createElement("div", createObj(ofArray([["className", join(" ", ["h-full", "flex", "justify-center", "content-center", "bg-background"])], (elems_8 = [createElement("div", createObj(ofArray([["className", join(" ", ["grow", "max-w-lg", "my-auto", "p-6", "flex", "flex-col", "border", "border-border", "rounded-md", "bg-[#FFFFFF]"])], (elems_7 = toList(delay(() => {
        let elems;
        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mx-auto", "w-24", "h-24", "mb-4"])], (elems = [Calendar_Warning()], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let elems_1;
            return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mx-auto", "mb-5", "mt-8"])], (elems_1 = [createElement(PageHeader, {
                Text: t("test_phase.header"),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => {
                let elems_5, elems_4, elems_2, elems_3;
                return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-3", "mx-auto", "mb-5"])], (elems_5 = [createElement(TextValue, {
                    FontWeight: "normal",
                    TestId: "",
                    Text: (props.CurrentUser.Role === "administrator") ? t("test_phase.info_text_admin") : t("test_phase.info_text_toolmanager"),
                }), createElement(TextValue, {
                    FontWeight: "normal",
                    TestId: "",
                    Text: t("test_phase.contact_question"),
                }), createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-2", "gap-2"])], (elems_4 = [createElement("div", {
                    className: join(" ", ["font-semibold"]),
                    children: t("user.phone_number"),
                }), createElement("div", createObj(singleton_1((elems_2 = [createElement("a", {
                    href: `tel:${phoneNumber}`,
                    children: phoneNumber,
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])))), createElement("div", {
                    className: join(" ", ["font-semibold"]),
                    children: t("general.email"),
                }), createElement("div", createObj(singleton_1((elems_3 = [createElement("a", {
                    href: "mailto:support@inventory-one.com",
                    children: "support@inventory-one.com",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))), delay(() => {
                    let elems_6;
                    return (props.CurrentUser.Role === "administrator") ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-center"])], (elems_6 = [createElement(Button, {
                        ComponentState: "enabled",
                        Label: t("test_phase.become_customer"),
                        OnClick: () => {
                            RouterModule_nav(ofArray(["customer", "wizard"]), 1, 1);
                        },
                        TestId: "",
                        Variant: "primary",
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))) : empty();
                }));
            }));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])));
}

