import { Union, Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { union_type, class_type, record_type, string_type, lambda_type, unit_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { SupportMessageDto, SupportMessageResponse_$reflection, SupportMessageDto_$reflection } from "../Shared/Support.js";
import { PostResponse$1_$reflection } from "../Shared/Shared.js";
import { Cmd_OfPromise_either } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../Communication.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { singleton, ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_successToast } from "../Components/Toast.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { useTranslation } from "react-i18next";
import { Dialog } from "../Components/Dialog.js";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { isNullOrWhiteSpace, isNullOrEmpty, join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { TextValue } from "../Components/Text.js";
import { IconButton, TextButton, Button } from "../Components/Button.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { TextArea } from "../Components/TextArea.js";
import { Input } from "../Components/Input.js";
import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.2.7.0/React.fs.js";
import Help_Chat_2 from "../../public/assets/icons/Help_Chat_2.svg";

class State extends Record {
    constructor(OnClose, Dto, FormState) {
        super();
        this.OnClose = OnClose;
        this.Dto = Dto;
        this.FormState = FormState;
    }
}

function State_$reflection() {
    return record_type("Widgets.SupportButton.State", [], State, () => [["OnClose", lambda_type(unit_type, unit_type)], ["Dto", SupportMessageDto_$reflection()], ["FormState", string_type]]);
}

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SupportMessageResponse", "SendSupportMessage", "SetMessage", "SetPhoneNumber", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Widgets.SupportButton.Msg", [], Msg, () => [[["Item", PostResponse$1_$reflection(SupportMessageResponse_$reflection())]], [], [["Item", string_type]], [["Item", string_type]], [["Item", class_type("System.Exception")]]]);
}

function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [new State(state.OnClose, state.Dto, "disabled"), Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response_1) => {
                const pr = response_1.arrayBuffer();
                return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(SupportMessageResponse_$reflection())));
            })))), ["/api/support/message", toString(0, Auto_generateBoxedEncoder_437914C6(SupportMessageDto_$reflection(), undefined, undefined, undefined)(state.Dto))], (Item) => (new Msg(0, [Item])), (Item_1) => (new Msg(4, [Item_1])))];
        case 2:
            return [new State(state.OnClose, new SupportMessageDto(state.Dto.PhoneNumber, msg.fields[0]), state.FormState), Cmd_none()];
        case 3:
            return [new State(state.OnClose, new SupportMessageDto(msg.fields[0], state.Dto.Message), state.FormState), Cmd_none()];
        case 4:
            return [state, Cmd_none()];
        default:
            return [new State(state.OnClose, state.Dto, "enabled"), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                state.OnClose();
            }), Cmd_successToast("support.message_sent")]))];
    }
}

function init(onClose) {
    return [new State(onClose, new SupportMessageDto("", ""), "enabled"), Cmd_none()];
}

export function SupportDialog(props) {
    let Title, elems_6, elems, elems_2, elems_1, elems_3, elems_5, elems_4, Label_5;
    let patternInput;
    const init_1 = init(props.OnClose);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const t = useTranslation()[0];
    const phoneNumber = t("global.phone_number");
    return createElement(Dialog, (Title = t("general.support_and_help"), {
        Body: singleton(createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-[1fr,1fr]", "gap-y-5", "gap-x-8", "max-w-2xl"])], (elems_6 = [createElement(TextValue, {
            FontWeight: "normal",
            TestId: "",
            Text: t("support.help_description"),
        }), createElement(TextValue, {
            FontWeight: "normal",
            TestId: "",
            Text: t("support.meeting_message"),
        }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "items-start"])], (elems = [createElement(Button, {
            ComponentState: "enabled",
            Label: t("support.help_button"),
            OnClick: () => {
                window.open("https://support.inventory-one.com/help/de-de", "_blank", "noreferrer");
            },
            TestId: "open-help-button-test-id",
            Variant: "primary",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(singleton((elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "items-center"])], (elems_1 = [createElement(Button, {
            ComponentState: "enabled",
            Label: t("support.meeting_button"),
            OnClick: () => {
                window.open("https://meet.inventory-one.com/team/inventory-one-gmbh/support", "_blank", "noreferrer");
            },
            TestId: "send-support-meeting-dialog-button-test-id",
            Variant: "primary",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])))), createElement("div", {
            className: join(" ", ["col-span-2", "border", "border-border"]),
        }), createElement(TextValue, {
            FontWeight: "normal",
            TestId: "",
            Text: t("support.phone_message"),
        }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "items-center", "text-primary", "font-bold", "text-xl"])], (elems_3 = [createElement("a", {
            className: join(" ", []),
            href: `tel:${phoneNumber}`,
            children: phoneNumber,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("div", {
            className: join(" ", ["col-span-2", "border", "border-border"]),
        }), createElement("div", createObj(ofArray([["className", join(" ", ["col-span-2", "flex", "flex-col", "gap-4"])], (elems_5 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "gap-1", "items-center"])], (elems_4 = [createElement(TextValue, {
            FontWeight: "normal",
            TestId: "",
            Text: t("support.send_message"),
        }), createElement("a", {
            className: join(" ", ["font-semibold", "text-sm", "text-primary"]),
            href: "mailto:support@inventory-one.com",
            children: "support@inventory-one.com",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement(TextArea, {
            ComponentState: state_1.FormState,
            Label: t("support.message"),
            OnChange: (value_25) => {
                dispatch(new Msg(2, [value_25]));
            },
            TestId: "",
            Value: state_1.Dto.Message,
        }), createElement(Input, {
            ComponentState: state_1.FormState,
            Label: t("support.your_phonenumber"),
            OnChange: (value_26) => {
                dispatch(new Msg(3, [value_26]));
            },
            TestId: "",
            Value: state_1.Dto.PhoneNumber,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: state_1.FormState,
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-support-dialog-button-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, (Label_5 = t("support.send"), {
            ComponentState: (isNullOrEmpty(state_1.Dto.Message) ? true : isNullOrWhiteSpace(state_1.Dto.Message)) ? "disabled" : state_1.FormState,
            Label: Label_5,
            OnClick: () => {
                dispatch(new Msg(1, []));
            },
            TestId: "send-support-dialog-button-test-id",
            Variant: "blueButton",
        }))]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

export function SupportButton() {
    let elems;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setIsSupportDialogOpen = patternInput[1];
    return createElement("div", createObj(singleton((elems = [createElement(SupportDialog, {
        IsOpen: patternInput[0],
        OnClose: () => {
            setIsSupportDialogOpen(false);
        },
    }), createElement(IconButton, {
        ComponentState: "enabled",
        Icon: Help_Chat_2(),
        OnClick: () => {
            setIsSupportDialogOpen(true);
        },
        TestId: "open-support-dialog-button",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]))));
}

