import { Union, Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { bool_type, anonRecord_type, list_type, union_type, record_type, class_type, option_type, float64_type, string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { Label_$reflection, CommercialDataFormDto_$reflection, ReminderDate_$reflection, Document$_$reflection, LabelId_$reflection, ReminderDateDto_$reflection, CommercialData_$reflection, ReservationState_$reflection, ReservationGroupId_$reflection, ConsumableId_$reflection, ConsumableReservationId_$reflection, StorageId_$reflection } from "./Shared.js";
import { CustomProperty_$reflection, CustomPropertyValueDto_$reflection } from "./PropertyConfiguration.js";
import { printf, toText } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { filter, sumBy } from "../fable_modules/fable-library-js.4.19.2/List.js";

export class StorageToUserConsumableAssignment extends Record {
    constructor(ReceiverId, Receiver, SenderId, Sender, StorageId, Storage, Quantity, CommissionNumber, Comment$, SignatureUrl, Timestamp) {
        super();
        this.ReceiverId = ReceiverId;
        this.Receiver = Receiver;
        this.SenderId = SenderId;
        this.Sender = Sender;
        this.StorageId = StorageId;
        this.Storage = Storage;
        this.Quantity = Quantity;
        this.CommissionNumber = CommissionNumber;
        this.Comment = Comment$;
        this.SignatureUrl = SignatureUrl;
        this.Timestamp = Timestamp;
    }
}

export function StorageToUserConsumableAssignment_$reflection() {
    return record_type("Shared.Consumable.StorageToUserConsumableAssignment", [], StorageToUserConsumableAssignment, () => [["ReceiverId", string_type], ["Receiver", string_type], ["SenderId", string_type], ["Sender", string_type], ["StorageId", string_type], ["Storage", string_type], ["Quantity", float64_type], ["CommissionNumber", string_type], ["Comment", string_type], ["SignatureUrl", option_type(string_type)], ["Timestamp", class_type("System.DateTime")]]);
}

export class StorageToSubContractorConsumableAssignment extends Record {
    constructor(ReceiverId, Receiver, SenderId, Sender, StorageId, Storage, SignatureUrl, Quantity, Comment$, CommissionNumber, Timestamp) {
        super();
        this.ReceiverId = ReceiverId;
        this.Receiver = Receiver;
        this.SenderId = SenderId;
        this.Sender = Sender;
        this.StorageId = StorageId;
        this.Storage = Storage;
        this.SignatureUrl = SignatureUrl;
        this.Quantity = Quantity;
        this.Comment = Comment$;
        this.CommissionNumber = CommissionNumber;
        this.Timestamp = Timestamp;
    }
}

export function StorageToSubContractorConsumableAssignment_$reflection() {
    return record_type("Shared.Consumable.StorageToSubContractorConsumableAssignment", [], StorageToSubContractorConsumableAssignment, () => [["ReceiverId", string_type], ["Receiver", string_type], ["SenderId", string_type], ["Sender", string_type], ["StorageId", string_type], ["Storage", string_type], ["SignatureUrl", option_type(string_type)], ["Quantity", float64_type], ["Comment", string_type], ["CommissionNumber", string_type], ["Timestamp", class_type("System.DateTime")]]);
}

export class ReturnConsumableToStorage extends Record {
    constructor(SenderId, Sender, ReceiverId, Receiver, StorageId, Storage, Quantity, Comment$, CommissionNumber, Timestamp) {
        super();
        this.SenderId = SenderId;
        this.Sender = Sender;
        this.ReceiverId = ReceiverId;
        this.Receiver = Receiver;
        this.StorageId = StorageId;
        this.Storage = Storage;
        this.Quantity = Quantity;
        this.Comment = Comment$;
        this.CommissionNumber = CommissionNumber;
        this.Timestamp = Timestamp;
    }
}

export function ReturnConsumableToStorage_$reflection() {
    return record_type("Shared.Consumable.ReturnConsumableToStorage", [], ReturnConsumableToStorage, () => [["SenderId", string_type], ["Sender", string_type], ["ReceiverId", string_type], ["Receiver", string_type], ["StorageId", string_type], ["Storage", string_type], ["Quantity", float64_type], ["Comment", string_type], ["CommissionNumber", string_type], ["Timestamp", class_type("System.DateTime")]]);
}

export class UpdatedConsumableStock extends Record {
    constructor(SenderId, Sender, StorageId, Storage, Quantity, Comment$, Timestamp) {
        super();
        this.SenderId = SenderId;
        this.Sender = Sender;
        this.StorageId = StorageId;
        this.Storage = Storage;
        this.Quantity = Quantity;
        this.Comment = Comment$;
        this.Timestamp = Timestamp;
    }
}

export function UpdatedConsumableStock_$reflection() {
    return record_type("Shared.Consumable.UpdatedConsumableStock", [], UpdatedConsumableStock, () => [["SenderId", string_type], ["Sender", string_type], ["StorageId", string_type], ["Storage", string_type], ["Quantity", float64_type], ["Comment", string_type], ["Timestamp", class_type("System.DateTime")]]);
}

export class UpdatedConsumableStorage extends Record {
    constructor(SenderId, Sender, StorageId, Storage, Quantity, Timestamp) {
        super();
        this.SenderId = SenderId;
        this.Sender = Sender;
        this.StorageId = StorageId;
        this.Storage = Storage;
        this.Quantity = Quantity;
        this.Timestamp = Timestamp;
    }
}

export function UpdatedConsumableStorage_$reflection() {
    return record_type("Shared.Consumable.UpdatedConsumableStorage", [], UpdatedConsumableStorage, () => [["SenderId", string_type], ["Sender", string_type], ["StorageId", string_type], ["Storage", string_type], ["Quantity", float64_type], ["Timestamp", class_type("System.DateTime")]]);
}

export class ConsumableEvent extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["StorageToUserAssignment", "StorageToSubcontractorAssignment", "ReturnToStorage", "UpdatedStock", "UpdatedStorage"];
    }
}

export function ConsumableEvent_$reflection() {
    return union_type("Shared.Consumable.ConsumableEvent", [], ConsumableEvent, () => [[["Item", StorageToUserConsumableAssignment_$reflection()]], [["Item", StorageToSubContractorConsumableAssignment_$reflection()]], [["Item", ReturnConsumableToStorage_$reflection()]], [["Item", UpdatedConsumableStock_$reflection()]], [["Item", UpdatedConsumableStorage_$reflection()]]]);
}

export class ConsumableStockPerStorage extends Record {
    constructor(StorageId, StorageSpace, Stock, MinimumStockQuantity, QRCodeId, QRCodeIds) {
        super();
        this.StorageId = StorageId;
        this.StorageSpace = StorageSpace;
        this.Stock = Stock;
        this.MinimumStockQuantity = MinimumStockQuantity;
        this.QRCodeId = QRCodeId;
        this.QRCodeIds = QRCodeIds;
    }
}

export function ConsumableStockPerStorage_$reflection() {
    return record_type("Shared.Consumable.ConsumableStockPerStorage", [], ConsumableStockPerStorage, () => [["StorageId", StorageId_$reflection()], ["StorageSpace", string_type], ["Stock", float64_type], ["MinimumStockQuantity", option_type(float64_type)], ["QRCodeId", string_type], ["QRCodeIds", list_type(string_type)]]);
}

export class StockPerStorageDto extends Record {
    constructor(StorageId, StorageName, StorageSpace, Stock, MinimumStockQuantity, QRCodeIds, Location) {
        super();
        this.StorageId = StorageId;
        this.StorageName = StorageName;
        this.StorageSpace = StorageSpace;
        this.Stock = Stock;
        this.MinimumStockQuantity = MinimumStockQuantity;
        this.QRCodeIds = QRCodeIds;
        this.Location = Location;
    }
}

export function StockPerStorageDto_$reflection() {
    return record_type("Shared.Consumable.StockPerStorageDto", [], StockPerStorageDto, () => [["StorageId", string_type], ["StorageName", string_type], ["StorageSpace", string_type], ["Stock", float64_type], ["MinimumStockQuantity", option_type(float64_type)], ["QRCodeIds", list_type(string_type)], ["Location", option_type(anonRecord_type(["Id", string_type], ["Name", string_type]))]]);
}

export class ConsumableReservation extends Record {
    constructor(Id, Name, StartDate, EndDate, ReceiverId, Receiver, SenderId, Sender, ConsumableId, Description, StorageId, Quantity, GroupId, State) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.ReceiverId = ReceiverId;
        this.Receiver = Receiver;
        this.SenderId = SenderId;
        this.Sender = Sender;
        this.ConsumableId = ConsumableId;
        this.Description = Description;
        this.StorageId = StorageId;
        this.Quantity = Quantity;
        this.GroupId = GroupId;
        this.State = State;
    }
}

export function ConsumableReservation_$reflection() {
    return record_type("Shared.Consumable.ConsumableReservation", [], ConsumableReservation, () => [["Id", ConsumableReservationId_$reflection()], ["Name", string_type], ["StartDate", class_type("System.DateTime")], ["EndDate", class_type("System.DateTime")], ["ReceiverId", string_type], ["Receiver", string_type], ["SenderId", string_type], ["Sender", string_type], ["ConsumableId", ConsumableId_$reflection()], ["Description", string_type], ["StorageId", StorageId_$reflection()], ["Quantity", float64_type], ["GroupId", option_type(ReservationGroupId_$reflection())], ["State", ReservationState_$reflection()]]);
}

export class ConsumableReservationFormDto extends Record {
    constructor(Id, StartDate, EndDate, Name, ReceiverId, Receiver, SenderId, Sender, ConsumableId, NotifyUser, Description, StorageId, Quantity) {
        super();
        this.Id = Id;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.Name = Name;
        this.ReceiverId = ReceiverId;
        this.Receiver = Receiver;
        this.SenderId = SenderId;
        this.Sender = Sender;
        this.ConsumableId = ConsumableId;
        this.NotifyUser = NotifyUser;
        this.Description = Description;
        this.StorageId = StorageId;
        this.Quantity = Quantity;
    }
}

export function ConsumableReservationFormDto_$reflection() {
    return record_type("Shared.Consumable.ConsumableReservationFormDto", [], ConsumableReservationFormDto, () => [["Id", string_type], ["StartDate", class_type("System.DateTime")], ["EndDate", class_type("System.DateTime")], ["Name", option_type(string_type)], ["ReceiverId", string_type], ["Receiver", string_type], ["SenderId", string_type], ["Sender", string_type], ["ConsumableId", string_type], ["NotifyUser", option_type(bool_type)], ["Description", string_type], ["StorageId", string_type], ["Quantity", float64_type]]);
}

export class ConsumableDetailReservationDto extends Record {
    constructor(Id, Name, Description, StartDate, EndDate, Quantity, StorageName, ReceiverInformation, SenderInformation) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Description = Description;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.Quantity = Quantity;
        this.StorageName = StorageName;
        this.ReceiverInformation = ReceiverInformation;
        this.SenderInformation = SenderInformation;
    }
}

export function ConsumableDetailReservationDto_$reflection() {
    return record_type("Shared.Consumable.ConsumableDetailReservationDto", [], ConsumableDetailReservationDto, () => [["Id", string_type], ["Name", string_type], ["Description", string_type], ["StartDate", class_type("System.DateTime")], ["EndDate", class_type("System.DateTime")], ["Quantity", float64_type], ["StorageName", string_type], ["ReceiverInformation", anonRecord_type(["ReceiverId", string_type], ["ReceiverName", string_type])], ["SenderInformation", anonRecord_type(["ReceiverId", string_type], ["SenderName", string_type])]]);
}

export class ConsumableDetailDto extends Record {
    constructor(Id, ImageUrl, MasterData, Unit, StockPerStorage, Categories, CommercialData, CustomProperties, Reminders, Reservations) {
        super();
        this.Id = Id;
        this.ImageUrl = ImageUrl;
        this.MasterData = MasterData;
        this.Unit = Unit;
        this.StockPerStorage = StockPerStorage;
        this.Categories = Categories;
        this.CommercialData = CommercialData;
        this.CustomProperties = CustomProperties;
        this.Reminders = Reminders;
        this.Reservations = Reservations;
    }
}

export function ConsumableDetailDto_$reflection() {
    return record_type("Shared.Consumable.ConsumableDetailDto", [], ConsumableDetailDto, () => [["Id", string_type], ["ImageUrl", string_type], ["MasterData", anonRecord_type(["AdditionalName", string_type], ["Description", string_type], ["InventoryNumber", string_type], ["Name", string_type])], ["Unit", string_type], ["StockPerStorage", list_type(StockPerStorageDto_$reflection())], ["Categories", list_type(anonRecord_type(["CategoryId", string_type], ["CategoryName", string_type], ["ColorHex", string_type]))], ["CommercialData", CommercialData_$reflection()], ["CustomProperties", list_type(CustomPropertyValueDto_$reflection())], ["Reminders", list_type(ReminderDateDto_$reflection())], ["Reservations", list_type(ConsumableDetailReservationDto_$reflection())]]);
}

export class Consumable extends Record {
    constructor(Id, Name, AdditionalName, Description, Unit, ImageUrl, StockPerStorage, Events, LabelIds, CommercialData, Documents, InventoryNumber, CustomProperties, IsDeleted, ReminderDates, Reservations, CreatedAt, UpdatedAt) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.AdditionalName = AdditionalName;
        this.Description = Description;
        this.Unit = Unit;
        this.ImageUrl = ImageUrl;
        this.StockPerStorage = StockPerStorage;
        this.Events = Events;
        this.LabelIds = LabelIds;
        this.CommercialData = CommercialData;
        this.Documents = Documents;
        this.InventoryNumber = InventoryNumber;
        this.CustomProperties = CustomProperties;
        this.IsDeleted = IsDeleted;
        this.ReminderDates = ReminderDates;
        this.Reservations = Reservations;
        this.CreatedAt = CreatedAt;
        this.UpdatedAt = UpdatedAt;
    }
}

export function Consumable_$reflection() {
    return record_type("Shared.Consumable.Consumable", [], Consumable, () => [["Id", ConsumableId_$reflection()], ["Name", string_type], ["AdditionalName", string_type], ["Description", string_type], ["Unit", string_type], ["ImageUrl", string_type], ["StockPerStorage", list_type(ConsumableStockPerStorage_$reflection())], ["Events", list_type(ConsumableEvent_$reflection())], ["LabelIds", list_type(LabelId_$reflection())], ["CommercialData", CommercialData_$reflection()], ["Documents", list_type(Document$_$reflection())], ["InventoryNumber", string_type], ["CustomProperties", list_type(CustomProperty_$reflection())], ["IsDeleted", bool_type], ["ReminderDates", list_type(ReminderDate_$reflection())], ["Reservations", list_type(ConsumableReservation_$reflection())], ["CreatedAt", class_type("System.DateTime")], ["UpdatedAt", class_type("System.DateTime")]]);
}

export class ConsumableFormStockPerStorage extends Record {
    constructor(StorageId, StorageSpace, Stock, MinimumStockQuantity, QRCodeIds) {
        super();
        this.StorageId = StorageId;
        this.StorageSpace = StorageSpace;
        this.Stock = Stock;
        this.MinimumStockQuantity = MinimumStockQuantity;
        this.QRCodeIds = QRCodeIds;
    }
}

export function ConsumableFormStockPerStorage_$reflection() {
    return record_type("Shared.Consumable.ConsumableFormStockPerStorage", [], ConsumableFormStockPerStorage, () => [["StorageId", string_type], ["StorageSpace", string_type], ["Stock", float64_type], ["MinimumStockQuantity", option_type(float64_type)], ["QRCodeIds", list_type(string_type)]]);
}

export class ConsumableCreateFormDto extends Record {
    constructor(Name, AdditionalName, Description, Unit, ImageUrl, StockPerStorage, CategoryIds, CommercialData, InventoryNumber, CustomProperties) {
        super();
        this.Name = Name;
        this.AdditionalName = AdditionalName;
        this.Description = Description;
        this.Unit = Unit;
        this.ImageUrl = ImageUrl;
        this.StockPerStorage = StockPerStorage;
        this.CategoryIds = CategoryIds;
        this.CommercialData = CommercialData;
        this.InventoryNumber = InventoryNumber;
        this.CustomProperties = CustomProperties;
    }
}

export function ConsumableCreateFormDto_$reflection() {
    return record_type("Shared.Consumable.ConsumableCreateFormDto", [], ConsumableCreateFormDto, () => [["Name", string_type], ["AdditionalName", string_type], ["Description", string_type], ["Unit", string_type], ["ImageUrl", string_type], ["StockPerStorage", list_type(ConsumableFormStockPerStorage_$reflection())], ["CategoryIds", list_type(string_type)], ["CommercialData", CommercialDataFormDto_$reflection()], ["InventoryNumber", string_type], ["CustomProperties", list_type(CustomProperty_$reflection())]]);
}

export class ConsumableUpdateFormDto extends Record {
    constructor(Name, AdditionalName, Description, Unit, ImageUrl, CategoryIds, CommercialData, InventoryNumber, CustomProperties) {
        super();
        this.Name = Name;
        this.AdditionalName = AdditionalName;
        this.Description = Description;
        this.Unit = Unit;
        this.ImageUrl = ImageUrl;
        this.CategoryIds = CategoryIds;
        this.CommercialData = CommercialData;
        this.InventoryNumber = InventoryNumber;
        this.CustomProperties = CustomProperties;
    }
}

export function ConsumableUpdateFormDto_$reflection() {
    return record_type("Shared.Consumable.ConsumableUpdateFormDto", [], ConsumableUpdateFormDto, () => [["Name", string_type], ["AdditionalName", string_type], ["Description", string_type], ["Unit", string_type], ["ImageUrl", string_type], ["CategoryIds", list_type(string_type)], ["CommercialData", CommercialDataFormDto_$reflection()], ["InventoryNumber", string_type], ["CustomProperties", list_type(CustomProperty_$reflection())]]);
}

export class ConsumableResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Created", "Updated", "StorageIdNotSet", "TooManyConsumablesInSystem", "Error"];
    }
}

export function ConsumableResponse_$reflection() {
    return union_type("Shared.Consumable.ConsumableResponse", [], ConsumableResponse, () => [[["Item", Consumable_$reflection()]], [["Item", Consumable_$reflection()]], [], [], []]);
}

export class ConsumableDto extends Record {
    constructor(Id, StringId, Name, AdditionalName, InventoryNumber, Description, Storage, StorageId, StorageSpace, StorageStock, Unit, QRCodeIds, ImageUrl, Supplier, PurchasePrice, SupplierArticleNumber, CommercialDataComment, WarrantyDate, PurchaseDate, Labels, CustomProperties) {
        super();
        this.Id = Id;
        this.StringId = StringId;
        this.Name = Name;
        this.AdditionalName = AdditionalName;
        this.InventoryNumber = InventoryNumber;
        this.Description = Description;
        this.Storage = Storage;
        this.StorageId = StorageId;
        this.StorageSpace = StorageSpace;
        this.StorageStock = StorageStock;
        this.Unit = Unit;
        this.QRCodeIds = QRCodeIds;
        this.ImageUrl = ImageUrl;
        this.Supplier = Supplier;
        this.PurchasePrice = PurchasePrice;
        this.SupplierArticleNumber = SupplierArticleNumber;
        this.CommercialDataComment = CommercialDataComment;
        this.WarrantyDate = WarrantyDate;
        this.PurchaseDate = PurchaseDate;
        this.Labels = Labels;
        this.CustomProperties = CustomProperties;
    }
}

export function ConsumableDto_$reflection() {
    return record_type("Shared.Consumable.ConsumableDto", [], ConsumableDto, () => [["Id", ConsumableId_$reflection()], ["StringId", string_type], ["Name", string_type], ["AdditionalName", string_type], ["InventoryNumber", string_type], ["Description", string_type], ["Storage", string_type], ["StorageId", string_type], ["StorageSpace", string_type], ["StorageStock", string_type], ["Unit", string_type], ["QRCodeIds", string_type], ["ImageUrl", string_type], ["Supplier", string_type], ["PurchasePrice", string_type], ["SupplierArticleNumber", string_type], ["CommercialDataComment", string_type], ["WarrantyDate", option_type(class_type("System.DateTime"))], ["PurchaseDate", option_type(class_type("System.DateTime"))], ["Labels", list_type(Label_$reflection())], ["CustomProperties", list_type(CustomProperty_$reflection())]]);
}

export class ConsumableHistoryRow extends Record {
    constructor(Id, ConsumableId, EventType, EventDate, ConsumableName, AdditionalName, CommissionNumber, Comment$, Quantity, StorageName, Receiver, SignatureUrl, Sender, CustomProperties) {
        super();
        this.Id = Id;
        this.ConsumableId = ConsumableId;
        this.EventType = EventType;
        this.EventDate = EventDate;
        this.ConsumableName = ConsumableName;
        this.AdditionalName = AdditionalName;
        this.CommissionNumber = CommissionNumber;
        this.Comment = Comment$;
        this.Quantity = Quantity;
        this.StorageName = StorageName;
        this.Receiver = Receiver;
        this.SignatureUrl = SignatureUrl;
        this.Sender = Sender;
        this.CustomProperties = CustomProperties;
    }
}

export function ConsumableHistoryRow_$reflection() {
    return record_type("Shared.Consumable.ConsumableHistoryRow", [], ConsumableHistoryRow, () => [["Id", string_type], ["ConsumableId", ConsumableId_$reflection()], ["EventType", string_type], ["EventDate", class_type("System.DateTime")], ["ConsumableName", string_type], ["AdditionalName", string_type], ["CommissionNumber", string_type], ["Comment", string_type], ["Quantity", string_type], ["StorageName", string_type], ["Receiver", string_type], ["SignatureUrl", option_type(string_type)], ["Sender", string_type], ["CustomProperties", list_type(CustomPropertyValueDto_$reflection())]]);
}

export class ConsumableHistoryDto extends Record {
    constructor(Timestamp, EventType, Quantity, ReceiverInformation, SenderInformation, StorageInformation, Comment$, CommissionNumber, Id) {
        super();
        this.Timestamp = Timestamp;
        this.EventType = EventType;
        this.Quantity = Quantity;
        this.ReceiverInformation = ReceiverInformation;
        this.SenderInformation = SenderInformation;
        this.StorageInformation = StorageInformation;
        this.Comment = Comment$;
        this.CommissionNumber = CommissionNumber;
        this.Id = Id;
    }
}

export function ConsumableHistoryDto_$reflection() {
    return record_type("Shared.Consumable.ConsumableHistoryDto", [], ConsumableHistoryDto, () => [["Timestamp", class_type("System.DateTime")], ["EventType", string_type], ["Quantity", float64_type], ["ReceiverInformation", option_type(anonRecord_type(["Id", string_type], ["Name", string_type], ["SignatureUrl", option_type(string_type)]))], ["SenderInformation", anonRecord_type(["Id", string_type], ["Name", string_type])], ["StorageInformation", option_type(anonRecord_type(["Id", string_type], ["Name", string_type]))], ["Comment", string_type], ["CommissionNumber", string_type], ["Id", string_type]]);
}

export class ConsumableReminderUpdateResult extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["Succesful"];
    }
}

export function ConsumableReminderUpdateResult_$reflection() {
    return union_type("Shared.Consumable.ConsumableReminderUpdateResult", [], ConsumableReminderUpdateResult, () => [[]]);
}

export class ConsumableReservationResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Created", "Updated", "NotEnoughStockForDateRange"];
    }
}

export function ConsumableReservationResponse_$reflection() {
    return union_type("Shared.Consumable.ConsumableReservationResponse", [], ConsumableReservationResponse, () => [[], [], []]);
}

export class MultipleReservationConsumableReservationDto extends Record {
    constructor(ConsumableId, StorageId, Quantity) {
        super();
        this.ConsumableId = ConsumableId;
        this.StorageId = StorageId;
        this.Quantity = Quantity;
    }
}

export function MultipleReservationConsumableReservationDto_$reflection() {
    return record_type("Shared.Consumable.MultipleReservationConsumableReservationDto", [], MultipleReservationConsumableReservationDto, () => [["ConsumableId", string_type], ["StorageId", string_type], ["Quantity", float64_type]]);
}

export class ConsumablesMultipleReservationDto extends Record {
    constructor(Id, StartDate, EndDate, Name, Description, ReceiverId, SenderId, ReservedConsumables, NotifyUser) {
        super();
        this.Id = Id;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.Name = Name;
        this.Description = Description;
        this.ReceiverId = ReceiverId;
        this.SenderId = SenderId;
        this.ReservedConsumables = ReservedConsumables;
        this.NotifyUser = NotifyUser;
    }
}

export function ConsumablesMultipleReservationDto_$reflection() {
    return record_type("Shared.Consumable.ConsumablesMultipleReservationDto", [], ConsumablesMultipleReservationDto, () => [["Id", string_type], ["StartDate", class_type("System.DateTime")], ["EndDate", class_type("System.DateTime")], ["Name", string_type], ["Description", string_type], ["ReceiverId", string_type], ["SenderId", string_type], ["ReservedConsumables", list_type(MultipleReservationConsumableReservationDto_$reflection())], ["NotifyUser", bool_type]]);
}

export class MultipleConsumableReservationResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Created", "Updated", "NotEnoughStockForDateRange"];
    }
}

export function MultipleConsumableReservationResponse_$reflection() {
    return union_type("Shared.Consumable.MultipleConsumableReservationResponse", [], MultipleConsumableReservationResponse, () => [[], [], [["consumableIds", list_type(string_type)]]]);
}

export class AcceptConsumableNotificationDto extends Record {
    constructor(SignatureUrl, Comment$, CommissionNumber) {
        super();
        this.SignatureUrl = SignatureUrl;
        this.Comment = Comment$;
        this.CommissionNumber = CommissionNumber;
    }
}

export function AcceptConsumableNotificationDto_$reflection() {
    return record_type("Shared.Consumable.AcceptConsumableNotificationDto", [], AcceptConsumableNotificationDto, () => [["SignatureUrl", option_type(string_type)], ["Comment", option_type(string_type)], ["CommissionNumber", option_type(string_type)]]);
}

export function Helper_timestamp(_arg) {
    switch (_arg.tag) {
        case 1:
            return _arg.fields[0].Timestamp;
        case 2:
            return _arg.fields[0].Timestamp;
        case 3:
            return _arg.fields[0].Timestamp;
        case 4:
            return _arg.fields[0].Timestamp;
        default:
            return _arg.fields[0].Timestamp;
    }
}

export function Helper_commissionNumber(_arg) {
    switch (_arg.tag) {
        case 1:
            return _arg.fields[0].CommissionNumber;
        case 2:
            return _arg.fields[0].CommissionNumber;
        case 3:
            return "";
        case 4:
            return "";
        default:
            return _arg.fields[0].CommissionNumber;
    }
}

export function Helper_comment(_arg) {
    switch (_arg.tag) {
        case 1:
            return _arg.fields[0].Comment;
        case 2:
            return _arg.fields[0].Comment;
        case 3:
            return _arg.fields[0].Comment;
        case 4:
            return "";
        default:
            return _arg.fields[0].Comment;
    }
}

export function Helper_quantity(_arg) {
    switch (_arg.tag) {
        case 1:
            return _arg.fields[0].Quantity;
        case 2:
            return _arg.fields[0].Quantity;
        case 3:
            return _arg.fields[0].Quantity;
        case 4:
            return _arg.fields[0].Quantity;
        default:
            return _arg.fields[0].Quantity;
    }
}

export function Helper_storageId(_arg) {
    switch (_arg.tag) {
        case 1:
            return _arg.fields[0].StorageId;
        case 2:
            return _arg.fields[0].StorageId;
        case 3:
            return _arg.fields[0].StorageId;
        case 4:
            return _arg.fields[0].StorageId;
        default:
            return _arg.fields[0].StorageId;
    }
}

export function Helper_storage(_arg) {
    switch (_arg.tag) {
        case 1:
            return _arg.fields[0].Storage;
        case 2:
            return _arg.fields[0].Storage;
        case 3:
            return _arg.fields[0].Storage;
        case 4:
            return _arg.fields[0].Storage;
        default:
            return _arg.fields[0].Storage;
    }
}

export function Helper_receiver(_arg) {
    switch (_arg.tag) {
        case 1:
            return _arg.fields[0].Receiver;
        case 2:
            return _arg.fields[0].Receiver;
        case 3:
            return undefined;
        case 4:
            return undefined;
        default:
            return _arg.fields[0].Receiver;
    }
}

export function Helper_receiverId(_arg) {
    switch (_arg.tag) {
        case 1:
            return _arg.fields[0].ReceiverId;
        case 2:
            return _arg.fields[0].ReceiverId;
        case 3:
            return undefined;
        case 4:
            return undefined;
        default:
            return _arg.fields[0].ReceiverId;
    }
}

export function Helper_sender(_arg) {
    switch (_arg.tag) {
        case 1:
            return _arg.fields[0].Sender;
        case 2:
            return _arg.fields[0].Sender;
        case 3:
            return _arg.fields[0].Sender;
        case 4:
            return _arg.fields[0].Sender;
        default:
            return _arg.fields[0].Sender;
    }
}

export function Helper_senderId(_arg) {
    switch (_arg.tag) {
        case 1:
            return _arg.fields[0].SenderId;
        case 2:
            return _arg.fields[0].SenderId;
        case 3:
            return _arg.fields[0].SenderId;
        case 4:
            return _arg.fields[0].SenderId;
        default:
            return _arg.fields[0].SenderId;
    }
}

export function Helper_signatureUrl(_arg) {
    switch (_arg.tag) {
        case 1:
            return _arg.fields[0].SignatureUrl;
        case 2:
        case 3:
        case 4:
            return undefined;
        default:
            return _arg.fields[0].SignatureUrl;
    }
}

export function Helper_typeToTranslationString(_arg) {
    switch (_arg.tag) {
        case 1:
            return "event.withdrawal";
        case 2:
            return "event.return";
        case 3:
            return "event.stock_change";
        case 4:
            return "event.storage_change";
        default:
            return "event.withdrawal";
    }
}

export function Helper_typeToHistoryEvent(_arg) {
    switch (_arg.tag) {
        case 1:
            return "storageToSubcontractorAssignment";
        case 2:
            return "returnToStorage";
        case 3:
            return "updatedStock";
        case 4:
            return "updatedStorage";
        default:
            return "storageToUserAssignment";
    }
}

export function Helper_historyTypeToTranslationString(_arg) {
    switch (_arg) {
        case "storageToSubcontractorAssignment":
            return "event.withdrawal";
        case "returnToStorage":
            return "event.return";
        case "updatedStock":
            return "event.stock_change";
        case "updatedStorage":
            return "event.storage_change";
        default:
            return "event.withdrawal";
    }
}

export function Helper_unitToHumanString(_arg) {
    switch (_arg) {
        case "package":
            return "Packungen";
        case "pair":
            return "Paar";
        case "millimeter":
            return "Millimeter";
        case "centimeter":
            return "Zentimeter";
        case "meter":
            return "Meter";
        case "gram":
            return "Gramm";
        case "kilogram":
            return "Kilogramm";
        case "milliliter":
            return "Milliliter";
        case "liter":
            return "Liter";
        case "squaremetre":
            return "Quadratmeter";
        case "cubicmetre":
            return "Kubikmeter";
        case "roll":
            return "Rolle";
        case "flatRate":
            return "Pauschal";
        case "pallet":
            return "Palette";
        case "bunch":
            return "Bund";
        case "woodenBox":
            return "Holzbox";
        case "latticeBox":
            return "Gitterbox";
        default:
            return "Stück";
    }
}

export function Helper_unitToString(_arg) {
    switch (_arg) {
        case "package":
            return "package";
        case "pair":
            return "pair";
        case "millimeter":
            return "millimeter";
        case "centimeter":
            return "centimeter";
        case "meter":
            return "meter";
        case "gram":
            return "gram";
        case "kilogram":
            return "kilogram";
        case "milliliter":
            return "milliliter";
        case "liter":
            return "liter";
        case "squaremetre":
            return "squaremetre";
        case "cubicmetre":
            return "cubicmetre";
        case "roll":
            return "roll";
        case "flatRate":
            return "flatrate";
        case "pallet":
            return "pallet";
        case "bunch":
            return "bunch";
        case "woodenBox":
            return "woodenbox";
        case "latticeBox":
            return "latticebox";
        default:
            return "piece";
    }
}

export function Helper_unitFromString(unit) {
    switch (unit) {
        case "piece":
            return "piece";
        case "package":
            return "package";
        case "pair":
            return "pair";
        case "millimeter":
            return "millimeter";
        case "centimeter":
            return "centimeter";
        case "meter":
            return "meter";
        case "gram":
            return "gram";
        case "kilogram":
            return "kilogram";
        case "milliliter":
            return "milliliter";
        case "liter":
            return "liter";
        case "squaremetre":
            return "squaremetre";
        case "cubicmetre":
            return "cubicmetre";
        case "roll":
            return "roll";
        case "flatrate":
            return "flatRate";
        case "pallet":
            return "pallet";
        case "bunch":
            return "bunch";
        case "woodenbox":
            return "woodenBox";
        case "latticebox":
            return "latticeBox";
        default:
            throw new Error(toText(printf("Invalid unit %s"))(unit));
    }
}

export function Helper_unitToTranslationKey(_arg) {
    switch (_arg) {
        case "package":
            return "unit.package";
        case "pair":
            return "unit.pair";
        case "millimeter":
            return "unit.millimeter";
        case "centimeter":
            return "unit.centimeter";
        case "meter":
            return "unit.meter";
        case "gram":
            return "unit.gram";
        case "kilogram":
            return "unit.kilogram";
        case "milliliter":
            return "unit.milliliter";
        case "liter":
            return "unit.liter";
        case "squaremetre":
            return "unit.squaremetre";
        case "cubicmetre":
            return "unit.cubicmetre";
        case "roll":
            return "unit.roll";
        case "flatRate":
            return "unit.flatrate";
        case "pallet":
            return "unit.pallet";
        case "bunch":
            return "unit.bunch";
        case "woodenBox":
            return "unit.woodenbox";
        case "latticeBox":
            return "unit.latticebox";
        default:
            return "unit.piece";
    }
}

export function Helper_currentUserStock(events, userId) {
    return sumBy((event) => {
        switch (event.tag) {
            case 1: {
                const event_2 = event.fields[0];
                if (event_2.ReceiverId === userId) {
                    return event_2.Quantity;
                }
                else {
                    return 0;
                }
            }
            case 2:
            case 3:
            case 4:
                return 0;
            default: {
                const event_1 = event.fields[0];
                if (event_1.ReceiverId === userId) {
                    return event_1.Quantity;
                }
                else {
                    return 0;
                }
            }
        }
    }, events, {
        GetZero: () => 0,
        Add: (x, y) => (x + y),
    }) - sumBy((event_3) => {
        switch (event_3.tag) {
            case 1:
            case 0:
            case 3:
            case 4:
                return 0;
            default: {
                const event_4 = event_3.fields[0];
                if (event_4.SenderId === userId) {
                    return event_4.Quantity;
                }
                else {
                    return 0;
                }
            }
        }
    }, events, {
        GetZero: () => 0,
        Add: (x_1, y_1) => (x_1 + y_1),
    });
}

export function Helper_currentReceiverStock(receiverId, events) {
    return sumBy((event_3) => {
        switch (event_3.tag) {
            case 0:
                return event_3.fields[0].Quantity;
            case 2:
            case 3:
            case 4:
                return 0;
            default:
                return event_3.fields[0].Quantity;
        }
    }, filter((event) => {
        switch (event.tag) {
            case 0:
                return event.fields[0].ReceiverId === receiverId;
            case 2:
            case 3:
            case 4:
                return false;
            default:
                return event.fields[0].ReceiverId === receiverId;
        }
    }, events), {
        GetZero: () => 0,
        Add: (x, y) => (x + y),
    }) - sumBy((event_8) => {
        switch (event_8.tag) {
            case 1:
            case 0:
            case 3:
            case 4:
                return 0;
            default:
                return event_8.fields[0].Quantity;
        }
    }, filter((event_6) => {
        switch (event_6.tag) {
            case 1:
            case 0:
            case 3:
            case 4:
                return false;
            default:
                return event_6.fields[0].SenderId === receiverId;
        }
    }, events), {
        GetZero: () => 0,
        Add: (x_1, y_1) => (x_1 + y_1),
    });
}

