import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { record_type, lambda_type, unit_type, bool_type, union_type, class_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { UpdateStorageSpaceDto, StorageId_$reflection, ConsumableId_$reflection, UpdateStorageSpaceDto_$reflection, PostResponse$1_$reflection, EmptyResponse_$reflection } from "../../Shared/Shared.js";
import { Cmd_ofEffect, Cmd_batch, Cmd_none, Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { singleton, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { Cmd_successToast } from "../../Components/Toast.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { Input } from "../../Components/Input.js";
import { Interop_reactApi } from "../../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Dialog } from "../../Components/Dialog.js";
import { TextButton } from "../../Components/Button.js";

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetStorageSpace", "StorageSpaceChangedResponse", "ChangeStorageSpace", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Consumables.ChangeStorageSpaceDialog.Msg", [], Msg, () => [[["Item", string_type]], [["Item", PostResponse$1_$reflection(EmptyResponse_$reflection())]], [], [["Item", class_type("System.Exception")]]]);
}

function postStorageSpaceChangeCmd(consumableId, dto) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(EmptyResponse_$reflection())));
    })))), [`/api/consumables/${consumableId.fields[0]}/storagespace`, toString(0, Auto_generateBoxedEncoder_437914C6(UpdateStorageSpaceDto_$reflection(), undefined, undefined, undefined)(dto))], (Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(3, [Item_1])));
}

class State extends Record {
    constructor(ConsumableId, StorageId, StorageSpace, IsEditableForUser, SuccessCallback, OnClose, Dto, IsLoading) {
        super();
        this.ConsumableId = ConsumableId;
        this.StorageId = StorageId;
        this.StorageSpace = StorageSpace;
        this.IsEditableForUser = IsEditableForUser;
        this.SuccessCallback = SuccessCallback;
        this.OnClose = OnClose;
        this.Dto = Dto;
        this.IsLoading = IsLoading;
    }
}

function State_$reflection() {
    return record_type("Consumables.ChangeStorageSpaceDialog.State", [], State, () => [["ConsumableId", ConsumableId_$reflection()], ["StorageId", StorageId_$reflection()], ["StorageSpace", string_type], ["IsEditableForUser", bool_type], ["SuccessCallback", lambda_type(unit_type, unit_type)], ["OnClose", lambda_type(unit_type, unit_type)], ["Dto", UpdateStorageSpaceDto_$reflection()], ["IsLoading", bool_type]]);
}

function init(props) {
    return [new State(props.ConsumableId, props.StorageId, props.StorageSpace, props.IsEditableForUser, props.SuccessCallback, props.OnClose, new UpdateStorageSpaceDto(props.StorageId, props.StorageSpace), false), Cmd_none()];
}

function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [new State(state.ConsumableId, state.StorageId, state.StorageSpace, state.IsEditableForUser, state.SuccessCallback, state.OnClose, state.Dto, false), Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                state.SuccessCallback();
            }), Cmd_successToast("consumable.dialog_storage_space_change_success"), Cmd_ofEffect((_arg_1) => {
                state.OnClose();
            })]))];
        case 2:
            return [new State(state.ConsumableId, state.StorageId, state.StorageSpace, state.IsEditableForUser, state.SuccessCallback, state.OnClose, state.Dto, false), postStorageSpaceChangeCmd(state.ConsumableId, state.Dto)];
        case 3:
            return [new State(state.ConsumableId, state.StorageId, state.StorageSpace, state.IsEditableForUser, state.SuccessCallback, state.OnClose, state.Dto, false), Cmd_none()];
        default:
            return [new State(state.ConsumableId, state.StorageId, state.StorageSpace, state.IsEditableForUser, state.SuccessCallback, state.OnClose, new UpdateStorageSpaceDto(state.Dto.StorageId, msg.fields[0]), state.IsLoading), Cmd_none()];
    }
}

export function DialogBody(props) {
    let elems;
    const patternInput = useTranslation();
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "gap-4"])], (elems = [createElement(Input, {
        ComponentState: props.FormState,
        Label: patternInput[0]("consumable.storage_space"),
        OnChange: props.SetStorageSpace,
        TestId: "",
        Value: props.Dto.StorageSpace,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function ChangeStorageSpaceDialog(props) {
    let Title;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init({
        ConsumableId: props.ConsumableId,
        IsEditableForUser: props.IsEditableForUser,
        OnClose: props.OnClose,
        StorageId: props.StorageId,
        StorageSpace: props.StorageSpace,
        SuccessCallback: props.SuccessCallback,
    });
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.IsOpen]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    const formState = state_1.IsLoading ? "disabled" : "enabled";
    return createElement(Dialog, (Title = t("consumable.change_storage_space"), {
        Body: singleton(createElement(DialogBody, {
            Dto: state_1.Dto,
            FormState: formState,
            SetStorageSpace: (value) => {
                dispatch(new Msg(0, [value]));
            },
        })),
        Controls: ofArray([createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.cancel"),
            OnClick: props.OnClose,
            TestId: "close-change-storage-space-dialog-test-id",
            Variant: "blueButton",
        }), createElement(TextButton, {
            ComponentState: formState,
            Label: t("general.save"),
            OnClick: () => {
                dispatch(new Msg(2, []));
            },
            TestId: "save-change-storage-space-dialog-test-id",
            Variant: "blueButton",
        })]),
        IsOpen: props.IsOpen,
        OnClose: props.OnClose,
        Title: Title,
    }));
}

