import { Record, Union } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { class_type, record_type, string_type, union_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { Customer_$reflection } from "../Shared/Shared.js";
import { User_$reflection } from "../Shared/User.js";

export class ShowMoreLessState extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ShowLess", "ShowMore"];
    }
}

export function ShowMoreLessState_$reflection() {
    return union_type("Dashboard.Types.ShowMoreLessState", [], ShowMoreLessState, () => [[], []]);
}

export class DashboardArea extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ProblemReports", "Appointments", "MinimumQuantity", "ReturnDate", "Reservations"];
    }
}

export function DashboardArea_$reflection() {
    return union_type("Dashboard.Types.DashboardArea", [], DashboardArea, () => [[], [], [], [], []]);
}

export class State extends Record {
    constructor(DisplayName, Customer, ShowMoreProblemReports, ShowMoreAppointments, ShowMoreMinimumQuantityReminder, ShowMoreToolReturnDates, ShowMoreReservations) {
        super();
        this.DisplayName = DisplayName;
        this.Customer = Customer;
        this.ShowMoreProblemReports = ShowMoreProblemReports;
        this.ShowMoreAppointments = ShowMoreAppointments;
        this.ShowMoreMinimumQuantityReminder = ShowMoreMinimumQuantityReminder;
        this.ShowMoreToolReturnDates = ShowMoreToolReturnDates;
        this.ShowMoreReservations = ShowMoreReservations;
    }
}

export function State_$reflection() {
    return record_type("Dashboard.Types.State", [], State, () => [["DisplayName", string_type], ["Customer", Customer_$reflection()], ["ShowMoreProblemReports", ShowMoreLessState_$reflection()], ["ShowMoreAppointments", ShowMoreLessState_$reflection()], ["ShowMoreMinimumQuantityReminder", ShowMoreLessState_$reflection()], ["ShowMoreToolReturnDates", ShowMoreLessState_$reflection()], ["ShowMoreReservations", ShowMoreLessState_$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ChangeShowMoreLessState", "UserFetched", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Dashboard.Types.Msg", [], Msg, () => [[["Item", DashboardArea_$reflection()]], [["Item", User_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

