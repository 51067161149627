import { Union } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { union_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { createElement } from "react";
import React from "react";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { ofArray, singleton as singleton_1 } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";

export class Width extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Small", "Medium", "Large"];
    }
}

export function Width_$reflection() {
    return union_type("Components.Skeleton.Width", [], Width, () => [[], [], []]);
}

function SingleLine(props) {
    return createElement("div", {
        className: join(" ", toList(delay(() => append(singleton("h-4"), delay(() => append(singleton("bg-skeleton-grey"), delay(() => append(singleton("rounded-full"), delay(() => {
            let matchValue;
            return append((matchValue = props.Width, (matchValue.tag === 1) ? singleton("w-80") : ((matchValue.tag === 2) ? singleton("w-96") : singleton("w-64"))), delay(() => singleton("mb-4")));
        }))))))))),
    });
}

function Image() {
    let elems_2, elements_1, elements, path;
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "items-center", "justify-center", "w-full", "h-48", "bg-skeleton-grey", "rounded", "sm:w-96"])], (elems_2 = [createElement("svg", createObj(ofArray([["className", join(" ", ["w-10", "h-10", "text-skeleton-grey"])], ["xmlns", "http://www.w3.org/2000/svg"], ["fill", "currentColor"], ["viewBox", (((((0 + " ") + 0) + " ") + 20) + " ") + 18], (elements_1 = singleton_1(createElement("clipPath", createObj(ofArray([["id", "clip"], (elements = singleton_1(createElement("path", createObj(singleton_1((path = "M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z", ["d", path]))))), ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])))), ["children", Interop_reactApi.Children.toArray(Array.from(elements_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

function Normal() {
    let elems;
    return createElement("div", createObj(ofArray([["role", join(" ", ["status"])], ["className", join(" ", ["max-w-sm", "animate-pulse"])], (elems = [createElement(SingleLine, {
        Width: new Width(2, []),
    }), createElement(SingleLine, {
        Width: new Width(1, []),
    }), createElement(SingleLine, {
        Width: new Width(0, []),
    }), createElement("span", {
        className: join(" ", ["sr-only"]),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

function Detail() {
    let elems_1, elems;
    return createElement("div", createObj(ofArray([["role", join(" ", ["status"])], ["className", join(" ", ["space-y-8", "animate-pulse", "md:space-y-0", "md:space-x-8", "md:flex", "md:items-center"])], (elems_1 = [createElement(Image, null), createElement("div", createObj(ofArray([["className", join(" ", ["w-full"])], (elems = [createElement(SingleLine, {
        Width: new Width(2, []),
    }), createElement(SingleLine, {
        Width: new Width(1, []),
    }), createElement(SingleLine, {
        Width: new Width(0, []),
    }), createElement("span", {
        className: join(" ", ["sr-only"]),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

function Table() {
    let elems_1, elems;
    return createElement("div", createObj(ofArray([["role", join(" ", ["status"])], ["className", join(" ", ["space-y-8", "animate-pulse", "md:space-y-0", "md:space-x-8", "md:flex", "md:items-center"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["w-full"])], (elems = [createElement(SingleLine, {
        Width: new Width(2, []),
    }), createElement(SingleLine, {
        Width: new Width(2, []),
    }), createElement(SingleLine, {
        Width: new Width(2, []),
    }), createElement(SingleLine, {
        Width: new Width(2, []),
    }), createElement(SingleLine, {
        Width: new Width(2, []),
    }), createElement("span", {
        className: join(" ", ["sr-only"]),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function Skeleton(props) {
    let elems;
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-shrink-0", "flex-col", "mb-3", "bg-white", "rounded", "px-12", "py-9", "max-w-7xl"])], (elems = toList(delay(() => {
        const matchValue = props.Variant;
        return (matchValue === "normal") ? singleton(createElement(Normal, null)) : ((matchValue === "table") ? singleton(createElement(Table, null)) : singleton(createElement(Detail, null)));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

