import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import Information_Circle from "../../public/assets/icons/Information_Circle.svg";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";
import Check_Circle from "../../public/assets/icons/Check_Circle.svg";
import Delete_Circle from "../../public/assets/icons/Delete_Circle.svg";

function Alert(props) {
    let elems_8, elems_4, elems_7, elems_5;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["data-test-id", props.AlertType], ["className", join(" ", toList(delay(() => {
        let matchValue;
        return append((matchValue = props.AlertType, (matchValue === "hint") ? singleton("bg-[#FEFCE8]") : ((matchValue === "success") ? singleton("bg-[#EFFEF4]") : ((matchValue === "error") ? singleton("bg-light-red") : singleton("bg-[#EFF6FF]")))), delay(() => append(singleton("min-w-85"), delay(() => append(singleton("text-sm"), delay(() => append(singleton("flex"), delay(() => append(singleton("flex-row"), delay(() => append(singleton("p-3.5"), delay(() => singleton("rounded")))))))))))));
    })))], (elems_8 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col"])], (elems_4 = toList(delay(() => {
        let elems_1, elems_2, elems_3, elems;
        const matchValue_1 = props.AlertType;
        return (matchValue_1 === "hint") ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mr-3.5", "mt-0.5", "h-3.5", "w-3.5", "text-icon-orange", "stroke-2"])], (elems_1 = [Information_Circle()], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))) : ((matchValue_1 === "success") ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mr-3.5", "mt-0.5", "h-3.5", "w-3.5", "text-icon-green", "stroke-2"])], (elems_2 = [Check_Circle()], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))) : ((matchValue_1 === "error") ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mr-3.5", "mt-0.5", "h-3.5", "w-3.5", "text-icon-red", "stroke-2"])], (elems_3 = [Delete_Circle()], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))) : singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mr-3.5", "mt-0.5", "h-3.5", "w-3.5", "text-icon-blue", "stroke-2"])], (elems = [Information_Circle()], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-2.5"])], (elems_7 = [createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => {
        let matchValue_2;
        return append((matchValue_2 = props.AlertType, (matchValue_2 === "hint") ? singleton("text-[#854E0E]") : ((matchValue_2 === "success") ? singleton("text-[#1E6939]") : ((matchValue_2 === "error") ? singleton("text-[#981B1B]") : singleton("text-[#284CB1]")))), delay(() => singleton("font-medium")));
    })))], (elems_5 = toList(delay(() => {
        const matchValue_3 = props.AlertType;
        return (matchValue_3 === "hint") ? singleton(t("general.warning")) : ((matchValue_3 === "information") ? singleton(t("general.information")) : ((matchValue_3 === "success") ? singleton(t("general.success")) : singleton(t("general.error"))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))), createElement("div", {
        className: join(" ", toList(delay(() => {
            let matchValue_4;
            return append((matchValue_4 = props.AlertType, (matchValue_4 === "hint") ? singleton("text-[#A16309]") : ((matchValue_4 === "success") ? singleton("text-[#17813E]") : ((matchValue_4 === "error") ? singleton("text-[#B91C1B]") : singleton("text-[#315CD5]")))), delay(() => append(singleton("flex"), delay(() => append(singleton("flex-col"), delay(() => append(singleton("mb-5.5"), delay(() => append(singleton("leading-5"), delay(() => append(singleton("font-normal"), delay(() => singleton("text-sm")))))))))))));
        }))),
        children: Interop_reactApi.Children.toArray([props.Content]),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])));
}

export function InformationAlert(props) {
    return createElement(Alert, {
        AlertType: "information",
        Content: useTranslation()[0](props.Label),
    });
}

export function ErrorAlert(props) {
    return createElement(Alert, {
        AlertType: "error",
        Content: useTranslation()[0](props.Label),
    });
}

export function WarningAlert(props) {
    return createElement(Alert, {
        AlertType: "hint",
        Content: useTranslation()[0](props.Label),
    });
}

export function SuccessAlert(props) {
    return createElement(Alert, {
        AlertType: "success",
        Content: useTranslation()[0](props.Label),
    });
}

